import React, { useState } from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { IconCrossL } from '../../../icons';
import { Tooltip } from '../../../components';
import css from '../EditUserInfoModal.css';

const TooltipSectionInfo = ({ headingId, descriptionId, sidenoteId }) => {
    const [tooltipVisible, setTooltipVisibility] = useState(false);

    return (
        <>
            <Tooltip
                tooltipClassName={css.sideNoteTooltip}
                targetClassName={css.sideNotTooltipTarget}
                suppressOutsideClick
                isStatic
                staticTooltipState={tooltipVisible}
                staticTooltipStateHandler={value => setTooltipVisibility(value)}
                position="top"
                content={
                    <>
                        <p>
                            <FormattedMessage id={headingId} />
                        </p>
                        <p>
                            <FormattedMessage id={descriptionId} />
                        </p>
                        <IconCrossL clickHandler={() => setTooltipVisibility(false)} />
                    </>
                }
            >
                <p
                    className={css.sideNote}
                    onClick={() => setTooltipVisibility(prevState => !prevState)}
                >
                    <img src="https://horsedeal.imgix.net/static/icons/icon-info-m-Ground-Ground-040.svg" />
                    <FormattedMessage id={sidenoteId} />
                </p>
            </Tooltip>
        </>
    );
};

export default TooltipSectionInfo;
