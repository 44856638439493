import React from 'react';
/** icon-check-circle-l */
export default ({ rootClassName = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        className={rootClassName}
    >
        <path
            d="M11.842 0.304 C 6.403 0.831,1.940 4.662,0.635 9.923 C 0.353 11.061,0.287 11.646,0.287 13.000 C 0.287 14.354,0.353 14.939,0.635 16.077 C 1.770 20.653,5.347 24.230,9.923 25.365 C 11.061 25.647,11.646 25.713,13.000 25.713 C 14.256 25.713,14.870 25.652,15.813 25.433 C 18.024 24.920,19.986 23.890,21.614 22.388 C 23.546 20.606,24.835 18.386,25.433 15.813 C 25.652 14.870,25.713 14.256,25.713 13.000 C 25.713 11.744,25.652 11.130,25.433 10.187 C 24.919 7.975,23.890 6.014,22.386 4.384 C 20.380 2.208,17.769 0.826,14.842 0.389 C 14.149 0.286,12.512 0.240,11.842 0.304 M14.619 1.867 C 15.697 2.022,16.793 2.352,17.816 2.829 C 20.098 3.893,22.107 5.902,23.171 8.184 C 24.612 11.276,24.612 14.724,23.171 17.816 C 22.107 20.098,20.098 22.107,17.816 23.171 C 14.186 24.863,10.043 24.554,6.738 22.346 C 4.101 20.584,2.343 17.793,1.859 14.597 C 1.725 13.713,1.725 12.287,1.859 11.403 C 2.234 8.927,3.303 6.802,5.052 5.052 C 6.735 3.370,8.804 2.297,11.122 1.905 C 12.241 1.716,13.485 1.703,14.619 1.867 M17.658 8.313 C 17.587 8.357,16.176 10.103,14.523 12.193 C 12.871 14.284,11.502 15.989,11.481 15.981 C 11.460 15.974,11.032 15.383,10.528 14.668 C 10.025 13.953,9.553 13.305,9.479 13.228 C 9.223 12.962,8.967 12.946,8.606 13.175 C 8.249 13.401,8.137 13.753,8.315 14.086 C 8.429 14.297,10.294 16.936,10.436 17.087 C 10.499 17.153,10.674 17.269,10.826 17.343 C 11.063 17.460,11.149 17.479,11.454 17.482 C 11.884 17.485,12.178 17.393,12.432 17.176 C 12.602 17.030,18.318 9.839,18.622 9.388 C 18.879 9.007,18.787 8.617,18.380 8.358 C 18.144 8.209,17.859 8.191,17.658 8.313 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
