import React from 'react';
import { useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { Form, IconSpinner } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import OneSignalSubscriptionCheckBox from './OneSignalSubscriptionCheckBox';
import SendGridCheckboxGroup from './SendGridCheckboxGroup';
import css from './NotificationsControlForm.css';

export default () => {
    const currentUser = useSelector(s => ensureCurrentUser(s.user.currentUser));
    const currentUserDefined = Boolean(currentUser && currentUser.id);

    return currentUserDefined ? (
        <FinalForm
            onSubmit={() => null}
            initialValues={{}}
            currentUser={currentUser}
            render={({ currentUser, values, form, handleSubmit, unsubscribeGroups }) => (
                <Form onSubmit={handleSubmit} className={css.form}>
                    <h4 className={css.heading}>Push</h4>
                    <OneSignalSubscriptionCheckBox form={form} currentUser={currentUser} />
                    <h4 className={css.heading}>E-Mail</h4>
                    <SendGridCheckboxGroup
                        form={form}
                        values={values}
                        currentUser={currentUser}
                        unsubscribeGroups={unsubscribeGroups}
                    />
                </Form>
            )}
        />
    ) : (
        <IconSpinner />
    );
};
