import React from 'react';
import { FieldRadioButton, IconSpinner } from '../../components';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import { useAssets } from '../../hooks/useAssets';
import { HorseImage } from './HorseImage';
import css from './SwitchHorse.css';

export const HorseRadio = ({ listing, assetsLoadingRequests, form }) => {
    const {
        id: { uuid: id },
        attributes: { title },
    } = listing;

    const loading = assetsLoadingRequests && assetsLoadingRequests[id];

    const images = useAssets({
        listing,
        allowedTypes: ['image'],
        variant: SQUARE_SMALL_2X,
        useCachedResults: true,
    });

    if (loading) {
        return (
            <div>
                <IconSpinner />
            </div>
        );
    }

    return (
        <FieldRadioButton
            form={form}
            id={id}
            value={id}
            name="mainHorseId"
            label={
                <div className={css.label}>
                    <HorseImage images={images} />
                    {title}
                </div>
            }
        />
    );
};
