import React, { useRef } from 'react';
import { CircleStencil, FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';
import { FormattedMessage } from '../../util/reactIntl';
import { ModalPortal, Button } from '../../components';
import { Slider } from './Slider';
import ZoomInIcon from './ZoomInIcon';
import ZoomOutIcon from './ZoomOutIcon';
import IconCheckmark from './IconCheckmark';
import css from './UploadUserAvatar.css';

const isNumber = value => typeof value === 'number' && !Number.isNaN(parseInt(value));

export default ({ src, open, zoom, setZoom, onClose, onImageUpload }) => {
    const cropperRef = useRef();

    const resolveAbsoluteZoomFromRef = () =>
        cropperRef && cropperRef.current
            ? getAbsoluteZoom(cropperRef.current.getState(), cropperRef.current.getSettings())
            : undefined;

    const onUpdate = async () => {
        if (!cropperRef || !cropperRef.current) {
            return;
        }
        const { current } = cropperRef;

        const canvas = current.getCanvas();
        const formData = new FormData();

        canvas.toBlob(async function(blob) {
            formData.append('file', blob, 'avatar.jpeg');

            await onImageUpload({
                id: `user_avatar_${Date.now()}`,
                file: formData.get('file'),
            });
        });
    };

    const onZoom = (value, transitions) => {
        if (cropperRef && cropperRef.current) {
            const { current } = cropperRef;

            current.zoomImage(getZoomFactor(current.getState(), current.getSettings(), value), {
                transitions,
            });

            setZoom(value);
        }
    };

    const onZoomIn = () => {
        const absoluteZoom = resolveAbsoluteZoomFromRef();

        if (onZoom && isNumber(absoluteZoom)) {
            onZoom(Math.min(1, absoluteZoom + 0.125), true);
        }
    };

    const onZoomOut = () => {
        const absoluteZoom = resolveAbsoluteZoomFromRef();

        if (onZoom && isNumber(absoluteZoom)) {
            onZoom(Math.max(0, absoluteZoom - 0.125), true);
        }
    };

    return !open ? null : (
        <ModalPortal
            id="EditUserAvatar"
            isOpen
            onClose={onClose}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
        >
            <h3 className={css.editorHeading}>Cheese!</h3>
            <ul className={css.instructions}>
                <li>
                    <p>
                        <IconCheckmark /> Nutze keine Effekte
                    </p>
                </li>
                <li>
                    <p>
                        <IconCheckmark /> Zeige nur dein Gesicht
                    </p>
                </li>
                <li>
                    <p>
                        <IconCheckmark /> Schaue in die Kamera
                    </p>
                </li>
            </ul>
            <div className={css.editorHolder}>
                <FixedCropper
                    ref={cropperRef}
                    src={src}
                    stencilComponent={CircleStencil}
                    imageRestriction={ImageRestriction.stencil}
                    stencilSize={{
                        width: 250,
                        height: 250,
                    }}
                    stencilProps={{
                        handlers: false,
                        lines: false,
                        movable: false,
                        resizable: false,
                    }}
                    onChange={({ getState, getSettings }) => {
                        const absoluteZoom = getAbsoluteZoom(getState(), getSettings());
                        setZoom(absoluteZoom);
                    }}
                />
            </div>
            <div className={css.sliderSection}>
                <Slider value={zoom} onChange={onZoom} />
            </div>
            <div className={css.zoomSection}>
                <div onClick={onZoomOut}>
                    <ZoomOutIcon />
                </div>
                <div onClick={onZoomIn}>
                    <ZoomInIcon />
                </div>
            </div>
            <Button
                onClick={() => {
                    onUpdate(cropperRef);
                    onClose();
                }}
                className={css.actionButton}
            >
                <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-avatar" />
            </Button>
        </ModalPortal>
    );
};
