import React from 'react';
import { TopbarContainer } from '../../containers';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
} from '../../components';
import NotificationsControlForm from './NotificationsControlForm';
import css from './NotificationsControlPage.css';

export default () => (
    <Page title="Notifications control page">
        <LayoutSideNavigation
            rootClassName={css.sideNavigationLayout}
            containerClassName={css.containerClassName}
            joinContainerClasses
        >
            <LayoutWrapperTopbar>
                <TopbarContainer
                    currentPage="NotificationsControlPage"
                    desktopClassName={css.desktopTopbar}
                    mobileClassName={css.mobileTopbar}
                />
            </LayoutWrapperTopbar>
            <LayoutWrapperAccountSettingsSideNav currentTab="NotificationsControlPage" />
            <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                <div className={css.content}>
                    <h1 className={css.title}>Push & E-Mail</h1>
                    <NotificationsControlForm />
                </div>
            </LayoutWrapperMain>
            <LayoutWrapperFooter className={css.footer}>
                <Footer />
            </LayoutWrapperFooter>
        </LayoutSideNavigation>
    </Page>
);
