import {
    appStoreIAPProductsDictionary,
    LISTING_PACKAGE_ANONYM_LISTING_ID,
    LISTING_PACKAGE_SOCIAL_BOOST_ID,
} from '../marketplace-custom-config';
import { CREDITS_PACKAGE_1, CREDITS_PACKAGE_2, CREDITS_PACKAGE_3 } from '../credits-config';
import { initUsercentrics } from './scripts';

export const WEB_VIEW = 'ReactNativeWebView';

export const checkIOS = () => {
    if (typeof window !== 'object') return {};

    // https://stackoverflow.com/a/29696509
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const safari = !!ua.match(/version\/\d+.\d+.\d+ safari/i);
    const webkit = !!ua.match(/WebKit/i);
    /** native app */
    const webView = !!ua.match(/wv/i) || !!ua.match(/webview/i);

    // If iOS Chrome needs to be separated, use `!ua.match(/CriOS/i)` as
    // an extra condition.
    return { iOS, webkit, webView, safari, isNative: !!window[WEB_VIEW] };
};

export const isNonNativeIOS = () => {
    const { isNative, iOS, webkit } = checkIOS();
    return !isNative && iOS && webkit;
};

export const isNativeIOS = () => {
    const { iOS, isNative } = checkIOS();
    return iOS && isNative;
};

export const adaptToSafariTime = dateString =>
    dateString ? dateString.replace(/ /, 'T') : dateString;

export const adaptIOSFormField = ({ disabled }) => {
    const { iOS, safari } = checkIOS();
    if (!iOS && !safari) return {};
    /** fix UI issue related to an no-value input field when being disabled */
    if (disabled) {
        return {
            disabled: false,
            readOnly: 'readOnly',
            onFocus: e => e,
            onBlur: e => e,
            tabindex: '-1',
            style: { pointerEvents: 'none' },
        };
    }
    return {};
};
/**
 * Get marketplace product id from in app purchase product id
 *
 * @param {string} productId - in app purchase product id
 * @returns
 */
export const getMarketplaceProductId = productId => {
    /** e.g. { consumable_credits_30: p_c_30 } */
    const IAPtoMarketplaceIdsDictionary = Object.entries(appStoreIAPProductsDictionary).reduce(
        (acc, [key, value]) => ({ ...acc, [value]: key }),
        {}
    );

    return IAPtoMarketplaceIdsDictionary[productId]; // e.g.'p_c_30'
};
export const postWebViewMessage = message =>
    typeof window !== 'undefined' && window[WEB_VIEW].postMessage(message);

/**
 * Handle credits purchase for native ios app
 *
 * @param {*} packageId - CREDITS_PACKAGE_1,2,3
 * @returns void
 */
export const postIAPMessage = (packageId, onError = () => null) => {
    const iapProductId = appStoreIAPProductsDictionary[packageId];

    if (!iapProductId) return onError();

    postWebViewMessage(iapProductId);
};
/**
 * - Check if the Ios app has to be notified on successful login
 * - Notify app about the event
 * - The app logs in a user in the OS system via id & userType
 *
 * @param {object} currentUser - sdk current user
 * @returns void
 */
export const notifyIosAppOnLogInSubscriptionEnabled = currentUser => {
    if (!currentUser || !currentUser.id) return;

    const {
        id: { uuid },
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = currentUser;
    
    const email = currentUser?.attributes?.email;
    const shouldNotify = email === 'benjamin.kroeni@horsedeal.com';
    if(shouldNotify) {
        alert(`LoginUserInOS:${userType}-${uuid}`)
        }
    return postWebViewMessage(`LoginUserInOS:${userType}-${uuid}`);
};
/**
 * - Check if the Ios app has to be notified on successful login
 * - Notify app about the event
 * - The app shows the subscription modal
 *
 * @param {object} currentUser - sdk current user
 * @returns void
 */
export const notifyIosAppOnLogInNoSubscription = () => postWebViewMessage('ShowOSPrompt');

/**
 * For IOS, WebView only
 * @param {object} e - browser event
 */
export const addIosWvGlobalListeners = e => {
    try {
        const messageData = typeof e.data === 'string' ? JSON.parse(e.data) : {};

        if (messageData && String(messageData.isTracking) === 'true') {
            /**
             * Initiate usercentrics only when a user clicks the "Allow"
             * button in the ios Allow Tracking modal window
             */
            initUsercentrics();
        }
    } catch (e) {
        // to do
    }
};
/**
 * Check if event comes from WebView and holds
 * data needed for In app purchase
 *
 * @param {object} e - browser event
 */
export const checkIAPTransactionMessage = e => {
    try {
        const messageData = typeof e.data === 'string' ? JSON.parse(e.data) : {};

        if (messageData && messageData.transactionId && messageData.productId) {
            return messageData;
        }

        if (messageData && messageData.purchaseFailed) {
            return {
                error: true,
                reason: messageData.purchaseFailed,
            };
        }

        return null;
    } catch (e) {
        // to do
    }
};

export const checkIfCreditsProduct = id =>
    id === CREDITS_PACKAGE_1 || id === CREDITS_PACKAGE_2 || id === CREDITS_PACKAGE_3;

export const checkIfHorsePackagesProduct = id =>
    id === LISTING_PACKAGE_SOCIAL_BOOST_ID || id === LISTING_PACKAGE_ANONYM_LISTING_ID;
