import React from 'react';
import css from './InvitesModal.css';
import { IconCheckCircleXXL } from '../../icons';
import { ModalPortal, Button } from '../../components';

/**
 * Show this modal in case a rider invites
 * other people at the last step of the OF
 */
export default ({ onClose, isOpen }) => (
    <ModalPortal
        id="rider.invites.user.people"
        isOpen={isOpen}
        onClose={onClose}
        contentClassName={css.modalContent}
    >
        <IconCheckCircleXXL />
        <h3>Einladungen gesendet!</h3>
        <p>Dein Einladung wurde erfolgreich an die Empfänger:innen gesendet.</p>
        <Button type="button" onClick={onClose}>
            Schliessen
        </Button>
    </ModalPortal>
);
