import React from 'react';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDER_AVAILABILITY_NOT_CONFIRMED,
} from '../../../../marketplace-custom-config';
import { useListings } from '../../../../hooks/useListings';
import { resolveUserLatLng } from '../../../../util/user';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { IconSpinner, ListingCardsCarouselWithMatchingScore } from '../../../../components';
import { getVisibleSlidesByViewport } from '../../../../components/ListingCardsCarousel/helpers';
import config from '../../../../config';
import css from './SectionBestSuitsYouHorses.css';

const { listingTypeHorse } = config;

export default ({ currentUser }) => {
    const [isMobile, , computing, viewport] = useIsMobile(768);
    const {
        attributes: {
            profile: {
                publicData: { availabilityStatus },
                protectedData: { representationListingId },
            },
        },
    } = currentUser;

    const userLocationData = resolveUserLatLng(currentUser);
    const userAvailable =
        availabilityStatus === RIDER_AVAILABILITY_CONFIRMED ||
        availabilityStatus === RIDER_AVAILABILITY_NOT_CONFIRMED;

    const [listings, loading] = useListings({
        addDataToMarketplace: true,
        params: {
            per_page: 10,
            origin: userLocationData,
            pub_type: listingTypeHorse,
        },
        allowed: userAvailable && representationListingId,
    });

    const dataAvailable = viewport && Array.isArray(listings) && listings.length > 0;

    if (loading || computing) return <IconSpinner />;

    const carouselHolderClass =
        dataAvailable && listings.length === 2 ? css.carouselHolderTwoColumn : null;

    return dataAvailable ? (
        <section className={css.root}>
            <ListingCardsCarouselWithMatchingScore
                sliderClassName={css.sliderRoot}
                isMobile={isMobile}
                listings={listings}
                riderListing={{ id: { uuid: representationListingId } }}
                minMatchingRate={69}
                isLoggedIn
                hideButtonsOnEndStart
                sliderVisibleSlides={getVisibleSlidesByViewport(viewport).visibleSlidesByViewport}
                carouselHolderClassName={carouselHolderClass}
                header={
                    <>
                        <h2 className={css.heading}>Passen am besten zu dir</h2>
                        <p>Bei diesen Pferden hast Du die besten Chancen.</p>
                    </>
                }
            />
        </section>
    ) : null;
};
