import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { ensureCurrentUser } from '../../util/data';
import {
    useOneSignal,
    EVENT_OS_NOTIFICATIONS_LOGIN_SUCCESS,
    EVENT_OS_NOTIFICATIONS_LOGIN_FAILED,
} from '../../services/oneSignal';
/**
 * Listen to OS login event being dispatched;
 * update user tag with userType;
 * call a custom callback
 */
export const useOneSignalLoginEvents = ({
    allowed = true,
    callback = () => null,
    errorHandler,
}) => {
    const store = useStore();
    const oneSignal = useOneSignal();

    useEffect(() => {
        if (!allowed) return;

        const user = ensureCurrentUser(store.getState().user.currentUser);

        const onSuccess = () => {
            const { userType } = user.attributes.profile.publicData;
            // update user tag with userType
            oneSignal.addTag('userType', userType);
            // custom callback
            callback();
        };
        // array of unsubscribe callbacks
        const unsubscribes = [
            [EVENT_OS_NOTIFICATIONS_LOGIN_SUCCESS, onSuccess],
            [EVENT_OS_NOTIFICATIONS_LOGIN_FAILED, errorHandler],
        ].map(([e, c]) => oneSignal.subscribe(e, c));

        return () => unsubscribes.forEach(c => c()); // Cleanup on unmount
    }, [allowed]);
};
