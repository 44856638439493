import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { SecondaryButton, Form } from '../../components';
import { IconPen } from '../../icons';
import config from '../../config';
import css from './DeleteAccountForm.css';

const { userTypeHorseowner } = config;

export default ({ userType, onSubmit }) => (
    <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={css.form}>
                <h4 className={css.heading}>Mitgliedschaft</h4>
                <div className={css.contentBox}>
                    <div className={css.contentUpperRow}>
                        <h4>Freemium</h4>
                        <div className={css.contentEdit}>
                            <IconPen />
                            <p>Ändern</p>
                        </div>
                    </div>
                </div>
                <h4 className={css.heading}>Konto löschen</h4>
                <p className={css.description}>
                    Du möchtest dein Konto löschen? Bevor Du weiterfährst, stelle bitte Folgendes
                    sicher:
                </p>
                <ul>
                    <li>
                        Dein Kontostand ist ausgeglichen, d.h. es besteht kein Saldo zu unseren
                        Gunsten.
                    </li>
                    <li>Du hast keine ausstehenden Anfragen.</li>
                    {userType === userTypeHorseowner && <li>Du hast keine aktiven Inserate.</li>}
                </ul>
                <SecondaryButton className={css.actionButton}>Löschung beantragen</SecondaryButton>
            </Form>
        )}
    />
);
