import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../../util/reactIntl';
import { trimDisplayNameLastWord } from '../../../../util/text';
import { IconSpinner, Tooltip, Button } from '../../../../components';
import { ConditionalWrapper } from '../../../../util/common';
import css from './SectionContact.css';

export default ({
    actionId,
    displayName,
    loading,
    className,
    onContactUser,
    actionDisabed,
    text,
    state,
}) => (
    <ConditionalWrapper
        condition={!!actionId}
        wrapper={children => (
            <Tooltip
                position="top"
                content={
                    <>
                        <p>Anfragen nicht möglich</p>
                        <p>
                            <FormattedMessage
                                id={`Inquiry.action.disabled.desc.${actionId}`}
                                values={{
                                    displayName: trimDisplayNameLastWord(displayName),
                                }}
                            />
                        </p>
                    </>
                }
            >
                {children}
            </Tooltip>
        )}
    >
        <Button
            className={classNames(className, css[state])}
            onClick={() => onContactUser()}
            disabled={actionDisabed}
        >
            {loading ? <IconSpinner /> : text}
        </Button>
    </ConditionalWrapper>
);
