import React from 'react';
import { Alert } from '../../components';
import { isProfileComplete } from '../../util/user';
import { FormattedMessage } from '../../util/reactIntl';
import { CHECKLIST_ID } from './NavigationBar';
import { navigateTo } from './helpers';
import { RIDER_AVAILABILITY_CONFIRMED } from '../../marketplace-custom-config';
import { USER_AVAILABILITY } from './config';
import css from './StepsToBecomeAvailableAlert.css';

export default ({ user, handleEditSection, setModalParams }) => {
    const { isPrimaryFieldsComplete } = isProfileComplete(user);
    const { availabilityStatus, availabilityUpdatedAt } = user.attributes.profile.publicData;

    return (
        <Alert
            closeAllowed={false}
            rootClassName={css.visibilityHolder}
            header="ProfileEditRiderPage.visibilityHeading"
            description={
                <>
                    <p>Um sichtbar zu werden und Anfragen zu erhalten, musst Du folgendes tun:</p>
                    <div>
                        <ul>
                            {!isPrimaryFieldsComplete && (
                                <li>
                                    <a
                                        href={`#${CHECKLIST_ID}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            window.history.pushState(
                                                null,
                                                null,
                                                `#${CHECKLIST_ID}`
                                            );
                                            navigateTo(`#${CHECKLIST_ID}`);
                                        }}
                                    >
                                        Vervollständige dein Profil
                                    </a>
                                </li>
                            )}
                            {availabilityStatus !== RIDER_AVAILABILITY_CONFIRMED && (
                                <li
                                    onClick={() => {
                                        handleEditSection(USER_AVAILABILITY);
                                        setModalParams({
                                            isConfirm: !!availabilityUpdatedAt,
                                        });
                                    }}
                                >
                                    <FormattedMessage
                                        id={`ProfileEditRiderPage.visibilityActionSecondary${
                                            !!availabilityUpdatedAt ? '-confirm' : ''
                                        }`}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                </>
            }
        />
    );
};
