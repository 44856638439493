import { useEffect } from 'react';
import config from '../../config';
import {
    ERROR_INQUIRY_DISABLED_FOR_OWNER,
    ERROR_INQUIRY_DISABLED_FOR_RIDER_NOT_AVAILABLE,
    ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE,
} from './InquiryModal.helpers';
import { isProfileComplete } from '../../util/user';

const { userTypeHorseowner } = config;

export const useInquiryDisabled = ({ setError, fulfilled, currentUser, riderListing }) => {
    const { userType } = currentUser.attributes.profile.publicData;
    const { isPrimaryFieldsComplete } = isProfileComplete(currentUser);

    const isOwner = userType === userTypeHorseowner;
    const riderListingId = riderListing?.id?.uuid;

    const inquiryDisabledForOwner = isOwner && fulfilled && (!riderListing || !riderListingId);
    /** rider's listing is closed, user is not available, profile is not complete */
    const inquiryDisabledForRider =
        !isOwner && ((fulfilled && !riderListing) || !isPrimaryFieldsComplete);

    useEffect(() => {
        if (inquiryDisabledForRider) {
            const code = !isPrimaryFieldsComplete
                ? ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE
                : ERROR_INQUIRY_DISABLED_FOR_RIDER_NOT_AVAILABLE;

            setError(code);
        }
    }, [inquiryDisabledForRider]);

    useEffect(() => {
        if (inquiryDisabledForOwner) {
            setError(ERROR_INQUIRY_DISABLED_FOR_OWNER);
        }
    }, [inquiryDisabledForOwner]);
};
