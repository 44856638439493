import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NamedLink, IconSpinner } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import { getQuickSearchConfig } from './SectionQuickSearch.helpers';
import { requestCurrentLocation } from '../../../../ducks/location.duck';
import css from './SectionQuickSearch.css';

export default ({ currentUser }) => {
    const dispatch = useDispatch();
    /** Redux store data */
    const currentLocation = useSelector(s => s.location.currentLocation);
    const loading = useSelector(s => s.location.currentLocationRequestInProgress);

    const [config, setConfig] = useState(null);

    const { userType } = currentUser.attributes.profile.publicData;

    useEffect(() => {
        if (!currentLocation) return;

        setConfig(getQuickSearchConfig(currentLocation, userType));
    }, [currentLocation, userType]);

    useEffect(() => {
        dispatch(requestCurrentLocation());
    }, []);

    if (loading) {
        return <IconSpinner />;
    }

    return config ? (
        <section className={css.root}>
            <h2 className={css.heading}>
                <FormattedMessage id="SectionQuickSearch.heading" />
            </h2>
            <div className={css.content}>
                {config.options.map(({ search, id, icon }) => (
                    <NamedLink
                        key={id}
                        name="SearchPage"
                        className={css.quickSearchItem}
                        to={{
                            search,
                        }}
                    >
                        {icon}
                        <p>
                            <FormattedMessage id={id} />
                        </p>
                    </NamedLink>
                ))}
            </div>
        </section>
    ) : null;
};
