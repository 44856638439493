import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { USER_SKILLS } from '../config';
import { SKILLS_ID } from '../NavigationBar';
import css from '../ProfileSettingsForm.css';
import sectionCss from './SkillsSection.css';
import LogoutOverlay from '../LogoutOverlay';
import { IconSpinner, ToggleText } from '../../../components';
import { useIsMobile } from '../../../hooks/useIsMobile';
import ScoreBadge from '../ScoreBadge';

const SkillsSection = ({
    renderEditPencilIcon,
    handleEditSection,
    skills,
    userName,
    isLoggedOut,
    isPublic,
    switchUserRoleAction,
    pageParams,
    scoreData,
    scoreReqInProgress,
}) => {
    const [isMobile] = useIsMobile();

    const noSkills = !skills || skills.length === 0;

    if (isPublic && noSkills) {
        return null;
    }

    const logoutOverlayVisible = isLoggedOut || switchUserRoleAction;

    const classes = classNames({
        [css.infoBlock]: true,
        [css.noInfoBlock]: noSkills,
        [sectionCss.noInfoBlock]: noSkills,
        [css.viewProfileRequestWrapper]: logoutOverlayVisible,
        [sectionCss.viewProfileRequestWrapper]: logoutOverlayVisible,
    });

    return (
        <div className={classes} id={SKILLS_ID}>
            {scoreData && (
                <ScoreBadge
                    scoreData={{
                        total: scoreData.skillSCore || 0,
                        maxScore: scoreData.skillSCore || 0,
                    }}
                />
            )}
            {scoreReqInProgress && <IconSpinner />}
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.skillsHeading" />
                {!noSkills && (
                    <div className={css.editBlock}>
                        {renderEditPencilIcon(
                            USER_SKILLS,
                            null,
                            <p className={css.editText}>
                                <FormattedMessage id="ProfileSettingsForm.editText" />
                            </p>
                        )}
                    </div>
                )}
            </h2>
            {!noSkills ? (
                <ToggleText
                    initialState={isLoggedOut}
                    rootClass={sectionCss.toggleText}
                    maxHeight={isMobile ? 305 : 155}
                >
                    <div className={sectionCss.skillsSection}>
                        {skills.map(item => (
                            <p key={item} className={`${css.actionItem} ${sectionCss.skillsItem}`}>
                                {item}
                            </p>
                        ))}
                    </div>
                </ToggleText>
            ) : (
                <>
                    <div className={`${sectionCss.placeholderWrapper} ${css.placeholderWrapper}`}>
                        {Array.from({ length: 10 }, (_, i) => (
                            <div key={i} />
                        ))}
                    </div>

                    <button
                        type="button"
                        onClick={() => {
                            handleEditSection(USER_SKILLS);
                        }}
                        className={classNames([
                            css.actionItem,
                            css.actionBtn,
                            sectionCss.actionBtn,
                        ])}
                    >
                        <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-useSkills" />
                    </button>
                </>
            )}
            <LogoutOverlay
                userName={userName}
                isLoggedOut={isLoggedOut}
                rootClassName={sectionCss.viewProfileRequestAction}
                sectionName={USER_SKILLS}
                switchUserRoleAction={switchUserRoleAction}
                pageParams={pageParams}
            />
        </div>
    );
};

export default SkillsSection;
