import React from 'react';
import classNames from 'classnames';
import CameraIcon from './CameraIcon';
import DashedCircleIcon from './DashedCircleIcon';
import css from './UploadUserAvatar.css';

export default ({ uploadImageError }) => (
    <div className={css.avatarPlaceholderContainer}>
        <DashedCircleIcon
            rootClassName={classNames({
                [css.dashedCircleIcon]: true,
                [css.avatarUploadError]: !!uploadImageError,
            })}
        />
        <div className={css.avatarPlaceholder}>
            <CameraIcon />
            <div className={css.avatarPlaceholderHeading}>
                <span>Profilbild hochladen</span>
            </div>
            <div className={css.avatarPlaceholderFormats}>
                <span>.GIF, .JPG und .PNG Max. 10 MB</span>
            </div>
        </div>
    </div>
);
