import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { Topbar } from '../../components';
import { getOwnListingsById } from '../ManageListingsPage/ManageListingsPage.duck';
/**
 * TODO: get rid of TopbarContainerComponent, just use Topbar
 */
export const TopbarContainerComponent = props => <Topbar {...props} />;

TopbarContainerComponent.propTypes = {
    authInProgress: bool.isRequired,
    currentPage: string,
    currentSearchParams: object,
    currentUser: propTypes.currentUser,
    currentUserHasPublishedListings: bool.isRequired,
    currentUserHasOrders: bool,
    isAuthenticated: bool.isRequired,
    onLogout: func.isRequired,
    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    onResendVerificationEmail: func.isRequired,
    rawSearchParams: object,
    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
    // Topbar needs isAuthenticated
    const { isAuthenticated } = state.Auth;
    // Topbar needs user info.
    const {
        currentUser,
        currentUserHasPublishedListings,
        currentUserHasOrders,
        currentUserUnreadMessagesRider,
        currentUserUnreadMessagesOwner,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
    } = state.user;
    const { disableScrollRequests } = state.UI;
    const { currentPageResultIds } = state.ManageListingsPage;

    const { wishlistListingStatusChanged } = state.FavoritePage;

    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        currentUserHasPublishedListings,
        currentUserHasOrders,
        isAuthenticated,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        wishlistListingStatusChanged,
        currentUserUnreadMessagesRider,
        currentUserUnreadMessagesOwner,
        disableScrollRequests,
        hasDraftListing: (
            getOwnListingsById(state, currentPageResultIds) || [{ attributes: { state: null } }]
        ).some(({ attributes }) => attributes.state === 'draft'),
    };
};

const mapDispatchToProps = dispatch => ({
    onLogout: historyPush => dispatch(logout(historyPush)),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
