import React, { useState } from 'react';

import { SuccessIcon } from '../../../../icons';
import { ConditionalWrapper } from '../../../../util/common';
import { CroppText } from '../../../../util/text';

import ReviewStarHalf from './ReviewStarHalf';
import ReviewStarFull from './ReviewStarFull';
import css from './SectionCommunityFeedbacks.css';

export const ReviewItem = ({ author: { name }, rating, comments, time_ago, date_created }) => {
    const timeAgoConfig = {
        '1 year ago': 'Vor 1 Jahr',
    };

    const [contentRef, setContentRef] = useState(undefined);

    const daysAgoCalculated = Math.floor(
        (new Date() - new Date(date_created)) / (1000 * 60 * 60 * 24)
    );

    const getTimeSince = () => {
        if (daysAgoCalculated === 0) return 'Vor wenigen Stunden';
        if (daysAgoCalculated === 1) return 'Vor 1 Tag';
        return `Vor ${daysAgoCalculated} Tagen`;
    };

    const timeSince = timeAgoConfig[time_ago] || getTimeSince();
    const threeLinesHeight = 72;
    const remainder = 5 - rating;

    return (
        <div className={css.reviewItem}>
            <p className={css.upperSection}>
                <b className={css.userName}>{name}</b>
                {Array.from({ length: rating }, () => (
                    <ReviewStarFull />
                ))}
                {Array.from({ length: remainder }, () => (
                    <ReviewStarHalf />
                ))}
            </p>
            <p className={css.sidenote}>
                <SuccessIcon />
                <span>Verifiziertes Mitglied</span>
            </p>

            <ConditionalWrapper
                condition={contentRef}
                wrapper={children => (
                    <CroppText
                        maxHeight={threeLinesHeight}
                        componentRef={contentRef ? { current: contentRef } : null}
                    >
                        {children}
                    </CroppText>
                )}
            >
                <p ref={setContentRef} className={css.comments}>
                    {comments}
                </p>
            </ConditionalWrapper>

            <p className={css.timeSince}>{timeSince}</p>
        </div>
    );
};
