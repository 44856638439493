import React from 'react';

/** icon-mail-clock-m */
export default () => (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.234.531a1.151 1.151 0 0 0-.714.739c-.036.124-.04.572-.034 3.815L.493 8.76l.083.175c.093.196.29.409.469.51.257.143.236.142 2.248.142H5.16l.124-.073c.322-.189.312-.664-.017-.853L5.16 8.6l-1.812-.008c-1.256-.005-1.822-.016-1.846-.036-.029-.024-.035-.621-.035-3.228 0-1.76.009-3.195.021-3.19.012.005.92.801 2.017 1.769C4.602 4.875 5.549 5.7 5.61 5.741c.328.223.577.296 1.003.295.265 0 .337-.01.52-.073.117-.041.279-.114.36-.163.159-.097.153-.092 2.558-2.213.898-.792 1.643-1.444 1.657-1.449.016-.006.025.569.025 1.501 0 1.64-.002 1.61.15 1.753a.504.504 0 0 0 .761-.105l.063-.1.007-1.8c.005-1.073-.003-1.868-.018-1.968a1.123 1.123 0 0 0-.634-.84L11.88.493 6.627.488C1.645.484 1.366.486 1.234.531m9.659.983c-.03.025-.904.796-1.941 1.713C7.796 4.248 7.01 4.922 6.924 4.968a.73.73 0 0 1-.631.014c-.093-.043-.718-.579-2.079-1.779a251.333 251.333 0 0 1-1.947-1.727c0-.005 1.953-.009 4.34-.008 4.104 0 4.337.003 4.286.046M10.4 6.547A4.5 4.5 0 0 0 6.508 11 4.498 4.498 0 0 0 11 15.492 4.498 4.498 0 0 0 15.492 11a4.494 4.494 0 0 0-4.519-4.49 5.393 5.393 0 0 0-.573.037m.954.975a3.496 3.496 0 0 1 3.043 2.651c.378 1.513-.34 3.144-1.714 3.892a3.493 3.493 0 0 1-3.82-.301A3.493 3.493 0 0 1 8.1 9.043c.294-.441.858-.938 1.327-1.169a3.5 3.5 0 0 1 1.927-.352m-.762 1.484c-.103.019-.29.22-.324.349-.021.078-.028.379-.023.946.008.754.013.842.059.934.039.08.246.255.936.792.487.379.926.714.976.744.113.069.337.074.47.011a.545.545 0 0 0 .26-.353.527.527 0 0 0-.104-.394c-.034-.041-.411-.345-.837-.675l-.775-.6-.008-.735-.009-.736-.077-.101c-.122-.161-.317-.226-.544-.182"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
