import React, { useState, useRef } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    ButtonBack,
    ButtonNext,
} from 'pure-react-carousel';
import classNames from 'classnames';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, SignupSettings, SocialLoginButtons, ListingCard } from '../../components';
import { LoginForm, SignupForm } from '../../forms';
import { isLocalhost, parse } from '../../util/urlHelpers';
import { ArrowNextIcon } from '../../icons';
import SuccessIcon from './SuccessIcon';
import SuccessIconSmall from './SuccessIconSmall';

import css from './AuthForms.css';

const windowDefined = typeof window !== 'undefined';
const isMobile = windowDefined && window.innerWidth < 480;

// Panel width relative to the viewport
const panelMediumWidth = 50;
const panelLargeWidth = 62.5;
const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
].join(', ');

const AuthForms = ({
    tab,
    error,
    submitLogin,
    submitSignup,
    authInProgress,
    onOpenTermsOfService,
    listingData,
    listingDataFetchingInProgress,
    history,
    location,
}) => {
    const [opened, setOpened] = useState(false);

    const slider = useRef();

    const isLogin = tab === 'login';
    const isSignup = tab === 'signup';
    const isSignupRider = tab === 'signup-rider';
    const isSignupHorseowner = tab === 'signup-horse-owner';
    const signupTypeDefined = isSignupRider || isSignupHorseowner;

    const { referral } = parse(location.search);

    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showAppleLogin = !!process.env.REACT_APP_APPLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin || showAppleLogin;

    const navigateToPage = pageName => {
        const searchParams = {};
        if (referral) {
            searchParams.referral = referral;
        }

        history.push(createResourceLocatorString(pageName, routeConfiguration(), {}, searchParams));
    };

    const handleSubmitDecorator = (values, callback) => {
        if (isLocalhost()) return callback(values);

        if (windowDefined && window.checkRecaptchaBeforeSubmit) {
            window.checkRecaptchaBeforeSubmit(() => callback(values));
        } else {
            callback(values);
        }
    };

    const handleSubmitSignup = values => {
        const { fname, lname, ...rest } = values;

        const params = {
            firstName: fname.trim(),
            lastName: lname.trim(),
            ...rest,
        };

        const listingSlug = new URL(window.location.href).searchParams.get('slug');
        const listingId = new URL(window.location.href).searchParams.get('listingId');

        const paramsToRedirect =
            listingSlug && listingId ? { slug: listingSlug, id: listingId } : null;

        submitSignup(params, paramsToRedirect, referral);
    };

    const navigateToSignup = () => {
        history.push(
            createResourceLocatorString(
                'SignupPage',
                routeConfiguration(),
                {},
                referral ? { referral } : {}
            )
        );
    };

    const defaultConfirmSearchUnits = [
        signupTypeDefined ? `userType=${tab}` : null,
        !!referral ? `referral=${referral}` : null,
    ];
    const defaultConfirmSearch = defaultConfirmSearchUnits.filter(s => !!s).join('&');

    const socialLoginButtonsMaybe = showSocialLogins ? (
        <>
            <SocialLoginButtons
                defaultConfirmSearch={defaultConfirmSearch ? `?${defaultConfirmSearch}` : ''}
            >
                {showGoogleLogin && <SocialLoginButtons.GoogleLoginButton />}
                {showFacebookLogin && <SocialLoginButtons.FacebookLoginButton />}
                {showAppleLogin && <SocialLoginButtons.AppleLoginButton />}
            </SocialLoginButtons>
            <div className={css.socialButtonsOr}>
                <span className={css.socialButtonsOrText}>oder</span>
            </div>
        </>
    ) : null;

    const benefits = ['first', 'second', 'third'];
    const showSimpleBenefits = signupTypeDefined && !listingDataFetchingInProgress && !listingData;
    const showListingDataElements =
        signupTypeDefined && listingData && !listingDataFetchingInProgress;

    const renderBenefitsItem = benefit => (
        <div className={css.benefitsItem} key={benefit}>
            <SuccessIcon />
            <div>
                <h3>
                    <FormattedMessage id={`AuthForms.benefit-heading-${benefit}-${tab}`} />
                </h3>
                <p>
                    <FormattedMessage id={`AuthForms.benefit-desc-${benefit}-${tab}`} />
                </p>
            </div>
        </div>
    );

    const benefitsSliderElems = showListingDataElements ? (
        <>
            <div>
                <h4>
                    <SuccessIconSmall />
                    <FormattedMessage id="AuthForms.benefitsListingHeading" />
                </h4>
                <p>
                    <FormattedMessage id={`AuthForms.benefitsListingDescription-${tab}`} />
                </p>
            </div>
            <div className={css.listingCard}>
                <ListingCard
                    listing={listingData}
                    renderSizes={cardRenderSizes}
                    maxParagraphHeight={89}
                />
            </div>
            {/* {listingData.images[0] && (
        <div
          className={css.responsiveImage}
          style={{
            backgroundImage: `url(${listingData.images[0].attributes.variants['square-small'].url}`,
          }}
        />
      )} */}
        </>
    ) : null;

    const simpleBenefitHolderMob = (
        <aside className={`${css.benefitsHolder} ${css.benefitsHolderMob} ${opened && css.opened}`}>
            <p onClick={() => setOpened(prevState => !prevState)}>
                <FormattedMessage id="AuthForms.benefitsDesc" />
                <ArrowNextIcon />
            </p>
            {opened && benefits.map(renderBenefitsItem)}
        </aside>
    );

    const simpleBenefitHolderDesktop = showSimpleBenefits ? (
        <aside className={`${css.benefitsHolder} ${css.benefitsHolderDesktop}`}>
            {benefits.map(renderBenefitsItem)}
        </aside>
    ) : null;

    const sliderBenefitsHolderDesktop = showListingDataElements ? (
        <aside className={`${css.listingBenefitsHolderDesktop} ${css.benefitsHolder} `}>
            {benefitsSliderElems}
            <footer className={css.carouselHolder}>
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={isMobile ? 130 : 110}
                    isIntrinsicHeight={true}
                    totalSlides={3}
                    visibleSlides={1}
                    infinite
                >
                    <Slider ref={slider}>
                        {benefits.map((num, index) => (
                            <Slide index={index} key={`benefits-${num}-${tab}`}>
                                <div className={css.benefitsItemSlider}>
                                    <h3>
                                        <FormattedMessage
                                            id={`AuthForms.benefit-heading-${num}-${tab}`}
                                        />
                                    </h3>
                                    <p>
                                        <FormattedMessage
                                            id={`AuthForms.benefit-desc-${num}-${tab}`}
                                        />
                                    </p>
                                </div>
                            </Slide>
                        ))}
                    </Slider>
                    <div className={css.dotGroup}>
                        <DotGroup />
                    </div>
                    <ButtonBack className={classNames(css.sliderButton, css.sliderButtonBack)}>
                        <ArrowNextIcon />
                    </ButtonBack>
                    <ButtonNext className={classNames(css.sliderButton, css.sliderButtonNext)}>
                        <ArrowNextIcon />
                    </ButtonNext>
                </CarouselProvider>
            </footer>
        </aside>
    ) : null;

    const headingClassNames = classNames({
        [css.subHeading]: true,
        [css.backLink]: signupTypeDefined,
        [css.hide]: !!listingData,
    });

    return (
        <>
            <div
                className={`${css.content} ${isLogin ||
                    (signupTypeDefined && css.signupContent)} ${signupTypeDefined &&
                    css.signupOptionContent}`}
            >
                {/* show the slider or ...*/}
                {showListingDataElements && (
                    <>
                        <aside className={css.listingBenefitsHolderMob}>
                            {benefitsSliderElems}
                        </aside>
                        {simpleBenefitHolderMob}
                    </>
                )}

                <p
                    className={headingClassNames}
                    onClick={() => (signupTypeDefined ? navigateToSignup() : null)}
                >
                    {signupTypeDefined && <ArrowNextIcon />}
                    <FormattedMessage id={`AuthForms.${tab}`} />
                </p>

                <h1 className={css.heading}>
                    <FormattedMessage id={`AuthForms.heading-${tab}`} />
                </h1>

                {isLogin && (
                    <>
                        <p className={css.optionSection}>
                            <FormattedMessage id="AuthForms.areYouNewHere" />{' '}
                            <NamedLink name="SignupPage">
                                <FormattedMessage id="AuthForms.signUpLink" />
                            </NamedLink>
                        </p>
                        {socialLoginButtonsMaybe}
                        {error}
                        <LoginForm
                            className={css.form}
                            onSubmit={values => handleSubmitDecorator(values, submitLogin)}
                            inProgress={authInProgress}
                        />
                    </>
                )}

                {isSignup && (
                    <SignupSettings
                        riderClickHandler={() => navigateToPage('SignupRider')}
                        horseownerClickHandler={() => navigateToPage('SignupHorseowner')}
                    />
                )}

                {signupTypeDefined && (
                    <>
                        <p className={css.optionSection}>
                            <FormattedMessage id="AuthForms.alreadyHasAcc" />{' '}
                            <NamedLink name="LoginPage">
                                <FormattedMessage id="AuthForms.login" />
                            </NamedLink>
                        </p>
                        {/* ... simple or benefit holder */}
                        {showSimpleBenefits && simpleBenefitHolderMob}
                        {socialLoginButtonsMaybe}
                        {error}
                        <SignupForm
                            className={css.form}
                            onSubmit={values => handleSubmitDecorator(values, handleSubmitSignup)}
                            inProgress={authInProgress}
                            onOpenTermsOfService={onOpenTermsOfService}
                            initialValues={{ userType: isSignupRider ? 'rider' : 'horseowner' }}
                        />
                    </>
                )}
            </div>
            {/* show simple or ... */}
            {sliderBenefitsHolderDesktop}
            {/* the slider */}
            {simpleBenefitHolderDesktop}
        </>
    );
};

export default compose(withRouter)(AuthForms);
