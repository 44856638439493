import { useEffect } from 'react';
import { ERROR_ACTIVE_TX_ALREADY_EXISTS } from './InquiryModal.helpers';
import { useInquiryModalContext } from './useInquiryModalContext';

export const useActiveRequestForInitialListing = ({ listings, loading }) => {
    const noListings = !listings || (listings && listings.length === 0);
    const { setError } = useInquiryModalContext();

    useEffect(() => {
        if (loading) return;
        if (noListings) setError(ERROR_ACTIVE_TX_ALREADY_EXISTS);
    }, [noListings, loading]);
};
