import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.9 18.2H11.45L6.05 22.25V18.2H3.35C2.99196 18.2 2.64858 18.0578 2.39541 17.8046C2.14223 17.5514 2 17.208 2 16.85V3.35C2 2.99196 2.14223 2.64858 2.39541 2.39541C2.64858 2.14223 2.99196 2 3.35 2H20.9C21.258 2 21.6014 2.14223 21.8546 2.39541C22.1078 2.64858 22.25 2.99196 22.25 3.35V16.85C22.25 17.208 22.1078 17.5514 21.8546 17.8046C21.6014 18.0578 21.258 18.2 20.9 18.2Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
