import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    ButtonBack,
    ButtonNext,
} from 'pure-react-carousel';
import { injectIntl } from '../../util/reactIntl';
import { Button } from '../../components';
import { ArrowNextIcon } from '../../icons';

import { useCarouselConfig } from './useCarouselConfig';
import css from './UserTipsCarousel.css';

const UserTipsCarousel = ({ intl }) => {
    const carouselConfig = useCarouselConfig(intl);

    if (!carouselConfig) return null;

    const sliderItems = carouselConfig.filter(c => c.condition());
    const showSlider = sliderItems.length > 0;
    const isMultipleItems = sliderItems.length > 1;
    const isSingleItem = sliderItems.length === 1;

    return !showSlider ? null : (
        <aside
            className={classNames(css.carouselHolder, {
                [css.isSingle]: !!isSingleItem,
            })}
        >
            <CarouselProvider
                totalSlides={sliderItems.length}
                visibleSlides={1}
                infinite={false}
                isIntrinsicHeight
            >
                <Slider>
                    {sliderItems.map(
                        (
                            { id, heading, badge = null, description, actionText, handler },
                            index
                        ) => (
                            <Slide index={index} key={id}>
                                <div className={css.itemSlider}>
                                    <section>
                                        <h4>
                                            <span>{heading}</span>
                                            {badge && (
                                                <div className={css.badgeHolder}>{badge}</div>
                                            )}
                                        </h4>
                                        <p>{description}</p>
                                    </section>
                                    <Button
                                        type="button"
                                        className={css.sliderActionButton}
                                        onClick={handler}
                                    >
                                        {actionText}
                                    </Button>
                                </div>
                            </Slide>
                        )
                    )}
                </Slider>
                {isMultipleItems && (
                    <>
                        <div className={css.dotGroup}>
                            <DotGroup />
                        </div>
                        <aside className={css.sliderButtonsGroup}>
                            <ButtonBack
                                className={classNames([css.sliderButton, css.sliderButtonBack])}
                            >
                                <ArrowNextIcon />
                            </ButtonBack>
                            <ButtonNext
                                className={classNames([css.sliderButton, css.sliderButtonNext])}
                            >
                                <ArrowNextIcon />
                            </ButtonNext>
                        </aside>
                    </>
                )}
            </CarouselProvider>
        </aside>
    );
};

export default compose(injectIntl)(UserTipsCarousel);
