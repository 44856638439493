import React from 'react';
/** icon-compass-l */
/** icon-compass */
/** special case for the component */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M20.8688 2.05746L2.35073 10.037C1.8168 10.3031 1.90519 11.1013 2.52931 11.1888L11.6097 12.5192L12.8562 21.4746C12.9455 22.0943 13.7464 22.1835 14.0133 21.6514L21.9366 3.12176C22.2045 2.50122 21.5804 1.79139 20.8688 2.05746Z"
            stroke="#1A2B49"
            strokeWidth="1.42862"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
