import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { IconSpinner } from '../../../components';
import { commonDisciplines, QUALIFICATION_DOC } from '../../../marketplace-custom-config';
import { USER_QUALIFICATION } from '../config';
import { QUALIFICATION_ID } from '../NavigationBar';
import css from '../ProfileSettingsForm.css';
import sectionCss from './QualificationSection.css';
import LogoutOverlay from '../LogoutOverlay';
import ScoreBadge from '../ScoreBadge';

const QualificationSection = ({
    qualifications,
    certifications,
    renderEditPencilIcon,
    uploadDocumentsInProgress,
    userDocuments = {},
    isPublic,
    setModalParams,
    handleEditSection,
    isLoggedOut,
    userName,
    setPreviewModalOpen,
    setPreviewDoc,
    switchUserRoleAction,
    pageParams,
    scoreData,
    scoreReqInProgress,
}) => {
    const noQualificationData = !qualifications || qualifications.length === 0;

    if (isPublic && noQualificationData) {
        return null;
    }

    if (uploadDocumentsInProgress) {
        return <IconSpinner />;
    }

    const logoutOverlayVisible = isLoggedOut || switchUserRoleAction;

    const classes = classNames({
        [css.infoBlock]: true,
        [css.noInfoBlock]: noQualificationData,
        [css.viewProfileRequestWrapper]: logoutOverlayVisible,
        [sectionCss.viewProfileRequestWrapper]: logoutOverlayVisible,
    });

    const entities = Object.entries(certifications || []);

    return (
        <div className={classes} id={QUALIFICATION_ID}>
            {scoreData && (
                <ScoreBadge
                    scoreData={{
                        maxScore: scoreData.qualificationScore || 0,
                        total: scoreData.qualificationScore || 0,
                    }}
                />
            )}
            {scoreReqInProgress && <IconSpinner />}
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.qualificationHeading" />
            </h2>
            {noQualificationData ? (
                <section className={`${sectionCss.placeholderWrapper} ${css.placeholderWrapper}`}>
                    <div>
                        <div className={`${sectionCss.placeholderItem} ${css.placeholderItem}`} />
                        <div className={`${sectionCss.placeholderItem} ${css.placeholderItem}`} />
                        <div className={`${sectionCss.placeholderItem} ${css.placeholderItem}`} />
                    </div>
                    <div>
                        <div className={`${sectionCss.placeholderItem} ${css.placeholderItem}`} />
                        <div className={`${sectionCss.placeholderItem} ${css.placeholderItem}`} />
                        <div className={`${sectionCss.placeholderItem} ${css.placeholderItem}`} />
                    </div>
                </section>
            ) : (
                <div className={sectionCss.qualificationSection}>
                    {entities.map(([qualification, certifications]) =>
                        Array.isArray(certifications)
                            ? certifications.map(certification => {
                                  const document =
                                      userDocuments[QUALIFICATION_DOC] &&
                                      userDocuments[QUALIFICATION_DOC].find(
                                          ({ discipline, certification: certificationMetadata }) =>
                                              discipline === qualification &&
                                              certificationMetadata === certification
                                      );

                                  const { label } = commonDisciplines.find(
                                      ({ id }) => id === qualification
                                  ) || { label: qualification };

                                  return (
                                      <div
                                          key={`${certification}-${qualification}`}
                                          className={sectionCss.qualificationItem}
                                      >
                                          <p className={css.infoHeading}>
                                              {label}{' '}
                                              <code
                                                  className={css.editBlock}
                                                  onClick={() =>
                                                      setModalParams({
                                                          qualification,
                                                          certification,
                                                      })
                                                  }
                                              >
                                                  {renderEditPencilIcon(
                                                      USER_QUALIFICATION,
                                                      css.white
                                                  )}
                                              </code>
                                          </p>
                                          {certification && <p>{certification}</p>}
                                          <p>
                                              {document ? (
                                                  <span
                                                      className={css.previewLink}
                                                      onClick={() => {
                                                          setPreviewModalOpen(true);
                                                          setPreviewDoc(document);
                                                      }}
                                                  >
                                                      <FormattedMessage id="ProfileSettingsForm.sectionDoc" />
                                                  </span>
                                              ) : (
                                                  <FormattedMessage id="ProfileSettingsForm.noQualificationDocuments" />
                                              )}
                                          </p>
                                      </div>
                                  );
                              })
                            : null
                    )}
                </div>
            )}
            {(!isPublic || noQualificationData) && (
                <footer>
                    <button
                        type="button"
                        onClick={() => {
                            handleEditSection(USER_QUALIFICATION);
                            setModalParams({});
                        }}
                        className={`${css.actionItem} ${css.actionBtn}`}
                    >
                        <FormattedMessage id="ProfileSettingsForm.addQualificationAction" />
                    </button>
                </footer>
            )}
            <LogoutOverlay
                userName={userName}
                isLoggedOut={isLoggedOut}
                rootClassName={sectionCss.viewProfileRequestAction}
                sectionName={USER_QUALIFICATION}
                switchUserRoleAction={switchUserRoleAction}
                pageParams={pageParams}
            />
        </div>
    );
};

export default QualificationSection;
