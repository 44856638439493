import React from 'react';
import PropTypes, { object, string, bool } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import {
    MobileSearchPanel,
    EmailConfirmationBar,
    CreditsNotificationModal,
} from '../../components';
import {
    handleLogOutSuccess,
    redirectToURLWithModalState,
    redirectToURLWithoutModalState,
} from './Topbar.helpers';
import { compose } from 'redux';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { supportedCountriesGoogleMapConfig } from '../../util/googleMaps';
import { isProfileComplete } from '../../util/user';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { ensureCurrentUser } from '../../util/data';
import GenericError from './GenericError';
import DeleteAccountNotificationModal from '../../containers/DeleteAccountPage/DeleteAccountNotificationModal';
import TopbarDesktop from './components/TopbarDesktop/TopbarDesktop';
import TopbarMobile from './components/TopbarMobile/TopbarMobile';
import css from './Topbar.css';
import BottomBarMobile from './components/BottomBarMobile/BottomBarMobile';

const { userTypeRider } = config;

export const MAX_MOBILE_SCREEN_WIDTH = 1024;

const TopbarComponent = props => {
    const {
        className,
        rootClassName,
        desktopClassName,
        mobileRootClassName,
        mobileClassName,
        isAuthenticated,
        authInProgress,
        currentUser,
        currentUserHasPublishedListings,
        currentUserHasOrders,
        currentPage,
        viewport,
        intl,
        onResendVerificationEmail,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        wishlistListingStatusChanged,
        hasDraftListing,
        address: searchAddress,
        currentUserUnreadMessagesRider,
        currentUserUnreadMessagesOwner,
        onLogout,
        showEmailNotificationBar = true,
        history,
        location,
        ...rest
    } = props;

    const { mobilesearch, address, bounds } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });

    const handleMobileSearchOpen = () => redirectToURLWithModalState(props, 'mobilesearch');
    const handleMobileSearchClose = () => redirectToURLWithoutModalState(props, 'mobilesearch');

    const handleLogOut = () => onLogout().then(() => handleLogOutSuccess(history));
    const getLocationData = () => {
        // Only render current search if full place object is available in the URL params
        const locationFieldsPresent = address && bounds;

        const noSearchLocationData = locationFieldsPresent
            ? {
                  search: address,
                  selectedPlace: { address, bounds },
              }
            : null;

        const searchData = noSearchLocationData;
        const countryRegExp = new RegExp(
            Object.values(supportedCountriesGoogleMapConfig)
                .map(({ countryString }) => countryString)
                .join('|'),
            'gi'
        );

        searchData &&
            searchData.search &&
            (searchData.search = searchData.search.replace(countryRegExp, ''));

        return searchData;
    };

    const isMobileLayout = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const {
        attributes: {
            profile: {
                publicData: { userType, availabilityStatus },
            },
        },
    } = ensureCurrentUser(currentUser);

    const userIsRider = userType === userTypeRider;

    const { isPrimaryFieldsComplete } = currentUser ? isProfileComplete(currentUser) : {};

    const profileNotComplete =
        (!isPrimaryFieldsComplete || availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE) &&
        userIsRider;

    const notificationCount =
        isAuthenticated &&
        (userIsRider ? currentUserUnreadMessagesRider : currentUserUnreadMessagesOwner);

    const initialSearchFormValues = { location: getLocationData() };
    const classes = classNames(rootClassName || css.root, className);

    const {
        match: { params },
    } = rest;

    return (
        <div className={classes} data-role="top-bar">
            {showEmailNotificationBar && <EmailConfirmationBar />}
            {/** data-role=top-bar is used for wisepop event to position search map */}
            <TopbarMobile
                handleMobileSearchOpen={handleMobileSearchOpen}
                mobileRootClassName={mobileRootClassName}
                mobileClassName={mobileClassName}
                currentPage={currentPage}
                intl={intl}
            />
            <BottomBarMobile
                profileNotComplete={profileNotComplete}
                notificationCount={notificationCount}
                hasDraftListing={hasDraftListing}
                currentPage={currentPage}
                currentUser={currentUser}
                params={params}
            />
            <div className={css.desktop}>
                <TopbarDesktop
                    className={desktopClassName}
                    wishlistListingStatusChanged={wishlistListingStatusChanged}
                    currentUser={currentUser}
                    currentPage={currentPage}
                    initialSearchFormValues={initialSearchFormValues}
                    intl={intl}
                    isAuthenticated={isAuthenticated}
                    currentUserUnreadMessagesRider={currentUserUnreadMessagesRider}
                    currentUserUnreadMessagesOwner={currentUserUnreadMessagesOwner}
                    onLogout={handleLogOut}
                    searchAddress={searchAddress}
                    hasDraftListing={hasDraftListing}
                    profileNotComplete={profileNotComplete}
                    notificationCount={notificationCount}
                    {...rest}
                />
            </div>
            <MobileSearchPanel
                userType={userType}
                isOpen={isMobileSearchOpen}
                onClose={handleMobileSearchClose}
            />
            <GenericError />
            <CreditsNotificationModal />
            <DeleteAccountNotificationModal />
        </div>
    );
};

const { func, number, shape } = PropTypes;

TopbarComponent.propTypes = {
    className: string,
    rootClassName: string,
    desktopClassName: string,
    mobileRootClassName: string,
    mobileClassName: string,
    isAuthenticated: bool.isRequired,
    authInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    currentUserHasPublishedListings: bool.isRequired,
    currentUserHasOrders: bool,
    currentPage: string,
    onLogout: func.isRequired,
    onResendVerificationEmail: func.isRequired,
    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    rawSearchParams: object,
    // These are passed from Page to keep Topbar rendering aware of location changes
    history: shape({
        push: func.isRequired,
    }).isRequired,
    location: shape({
        search: string.isRequired,
    }).isRequired,

    // from withViewport
    viewport: shape({
        width: number.isRequired,
        height: number.isRequired,
    }).isRequired,
    // from injectIntl
    intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
