import React from 'react';
import classNames from 'classnames';
import { MenuItem, NamedLink } from '../../..';
import css from './ProfileMenuContent.css';

export default ({ currentPageClass, currentUser }) => {
    const {
        attributes: {
            profile: {
                publicData: { userType },
                privateData: { visitorsData_lastVisitedAt = {}, visitorsData = {} },
            },
        },
    } = currentUser;

    const visitorsDataLastVisitedAtByType = visitorsData_lastVisitedAt[userType] || null;
    const visitorsDataByType = visitorsData[userType] || [];
    const visitorsUnviewed =
        visitorsDataByType.length === 0
            ? false
            : !visitorsDataLastVisitedAtByType
            ? true
            : visitorsDataByType.some(
                  v =>
                      !!v &&
                      v.visitedAt &&
                      new Date(v.visitedAt) > new Date(visitorsDataLastVisitedAtByType)
              );

    return (
        <MenuItem
            key="VisitorsPage"
            rootClassName={classNames(css.topBarMenuItem, currentPageClass('VisitorsPage'))}
        >
            <NamedLink className={css.profileSettingsLink} name="VisitorsPage">
                <p>
                    <span>Meine Ansichten</span>
                    {visitorsUnviewed && <code className={css.notificationDot} />}
                </p>
            </NamedLink>
            <div className={classNames(css.topBarUnreadMessageBadge, css.topBarNewMenuItem)}>
                <span>Neu</span>
            </div>
        </MenuItem>
    );
};
