import { getUserCountry } from './location';
/**
 * Make a request to payrexx api to receive the url link for navigation to handle the payment
 * @param {object} params - extraParams, errorHandler, onInit, onFinalize
 * @returns navigates to payrexx url link
 */
export const createPayrexxPayLink = async ({
    extraParams = {},
    errorHandler = () => null,
    onInit = () => null,
    onFinalize = () => null,
    handler,
}) => {
    const { href } = window.location;
    const appendSearchParam = sp => (href.includes('?') ? `${href}&${sp}` : `${href}?${sp}`);

    onInit();

    try {
        const params = {
            redirectURLSuccess: appendSearchParam('paymentStatus=success'),
            redirectURLFailure: appendSearchParam('paymentStatus=failed'),
            userCountry: getUserCountry(),
            ...extraParams,
        };

        const payLinkResponse = await handler(params);

        if (payLinkResponse?.success) {
            window.open(payLinkResponse.payLink, '_top');
        } else {
            errorHandler();
        }
    } catch (err) {
        errorHandler();
    } finally {
        onFinalize();
    }
};
