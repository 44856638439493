import { getCurrentUserLocation } from '../../util/localStorage';
import config from '../../config';

const { defaultCountry, maps } = config;
const { supportedCountries } = maps;

// Get the current value with defaults from the given
// LocationAutocompleteInput props.
export const currentValue = props => {
    const value = props.input.value || {};
    const { search = '', predictions = [], selectedPlace = null } = value;

    return { search, predictions, selectedPlace };
};

// A list of default predictions that can be shown when the user
// focuses on the autocomplete input without typing a search. This can
// be used to reduce typing and Geocoding API calls for common
// searches.

export const getDefaultCountryPredictions = () => {
    const defaultSearchConfig = config.maps.search.defaults;
    // country is fetched from api ip service
    // countrySelected comes from publicData
    // countrySelected has the highest priority
    const { country, countrySelected } = getCurrentUserLocation();

    if (countrySelected && defaultSearchConfig[countrySelected]) {
        return config.maps.search.defaults[countrySelected];
    }

    if (country && defaultSearchConfig[country]) {
        return config.maps.search.defaults[country];
    }

    return config.maps.search.defaults[defaultCountry];
};

export const excludeTypes = [
    'archipelago',
    'colloquial_area',
    'continent',
    'establishment',
    'finance',
    'floor',
    'food',
    'general_contractor',
    'health',
    'natural_feature',
    'place_of_worship',
    'plus_code',
    'point_of_interest',
    'town_square',
    'furniture_store',
    'home_goods_store',
    'store',
    'transit_station',
    'bus_station',
];

// Touch devices need to be able to distinguish touches for scrolling and touches to tap
export const getTouchCoordinates = nativeEvent => {
    const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
    return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

const nonPostalCodeAddresses = [
    'Baden-Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen',
];

export const sanitizeAddressMembers = (description, place) => {
    /** check if there is a "Kanton" keyword in the search,
     * and if it is present, change address field to the specific pattern
     */
    const kantonMaybe = description && /kanton/i.test(description);

    if (kantonMaybe) {
        /** remove postal code from kantons */
        const _address = place.address.replace(/\d+ /, '');
        place.address = !/kanton/i.test(_address) ? `Kanton ${_address}` : _address;
    }

    const countriesNames = Object.values(supportedCountries).map(({ name }) => name);

    const sanitizeDesc = desc => {
        countriesNames.forEach(cn => (desc = desc.replace(cn, '')));
        desc = desc.replace(/,/g, '').trim();
        return desc;
    };

    if (description) {
        const sanitizedDescription = sanitizeDesc(description);

        /** e.g. is used for German states */
        const nonPostalCodeAddressMaybe = nonPostalCodeAddresses.some(
            member => member === sanitizedDescription
        );

        if (nonPostalCodeAddressMaybe) {
            /** remove postal code from non-postal code addresses */
            place.address = place.address.replace(/\d+ /, '');
        }
    }
};
