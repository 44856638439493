/** Sections */
export const USER_AVATAR = 'avatar';
export const USER_HEIGHT = 'userHeight';
export const USER_WEIGHT = 'userWeight';
export const USER_NAME = 'userName';
export const USER_AGE = 'userAge';
export const USER_MOBILITY = 'userMobility';
export const USER_EXPERINCE = 'userExperience';
export const USER_AVAILABILITY = 'userAvailability';
export const USER_AVAILABILITY_REASON_TO_CLOSE = 'userAvailabilityReasonToClose';
export const USER_INTEREST = 'userInterest';
export const USER_LANGUAGES = 'userLanguages';
export const USER_BIO = 'userDescription';
export const USER_DISCIPLINES = 'userDisciplines';
export const USER_QUALIFICATION = 'userQualification';
export const USER_SKILLS = 'userSkills';
export const USER_AWARDS = 'userAwards';
export const USER_TRAININGS = 'userTrainings';
export const USER_IMAGES_VIDEOS = 'userVideosImages';
export const USER_CLUB = 'userClub';
export const USER_ACCOUNT = 'userAccount';

/** Events */
export const EDIT_USER_DATA_VIA_SECTION = 'editUserDataEvent';
