import { useEffect } from 'react';

export const usePresavedInitialLocationValues = ({ form, values, currentListing }) => {
    /**
     * take initial location info from addressDetails field;
     * addressDetails is for already saved data
     */
    const { addressDetails = {} } = values;
    const { location, postalCode, number, street, country: listingCountry } = addressDetails;

    const { country: userCountry } = currentListing.author.attributes.profile.publicData;

    useEffect(() => {
        if (!form) return;

        const { change: changeFormField } = form;

        changeFormField('streetAddress', street);
        changeFormField('houseNumber', number);
        changeFormField('postalCode', postalCode);
        changeFormField('city', location);
        changeFormField('country', listingCountry || userCountry);
    }, []);
};
