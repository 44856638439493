/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import css from './LayoutWrapperAccountSettingsSideNav.css';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const scrollToTab = currentTab => {
    const el = document.querySelector(`#${currentTab}Tab`);

    if (el) {
        el.scrollIntoView({
            block: 'end',
            inline: 'end',
            behavior: 'smooth',
        });
    }
};

const LayoutWrapperAccountSettingsSideNav = ({ currentTab, viewport }) => {
    const { width } = viewport;

    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
        hasViewport && document.documentElement.classList.contains('fontsLoaded');
    const scrollToTabCallIsNeeded =
        hasHorizontalTabLayout && hasFontsLoaded && !hasVerticalTabLayout;

    useEffect(() => {
        // Check if scrollToTab call is needed (tab is not visible on mobile)
        scrollToTabCallIsNeeded && scrollToTab(currentTab);
    }, [scrollToTabCallIsNeeded, currentTab]);

    const tabs = [
        { text: 'Kontaktdaten', id: 'ContactDetailsPage' },
        { text: 'Wohnadresse', id: 'PersonalInfoPage' },
        { text: 'Anmeldung', id: 'PasswordChangePage' },
        { text: 'Konto', id: 'DeleteAccountPage' },
        { text: 'Push & E-Mail', id: 'NotificationsControlPage' },
        { text: 'Cookies', id: 'CookiesPage' },
    ].map(({ id, text }) => ({
        id,
        text,
        selected: currentTab === id,
        linkProps: { name: id, activeClassName: css.linkActive },
    }));

    return <LayoutWrapperSideNav tabs={tabs} className={css.root} />;
};

LayoutWrapperAccountSettingsSideNav.propTypes = {
    children: node,
    currentTab: string,

    viewport: shape({
        width: number.isRequired,
        height: number.isRequired,
    }).isRequired,
};

export default compose(withViewport)(LayoutWrapperAccountSettingsSideNav);
