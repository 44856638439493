import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import LanguagesIcon from '../icons/LanguagesIcon';

import { USER_LANGUAGES } from '../config';

import css from '../ProfileSettingsForm.css';
import { sortByLevel } from '../editSections/EditUserLanguagesSection';

const LanguagesSection = ({ renderEditPencilIcon, handleEditSection, languages, isPublic }) => {
    const noData = !languages || Object.entries(languages).length === 0;

    if (isPublic && noData) {
        return null;
    }

    return (
        <div className={css.infoBlock}>
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.languagesHeading" />
                {!noData && (
                    <div className={css.editBlock}>{renderEditPencilIcon(USER_LANGUAGES)}</div>
                )}
            </h2>
            {!noData &&
                Object.entries(languages)
                    .sort(sortByLevel)
                    .map(([language, level]) => (
                        <div className={css.infoLine} key={language}>
                            <LanguagesIcon />
                            <div>
                                <h5>{language}</h5>
                                <p className={`${css.infoAncient} ${css.mB0}`}>{level}</p>
                            </div>
                        </div>
                    ))}
            {!isPublic && noData && (
                <button
                    className={`${css.actionItem} ${css.actionBtn}`}
                    onClick={() => {
                        handleEditSection(USER_LANGUAGES);
                    }}
                >
                    <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-userLanguages" />
                </button>
            )}
        </div>
    );
};

export default LanguagesSection;
