import React from 'react';
/** use  icon-chevron  instead */
const ArrowNextIcon = ({ rootClassName = null }) => (
    <svg
        className={rootClassName}
        data-icon-type="arrow"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7856 7.42402C12.0716 7.74342 12.0716 8.25674 11.7856 8.57614L5.91922 15.0945C5.63181 15.4131 5.16831 15.4131 4.88233 15.0945C4.59493 14.7759 4.59493 14.2601 4.88233 13.9424L10.2295 8.00008L4.88233 2.0578C4.59493 1.73922 4.59493 1.22345 4.88233 0.905686C5.16831 0.587102 5.63181 0.587102 5.91922 0.905686L11.7856 7.42402Z"
            fill="#FFFFFF"
            stroke="#FFFFFF"
        />
    </svg>
);

export default ArrowNextIcon;
