import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchActiveTransactions } from '../../containers/ProfilePage/ProfilePage.duck';

export const useActiveTransactions = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchActiveTransactions());
    }, []);
};
