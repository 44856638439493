export const getVisibleSlidesByViewport = viewport => {
    if (!viewport) return {};

    const isDesktop = viewport > 1024;
    const isTablet = viewport > 768 && viewport <= 1024;

    const visibleSlidesByViewport = isDesktop ? 3 : isTablet ? 2 : 1;

    return { isDesktop, isTablet, visibleSlidesByViewport };
};
