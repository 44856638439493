import React, { useState } from 'react';
import { MissingPhoneNumberModal } from '../../components';
import { useDispatch } from 'react-redux';
import {
    verifyPhoneNumber,
    sendVerificationOtp,
} from '../../containers/ContactDetailsPage/ContactDetailsPage.duck';
import css from './ContactDetailsForm.css';

export default ({ phoneVerified, ...rest }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [refreshKey, setRefreshKey] = useState(new Date().getTime());
    const dispatch = useDispatch();

    const onResendVerificationOtp = phoneNumber => dispatch(sendVerificationOtp(phoneNumber));
    const onVerifyPhoneNumber = values => dispatch(verifyPhoneNumber(values));

    return (
        <>
            {!phoneVerified && (
                <p className={css.verificationLink} onClick={() => setModalVisible(true)}>
                    Verifizieren
                </p>
            )}
            <MissingPhoneNumberModal
                id="ListingPage.missingPhoneNumberModal"
                initialState="enterPhoneNumber"
                key={refreshKey}
                isOpen={modalVisible}
                redirectOnCLose={false}
                onClose={() => {
                    setModalVisible(false);
                    setRefreshKey(new Date().getTime());
                }}
                onSendVerificationOtp={onResendVerificationOtp}
                onVerifyPhoneNumber={onVerifyPhoneNumber}
                {...rest}
            />
        </>
    );
};
