import React from 'react';
/** icon-check-circle-xxl */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M33.300 0.370 C 24.885 0.980,16.768 4.710,10.739 10.739 C 2.066 19.412,-1.534 31.859,1.149 43.897 C 1.576 45.816,2.585 48.826,3.422 50.681 C 8.171 61.207,17.751 68.818,29.040 71.036 C 31.635 71.546,33.145 71.687,36.000 71.687 C 38.855 71.687,40.365 71.546,42.960 71.036 C 57.100 68.258,68.258 57.100,71.036 42.960 C 71.546 40.365,71.687 38.855,71.687 36.000 C 71.687 33.145,71.546 31.635,71.036 29.040 C 68.560 16.437,59.444 6.123,47.220 2.094 C 42.603 0.573,38.137 0.019,33.300 0.370 M40.158 4.010 C 51.088 5.490,60.406 12.261,65.106 22.140 C 67.286 26.722,68.216 30.895,68.209 36.060 C 68.204 39.490,67.860 41.969,66.963 45.050 C 63.878 55.639,55.330 64.091,44.700 67.061 C 41.707 67.897,39.332 68.211,36.000 68.211 C 33.391 68.211,32.169 68.103,29.880 67.669 C 19.931 65.782,11.281 59.080,6.894 49.860 C 5.332 46.577,4.450 43.550,3.940 39.720 C 3.745 38.250,3.745 33.750,3.940 32.280 C 5.013 24.223,8.435 17.581,14.305 12.161 C 19.174 7.666,25.763 4.663,32.280 3.970 C 32.973 3.896,33.729 3.815,33.960 3.788 C 34.824 3.691,38.868 3.835,40.158 4.010 M49.500 23.038 C 49.111 23.239,47.473 25.246,40.860 33.629 C 36.372 39.318,32.518 44.177,32.296 44.426 C 32.074 44.676,31.795 44.880,31.675 44.880 C 31.527 44.880,30.613 43.676,28.783 41.070 C 27.312 38.974,25.936 37.084,25.726 36.870 C 25.417 36.554,25.249 36.480,24.847 36.480 C 24.574 36.480,24.142 36.586,23.887 36.717 C 23.003 37.168,22.649 37.927,22.968 38.690 C 23.233 39.324,28.731 47.080,29.191 47.469 C 30.440 48.525,32.526 48.645,33.893 47.740 C 34.299 47.471,36.551 44.703,42.942 36.617 C 47.622 30.697,51.566 25.666,51.705 25.436 C 52.183 24.653,51.958 23.801,51.120 23.220 C 50.504 22.793,50.069 22.744,49.500 23.038 "
            stroke="none"
            fillRule="evenodd"
            fill="#1E9F55"
        />
    </svg>
);
