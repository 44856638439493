import React from 'react';
import { ModalPortal, Form, Button, FieldRadioButton, SecondaryButton } from '../../components';
import { Form as FinalForm } from 'react-final-form';

import css from './AvailableUnlocksModal.css';

export default ({
    isOpen,
    onClose,
    handleAcceptOrDeclineRequest,
    currentUser: {
        attributes: {
            profile: {
                publicData: { userType },
                privateData: { creditsAvailable = [] },
            },
        },
    },
    creditsNum = 10,
    modalHeading = 'Ansicht aufdecken',
    modalDesc = 'Nutze deine Credits, um diese Ansicht aufzudecken.',
    actionButtonText = 'Ansicht aufdecken',
}) => (
    <ModalPortal
        id="AvailableUnlocksModal"
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={() => null}
        containerClassName={css.modalContainer}
        contentClassName={css.modalContent}
        containerClassNameJoined
    >
        <FinalForm
            onSubmit={() => null}
            render={({ handleSubmit, values, form }) => {
                const { credit } = values;

                return (
                    <Form onSubmit={handleSubmit}>
                        <h3 className={css.modalHeading}>{modalHeading}</h3>
                        <p className={css.modalDesc}>{modalDesc}</p>
                        <FieldRadioButton
                            form={form}
                            className={css.field}
                            id="credit"
                            name="credit"
                            label={<p className={css.option}>{creditsNum} Credits verwenden</p>}
                            value="credit"
                        />
                        <p className={css.modalSumToPay}>
                            Von den verfügbaren {Number(creditsAvailable[userType] || 0)} Credits
                            werden {creditsNum} Credits von deinem Guthaben abgezogen.
                        </p>
                        <footer className={css.footer}>
                            <p className={css.sidenote}>
                                <span>Geschützt durch unsere </span>{' '}
                                <span
                                    className={css.modalLink}
                                    onClick={() => {
                                        window.open(
                                            'https://support.horsedeal.com/hc/de/articles/17568208438674',
                                            '_blank'
                                        );
                                    }}
                                >
                                    Zufriedenheitsgarantie
                                </span>
                            </p>
                            <Button
                                type="button"
                                disabled={!credit}
                                onClick={handleAcceptOrDeclineRequest}
                            >
                                {actionButtonText}
                            </Button>
                            <SecondaryButton type="button" onClick={onClose}>
                                Abbrechen
                            </SecondaryButton>
                        </footer>
                    </Form>
                );
            }}
        />
    </ModalPortal>
);
