import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    Page,
    InlineTextButton,
    LayoutSingleColumn,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    NamedLink,
    Button,
} from '../../components';
import { PasswordRecoveryForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { ArrowNextIcon, IconCheckCircleXXL } from '../../icons';

import {
    recoverPassword,
    clearPasswordRecoveryError,
    initiateEmail,
} from './PasswordRecoveryPage.duck';
import css from './PasswordRecoveryPage.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { parse } from '../../util/urlHelpers';

export const PasswordRecoveryPageComponent = ({
    scrollingDisabled,
    initialEmail: initialEmailProp,
    submittedEmail: submittedEmailProp,
    recoveryError,
    recoveryInProgress,
    passwordRequested,
    onChange,
    onSubmitEmail,
    onInitiatingEmail,
    step,
}) => {
    const history = useHistory();

    const { email: submittedEmailParam } = parse(history.location.search);

    const submittedEmail = submittedEmailProp || submittedEmailParam;
    const initialEmail = initialEmailProp;

    const navigate = (page, searchParams = {}) =>
        history.push(createResourceLocatorString(page, routeConfiguration(), {}, searchParams));

    const handleSubmitEmail = email => {
        onSubmitEmail(email).then(() =>
            navigate('PasswordRecoveryPageConfirmation', {
                email,
            })
        );
    };
    const isConfirmation = step === 'confirmation';

    const submitEmailContent = (
        <div className={css.submitEmailContent}>
            <h3 className={css.subHeading}>
                <NamedLink name="LoginPage">
                    <ArrowNextIcon />
                    Anmelden
                </NamedLink>
            </h3>
            <h1 className={css.modalTitle}>Passwort vergessen?</h1>
            <p className={css.modalMessage}>
                Keine Panik! Verrate uns deine E-Mail-Adresse und wir werden dir einen Link
                zusenden, mit dem Du dein Passwort sicher zurücksetzen kannst.
            </p>
            <PasswordRecoveryForm
                inProgress={recoveryInProgress}
                onChange={onChange}
                onSubmit={values => handleSubmitEmail(values.email)}
                initialValues={{ email: initialEmail }}
                recoveryError={recoveryError}
            />
        </div>
    );

    const submittedEmailText = passwordRequested ? (
        <span className={css.email}>{initialEmail}</span>
    ) : (
        <span className={css.email}>{submittedEmail}</span>
    );

    const emailSubmittedContent = (
        <div className={css.emailSubmittedContent}>
            <IconCheckCircleXXL />
            <h1 className={css.modalTitle}>Vielen Dank! Überprüfe jetzt deinen Posteingang.</h1>
            <p className={css.modalMessage}>
                Wir haben dir soeben einen Link zum Zurücksetzen deines Passworts an{' '}
                {submittedEmailText} gesendet.
            </p>
            <div className={css.bottomWrapper}>
                <p className={css.helperText}>
                    {recoveryInProgress && 'Sende E-Mail…'}
                    {!recoveryInProgress && (
                        <>
                            <span>Keine E-Mail erhalten?</span>{' '}
                            <InlineTextButton
                                rootClassName={css.helperLink}
                                onClick={() => onSubmitEmail(submittedEmail)}
                            >
                                Nochmals senden
                            </InlineTextButton>
                        </>
                    )}
                </p>
                <p className={css.helperText}>
                    <span>Falsche E-Mail-Adresse?</span>{' '}
                    <InlineTextButton
                        rootClassName={css.helperLink}
                        onClick={() => navigate('PasswordRecoveryPage')}
                    >
                        Ändern
                    </InlineTextButton>
                </p>
                <Button>
                    <NamedLink name="LoginPage">Zur Anmeldung</NamedLink>
                </Button>
            </div>
        </div>
    );

    const genericErrorContent = (
        <div className={css.genericErrorContent}>
            <h1 className={css.modalTitle}>Whoops!</h1>
            <p className={css.modalMessage}>
                Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche es nochmals.
            </p>
        </div>
    );

    let content = null;
    if (isPasswordRecoveryEmailNotFoundError(recoveryError)) {
        content = submitEmailContent;
    } else if (recoveryError) {
        content = genericErrorContent;
    } else if (isConfirmation) {
        content = emailSubmittedContent;
    } else {
        content = submitEmailContent;
    }

    useEffect(() => {
        if (submittedEmailParam) {
            onInitiatingEmail(submittedEmailParam);
        }
    }, []);

    return (
        <Page title="Neues Passwort anfordern" scrollingDisabled={scrollingDisabled}>
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.layoutWrapperMain} blobBackground>
                    <div className={css.root}>{content}</div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, func, string } = PropTypes;

PasswordRecoveryPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,
    initialEmail: string,
    submittedEmail: string,
    recoveryError: propTypes.error,
    recoveryInProgress: bool.isRequired,
    passwordRequested: bool.isRequired,
    onChange: func.isRequired,
    onSubmitEmail: func.isRequired,
    onRetypeEmail: func.isRequired,
};

const mapStateToProps = state => {
    const {
        initialEmail,
        submittedEmail,
        recoveryError,
        recoveryInProgress,
        passwordRequested,
    } = state.PasswordRecoveryPage;
    return {
        scrollingDisabled: isScrollingDisabled(state),
        initialEmail,
        submittedEmail,
        recoveryError,
        recoveryInProgress,
        passwordRequested,
    };
};

const mapDispatchToProps = dispatch => ({
    onChange: () => dispatch(clearPasswordRecoveryError()),
    onSubmitEmail: email => dispatch(recoverPassword(email)),
    onInitiatingEmail: email => dispatch(initiateEmail(email)),
});

const PasswordRecoveryPage = compose(connect(mapStateToProps, mapDispatchToProps))(
    PasswordRecoveryPageComponent
);

export default PasswordRecoveryPage;
