import React from 'react';

import { IconSpinner, ListingCardsCarousel } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import { useListings } from '../../../../hooks/useListings';
import css from './SectionListings.css';
import classNames from 'classnames';
import { getUserCountry } from '../../../../util/location';
import config from '../../../../config';
import { insertAuthorDataIntoListing } from '../../../../util/listings';
import { useListingsWithDistance } from '../../../../hooks/useListingsWithDistance';
import { resolveUserLatLng } from '../../../../util/user';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { getVisibleSlidesByViewport } from '../../../../components/ListingCardsCarousel/helpers';

const { listingTypeHorse } = config;

export default ({
    headingId,
    descriptionId,
    descriptionValues: descriptionValuesProp,
    rootClassName,
    children,
    params = {},
    sorter,
    insertAuthorData = true,
    currentUser,
}) => {
    const [isMobile, , computing, viewport] = useIsMobile(768);
    const userCountry = getUserCountry();
    const originStr = resolveUserLatLng(currentUser);

    const [listings, inProgress, included] = useListings({
        addDataToMarketplace: true,
        params: {
            include: ['author', 'author.profileImage', 'images'],
            'fields.user': [
                'profile.displayName',
                'profile.abbreviatedName',
                'profile.bio',
                'profile.publicData.country',
            ],
            'fields.listing': ['title', 'state', 'geolocation', 'price', 'publicData', 'createdAt'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
                'variants.square-small',
            ],
            pub_userCountry: userCountry,
            pub_type: listingTypeHorse,
            origin: originStr, // sort by distance by default
            ...params,
        },
    });

    const descriptionValues = descriptionValuesProp ? { values: descriptionValuesProp } : {};

    const dataAvailable =
        viewport && !computing && !inProgress && Array.isArray(listings) && listings.length > 0;
    const includedDataAvailable = !!Array.isArray(included) && included.length > 0;

    const listingsWithDistance = useListingsWithDistance(dataAvailable ? listings : [], originStr);

    if (inProgress) {
        return <IconSpinner />;
    }

    const applySorting = listings => (sorter ? listings.sort(sorter) : listings);
    /**
     * extract listings' authors data from
     * marketplace data instead of inseting
     * them directly via insertAuthorDataIntoListing
     */
    const applyAuthDataInserting = listings =>
        insertAuthorData && includedDataAvailable
            ? listings.map(listing => insertAuthorDataIntoListing(listing, included))
            : listings;

    const { visibleSlidesByViewport } = getVisibleSlidesByViewport(viewport);

    const carouselHolderClass =
        dataAvailable && listings.length === 2 ? css.carouselHolderTwoColumn : null;

    return dataAvailable ? (
        <section
            className={classNames(css.root, {
                [rootClassName]: !!rootClassName,
            })}
        >
            <h2 className={css.heading}>
                <FormattedMessage id={headingId} />
            </h2>
            <p>
                <FormattedMessage id={descriptionId} {...descriptionValues} />
            </p>

            <ListingCardsCarousel
                sliderClassName={css.sliderRoot}
                sliderVisibleSlides={visibleSlidesByViewport}
                listings={applyAuthDataInserting(applySorting(listingsWithDistance))}
                isMobile={isMobile}
                key={isMobile}
                hideButtonsOnEndStart
                minListingsCount={1}
                carouselHolderClassName={carouselHolderClass}
            />
            {children}
        </section>
    ) : null;
};
