import { useEffect } from 'react';
import { ERROR_NO_AVAILABLE_LISTINGS_FOR_NEW_TX } from './InquiryModal.helpers';
import { useInquiryModalContext } from './useInquiryModalContext';

export const useNoAvailableListings = ({
    horseownerListingsIdsStr,
    filterActiveTxListings,
    horseownerListings,
}) => {
    const { setError } = useInquiryModalContext();

    useEffect(() => {
        if (!horseownerListingsIdsStr) return;

        const listingsWithActiveTx = horseownerListings.filter(l => !filterActiveTxListings(l));
        const allListingsHasAlreadyTx = horseownerListings.length === listingsWithActiveTx.length;

        if (allListingsHasAlreadyTx) setError(ERROR_NO_AVAILABLE_LISTINGS_FOR_NEW_TX);
    }, [horseownerListingsIdsStr]);
};
