import { types as sdkTypes } from '../util/sdkLoader';
import { checkUserPredictionData, setUserPredictionData } from '../util/localStorage';
import {
    addPlaceDetailsDataToDB,
    addPredictionsToDB,
    getPlaceDetailsDataFromDB,
    getPredictionsFromDB,
} from '../services/googleServices';
import { getUserCountry } from './location';

export const CURRENT_LOCATION_ID = 'current-location';
// Switzerland id
export const CH_COUNTRY_PLACE_ID = 'ChIJYW1Zb-9kjEcRFXvLDxG1Vlw';
// Deutschland id
export const DE_COUNTRY_PLACE_ID = 'ChIJa76xwh5ymkcRW-WRjmtd6HU';
// Netherlands id
export const NL_COUNTRY_PLACE_ID = 'ChIJu-SH28MJxkcRnwq9_851obM';
// Austria id
export const AT_COUNTRY_PLACE_ID = 'ChIJfyqdJZsHbUcRr8Hk3XvUEhA';

// Switzerland name
export const CH_COUNTRY_NAME = 'Schweiz';
// Deutschland name
export const DE_COUNTRY_NAME = 'Deutschland';
// Netherlands name
export const NL_COUNTRY_NAME = 'Netherlands';
// Austria name
export const AT_COUNTRY_NAME = 'Austria';

export const CH_COUNTRY_STRING = ', Schweiz';
export const DE_COUNTRY_STRING = ', Deutschland';
export const NL_COUNTRY_STRING = ', Netherlands';
export const AT_COUNTRY_STRING = ', Austria';

export const supportedCountriesGoogleMapConfig = {
    [CH_COUNTRY_PLACE_ID]: {
        id: CH_COUNTRY_PLACE_ID,
        countryName: CH_COUNTRY_NAME,
        countryString: CH_COUNTRY_STRING,
        abbr: 'ch',
    },
    [DE_COUNTRY_PLACE_ID]: {
        id: DE_COUNTRY_PLACE_ID,
        countryName: DE_COUNTRY_NAME,
        countryString: DE_COUNTRY_STRING,
        abbr: 'de',
    },
};
// nl & at predictions are not allowed in the search page right now
// but users are allowed to select Netherlands or Austria as their current location
export const ultimateGoogleMapsCountriesConfig = {
    ...supportedCountriesGoogleMapConfig,
    [NL_COUNTRY_PLACE_ID]: {
        id: NL_COUNTRY_PLACE_ID,
        countryName: NL_COUNTRY_NAME,
        countryString: NL_COUNTRY_STRING,
        abbr: 'nl',
    },
    [AT_COUNTRY_PLACE_ID]: {
        id: AT_COUNTRY_PLACE_ID,
        countryName: AT_COUNTRY_NAME,
        countryString: AT_COUNTRY_STRING,
        abbr: 'at',
    },
};

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

/** Google maps LatLng */
export const gmLatLng = (lat, lng) => {
    if (lat && lng && window.google && window.google.maps && window.google.maps.LatLng) {
        return new window.google.maps.LatLng(lat, lng);
    }
    return null;
};

const placeOrigin = place => {
    if (place && place.geometry && place.geometry.location) {
        return new SDKLatLng(place.geometry.location.lat(), place.geometry.location.lng());
    }
    return null;
};

const placeBounds = place => {
    if (place && place.geometry && place.geometry.viewport) {
        const ne = place.geometry.viewport.getNorthEast();
        const sw = place.geometry.viewport.getSouthWest();
        return new SDKLatLngBounds(
            new SDKLatLng(ne.lat(), ne.lng()),
            new SDKLatLng(sw.lat(), sw.lng())
        );
    }
    return null;
};

const insertPlaceDetailsToDB = (placeDetailsDataExists, placeId, placeDetails) => {
    if (!placeDetailsDataExists) {
        addPlaceDetailsDataToDB(placeId, placeDetails);
    }
};

/**
 * Add n radius to bounds
 *
 * @param {Object<util.propTypes.place>} point - center/origin which radius is added to
 *
 * @param {Number} n - Distance in num surrounded the place origin
 */
export const addDistanceToBounds = (point, n) => {
    const spherical = window?.google?.maps?.geometry?.spherical;
    // /** or any default value */
    if (!spherical) return null;

    const northEast = spherical.computeOffset(point, n, 45);
    const southWest = spherical.computeOffset(point, n, 225);

    return new SDKLatLngBounds(
        new SDKLatLng(northEast.lat(), northEast.lng()),
        new SDKLatLng(southWest.lat(), southWest.lng())
    );
};

/**
 * Get a detailed place object
 *
 * @param {String} placeId - ID for a place received from the
 * autocomplete service
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 *
 * @return {Promise<util.propTypes.place>} Promise that
 * resolves to the detailed place, rejects if the request failed
 */
export const getPlaceDetails = async (placeId, sessionToken, prediction) =>
    new Promise(async (resolve, reject) => {
        if (!placeId) {
            return reject('No place id found.');
        }
        /** try to get the place details data from DB first */
        const dbPlaceDetailsData = await getPlaceDetailsDataFromDB(placeId);

        if (dbPlaceDetailsData) {
            return resolve(dbPlaceDetailsData);
        }

        const serviceStatus = window.google.maps.places.PlacesServiceStatus;
        const el = document.createElement('div');
        const service = new window.google.maps.places.PlacesService(el);
        const fields = ['address_component', 'formatted_address', 'geometry', 'place_id', 'name'];
        const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

        service.getDetails({ placeId, fields, ...sessionTokenMaybe }, async (place, status) => {
            if (status !== serviceStatus.OK) {
                reject(
                    new Error(
                        `Could not get details for place id "${placeId}", error status was "${status}"`
                    )
                );
            } else {
                const point = new window.google.maps.LatLng(
                    place.geometry.location.lat(),
                    place.geometry.location.lng()
                );

                const bounds = prediction.distance
                    ? addDistanceToBounds(point, prediction.distance)
                    : placeBounds(place);

                const { address_components, place_id } = place;
                /** check if postalCode\postalIndex is present
                 * at search result, and if there is no one,
                 * make additional query to receive it from
                 * nearby position
                 */

                /**
                 * if the place is a country, skip adding a postal code
                 * since countries don't have postal codes
                 */
                const searchPlaceIsCountry = ultimateGoogleMapsCountriesConfig[place_id];
                const postalCodeAbsent =
                    !searchPlaceIsCountry &&
                    !address_components.some(s => ~s.types.indexOf('postal_code'));

                if (postalCodeAbsent) {
                    const request = {
                        radius: prediction.distance ? prediction.distance : 12500,
                        location: {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng(),
                        },
                        type: ['postal_code'],
                    };

                    service.nearbySearch(request, (results, status) => {
                        const postalCodeFound =
                            status === serviceStatus.OK && results && results[0] && results[0].name;

                        const placeDetails = {
                            address: postalCodeFound
                                ? `${results[0].name} ${place.formatted_address}`
                                : place.formatted_address,
                            origin: placeOrigin(place),
                            bounds,
                            address_components: postalCodeFound
                                ? [
                                      ...place.address_components,
                                      { long_name: results[0].name, types: ['postal_code'] },
                                  ]
                                : place.address_components,
                        };

                        insertPlaceDetailsToDB(dbPlaceDetailsData, placeId, placeDetails);
                        resolve(placeDetails);
                    });
                } else {
                    const placeDetails = {
                        address: place.formatted_address,
                        origin: placeOrigin(place),
                        bounds: prediction.distance ? bounds : placeBounds(place),
                        address_components: place.address_components,
                    };

                    insertPlaceDetailsToDB(dbPlaceDetailsData, placeId, placeDetails);
                    resolve(placeDetails);
                }
            }
        });
    });

const predictionSuccessful = status => {
    const { OK, ZERO_RESULTS } = window.google.maps.places.PlacesServiceStatus;
    return status === OK || status === ZERO_RESULTS;
};

const searchConfigurationsCheck = searchConfigurations => {
    const searchConfigurationsIsCustomized = Object.keys(searchConfigurations).length;

    const { country } =
        (searchConfigurations || { componentRestrictions: { country: null } })
            .componentRestrictions || {};
    const countriesAllowed = Object.values(ultimateGoogleMapsCountriesConfig).map(
        ({ abbr }) => abbr
    );

    const errorMessage = country =>
        `Unknown country has been provided. Expected one of ${countriesAllowed.join(
            ', '
        )} instead got ${country}.`;

    if (typeof country === 'string') {
        if (countriesAllowed.indexOf(country) === -1) {
            throw new Error(errorMessage(country));
        }
    } else if (Array.isArray(country)) {
        const disallowedCountry = country.filter(s => !countriesAllowed.find(q => q === s))[0];
        if (disallowedCountry) {
            throw new Error(errorMessage(disallowedCountry));
        }
    }

    return !!searchConfigurationsIsCustomized;
};

/**
 * Get place predictions for the given search
 *
 * @param {String} search - place name or address to search
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 * @param {Object} searchConfigurations - defines the search configurations that can be used with
 * the autocomplete service. Used to restrict search to specific country (or countries).
 * @param {Array} excludeTypes - types of location that has to be ignored from the predictions
 *
 * @return {Promise<{ search, predictions[] }>} - Promise of an object
 * with the original search query and an array of
 * `google.maps.places.AutocompletePrediction` objects
 */
export const getPlacePredictions = (
    search,
    sessionToken,
    searchConfigurations = {},
    excludeTypes = []
) =>
    new Promise(async (resolve, reject) => {
        const searchConfigurationsMaybe = searchConfigurationsCheck(searchConfigurations)
            ? { ...searchConfigurations }
            : {
                  // types: ['(cities)'],
                  componentRestrictions: { country: getUserCountry() || 'ch' },
              };
        /** first, check for local storage values */
        const userPredictionData = checkUserPredictionData(search);
        const userPredictionDataDefined =
            typeof userPredictionData === 'object' &&
            userPredictionData.data &&
            userPredictionData.data.length > 0;

        if (userPredictionDataDefined) {
            return resolve({
                search,
                predictions: userPredictionData.data,
            });
        }
        /** second, check db value */
        const configCountryCode = searchConfigurationsMaybe?.componentRestrictions?.country;
        const dbCountry = Array.isArray(configCountryCode)
            ? configCountryCode[0]
            : configCountryCode;
        const dbPredictionData = await getPredictionsFromDB(search, dbCountry);
        const dbPredictionDataDefined =
            Array.isArray(dbPredictionData) && dbPredictionData.length > 0;

        if (dbPredictionDataDefined && !userPredictionDataDefined) {
            /**
             * if there there is no predictions data in the localStorage
             * but it does exist in the db, re-set the data to the localStorage
             * to reduce the number of requests to db in the future
             */
            setUserPredictionData(search, dbPredictionData[0].predictions);
        }

        if (dbPredictionDataDefined) {
            return resolve({
                search,
                predictions: dbPredictionData[0].predictions,
            });
        }

        const service = new window.google.maps.places.AutocompleteService();
        const sessionTokenMaybe = sessionToken ? { sessionToken } : {};
        /**
         * if it is necessary to get rid of country (Switzerland) in predictions,
         * navigate to index.html and paste region=CH at google query param string
         */
        service.getPlacePredictions(
            { input: search, ...sessionTokenMaybe, ...searchConfigurationsMaybe },
            (predictions, status) => {
                if (!predictionSuccessful(status)) {
                    reject(new Error(`Prediction service status not OK: ${status}`));
                } else {
                    const excludeTypesDefined = excludeTypes && excludeTypes.length;
                    const predictionsSanitized = excludeTypesDefined
                        ? (predictions || []).filter(
                              ({ types }) => !types.some(h => excludeTypes.includes(h))
                          )
                        : predictions;

                    const results = {
                        search,
                        predictions: predictionsSanitized,
                    };

                    if (!userPredictionDataDefined) {
                        /**
                         * set prediction per search string to
                         * local storage for possible future reusing
                         */
                        setUserPredictionData(search, predictionsSanitized);
                    }

                    if (!dbPredictionDataDefined) {
                        /**
                         * set prediction per search string to
                         * db for possible future reusing
                         */
                        addPredictionsToDB(search, predictionsSanitized, dbCountry);
                    }

                    resolve(results);
                }
            }
        );
    });

/**
 * Deprecation: use function from src/util/maps.js
 * Cut some precision from bounds coordinates to tackle subtle map movements
 * when map is moved manually
 *
 * @param {LatLngBounds} sdkBounds - bounds to be changed to fixed precision
 * @param {Number} fixedPrecision - integer to be used on tofixed() change.
 *
 * @return {SDKLatLngBounds} - bounds cut to given fixed precision
 */
export const sdkBoundsToFixedCoordinates = (sdkBounds, fixedPrecision) => {
    const fixed = n => Number.parseFloat(n.toFixed(fixedPrecision));
    const ne = new SDKLatLng(fixed(sdkBounds.ne.lat), fixed(sdkBounds.ne.lng));
    const sw = new SDKLatLng(fixed(sdkBounds.sw.lat), fixed(sdkBounds.sw.lng));

    return new SDKLatLngBounds(ne, sw);
};

/**
 * Deprecation: use function from src/util/maps.js
 * Check if given bounds object have the same coordinates
 *
 * @param {LatLngBounds} sdkBounds1 - bounds #1 to be compared
 * @param {LatLngBounds} sdkBounds2 - bounds #2 to be compared
 *
 * @return {boolean} - true if bounds are the same
 */
export const hasSameSDKBounds = (sdkBounds1, sdkBounds2) => {
    if (!(sdkBounds1 instanceof SDKLatLngBounds) || !(sdkBounds2 instanceof SDKLatLngBounds)) {
        return false;
    }
    return (
        sdkBounds1.ne.lat === sdkBounds2.ne.lat &&
        sdkBounds1.ne.lng === sdkBounds2.ne.lng &&
        sdkBounds1.sw.lat === sdkBounds2.sw.lat &&
        sdkBounds1.sw.lng === sdkBounds2.sw.lng
    );
};

/**
 * Calculate a bounding box in the given location
 *
 * @param {latlng} center - center of the bounding box
 * @param {distance} distance - distance in meters from the center to
 * the sides of the bounding box
 *
 * @return {LatLngBounds} bounding box around the given location
 *
 */
export const locationBounds = (latlng, distance) => {
    const bounds = new window.google.maps.Circle({
        center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
        radius: distance,
    }).getBounds();

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    return new SDKLatLngBounds(
        new SDKLatLng(ne.lat(), ne.lng()),
        new SDKLatLng(sw.lat(), sw.lng())
    );
};
