import React, { useState, useEffect } from 'react';

import { checkIOS, isNativeIOS } from '../../util/ios';
import {
    MasterCardOldPaymentIcon,
    VisaOldPaymentIconCHF,
    ApplePayPaymentIcon,
    GooglePayPaymentIcon,
    VisaPaymentIcon,
    MasterCardPaymentIcon,
    TwintPaymentIcon,
    PostFinancePaymentIcon,
    VisaOldPaymentIconEUR,
    PayPalPaymentIcon,
} from '../../icons';
import { getUserCountry } from '../../util/location';
import config from '../../config';
import css from './PaymentIconsBar.css';
import classNames from 'classnames';

export default ({ rootClassName }) => {
    const [showBar, setShowBar] = useState(false);

    useEffect(() => {
        /** Do not show the bar for ios webview app, in this case the IAP is used for payment */
        setShowBar(!isNativeIOS());
    }, []);

    const {
        maps: { supportedCountries },
    } = config;

    const userCountry = getUserCountry();
    const defaultcurrency = 'CHF';

    const { currency = defaultcurrency } = supportedCountries[userCountry];

    const commonIcons = [
        <MasterCardPaymentIcon />,
        <VisaPaymentIcon />,
        <GooglePayPaymentIcon />,
        <ApplePayPaymentIcon />,
        <MasterCardOldPaymentIcon />,
    ];

    const iconsListConfig = {
        CHF: [
            <TwintPaymentIcon />,
            <PostFinancePaymentIcon />,
            ...commonIcons,
            <VisaOldPaymentIconCHF />,
        ],
        EUR: [<PayPalPaymentIcon />, ...commonIcons, <VisaOldPaymentIconEUR />],
    };

    const rootClasses = classNames(css.root, {
        [rootClassName]: !!rootClassName,
    });

    return showBar ? <div className={rootClasses}>{iconsListConfig[currency]}</div> : null;
};
