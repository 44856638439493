import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { queryListings } from '../ducks/SdkDataAccessLayer.duck';
import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';

export const useListings = ({ params, retriggerKey, allowed = true, addDataToMarketplace }) => {
    const [fulfilled, setFulfilled] = useState(false);
    const [listings, setListings] = useState(null);
    const [loading, setLoading] = useState(false);
    const [included, setIncluded] = useState(null);
    const [meta, setMeta] = useState(null);

    const dispatch = useDispatch();
    /**
     * It gives an incorrect condition
     * when fulfilled and loading are both "true"
     */
    // useEffect(() => {
    //     if (fulfilled) {
    //         return;
    //     }
    //     setFulfilled(loading);
    // }, [loading]);

    const paramsFlag = JSON.stringify(params || {});

    useEffect(() => {
        const fetchRiderListingRepresentation = async () => {
            setLoading(true);
            setListings(null);
            setIncluded(null);
            setMeta(null);

            try {
                const response = await dispatch(
                    queryListings({
                        include: ['author', 'images'],
                        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
                        ...(params || {}),
                    })
                );

                if (response && response.data && response.data.data[0]) {
                    const {
                        data: listingsData,
                        included: includedData,
                        meta: metaData,
                    } = response.data;
                    setListings(listingsData);
                    setIncluded(includedData);
                    setMeta(metaData);

                    addDataToMarketplace && dispatch(addMarketplaceEntities(response));
                }
            } catch (e) {
                // to do
            } finally {
                setLoading(false);
                setFulfilled(true);
            }
        };

        allowed && fetchRiderListingRepresentation();
    }, [paramsFlag, retriggerKey, allowed]);

    return [listings, loading, included, fulfilled, meta];
};
