import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ReportProfile.css';
import ReportProfileModal from './ReportProfileModal';
import { submitComplaintReport } from '../../containers/ListingPage/ListingPage.duck';
import classNames from 'classnames';

export default ({ user, rootClassName, reportProfileMessageId, ...rest }) => {
    const [visible, setVisibility] = useState(false);

    const dispatch = useDispatch();

    const onSubmitComplaintReport = data => dispatch(submitComplaintReport(data));

    const sendComplaintReportInProgress = useSelector(
        s => s.ListingPage.sendComplaintReportInProgress
    );

    return (
        <>
            <section
                className={classNames(css.reportProfile, {
                    [rootClassName]: !!rootClassName,
                })}
                onClick={() => setVisibility(true)}
            >
                <img src="/static/flagIcon.png" alt="report profile" />{' '}
                <FormattedMessage id={reportProfileMessageId || 'ReportProfile.reportProfile'} />
            </section>

            {visible && (
                <ReportProfileModal
                    isOpen={visible}
                    onClose={() => setVisibility(false)}
                    onSubmitComplaintReport={onSubmitComplaintReport}
                    sendComplaintReportInProgress={sendComplaintReportInProgress}
                    user={user}
                    {...rest}
                />
            )}
        </>
    );
};
