import {
    USER_ACCOUNT,
    USER_AVAILABILITY,
    USER_AVATAR,
    USER_BIO,
    USER_DISCIPLINES,
    USER_IMAGES_VIDEOS,
    USER_INTEREST,
    USER_LANGUAGES,
    USER_SKILLS,
} from '../forms/ProfileSettingsForm/config';
import { GALLERY_FILES } from '../marketplace-custom-config';

export const getSectionCompleteness = (currentUser, userDocuments) => {
    if (!currentUser?.attributes?.profile?.publicData) return undefined;

    const { attributes, profileImage } = currentUser;
    const { profile, emailVerified: emailVerifiedAttributes } = attributes;
    const { id: imageId } = profileImage || {};

    const {
        bio,
        publicData: {
            availability,
            interest,
            desiredDisciplines,
            emailVerified: emailVerifiedPublicData,
            disciplinesLevel = {},
            skills,
            languages,
            phoneVerified,
        },
    } = profile;

    const emailVerified = emailVerifiedAttributes || emailVerifiedPublicData;

    const sections = [
        { label: USER_AVAILABILITY, mandatory: true, completed: !!availability },
        { label: USER_AVATAR, mandatory: true, completed: !!profileImage && !!imageId },
        { label: USER_BIO, mandatory: true, completed: !!bio },
        {
            label: USER_INTEREST,
            mandatory: true,
            completed: !!interest && interest.length > 0,
        },
        {
            label: USER_DISCIPLINES,
            mandatory: true,
            completed:
                !!desiredDisciplines &&
                desiredDisciplines.length > 0 &&
                desiredDisciplines.every(d => disciplinesLevel[d]),
        },
        { label: USER_SKILLS, mandatory: false, completed: !!skills && skills.length > 0 },
        {
            label: USER_IMAGES_VIDEOS,
            mandatory: false,
            completed: !!(
                userDocuments &&
                userDocuments[GALLERY_FILES] &&
                userDocuments[GALLERY_FILES].length > 0
            ),
        },

        {
            label: USER_LANGUAGES,
            mandatory: false,
            completed: !!languages && Object.values(languages || {}).length > 0,
        },
        { label: USER_ACCOUNT, mandatory: false, completed: !!phoneVerified && !!emailVerified },
    ];

    const mandatoryCount = sections.reduce((sum, section) => sum + (section.mandatory ? 1 : 0), 0);
    const mandatoryCompletedCount = sections.reduce(
        (sum, section) => sum + (section.completed && section.mandatory ? 1 : 0),
        0
    );
    const totalCompletedCount = sections.reduce(
        (sum, section) => sum + (section.completed ? 1 : 0),
        0
    );
    const totalCount = sections.length;

    return {
        sections,
        all: totalCompletedCount / totalCount,
        mandatory: mandatoryCompletedCount / mandatoryCount,
    };
};
