import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    Page,
    NamedLink,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    ModalPortal,
    Button,
} from '../../components';
import { PasswordResetForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { resetPassword } from './PasswordResetPage.duck';
import css from './PasswordResetPage.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import classNames from 'classnames';
import { IconCheckCircleXXL } from '../../icons';

const parseUrlParams = ({ search }) => {
    const { t: token, e: email } = parse(search);

    return { token, email };
};

const PasswordResetPageComponent = ({
    history,
    location,
    scrollingDisabled,
    resetPasswordInProgress,
    resetPasswordError,
    onSubmitPassword,
}) => {
    const [newPasswordSubmitted, setNewPasswordSubmitted] = useState(false);

    const { token, email } = parseUrlParams(location);
    const paramsValid = !!(token && email);

    const handleSubmit = ({ password }) => {
        setNewPasswordSubmitted(false);

        onSubmitPassword(email, token, password).then(() => {
            setNewPasswordSubmitted(true);
        });
    };

    const handleClose = () =>
        history.push(createResourceLocatorString('LoginPage', routeConfiguration()));

    const recoveryLink = (
        <NamedLink name="PasswordRecoveryPage">Seite zum Zurücksetzen deines Passworts</NamedLink>
    );
    const paramsErrorContent = (
        <p>
            <span>
                Whoops, es sieht so aus, als wäre der Link zum Zurücksetzen deines Passworts
                ungültig. Versuche es nochmals in dem Du auf den Link in der erhaltenen E-Mail
                klickst oder beantrage einen neuen Link:
            </span>{' '}
            {recoveryLink}.
        </p>
    );

    const resetFormContent = (
        <>
            <h1 className={classNames(css.modalTitle, css.resetPasswordModalTitle)}>
                Neues Passwort
            </h1>
            {resetPasswordError ? (
                <p className={css.error}>
                    Da ist etwas schief gelaufen. Bitte versuche es nochmals.
                </p>
            ) : null}
            <PasswordResetForm
                className={css.form}
                onSubmit={handleSubmit}
                inProgress={resetPasswordInProgress}
            />
        </>
    );

    const resetDoneContent = (
        <>
            <IconCheckCircleXXL />
            <h1 className={css.modalTitle}>Gut gemacht!</h1>
            <p className={css.modalMessage}>Dein Passwort wurde erfolgreich geändert.</p>
            <Button type="button" onClick={handleClose} className={css.submiAction}>
                Schliessen
            </Button>
        </>
    );

    let content = null;

    if (!paramsValid) {
        content = paramsErrorContent;
    } else if (!resetPasswordError && newPasswordSubmitted) {
        content = resetDoneContent;
    } else {
        content = resetFormContent;
    }

    return (
        <Page title="Hier zurücksetzen" scrollingDisabled={scrollingDisabled} referrer="origin">
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.layoutWrapperMain} blobBackground>
                    <ModalPortal
                        isOpen
                        id="password-reset-modal"
                        onClose={handleClose}
                        contentClassName={css.modalContent}
                    >
                        {content}
                    </ModalPortal>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, func, shape, string } = PropTypes;

PasswordResetPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,
    resetPasswordInProgress: bool.isRequired,
    resetPasswordError: propTypes.error,
    onSubmitPassword: func.isRequired,

    // from withRouter
    location: shape({
        search: string,
    }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { resetPasswordInProgress, resetPasswordError } = state.PasswordResetPage;
    return {
        scrollingDisabled: isScrollingDisabled(state),
        resetPasswordInProgress,
        resetPasswordError,
    };
};

const mapDispatchToProps = dispatch => ({
    onSubmitPassword: (email, token, password) => dispatch(resetPassword(email, token, password)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PasswordResetPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(PasswordResetPageComponent);

export default PasswordResetPage;
