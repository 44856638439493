import React from 'react';
import { TertiaryButton, Button, ModalPortal } from '../../components';
import css from './PushNotificationsSubscriptionModal.css';

export default ({ onInitialize, onAbort, isOpen }) => (
    <ModalPortal id="PushNotificationsSubscriptionModal.modal" onClose={onAbort} isOpen={isOpen}>
        <h3 className={css.modalHeading}>Benachrichtigungen aktivieren</h3>
        <p className={css.modalDesc}>
            Aktiviere Push-Benachrichtigungen, damit wir dich über neue Treffer in deiner Nähe,
            Anfragen und Nachrichten auf dem Laufenden halten können.
        </p>
        <footer className={css.modalAction}>
            <Button type="button" onClick={onInitialize}>
                Aktivieren
            </Button>
            <TertiaryButton type="button" onClick={onAbort}>
                Nein, danke
            </TertiaryButton>
        </footer>
    </ModalPortal>
);
