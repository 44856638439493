import React from 'react';
import { ListingCardsCarouselWithMatchingScore } from '../../../../components';

export default ({ viewport, listings, ...rest }) => {
    if (!viewport) return null;
    if (!Array.isArray(listings)) return null;
    if (listings.length === 0) return null;

    const isDesktop = viewport > 768;
    const visibleSlidesByViewport = isDesktop ? 2 : 1;

    return (
        <ListingCardsCarouselWithMatchingScore
            sliderVisibleSlides={Math.min(visibleSlidesByViewport, listings.length)}
            listings={listings}
            {...rest}
        />
    );
};
