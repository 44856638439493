export const getUserAddressInfo = async () => {
    /**
     * This request has to be made from client side
     * otherwise a servers ip address will be fetched
     * instead of a user's one
     */
    const response = await fetch(
        `https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_TOKEN}`
    );
    const data = await response.json();

    return data;
};
