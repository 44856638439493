import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { getUserLocationUnitStr } from '../../util/location';
import { FILL_ADDRESS_STEP } from './helpers';

const DEBOUNCE_WAIT_TIME = 500;
const defVal = { active: true };

/**
 * Get location string from Form fields
 * @param {*} param0
 */
export const useLocationUnitsString = ({
    intl,
    setLocationUnits,
    values,
    form,
    valid,
    currentStep,
}) => {
    const { city, houseNumber, streetAddress, postalCode, country } = values;
    const { getFieldState } = form;
    /** no prediction is made until any of the Form field is focused */
    const { active: streetAddressActive } = getFieldState('streetAddress') || defVal;
    const { active: houseNumberActive } = getFieldState('houseNumber') || defVal;
    const { active: postalCodeActive } = getFieldState('postalCode') || defVal;
    const { active: cityActive } = getFieldState('city') || defVal;

    const predictAllowed =
        !streetAddressActive && !houseNumberActive && !postalCodeActive && !cityActive;

    const allFieldsAreFilled = city && houseNumber && streetAddress && postalCode;

    const resolvePredictionAllowed =
        allFieldsAreFilled && currentStep === FILL_ADDRESS_STEP && predictAllowed && valid;

    useEffect(() => {
        debounce(() => {
            predictAllowed &&
                setLocationUnits(
                    getUserLocationUnitStr({
                        intl,
                        country,
                        postalCode,
                        city,
                        streetAddress,
                        houseNumber,
                    })
                );
        }, DEBOUNCE_WAIT_TIME)();
    }, [resolvePredictionAllowed]);
};
