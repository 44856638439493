import React from 'react';
import classNames from 'classnames';

import { Button } from '../../components';
import { IconCheckCircleXXL } from '../../icons';

import css from './StepPhoneNumberConfirmed.css';

export default ({ onClose }) => (
    <div className={css.completeProfileWrapper}>
        <IconCheckCircleXXL />
        <h1 className={classNames(css.completeProfileHeader)}>Gut gemacht!</h1>
        <p className={css.completeProfileParagraph}>
            Deine Mobilnummer wurde erfolgreich verifiziert.
        </p>
        <Button type="button" onClick={onClose} className={css.closeButton}>
            Schliessen
        </Button>
    </div>
);
