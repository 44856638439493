import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ModalPortal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ChangeUserTypeModal.css';

const ChangeUserTypeModal = ({ modalOpen, setModalOpen, viewProfileRequestModalDescId }) => {
    const currentUser = useSelector(s => s.user.currentUser);
    const { userType } = currentUser.attributes.profile.publicData;

    return (
        <ModalPortal
            id="switch-user-role-modal"
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
        >
            <h3>Profil wechseln</h3>
            <p>
                <FormattedMessage
                    id={
                        viewProfileRequestModalDescId ||
                        'ChangeUserTypeModal.viewProfileRequestModal-desc'
                    }
                />
            </p>
            <footer>
                <p>
                    Dein bereits erstelltes Profil bleibt bestehen und Du kannst jederzeit
                    zurückwechseln.
                </p>
                <Button type="button" onClick={() => setModalOpen(false)} disabled>
                    <FormattedMessage id={`ChangeUserTypeModal.switchFromRole-${userType}`} />
                </Button>
            </footer>
        </ModalPortal>
    );
};

export default ChangeUserTypeModal;
