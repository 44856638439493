import React, { useRef } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { FieldCheckbox, FieldRadioButton, NamedLink, Tooltip } from '../../components';

import css from './EditListingLocationForm.css';
import classNames from 'classnames';
import InfoIcon from '../../icons/InfoIcon';
import { services } from '../../marketplace-custom-config';

const EditListingLocationPageThree = ({ intl, form }) => {
    const containerRef = useRef();

    return (
        <div>
            <div ref={containerRef}>
                <h1 className={css.locationTypeTitle}>
                    <FormattedMessage id="EditListingLocationForm.offerTypeTitle" />
                </h1>
                <p className={css.locationTypeSubTitle}>
                    <FormattedMessage id="EditListingLocationForm.offerTypeSubTitle" />
                </p>
                <h4 className={css.equipmentsTypeTitle}>
                    <FormattedMessage id="EditListingLocationForm.preformanceOfferTitle" />
                </h4>

                <div className={classNames([css.radioButton, css.noBorder, css.checkboxHolder])}>
                    {services.map(({ key: option }) => (
                        <FieldRadioButton
                            id={option}
                            key={option}
                            value={option}
                            form={form}
                            name="services"
                            label={intl.formatMessage({
                                id: `EditListingLocationForm.preformanceOfferHorseTypeRadioButton-${option}`,
                            })}
                            validate={required(
                                intl.formatMessage({
                                    id: 'EditListingPricingForm.packagePriceRequired',
                                })
                            )}
                        >
                            <div className={css.tooltipPosition}>
                                <Tooltip
                                    id={option}
                                    parentContainerRef={containerRef}
                                    targetClassName={css.tooltipContentHolder}
                                    tooltipClassName={css.tooltip}
                                    padding={0}
                                    content={
                                        <>
                                            <p>
                                                <FormattedMessage
                                                    id={`EditListingLocationForm.toolTipTitle-${option}`}
                                                />
                                            </p>
                                            <p>
                                                <FormattedMessage
                                                    id={`EditListingLocationForm.toolTipContent-${option.toLowerCase()}`}
                                                />
                                            </p>
                                        </>
                                    }
                                >
                                    <InfoIcon width={18} height={18} />
                                </Tooltip>
                            </div>
                        </FieldRadioButton>
                    ))}
                </div>

                <h4 className={css.equipmentsTypeTitle}>
                    <FormattedMessage id="EditListingLocationForm.ridingLessonsTitle" />
                </h4>
                <div className={css.mobilityToggle}>
                    <FieldCheckbox
                        type="checkbox"
                        id="ridingLessons"
                        name="ridingLessons"
                        label={intl.formatMessage({
                            id: 'EditListingLocationForm.ridingLessonsLabel',
                        })}
                        value="ridingLessons"
                        form={form}
                        togglerMode
                        reversePosition
                        isSingleElem
                    />
                </div>
            </div>
        </div>
    );
};

export default EditListingLocationPageThree;
