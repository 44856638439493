import React from 'react';
/** icon-landingpage-xl */
/** icon-landingpage */
export default ({ rootClassName }) => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rootClassName}
    >
        <path
            d="M41.3317 19.9993V6.66655C41.3317 5.95934 41.0507 5.28109 40.5507 4.78101C40.0506 4.28094 39.3723 4 38.6651 4H6.66655C5.95934 4 5.28109 4.28094 4.78101 4.78101C4.28094 5.28109 4 5.95934 4 6.66655V35.9986C4 36.7058 4.28094 37.384 4.78101 37.8841C5.28109 38.3842 5.95934 38.6651 6.66655 38.6651H22.6658M4 11.9996H41.3317M28.0025 25.3324H44V35.9986H28.0025V25.3324ZM28.0025 25.3324V43.9982"
            stroke="#8F2593"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
