import React from 'react';
import classNames from 'classnames';
import { Slide } from 'pure-react-carousel';

import { ListingCard } from '../../components';
import { useIsMounted } from '../../hooks/useIsMounted';
import Carousel from './Carousel';
import css from './ListingCardsCarousel.css';

const ListingCardsCarousel = ({
    isMobile,
    listings,
    panelMediumWidth = 50,
    panelLargeWidth = 62.5,
    touchEnabled = false,
    sliderVisibleSlides,
    sliderClassName,
    hideButtonsOnEndStart = false,
    minListingsCount = 1,
    rendererSingleItem: rendererSingleItemProp,
    rendererCarousel: rendererCarouselProp,
    ...rest
}) => {
    const appIsMounted = useIsMounted();

    const cardRenderSizes = [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
    ].join(', ');

    const { length: listingsNum } = listings || [];

    if (listingsNum === 0) return null;

    return appIsMounted ? (
        <Carousel
            isMobile={isMobile}
            items={listings}
            touchEnabled={touchEnabled}
            sliderVisibleSlides={sliderVisibleSlides}
            sliderClassName={sliderClassName}
            hideButtonsOnEndStart={hideButtonsOnEndStart}
            minItemsCount={minListingsCount}
            rendererSingleItem={
                typeof rendererSingleItemProp === 'function'
                    ? listing => rendererSingleItemProp(listing)
                    : listing => (
                          <ListingCard
                              key={listing.id.uuid}
                              rootClassName={classNames(
                                  css.listingPageCard,
                                  css.listingSimilarDiscipline,
                                  {
                                      [css.listingPageCardSingle]:
                                          listings && listings.length === 1,
                                  }
                              )}
                              listing={listing}
                              listingRefClassName={css.listingRefClassName}
                              renderSizes={cardRenderSizes}
                              maxParagraphHeight={89}
                          />
                      )
            }
            rendererCarousel={
                typeof rendererCarouselProp === 'function'
                    ? (listing, index) => rendererCarouselProp(listing, index)
                    : (listing, index) => (
                          <Slide index={index} key={listing.id.uuid}>
                              <ListingCard
                                  rootClassName={classNames(
                                      css.listingPageCard,
                                      css.listingSimilarDiscipline
                                  )}
                                  listing={listing}
                                  listingRefClassName={css.listingRefClassName}
                                  renderSizes={cardRenderSizes}
                                  maxParagraphHeight={89}
                              />
                          </Slide>
                      )
            }
            {...rest}
        />
    ) : null;
};

export default ListingCardsCarousel;
