import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.5385 1.96638H7.46154C7.20664 1.96638 7 2.17302 7 2.42792V21.1624C7 21.5347 7.41814 21.7538 7.72425 21.5419L12.7373 18.0713C12.8953 17.9619 13.1047 17.9619 13.2627 18.0713L18.2757 21.5419C18.5819 21.7538 19 21.5347 19 21.1624V2.42792C19 2.17302 18.7934 1.96638 18.5385 1.96638Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
        />
    </svg>
);
