import { useEffect } from 'react';
import { resolveStatusByMatchingMatrixScore } from '../../util/user';
/**
 * if a horseowner doesn't have mainHorseId data,
 * pre-select a horse with a hightest score,
 * otherwise set horseListing form field as mainHorseId
 */
export const useMainHorseId = ({
    mainHorseId,
    setMaxScore,
    listingId,
    maxScore,
    score,
    title,
    form,
}) => {
    useEffect(() => {
        const scorePalette = resolveStatusByMatchingMatrixScore(score);

        const isMainHorse = mainHorseId === listingId;

        if (mainHorseId && !isMainHorse) return;

        if (isMainHorse || !maxScore || (maxScore && score > maxScore)) {
            setMaxScore(score);

            form.change('matchingScore', scorePalette);
            form.change('listingId', listingId);
            form.change('listingTitle', title);
        }
    }, [score, mainHorseId]);
};
