import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import css from './BottomBarMobile.css';
import classNames from 'classnames';
import config from '../../../../config';

import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../../../marketplace-custom-config';
import NamedLink from '../../../NamedLink/NamedLink';
import { isCurrentPage } from '../../Topbar.helpers';
import { USER_AVAILABILITY } from '../../../../forms/ProfileSettingsForm/config';
import EditUserInfoModal from '../../../../forms/ProfileSettingsForm/EditUserInfoModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routeConfiguration';
import { getPlaceDetails } from '../../../../util/geocoder';
import IconSpinner from '../../../IconSpinner/IconSpinner';
import { updateUserProfileInfo } from '../../../../ducks/user.duck';
import { setDefaultQueryParams } from '../../../../util/urlHelpers';
import { CURRENT_LOCATION_ID } from '../../../../util/googleMaps';
/** The component uses it's own icon instead global ones */
import IconBell from './icon-bottom-menu-bell-l';
import IconBookmark from './icon-bottom-menu-bookmark-l';
import IconClock from './icon-bottom-menu-clock-l';
import IconMap from './icon-bottom-menu-map-l';
import IconMessage from './icon-bottom-menu-message-l';
import IconProfil from './icon-bottom-menu-profil-l';

const { userTypeRider, userTypeHorseowner, listingTypeHorse, listingTypeRider } = config;

const authPages = ['LoginPage', 'SignupPage', 'SignupRider', 'SignupHorseowner'];
const inboxPages = ['OrderDetailsPage', 'OrderMessagesPage'];

export default ({
    profileNotComplete,
    notificationCount,
    hasDraftListing,
    currentPage,
    currentUser,
    params,
}) => {
    const history = useHistory();

    const dispatch = useDispatch();

    const onUpdateProfile = data => dispatch(updateUserProfileInfo(data));

    const [loading, setLoading] = useState(false);
    const [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);

    const { userType, availability, availabilityUpdatedAt } =
        currentUser?.attributes?.profile?.publicData || {};
    const { newEntryAddress } = currentUser?.attributes?.profile?.privateData || {};

    const userIsOwner = userType === userTypeHorseowner;
    const userIsRider = userType === userTypeRider;
    const userIsNotAvailable = userIsRider && availability === RIDER_AVAILABILITY_NOT_AVAILABLE;
    const userIsLoggedIn = Boolean(currentUser?.id?.uuid);

    const noAvailabilityHasBeenSetYet = typeof availabilityUpdatedAt === 'undefined';

    const currentPageClass = (page, user) =>
        isCurrentPage(page, currentPage, params, user || currentUser) ? css.currentPage : null;

    const navigate = queryParams =>
        history.push(
            createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams)
        );

    const navigateToNewEntryAddress = async () => {
        // if (!newEntryAddress) return;
        if (loading) return;

        const defaultParams = setDefaultQueryParams({ origin: 'origin', bounds: 'bounds' });
        setLoading(true);

        try {
            /**
             * newEntryAddress field is set on the back-end side,
             * see notifyHorseownerOnNewRiders, notifyRiderOnNewHorses
             */
            if (newEntryAddress) {
                await onUpdateProfile({ privateData: { newEntryAddress: null } });
            }

            const newEntryLocationData = await getPlaceDetails({
                place_id: CURRENT_LOCATION_ID,
            });

            if (!newEntryLocationData) {
                throw new Error('No location data.');
            }

            navigate({
                address: newEntryLocationData.address,
                origin: newEntryLocationData.origin,
                bounds: newEntryLocationData.bounds,
                listingType: userIsRider ? listingTypeHorse : listingTypeRider,
                mapView: true,
            });
        } catch (e) {
            navigate(defaultParams);
        } finally {
            setLoading(false);
        }
    };
    /** use synthetic data to skip currentUser check in isCurrentPage function */
    const syntheticUser = { id: { uuid: 'synthetic' } };
    const isAuthPage = authPages.some(p => currentPageClass(p, syntheticUser))
        ? css.currentPage
        : null;

    const isInboxPage = inboxPages.some(currentPageClass) ? css.currentPage : null;

    return (
        <>
            <nav
                className={classNames(css.root, {
                    [css.loggedOut]: !userIsLoggedIn,
                })}
            >
                <div
                    className={classNames(css.item, currentPageClass('SearchPage'))}
                    onClick={() => navigateToNewEntryAddress()}
                >
                    {loading && (
                        <div className={css.overlay}>
                            <IconSpinner />
                        </div>
                    )}
                    <code>
                        <IconMap />
                    </code>
                    <p>Karte</p>
                    {newEntryAddress && (
                        <code className={classNames(css.notificationDot, css.map)} />
                    )}
                </div>
                {userIsLoggedIn && (
                    <NamedLink
                        className={classNames(css.item, isInboxPage)}
                        name="OrderMessagesPage"
                        disabled={Boolean(isInboxPage)}
                    >
                        <code>
                            <IconMessage />
                        </code>
                        <p>Nachrichten</p>
                        {!!notificationCount && (
                            <code className={classNames(css.notificationDot, css.message)} />
                        )}
                    </NamedLink>
                )}
                {userIsRider && (
                    <div className={css.item} onClick={() => setAvailabilityModalOpen(true)}>
                        <code>
                            <IconClock />
                        </code>
                        <p>Status</p>
                        {userIsNotAvailable && (
                            <code className={classNames(css.notificationDot, css.availability)} />
                        )}
                    </div>
                )}
                {userIsOwner && (
                    <NamedLink
                        className={classNames(css.item, currentPageClass('ManageListingsPage'))}
                        name="ManageListingsPage"
                        disabled={Boolean(currentPageClass('ManageListingsPage'))}
                    >
                        <code>
                            <IconBookmark />
                        </code>
                        <p>Pferde</p>
                        {hasDraftListing && (
                            <code className={classNames(css.notificationDot, css.horse)} />
                        )}
                    </NamedLink>
                )}
                <div className={classNames(css.item, css.wp) + ' wisp'}>
                    <code
                        tabIndex="0"
                        role="button"
                        aria-label="Notifications"
                        href="#"
                        // className="wisp"
                    >
                        <IconBell />
                    </code>
                    <p>Updates</p>
                </div>
                {userIsLoggedIn && (
                    <NamedLink
                        className={classNames(css.item, currentPageClass('ProfileSettingsPage'))}
                        name="ProfileSettingsPage"
                        params={{ userType: userType || userTypeRider }}
                        disabled={Boolean(currentPageClass('ProfileSettingsPage'))}
                    >
                        <code>
                            <IconProfil />
                        </code>
                        <p>Profil</p>
                        {profileNotComplete && (
                            <code className={classNames(css.notificationDot, css.profile)} />
                        )}
                    </NamedLink>
                )}
                {!userIsLoggedIn && (
                    <NamedLink
                        className={classNames(css.item, isAuthPage)}
                        name="LoginPage"
                        disabled={Boolean(isAuthPage)}
                    >
                        <code>
                            <IconProfil />
                        </code>
                        <p>Konto</p>
                    </NamedLink>
                )}
            </nav>

            {availabilityModalOpen && (
                <EditUserInfoModal
                    isOpen
                    editSectionName={USER_AVAILABILITY}
                    currentUser={currentUser}
                    modalParams={{ isConfirm: !noAvailabilityHasBeenSetYet }}
                    onClose={() => setAvailabilityModalOpen(false)}
                    modalId="BottomBarMobile.EditUserInfoModal"
                />
            )}
        </>
    );
};
