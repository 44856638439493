import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { Followers } from '../../components';
import { searchForNearestListings } from './helpers';

import css from './UserTipsCarousel.css';
import {
    CREDITS_ACTION_NEW_RECOMMENDATION_RECEIVED,
    CREDITS_ACTION_REFERRAL_INVITER,
} from '../../credits-config';
import { ensureCurrentUser } from '../../util/data';
import { isProfileComplete } from '../../util/user';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { GET_RECOMMENDATIONS_FORM } from '../../containers/EditUserInfoPage/EditUserInfoPage';
import { checkIfCreditsEventExists } from '../../util/credits';

const { userTypeHorseowner, userTypeRider } = config;

export const useCarouselConfig = intl => {
    const [config, setConfig] = useState(null);

    const history = useHistory();

    /** Redux store data */
    const currentUser = useSelector(s => ensureCurrentUser(s.user.currentUser));
    const ownListings = useSelector(s => s.ManageListingsPage.ownEntities?.ownListing);

    const {
        publicData,
        privateData: { creditsHistory },
    } = currentUser.attributes.profile;
    const { userType, availabilityStatus } = publicData;

    const creditsByUserType = creditsHistory && creditsHistory[userType];

    const newListingPage = createResourceLocatorString('NewListingPage', routeConfiguration());
    const referralPage = createResourceLocatorString('ReferralPage', routeConfiguration());

    const editUserInfo = createResourceLocatorString(
        'EditUserInfo',
        routeConfiguration(),
        {
            section: GET_RECOMMENDATIONS_FORM,
            userType: userTypeRider,
        },
        {}
    );

    const { isPrimaryFieldsComplete } = isProfileComplete(currentUser);

    useEffect(() => {
        const isRider = userType === userTypeRider;
        const showSliderForRider = !(
            !isPrimaryFieldsComplete || availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE
        );

        if (isRider && !showSliderForRider) {
            return setConfig(null);
        }

        const carouselConfig = {
            [userTypeRider]: [
                {
                    id: 'first-recommendation',
                    heading: 'Steigere das Vertrauen',
                    badge: <div className={css.creditsBadge}>+ 20 Credits</div>,
                    description:
                        'Empfehlungen in deinem Reiter-Profil erhöhen deine Erfolgschancen.',
                    actionText: 'Empfehlungen erhalten',
                    handler: () => history.push(editUserInfo),
                    /** Display until a rider got her/his first recommendation from someone */
                    condition: () =>
                        !checkIfCreditsEventExists(
                            currentUser,
                            CREDITS_ACTION_NEW_RECOMMENDATION_RECEIVED
                        ),
                },
                {
                    id: 'invites-user',
                    heading: 'Lade Freunde ein',
                    badge: <div className={css.creditsBadge}>+ 50 Credits</div>,
                    description: 'Erhalte 50 Credits und verschenke 100 Credits an deine Freunde.',
                    actionText: 'Freunde einladen',
                    handler: () => history.push(referralPage),
                    /** Display until the current user invites another user. */
                    condition: () =>
                        !checkIfCreditsEventExists(currentUser, CREDITS_ACTION_REFERRAL_INVITER),
                },
            ],
            [userTypeHorseowner]: [
                {
                    id: 'add-new-listing',
                    heading: 'Erhalte Anfragen',
                    badge: <div className={css.creditsBadge}>+ 20 Credits</div>,
                    description:
                        'Inseriere dein Pferd kostenlos und erhalte Anfragen von Pferdemenschen in deiner Nähe.',
                    actionText: 'Pferd inserieren',
                    handler: () => history.push(newListingPage),
                    /** Display until Horse Owner publishes her/his first horse listing. */
                    condition: () =>
                        !ownListings ||
                        !Object.values(ownListings).some(l => l.attributes.state !== 'draft'),
                },
                {
                    id: 'search-for-neardy-riders',
                    heading: 'Suche in der Nähe',
                    badge: <Followers rootClassName={css.followers} width={28} />,
                    description:
                        'Suche nach verfügbaren Pferdemenschen, die deinen Anforderungen entsprechen.',
                    actionText: 'Suche starten',
                    handler: () => searchForNearestListings(history, { intl, ...publicData }),
                    condition: () => true /** Display permanently. */,
                },
                {
                    id: 'another user-invited',
                    heading: 'Lade Freunde ein',
                    badge: <div className={css.creditsBadge}>+ 50 Credits</div>,
                    description: 'Erhalte 50 Credits und verschenke 100 Credits an deine Freunde.',
                    actionText: 'Freunde einladen',
                    handler: () => history.push(referralPage),
                    /** Display until the current user invites another user. */
                    condition: () =>
                        !checkIfCreditsEventExists(currentUser, CREDITS_ACTION_REFERRAL_INVITER),
                },
            ],
        };

        setConfig(carouselConfig[userType]);
    }, [userType, availabilityStatus, ownListings, creditsByUserType, isPrimaryFieldsComplete]);

    return config;
};
