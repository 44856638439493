import { useState, useEffect } from 'react';
import { getMatchingScore } from '../util/api';
import { resolveStatusByMatchingMatrixScore, SCORE_INSUFFICIENT } from '../util/user';

export const useMatchingMatrix = ({ riderListingId, horseOwnerListingId }) => {
    const [fulfilled, setFulfilled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [matchingScore, setMatchingScore] = useState(null);
    const [params, setParams] = useState(null);

    const reset = loadingFlag => {
        setMatchingScore(null);
        setLoading(loadingFlag);
    };

    const onGettingMatchingScore = async (riderListingId, horseOwnerListingId) => {
        reset(true);

        try {
            const scoreRes = await getMatchingScore({
                riderListingId,
                horseOwnerListingId,
            });
            const scorePalette =
                scoreRes && scoreRes.finalScore
                    ? resolveStatusByMatchingMatrixScore(scoreRes.finalScore)
                    : SCORE_INSUFFICIENT;

            setMatchingScore({ ...scoreRes, scorePalette });
        } catch (e) {
            // to do
        } finally {
            setLoading(false);
            setFulfilled(true);
        }
    };

    useEffect(() => {
        if (!riderListingId || !horseOwnerListingId) {
            return reset(false);
        }
        const paramsStr = [riderListingId, horseOwnerListingId].join(',');
        if (paramsStr === params) return;
        setParams(paramsStr);
    }, [riderListingId, horseOwnerListingId]);

    /**
     * It gives an incorrect score, e.g.
     * on Transaction page when a rider sends a request to a horseowner,
     * there is a condition: fulfilled is 'true' and loading is also 'true'
     */

    // useEffect(() => {
    //     if (fulfilled) {
    //         return;
    //     }
    //     setFulfilled(loading);
    // }, [loading]);

    useEffect(() => {
        if (!params) return;

        const [riderListingId, horseOwnerListingId] = params.split(',');
        onGettingMatchingScore(riderListingId, horseOwnerListingId);
    }, [params]);

    return [loading, matchingScore, fulfilled];
};
