import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
    composeValidators,
    autocompleteSearchRequired,
    autocompletePlaceSelected,
    minLength,
    required,
    numbersAndLetters,
    isValidAddress,
    onlyNumbers,
} from '../../util/validators';
import {
    availableCountries,
    PREDICT_LOCATION_STEP,
    FILL_ADDRESS_STEP,
    getSearchConfigurations,
    prettifyFormValue,
} from './helpers';
import { compose } from 'redux';
import { CURRENT_LOCATION_ID } from '../../util/googleMaps';
import { injectIntl } from '../../util/reactIntl';
import { getUserCountry } from '../../util/location';
import { FieldSelect, FieldTextInput, LocationAutocompleteInputField } from '../../components';
import { useInitialValuesToSetStep } from './useInitialValuesToSetStep';
import { useLocationUnitsString } from './useLocationUnitsString';
import { useAddressComponentsData } from './useAddressComponentsData';
import { useLocationPredictions } from './useLocationPredictions';
import GoogleMapPreview from './GoogleMapPreview';
import css from './EntityLocationFormFields.css';

const identity = v => v;

const EntityLocationFormFields = ({
    intl,
    form,
    valid,
    values,
    suppressMap,
    initialValues = {},
    disabledFields = {},
    noLocationFoundContent,
    initialStep = PREDICT_LOCATION_STEP,
    notifyOnChange = () => null,
}) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [locationUnits, setLocationUnits] = useState(null);

    const isLocation = currentStep === PREDICT_LOCATION_STEP;
    const isAddress = currentStep === FILL_ADDRESS_STEP;

    /** Set values from address_components when a user selects prediction */
    useAddressComponentsData({ form, values, currentStep, setCurrentStep });
    /**Check if there is already location data, and change step to editing address */
    useInitialValuesToSetStep({ intl, initialValues, setCurrentStep, setLocationUnits });
    /** Get location string from Form fields */
    useLocationUnitsString({ intl, values, form, valid, currentStep, setLocationUnits });
    /** Call google api with location Form fields string to get GEO data and show map preview */
    const { predictionInProgress, predictionSelected } = useLocationPredictions({
        form,
        locationUnits,
    });

    useEffect(() => {
        notifyOnChange({ currentStep });
    }, [currentStep]);

    const formatMessage = id => intl.formatMessage({ id });
    const userCountry = getUserCountry();
    const searchConfigurations = getSearchConfigurations(userCountry);
    const geolocationMaybe =
        predictionSelected && predictionSelected.origin ? predictionSelected : null;

    return (
        <>
            {!suppressMap && (
                <GoogleMapPreview
                    geolocationMaybe={geolocationMaybe}
                    predictionInProgress={predictionInProgress}
                />
            )}
            <div>
                {isLocation && (
                    <>
                        <LocationAutocompleteInputField
                            useDefaultPredictions
                            name="location"
                            format={identity}
                            className={css.inputLocationWrapper}
                            predictionsClassName={css.locationPrediction}
                            searchConfigurations={searchConfigurations}
                            defaultPredictions={[{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]}
                            placeholder={formatMessage('CollectUserInfoWizard.locationPlaceholder')}
                            validate={composeValidators(
                                autocompleteSearchRequired('Address required'),
                                autocompletePlaceSelected('Address required')
                            )}
                        />
                        <p
                            className={css.notLocationFound}
                            onClick={() => setCurrentStep('address')}
                        >
                            {noLocationFoundContent || 'Ich finde meine Wohnadresse nicht'}
                        </p>
                    </>
                )}
                {isAddress && (
                    <>
                        <div
                            className={classNames([
                                css.formRowAdaptive,
                                css.formRowLocation,
                                css.formRowLocationMain,
                            ])}
                        >
                            <FieldTextInput
                                // 'route'
                                type="text"
                                id="streetAddress"
                                name="streetAddress"
                                className={css.fieldItem}
                                disabled={predictionInProgress}
                                placeholder={formatMessage(
                                    'CollectUserInfoWizard.streetPlaceholder'
                                )}
                                validate={composeValidators(
                                    required(formatMessage('CollectUserInfoWizard.streetRequired')),
                                    isValidAddress('Zahlen und Sonderzeichen sind nicht erlaubt.')
                                )}
                                handleBlur={() => prettifyFormValue('streetAddress', form)}
                            />
                            <FieldTextInput
                                // 'street_number'
                                type="text"
                                id="houseNumber"
                                name="houseNumber"
                                className={css.fieldItem}
                                disabled={predictionInProgress}
                                placeholder={formatMessage(
                                    'CollectUserInfoWizard.numberPlaceholder'
                                )}
                                validate={composeValidators(
                                    required(
                                        formatMessage('CollectUserInfoWizard.houseNumberRequired')
                                    ),
                                    numbersAndLetters('Es sind nur Buchstaben oder Zahlen erlaubt.')
                                )}
                                handleBlur={() => prettifyFormValue('houseNumber', form)}
                            />
                        </div>
                        <div className={classNames([css.formRowAdaptive, css.formRowLocation])}>
                            <FieldTextInput
                                // 'postal_code'
                                type="number"
                                id="postalCode"
                                name="postalCode"
                                className={css.fieldItem}
                                disabled={predictionInProgress}
                                placeholder={formatMessage(
                                    'CollectUserInfoWizard.indexPlaceholder'
                                )}
                                validate={composeValidators(
                                    required(
                                        formatMessage('CollectUserInfoWizard.postalIndexRequired')
                                    ),
                                    onlyNumbers(
                                        formatMessage(
                                            'CollectUserInfoWizard.onlyNumbersAllowedMessage'
                                        )
                                    ),
                                    minLength(
                                        formatMessage('CollectUserInfoWizard.postalIndexRequired'),
                                        4
                                    )
                                )}
                            />
                            <FieldTextInput
                                /** locality */
                                type="text"
                                id="city"
                                name="city"
                                className={css.fieldItem}
                                disabled={predictionInProgress}
                                placeholder={formatMessage('CollectUserInfoWizard.cityLabel')}
                                validate={composeValidators(
                                    required(
                                        formatMessage('CollectUserInfoWizard.residenceRequired')
                                    ),
                                    isValidAddress('Zahlen und Sonderzeichen sind nicht erlaubt.')
                                )}
                                handleBlur={() => prettifyFormValue('city', form)}
                            />
                        </div>
                        <FieldSelect
                            id="country"
                            name="country"
                            form={form}
                            className={css.fieldItem}
                            disabled={predictionInProgress || disabledFields['country']}
                            placeholder={formatMessage('CollectUserInfoWizard.countryPlaceholder')}
                            validate={required(
                                formatMessage('CollectUserInfoWizard.countryRequired')
                            )}
                            optionsList={availableCountries.map(c => ({
                                value: c.code,
                                label: intl.formatMessage({
                                    id: `PayoutDetailsForm.countryNames.${c.code}`,
                                }),
                            }))}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default compose(injectIntl)(EntityLocationFormFields);
