import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
    TRANSITION_APPOINTMENT_CANCEL,
    TRANSITION_APPOINTMENT_DECLINE,
    TRANSITION_APPOINTMENT_ACCEPT,
    TRANSITION_ENQUIRY_DECLINE,
    TRANSITION_ENQUIRY_DECLINE_OWNER,
    TRANSITION_ENQUIRY_ACCEPT,
    TRANSITION_ENQUIRY_ACCEPT_OWNER,
    TRANSITION_ENQUIRY_EXPIRE,
    TRANSITION_ENQUIRE,
    resolveOtherPartyData,
    anonymizeOtherParty,
} from '../../util/transaction';
import { ensureCurrentUser } from '../../util/data';
import { UserDisplayName, NamedLink } from '../../components';
import * as log from '../../util/log';

import css from './TransitionMessage.css';

export const resolveTransitionMessage = props => {
    const {
        transition,
        intl,
        onOpenReviewModal,
        ownRole,
        currentTransaction,
        isCustomer,
        currentUser: user,
        suppressLinks = false,
    } = props;
    const customer = currentTransaction.customer;
    const provider = currentTransaction.provider;
    const currentUser = ensureCurrentUser(user);

    const { listing } = currentTransaction;
    const otherParty = anonymizeOtherParty(resolveOtherPartyData(currentUser, customer, provider))(
        currentTransaction
    );
    const {
        id: { uuid: otherPartyId },
        attributes: {
            profile: {
                publicData: { userType: otherPartyType },
            },
        },
    } = otherParty;

    const otherUsersName = <UserDisplayName user={otherParty} intl={intl} shouldBeTrimmed />;

    const isOwnTransition =
        transition.by === ownRole ||
        (transition.sender && transition.sender.userId === currentUser.id.uuid);

    const currentTransition = transition.transition;

    const displayName = otherUsersName || '';

    const { attributes } = listing || { attributes: { deleted: true, state: '' } };
    const listingTitle = attributes.title;

    const otherPartyLinkProps = {
        name: 'ProfilePage',
        params: { id: otherPartyId, userType: otherPartyType },
    };

    const otherPartyLink = <NamedLink {...otherPartyLinkProps}>{displayName}</NamedLink>;

    const faqLink = isCustomer
        ? 'https://support.horsedeal.com/hc/de/articles/17568334877842'
        : 'https://support.horsedeal.com/hc/de/articles/17568334877842';

    const manageSubscriptionLink = 'Currently not available';

    const leaveReviewLink = (
        <>
            <span
                className={classNames(css.pseudoLink, css.leaveReviewLink)}
                onClick={onOpenReviewModal}
            >
                <FormattedMessage id="ActivityFeed.leaveReviewLink" values={{ displayName }} />
            </span>
            <span className={css.pseudoLinkPoint}>.</span>
        </>
    );

    switch (currentTransition) {
        case TRANSITION_ENQUIRE:
            return null;
        case TRANSITION_APPOINTMENT_CANCEL:
            return isOwnTransition ? (
                <FormattedMessage id="AppointmentMessage.ownTransitionMessageCanceled" />
            ) : (
                <FormattedMessage
                    id="AppointmentMessage.transitionMessageCanceled"
                    values={{ otherPartyName: displayName }}
                />
            );
        case TRANSITION_APPOINTMENT_DECLINE:
            return !isOwnTransition ? (
                <FormattedMessage id="AppointmentMessage.ownTransitionMessageDeclined" />
            ) : (
                <FormattedMessage
                    id="AppointmentMessage.transitionMessageDeclined"
                    values={{ otherPartyName: displayName }}
                />
            );
        case TRANSITION_APPOINTMENT_ACCEPT:
            return !isOwnTransition ? (
                <FormattedMessage id="AppointmentMessage.ownTransitionMessageApproved" />
            ) : (
                <FormattedMessage
                    id="AppointmentMessage.transitionMessageApproved"
                    values={{ otherPartyName: displayName }}
                />
            );

        case TRANSITION_ENQUIRY_DECLINE:
        case TRANSITION_ENQUIRY_DECLINE_OWNER:
            return isOwnTransition ? (
                <FormattedMessage id="ActivityFeed.userDeclinesRequest" values={{ displayName }} />
            ) : (
                <FormattedMessage
                    id="ActivityFeed.providerDeclinesRequest"
                    values={{ displayName }}
                />
            );
        case TRANSITION_ENQUIRY_ACCEPT:
        case TRANSITION_ENQUIRY_ACCEPT_OWNER:
            return isOwnTransition ? (
                <span>
                    Du hast die Anfrage akzeptiert. Wir haben {displayName} darüber informiert.
                </span>
            ) : (
                <span>Toll! {displayName} hat deine Anfrage akzeptiert.</span>
            );
        case TRANSITION_ENQUIRY_EXPIRE:
            return (
                <React.Fragment>
                    <span>Die Anfrage wurde nach 14 Tagen automatisch abgelehnt.</span>{' '}
                    <span
                        className={classNames({
                            [css.pseudoLink]: !suppressLinks,
                        })}
                        onClick={() => (suppressLinks ? null : window.location.replace(faqLink))}
                    >
                        Warum?
                    </span>
                </React.Fragment>
            );

        default:
            log.error(
                new Error(`Unknown transaction transition type - ${currentTransition}`),
                'unknown-transition-type',
                {
                    transitionType: currentTransition,
                }
            );
            return '';
    }
};
