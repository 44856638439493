import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import css from './EditListingPhotosPanel.css';
import { DEFAULT_LISTING_TITLE } from '../../marketplace-custom-config';
import { DEFAULT_VARIANT, SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import { useAssetsToSdk } from '../../hooks/useAssetsToSdk';
import { useSelector } from 'react-redux';

const getListingMainImagePath = files => {
    try {
        const images = (files || []).filter(
            file => file?.mimetype === 'image/jpeg' || file?.mimetype === 'image/png'
        );

        const url = images[0] ? images[0].attributes.variants['landscape-crop2x'].url : null;
        const image = url ? url.replace(new RegExp(DEFAULT_VARIANT), SQUARE_SMALL_2X) : null;

        return image;
    } catch (e) {
        return null;
    }
};

const EditListingPhotosPanel = ({
    className,
    rootClassName,
    errors,
    fetchInProgress,
    newListingPublished,
    listing,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onChange,
    onSubmit,
    createListingDraftInProgress,
    externalListing,
    ...rest
}) => {
    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const listingId = currentListing?.id?.uuid;
    const listingPublicData = currentListing?.attributes?.publicData || {};
    const title = currentListing?.attributes?.title;
    const description = currentListing?.attributes?.description;
    const {
        anonymousListingPackageIntention,
        gender,
        age,
        hight,
        breed,
        color,
    } = listingPublicData;

    /** Redux store data */
    const listingAssets = useSelector(s => {
        const assets = s.Assets.assetsData;
        return (
            assets &&
            assets[listingId] &&
            (assets[listingId][DEFAULT_VARIANT] || assets[listingId][SQUARE_SMALL_2X])
        );
    });
    const uploadAssetsInProgress = useSelector(s => {
        const requests = s.Assets.assetsLoadingRequests;
        return requests && requests[listingId];
    });
    const listingAssetsUploadError = useSelector(s => {
        const errors = s.Assets.assetsErrors;
        return errors && errors[listingId];
    });
    const assetsErrors = useSelector(s => s.Assets.assetsErrors);

    const {
        metadata: { anonymousListing: isAnonymousListing },
        publicData: { anonymousListingTitle },
    } = currentListing.attributes;

    const notEmptyListingTitle = isAnonymousListing
        ? anonymousListingTitle
        : title == DEFAULT_LISTING_TITLE
        ? ''
        : title;

    const images = useAssetsToSdk({
        assets: listingAssets,
        inProgress: uploadAssetsInProgress,
    });

    return (
        <div className={classes}>
            {listingAssetsUploadError && <p className={css.error}>{listingAssetsUploadError}</p>}
            <EditListingPhotosForm
                className={css.form}
                disabled={fetchInProgress}
                ready={newListingPublished}
                fetchErrors={errors}
                initialValues={{
                    images,
                    otherFields: {
                        title: notEmptyListingTitle,
                        anonymousListingPackageIntention:
                            anonymousListingPackageIntention || isAnonymousListing,
                        gender,
                        age,
                        hight,
                        description,
                        breed,
                        color,
                    },
                }}
                images={images}
                assets={listingAssets}
                uploadAssetsInProgress={uploadAssetsInProgress}
                onSubmit={values => {
                    const mainListingImage = getListingMainImagePath(images);
                    const {
                        addImage,
                        title,
                        anonymousListingPackageIntention,
                        gender,
                        age,
                        hight,
                        description,
                        breed,
                        color,
                        isAutoSave,
                        redirectToListingPage,
                        hasVideo = false,
                        hasGalleryAssets = false,
                        ...updateValues
                    } = values;

                    const allValues = {
                        publicData: {
                            anonymousListingPackageIntention,
                            gender,
                            age,
                            hight,
                            breed,
                            color,
                            hasVideo,
                            hasGalleryAssets,
                        },
                        title: isAnonymousListing
                            ? 'Anonym'
                            : title
                            ? title
                            : DEFAULT_LISTING_TITLE,
                        description,
                        ...updateValues,
                    };

                    if (isAutoSave) {
                        allValues.isAutoSave = isAutoSave;
                    }

                    if (redirectToListingPage) {
                        allValues.redirectToListingPage = redirectToListingPage;
                    }

                    if (mainListingImage) {
                        allValues.publicData.mainListingImage = mainListingImage;
                    }

                    onSubmit(allValues);
                }}
                onChange={onChange}
                saveActionMsg={submitButtonText}
                updated={panelUpdated}
                updateInProgress={updateInProgress}
                createListingDraftInProgress={createListingDraftInProgress}
                externalListing={externalListing}
                isAnonymousListing={isAnonymousListing}
                currentListing={currentListing}
                {...rest}
            />
        </div>
    );
};

EditListingPhotosPanel.propTypes = {
    className: string,
    rootClassName: string,
    errors: object,
    images: array,

    // We cannot use propTypes.listing since the listing might be a draft.
    listing: object,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    externalListing: bool,
};

export default EditListingPhotosPanel;
