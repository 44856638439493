import { useEffect } from 'react';
import { resolveStatusByMatchingMatrixScore } from '../../util/user';
import { SEND_INQUIRY_STEP } from './InquiryModal.helpers';
import { useInquiryModalContext } from './useInquiryModalContext';
/**
 * if a horseowner has a single listing, pre-select it in the form
 */
export const useSingleListing = ({
    matchingMatrixLoading,
    isSingleListing,
    matchingScore,
    fulfilled,
    listings,
    form,
}) => {
    const { setStep } = useInquiryModalContext();

    useEffect(() => {
        if (matchingMatrixLoading || !fulfilled) return;

        if (isSingleListing) {
            const listing = listings[0];
            const {
                id: { uuid: listingId },
                attributes: { title },
            } = listing;

            const score = (matchingScore && matchingScore.finalScore) || 0;
            const scorePalette = resolveStatusByMatchingMatrixScore(score);

            form.change('matchingScore', scorePalette);
            form.change('listingId', listingId);
            form.change('listingTitle', title);

            setStep(SEND_INQUIRY_STEP);
        }
    }, [isSingleListing, matchingMatrixLoading, matchingScore, fulfilled]);
};
