import React from 'react';

import classNames from 'classnames';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './EditTab.css';
import { ArrowNextIcon, IconCheckmark } from '../../../../icons';
import { isDraft } from '../../Helpers';

export default ({
    className,
    id,
    text,
    handleTabClick,
    tabNum,
    disabled,
    params,
    customProps: { percentage = 0, completedSteps, allStepsCount, index },
}) => {
    const isTabCompleted = percentage && percentage == 100;
    const tabWithBorder = percentage && !disabled && !isTabCompleted;
    const isDraftListing = isDraft(params);

    const linkClasses = classNames(css.link, {
        [css.disabled]: disabled,
        [css.tabWithBorder]: tabWithBorder,
        [css.completedTab]: isTabCompleted,
        [css.enabled]: !isDraftListing,
        [className]: !!className,
    });

    return (
        <div
            id={id}
            className={linkClasses}
            style={
                tabWithBorder
                    ? {
                          background: `linear-gradient(to right, #8F2593 ${percentage}%, #E6E6E6 0%) bottom`,
                      }
                    : {}
            }
        >
            <div
                className={css.tabWrapper}
                onClick={() => !isDraftListing && handleTabClick(tabNum)}
            >
                {isTabCompleted ? (
                    <div className={css.tabCompletedIconHolder}>
                        <IconCheckmark />
                    </div>
                ) : (
                    <div
                        className={classNames(css.tabIndex, {
                            [css.tabIndexDisabled]: disabled,
                        })}
                    >
                        {index}
                    </div>
                )}

                <div className={css.textContainer}>
                    <h2 className={css.textTitle}>{text}</h2>
                    <div className={css.subLabelWrapper}>
                        {isTabCompleted ? (
                            <FormattedMessage id="EditListingWizard.tabSubLabelAllStepsComplete" />
                        ) : (
                            <FormattedMessage
                                id="EditListingWizard.tabSubLabelCounter"
                                values={{
                                    completedSteps,
                                    allStepsCount,
                                }}
                            />
                        )}
                    </div>
                </div>
                {!isDraft(params) && (
                    <div className={css.tabArrowContainer}>
                        <ArrowNextIcon
                            rootClassName={classNames(css.arrowIcon, {
                                [css.arrowIconDisabled]: disabled,
                            })}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
