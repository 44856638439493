import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from '../../../util/reactIntl';
import { createHorseListingServicesPaylink, getlistingPackagesByCountry } from '../../../util/api';
import { Alert, Button } from '../../../components';
import {
    CHECKOUT,
    BOOSTING_CH,
    BOOSTING_DE,
    parse,
    LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
    LISTING_PAGE_DRAFT_VARIANT,
    createSlug,
    LISTING_PAGE_PARAM_TYPE_DRAFT,
} from '../../../util/urlHelpers';
import { getUserCountry } from '../../../util/location';
import { IconLock } from '../../../icons';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { isNativeIOS, postIAPMessage } from '../../../util/ios';
import EditListingPricingPagePackagesList from './EditListingPricingPagePackagesList';
import EditListingPricingPageCheckout from './EditListingPricingPageCheckout';
import css from './EditListingPricingPageTwo.css';
import { useNativeIosPurchasingEvent } from '../../../hooks/useNativeIosPurchasingEvent';
import { createPayrexxPayLink } from '../../../util/payments';

const EditListingPricingPageTwo = ({
    form,
    listingId,
    values,
    onSubmit,
    currentListing,
    ensuredCurrentUser: {
        attributes: {
            email,
            profile: {
                publicData: { country },
            },
        },
    },
}) => {
    const history = useHistory();
    const params = useParams();

    const {
        location: { search },
    } = history;
    const { tab, type: variant, slug } = params;

    const [ready, setReady] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [displayPackages, setDisplayPackages] = useState([]);

    const {
        metadata: {
            anonymousListing: anonymousListingPackagePurchased,
            socialBoost: socialBoostPackagePurchased,
        },
    } = currentListing.attributes;

    const isPackagesSelectStep = tab === BOOSTING_CH || tab === BOOSTING_DE;
    const isCheckoutStep = tab === CHECKOUT;
    const { socialBoost, anonymousListing } = values || {};
    const { packages: packagesSearchString } = parse(search) || {};
    const { socialBoost: socialBoostSearchParam, anonymousListing: anonymousListingSearchParam } = (
        packagesSearchString || ''
    )
        .split(',')
        .reduce((acc, pkg) => ({ ...acc, [pkg]: true }), {});

    const packagePurchased = socialBoost || anonymousListing;
    const valuesSelected = [
        (socialBoost || socialBoostSearchParam) && !socialBoostPackagePurchased
            ? 'socialBoost'
            : null,
        (anonymousListing || anonymousListingSearchParam) && !anonymousListingPackagePurchased
            ? 'anonymousListing'
            : null,
    ].filter(v => !!v);

    const { paymentStatus } = parse(search) || {};

    const showError = error || paymentStatus == 'failed';
    const showAlert = paymentStatus == 'success';

    const countryBoostingConfig = {
        CH: BOOSTING_CH,
        DE: BOOSTING_DE,
    };

    const isDraftVariant = variant === LISTING_PAGE_DRAFT_VARIANT;
    const boostingPath = (country && countryBoostingConfig[country]) || BOOSTING_CH;
    const listingSlug = slug || createSlug(currentListing);
    const editParams = {
        id: listingId,
        slug: isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : listingSlug,
    };

    const handlePayLinkCreation = async () =>
        await createPayrexxPayLink({
            handler: createHorseListingServicesPaylink,
            errorHandler: () => setError('paymentFailed'),
            onInit: () => {
                setError(null);
                setLoading(true);
            },
            onFinalize: () => setLoading(false),
            extraParams: {
                listingId,
                userEmail: email,
                socialBoost: socialBoostPackagePurchased
                    ? null
                    : socialBoost || socialBoostSearchParam,
                anonymousListing: anonymousListingPackagePurchased
                    ? null
                    : anonymousListing || anonymousListingSearchParam,
            },
        });

    const handlePackagesPurchase = e => {
        e.preventDefault();
        setLoading(true);

        if (isNativeIOS()) {
            valuesSelected.forEach(packageId =>
                postIAPMessage(packageId, () => setError('iapIdMissed'))
            );
        } else {
            handlePayLinkCreation();
        }
    };

    const fetchPackages = async () => {
        setLoading(true);

        const packagesResponse = await getlistingPackagesByCountry({
            userCountry: getUserCountry(),
        });

        if (packagesResponse?.success) {
            setDisplayPackages(
                packagesResponse.neededPackages.map(pkg => ({
                    ...pkg,
                    disabled: !!currentListing.attributes.metadata[pkg.id],
                }))
            );
        } else {
            setError('paymentInfoFetchingFailed');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    useEffect(() => {
        if (paymentStatus == 'success') {
            onSubmit({ ...values, redirectToListingPage: true });
        }
        if (paymentStatus == 'failed') {
            setError('paymentFailed');
        }
    }, [paymentStatus]);

    useNativeIosPurchasingEvent({
        errorHandler: () => {
            setLoading(false);
            setError('paymentFailed');
            setReady(false);
        },
        paymentFailedHandler: () => {
            setError('paymentInfoFetchingFailed');
        },
        onSuccessHandler: () => {
            setReady(true);

            const formState = form.getState();
            onSubmit({ ...formState.values, redirectToListingPage: true });
        },
        args: [listingId],
    });

    return (
        <div className={css.pricingPageTwoWrapper}>
            {showAlert && (
                <Alert
                    type="warning"
                    header="EditListingWizard.EditListingPricingPageTwo.redirectNotficationHeader"
                    description="EditListingWizard.EditListingPricingPageTwo.redirectNotficationDesc"
                    rootClassName={css.paymentFailureAlert}
                />
            )}
            {showError && (
                <>
                    <Alert
                        type="failure"
                        header={`EditListingWizard.EditListingPricingPageTwo.failureHeader-${error ||
                            'default'}`}
                        description={`EditListingWizard.EditListingPricingPageTwo.failureMessage-${error ||
                            'default'}`}
                        rootClassName={css.paymentFailureAlert}
                    />
                    <footer className={css.errorActionsHolder}>
                        <Button type="button" onClick={() => window.location.reload()}>
                            Aktualisierung
                        </Button>
                        <Button
                            type="button"
                            onClick={() => {
                                setError(null);

                                history.push(
                                    createResourceLocatorString(
                                        'EditListingPage',
                                        routeConfiguration(),
                                        {
                                            ...editParams,
                                            type: LISTING_PAGE_PARAM_TYPE_EDIT_AND_REDIRECT,
                                            tab: boostingPath,
                                        },
                                        {}
                                    )
                                );
                            }}
                        >
                            Abbrechen
                        </Button>
                    </footer>
                </>
            )}
            {!showError && (
                <h3 className={css.secondPageTitle}>
                    <FormattedMessage id={`EditListingPricingForm.extraPackageHeading-${tab}`} />
                    {isCheckoutStep && (
                        <p className={css.checkoutInfoProtectionSidenote}>
                            <IconLock />
                            Sichere Zahlung
                        </p>
                    )}
                </h3>
            )}

            {isPackagesSelectStep && !showError && (
                <EditListingPricingPagePackagesList
                    loading={loading}
                    displayPackages={displayPackages}
                    valuesSelected={valuesSelected}
                    packagePurchased={packagePurchased}
                    navigateToCheckoutStep={() =>
                        history.push(
                            createResourceLocatorString(
                                'EditListingPage',
                                routeConfiguration(),
                                {
                                    ...params,

                                    tab: CHECKOUT,
                                },
                                {
                                    packages: valuesSelected.join(','),
                                }
                            )
                        )
                    }
                />
            )}
            {isCheckoutStep && (
                <EditListingPricingPageCheckout
                    handlePackagesPurchase={handlePackagesPurchase}
                    displayPackages={displayPackages}
                    loading={loading}
                    ready={ready}
                    search={search}
                />
            )}
        </div>
    );
};

export default EditListingPricingPageTwo;
