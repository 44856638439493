import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';

import css from './EditUserData.css';

export default ({ submittedValues, setSubmittedValues, ...restProps }) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                handleSubmit,
                inProgress,
                invalid,
                ready,
                form,
                values,
                setModalVisibility,
                setModalForm,
                saveEmailError,
                pristine,
            } = fieldRenderProps;

            const emailRequired = validators.required('Wie lautet deine E-Mail-Adresse?');
            const emailValid = validators.emailFormatValid('Gib eine gültige E-Mail-Adresse an');
            const isIncorrectPasswrod = error =>
                error && error.status ? error.status === 403 : false;
            const passwordMinLength = validators.minLength(
                `Das Passwort ist zu kurz. Die Mindestlänge beträgt ${validators.PASSWORD_MIN_LENGTH}.`,
                validators.PASSWORD_MIN_LENGTH
            );
            const passwordMaxLength = validators.maxLength(
                `Das Passwort ist zu lang. Die maximale Länge beträgt ${validators.PASSWORD_MAX_LENGTH}.`,
                validators.PASSWORD_MAX_LENGTH
            );
            const passwordRequired = validators.requiredStringNoTrim(
                'Wir benötigen dein aktuelles Passwort'
            );

            const submittedOnce = Object.keys(submittedValues).length > 0;
            const pristineSinceLastSubmit = submittedOnce && isEqual(values, submittedValues);
            const classes = classNames(rootClassName || css.root, className);
            const submitDisabled = invalid || pristineSinceLastSubmit || inProgress || pristine;

            return (
                <Form
                    className={classes}
                    onSubmit={e => {
                        setSubmittedValues(values);

                        handleSubmit(e)
                            .then(() => {
                                form.reset();
                                setModalVisibility(false);
                                setModalForm(null);
                            })
                            .catch(() => {
                                // Error is handled in duck file already.
                            });
                    }}
                >
                    <h3 className={css.title}>E-Mail-Adresse ändern </h3>
                    <div className={css.editSection}>
                        <FieldTextInput
                            className={css.password}
                            type="email"
                            id="email"
                            name="email"
                            autoComplete="email"
                            placeholder="Neue E-Mail-Adresse"
                            validate={validators.composeValidators(emailRequired, emailValid)}
                        />
                        <FieldTextInput
                            className={css.password}
                            type="password"
                            id="currentPassword"
                            name="currentPassword"
                            autoComplete="current-password"
                            placeholder="Passwort"
                            validate={validators.composeValidators(
                                passwordRequired,
                                passwordMinLength,
                                passwordMaxLength
                            )}
                        />
                    </div>
                    <div className={css.bottomWrapper}>
                        {saveEmailError && !inProgress && (
                            <span className={css.error}>
                                {isIncorrectPasswrod(saveEmailError)
                                    ? 'Falsches Passwort'
                                    : 'Etwas ist schief gelaufen'}
                            </span>
                        )}
                        <Button
                            type="submit"
                            inProgress={inProgress}
                            ready={ready}
                            disabled={submitDisabled}
                        >
                            E-Mail-Adresse ändern
                        </Button>
                    </div>
                </Form>
            );
        }}
    />
);
