import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { Button, SecondaryButton, NamedLink, PreviewButton } from '../../../../components';
import { ArrowNextIcon } from '../../../../icons';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import css from './BottomNavigation.css';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routeConfiguration';
import { TEMP_LISTING_STORAGE_KEY } from '../../../../util/localStorage';
import { getListingNavParams } from '../../../../util/listings';

const BottomNavigation = ({
    allStepsCount,
    currentStep,
    tabNum,
    handleStepChange,
    isOfflineFlow = false,
    submitInProgress,
    submitDisabled,
    proceedDisabled,
    values,
    currentListing,
    isNewListingFlow,
    showPreviewListingBtn,
    onSubmit,
    nextBtnMsgId,
    backTitleId,
    hideNextArrow,
    history,
}) => {
    const isLastStep = allStepsCount == currentStep;

    const handleOnNextStep = () => {
        if (tabNum == 1) {
            localStorage.setItem(TEMP_LISTING_STORAGE_KEY, JSON.stringify(values));
        }

        if (isLastStep && tabNum == 1) {
            return history.push(
                createResourceLocatorString(
                    'SignupHorseowner',
                    routeConfiguration(),
                    {},
                    {
                        tempListingInfoSaved: true,
                    }
                )
            );
        } else {
            handleStepChange({
                action: 'next',
                tabNum: tabNum,
            });
        }
    };

    const handleSaveAndExit = () => {
        onSubmit({
            ...values,
            redirectToListingPage: true,
        });
    };

    const { params, name } = currentListing.id ? getListingNavParams(currentListing) : {};

    const previewListing = () =>
        history.push(
            createResourceLocatorString(
                name,
                routeConfiguration(),
                { ...params },
                {
                    skipUnsavedChangesAlert: 'true',
                }
            )
        );

    return (
        <div className={css.root}>
            {showPreviewListingBtn && params && name && (
                <div className={css.viewListingWrapper}>
                    <PreviewButton onClickHandler={previewListing} />
                </div>
            )}
            <div className={css.navWrapper}>
                <div
                    className={classNames(css.innerWrapper, {
                        [css.rowDirection]: isNewListingFlow,
                    })}
                >
                    <div className={isNewListingFlow ? css.halfBtn : css.fullBtn}>
                        {/* back btn */}
                        {currentStep > 1 && isNewListingFlow ? (
                            <a
                                className={css.backBtn}
                                type="button"
                                onClick={() =>
                                    handleStepChange({
                                        action: 'previous',
                                        tabNum: tabNum,
                                    })
                                }
                            >
                                <FormattedMessage
                                    id={backTitleId || 'EditListingWizard.backTitle'}
                                />
                            </a>
                        ) : null}

                        {/* save and go to listing page btn */}
                        {!isNewListingFlow && (
                            <SecondaryButton
                                className={css.backToListingBtn}
                                type="button"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                onClick={() => handleSaveAndExit()}
                            >
                                <FormattedMessage
                                    id={backTitleId || 'EditListingWizard.saveAndExit'}
                                />
                            </SecondaryButton>
                        )}
                    </div>

                    <div className={isNewListingFlow ? css.halfBtn : css.fullBtn}>
                        {/* save to local storage and next btn */}
                        {isOfflineFlow ? (
                            <Button
                                className={css.nextBtn}
                                type="button"
                                inProgress={submitInProgress}
                                disabled={submitDisabled}
                                onClick={() => handleOnNextStep()}
                            >
                                <FormattedMessage id="EditListingWizard.saveEditandNew" />
                                <ArrowNextIcon />
                            </Button>
                        ) : (
                            /* save and next btn */
                            <Button
                                className={css.nextBtn}
                                type="submit"
                                inProgress={submitInProgress}
                                disabled={submitDisabled || proceedDisabled}
                            >
                                <FormattedMessage
                                    id={
                                        nextBtnMsgId
                                            ? nextBtnMsgId
                                            : isNewListingFlow
                                            ? 'EditListingWizard.saveEditandNew'
                                            : 'EditListingWizard.nextBtnInEditMode'
                                    }
                                />
                                {hideNextArrow ? null : <ArrowNextIcon />}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default compose(withRouter)(BottomNavigation);
