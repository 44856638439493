import React from 'react';
/** icon-compass-l */
/** icon-compass */
export default ({ rootClassName = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={rootClassName}
    >
        <path
            d="M22.120 0.066 C 22.065 0.075,21.941 0.101,21.843 0.123 C 21.633 0.172,0.929 9.088,0.722 9.219 C 0.540 9.333,0.306 9.593,0.205 9.790 C -0.165 10.515,0.206 11.471,0.963 11.742 C 1.060 11.777,3.334 12.127,6.015 12.521 C 8.695 12.914,10.893 13.241,10.898 13.248 C 10.903 13.255,11.199 15.366,11.555 17.940 C 11.911 20.514,12.222 22.710,12.246 22.820 C 12.391 23.484,12.948 23.928,13.640 23.933 C 13.885 23.934,13.985 23.915,14.179 23.826 C 14.486 23.685,14.650 23.558,14.794 23.349 C 14.889 23.211,23.637 2.828,23.828 2.300 C 23.942 1.984,23.966 1.702,23.902 1.399 C 23.759 0.712,23.219 0.191,22.533 0.079 C 22.405 0.058,22.282 0.043,22.260 0.046 C 22.238 0.049,22.175 0.058,22.120 0.066 M22.392 1.608 C 22.418 1.634,22.440 1.681,22.440 1.711 C 22.440 1.769,13.731 22.160,13.686 22.209 C 13.671 22.224,13.355 20.046,12.983 17.369 C 12.611 14.691,12.283 12.416,12.253 12.313 C 12.191 12.099,12.031 11.943,11.808 11.880 C 11.727 11.857,9.446 11.514,6.740 11.119 C 4.034 10.723,1.804 10.394,1.784 10.388 C 1.764 10.381,2.664 9.981,3.784 9.498 C 4.904 9.016,9.501 7.033,14.000 5.093 C 18.499 3.153,22.217 1.564,22.262 1.563 C 22.307 1.561,22.366 1.582,22.392 1.608 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
