import React, { useState } from 'react';
import { injectIntl } from '../../util/reactIntl';
import { ModalPortal } from '../../components';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { CONFIRM_PHONE_NUMBER, ENTER_PHONE_NUMBER, PHONE_NUMBER_CONFIRMED } from './helpers';
import StepConfirmPhoneNumber from './StepConfirmPhoneNumber';
import StepPhoneNumberConfirmed from './StepPhoneNumberConfirmed';
import StepEnterPhoneNumber from './StepEnterPhoneNumber';
import css from './MissingPhoneNumberModal.css';

const MissingPhoneNumberModalComponent = ({
    id = 'MissingPhoneNumberModal',
    intl,
    initialState,
    isOpen,
    history,
    onClose,
    onSubmitContactDetails,
    onSendVerificationOtp,
    onVerifyPhoneNumber,
    missingPhoneNumberDescriptionId = 'missingPhoneNumberDescription',
    redirectOnCLose = true,
    currentUser,
    phoneNumber: currentPhoneNumber,
    countryCode: countryCodeFromProps,
}) => {
    const { attributes } = currentUser || {};
    const { profile, email } = attributes || {};

    const protectedData = currentUser ? profile.protectedData : null;
    const currentPhoneNumberVerified = protectedData && protectedData.phoneVerified;
    const currentEmail = currentUser ? email : null;

    const initialStateMaybe =
        initialState || (currentPhoneNumber && CONFIRM_PHONE_NUMBER) || ENTER_PHONE_NUMBER;

    const [verificationState, setVerificationState] = useState(initialStateMaybe);
    const [otpCode, setOtpCode] = useState(null);
    const [otpError, setOtpError] = useState(null);
    const [phoneSubmitError, setPhoneSubmitError] = useState(null);
    const [phoneVerificationError, setPhoneVerificationError] = useState(null);
    const [submitInProgress, setSubmitInProgress] = useState(null);

    const initialPhoneNumber = currentPhoneNumber ? currentPhoneNumber.replace(/\+\d\d/, '') : null;

    const handleCompleteProfileDismiss = () =>
        history.push(createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}));

    const sendOtp = async ({ phoneNumber, countryCode }) => {
        try {
            const phoneNumberFormatted = countryCode.toString() + phoneNumber.replace(/\D/g, '');
            const otp = await onSendVerificationOtp(phoneNumberFormatted);

            setOtpCode(otp);
            setVerificationState(CONFIRM_PHONE_NUMBER);
            setSubmitInProgress(false);
        } catch (e) {
            setOtpError(e || intl.formatMessage({ id: 'MissingPhoneNumberModal.otpSubmitError' }));
        } finally {
            setSubmitInProgress(false);
        }
    };

    const handleSubmitPhoneNumberForm = async values => {
        setPhoneSubmitError(null);
        setSubmitInProgress(true);
        setOtpError(null);

        try {
            await onSubmitContactDetails({ ...values, currentEmail, currentPhoneNumber });
            await sendOtp(values);
        } catch (e) {
            setPhoneSubmitError(
                e.message ||
                    e.data ||
                    intl.formatMessage({ id: 'MissingPhoneNumberModal.phoneNumberError' })
            );
            setSubmitInProgress(false);
        }
    };

    const verifyPhoneNumber = () => {
        setSubmitInProgress(true);
        setPhoneVerificationError(null);
        onVerifyPhoneNumber({
            phoneNumber: `${countryCodeFromProps}${currentPhoneNumber}`,
            phoneVerified: true,
        })
            .then(() => setVerificationState(PHONE_NUMBER_CONFIRMED))
            .catch(e => {
                setPhoneVerificationError(
                    e.message ||
                        e.data ||
                        intl.formatMessage({ id: 'MissingPhoneNumberModal.phoneNumberError' })
                );
            })
            .finally(() => setSubmitInProgress(false));
    };

    const handleReSendOtp = async () => {
        if (!currentPhoneNumber) {
            return setOtpError(
                intl.formatMessage({ id: 'MissingPhoneNumberModal.otpCodePhoneMissingError' })
            );
        }

        setOtpError(null);
        setSubmitInProgress(true);

        await sendOtp({
            countryCode: countryCodeFromProps,
            phoneNumber: currentPhoneNumber,
        });
    };

    const stateConfig = {
        [ENTER_PHONE_NUMBER]: {
            content: (
                <StepEnterPhoneNumber
                    countryCode={countryCodeFromProps}
                    phoneNumber={initialPhoneNumber}
                    submitInProgress={submitInProgress}
                    phoneSubmitError={phoneSubmitError}
                    missingPhoneNumberDescriptionId={missingPhoneNumberDescriptionId}
                    handleSubmitPhoneNumberForm={handleSubmitPhoneNumberForm}
                />
            ),
        },
        [CONFIRM_PHONE_NUMBER]: {
            content: (
                <StepConfirmPhoneNumber
                    otpCode={otpCode}
                    countryCode={countryCodeFromProps}
                    currentPhoneNumber={currentPhoneNumber}
                    submitInProgress={submitInProgress}
                    otpError={otpError}
                    phoneVerificationError={phoneVerificationError}
                    verifyPhoneNumber={verifyPhoneNumber}
                    handleReSendOtp={handleReSendOtp}
                    setVerificationState={setVerificationState}
                    setOtpError={setOtpError}
                />
            ),
        },
        [PHONE_NUMBER_CONFIRMED]: {
            content: <StepPhoneNumberConfirmed onClose={onClose} />,
        },
    };

    const handleClose =
        redirectOnCLose && (!currentPhoneNumber || !currentPhoneNumberVerified)
            ? handleCompleteProfileDismiss
            : onClose;

    const modalComponent = (
        <ModalPortal
            id={id}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
            isOpen={isOpen}
            onClose={handleClose}
        >
            {stateConfig[verificationState].content}
        </ModalPortal>
    );

    return modalComponent;
};

export default compose(withRouter, injectIntl)(MissingPhoneNumberModalComponent);
