import React from 'react';
/** icon-compass-filled-l */
/** icon-compass-filled */
export default ({ rootClassName = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={rootClassName}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.6212 3.47477L23.6217 3.47355C24.1647 2.21284 22.9118 0.847915 21.5487 1.35757C21.5372 1.36186 21.5258 1.36644 21.5146 1.37129L2.0713 9.74953C2.05857 9.75501 2.04599 9.76085 2.03358 9.76703C1.44277 10.0615 1.18769 10.6663 1.26281 11.215C1.34011 11.7796 1.76769 12.2933 2.44882 12.39L2.45146 12.3904L11.4244 13.705L12.6556 22.5508L12.6561 22.5544C12.7546 23.2377 13.2736 23.6606 13.835 23.7369C14.3807 23.811 14.9872 23.5604 15.2838 22.9693C15.2906 22.9557 15.297 22.9419 15.303 22.9279L23.6212 3.47477Z"
            fill="#1A2B49"
        />
    </svg>
);
