import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { required } from '../../../util/validators';
import { Button, FieldSelect, FieldCheckbox, FieldTextInput } from '../../../components';
import { getYearsList, resetDateFieldOnChange } from './helpers';
import { getUsersClubsByChars, getUsersClubs } from '../../../util/api';
import { isLocalhost } from '../../../util/urlHelpers';
import css from '../EditUserInfoModal.css';

const yearsList = getYearsList();

const EditUserClubsForm = ({
    form,
    values,
    pristine,
    modalParams,
    currentUser,
    parentSubmit,
    updateInProgress,
}) => {
    const [initialSuggestions, setInitialSuggestions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    const { dateFrom, dateTo, clubName = '', url: clubUrl = null, isMember } = values;
    /**
     * Club has to be selected from list, a custom value is not allowed
     */
    const clubNameSelected =
        clubName &&
        (initialSuggestions.some(s => s.name === clubName) ||
            suggestions.some(s => s.name === clubName));

    const disabled = !clubNameSelected || !dateFrom || (isMember ? false : !dateTo);
    const isEditFlow = Boolean(modalParams && modalParams.name);
    const showInitialSuggestions = clubName.length === 0;
    const showRequestedSuggestions = clubName.length >= 4;
    const first4Chars = !showRequestedSuggestions ? '' : clubName.trim();

    const autocompleteOptionsList = showInitialSuggestions
        ? initialSuggestions
        : showRequestedSuggestions
        ? suggestions
        : [];

    const {
        id: { uuid: userId },
        attributes: {
            profile: { firstName, lastName },
            email,
        },
    } = currentUser;

    const handleInitialClubsRequest = async () => {
        try {
            const response = await getUsersClubs();
            const data = await response.json();
            setInitialSuggestions(data.data || []);
            setSuggestions([]);
        } catch (e) {
            if (isLocalhost()) {
                console.log('handleInitialClubsRequest.error', e.message);
            }
        }
    };

    const handleSuggestionsRequest = async chars => {
        try {
            const data = await getUsersClubsByChars(JSON.stringify({ chars }));
            setSuggestions(data.data || []);
            setInitialSuggestions([]);
        } catch (e) {
            if (isLocalhost()) {
                console.log('handleSuggestionsRequest.error', e.message);
            }
        }
    };

    const renderClubsItem = clubItem => (
        <p
            key={clubItem._id}
            className={css.autocompleteDefaultItem}
            onClick={() => {
                form.change('clubName', clubItem.name);
                form.change('url', clubItem.url);
            }}
        >
            {clubItem.name}
        </p>
    );

    useEffect(() => {
        if (userId && showInitialSuggestions) {
            /** Fetch clubs that match with the users postalCode and show them as suggestions. */
            handleInitialClubsRequest();
        }
    }, [userId, showInitialSuggestions]);

    useEffect(() => {
        if (first4Chars && !pristine) {
            /** Fetch clubs when a user types smth inside the form field */
            handleSuggestionsRequest(first4Chars);
        }
    }, [first4Chars, pristine]);

    return (
        <section>
            <FieldTextInput
                className={css.mb8}
                type="text"
                id="clubName"
                name="clubName"
                autocomplete="clubName"
                placeholder="Verein hinzufügen"
                validate={required('Dieses Feld wird benötigt')}
                autocompleteOptionsList={autocompleteOptionsList}
                autocompleteListRenderer={clubItem => renderClubsItem(clubItem)}
                autocompleteListVisible
                searchFieldComponent
                form={form}
            />
            <p className={classNames(css.helperText, css.mt8)}>
                Verein wird nicht in der Liste angezeigt?{' '}
                <a
                    href={`https://forms.horsedeal.com/verein-vorschlagen?vorname=${firstName}&nachname=${lastName}&emailadresse=${email}`}
                    target="_blank"
                    rel="nofollow"
                >
                    Verein vorschlagen
                </a>
            </p>

            <div className={css.trainingYearsSection}>
                <FieldSelect
                    className={css.mb24}
                    type="text"
                    id="dateFrom"
                    name="dateFrom"
                    placeholder="Von Jahr"
                    form={form}
                    optionsList={yearsList}
                    validate={required('Dieses Feld wird benötigt')}
                    notifyOnChange={() => resetDateFieldOnChange({ form })}
                />

                <FieldSelect
                    className={css.mb24}
                    form={form}
                    type="text"
                    placeholder="Bis Jahr"
                    id={isMember ? 'notUsed' : 'dateTo'}
                    name={isMember ? 'notUsed' : 'dateTo'}
                    disabled={!!isMember}
                    validate={required('Dieses Feld wird benötigt')}
                    notifyOnChange={() => resetDateFieldOnChange({ form })}
                    optionsList={
                        dateFrom ? yearsList.filter(({ value }) => value >= dateFrom) : yearsList
                    }
                />
            </div>
            <FieldCheckbox
                className={classNames([css.blck, css.mb24, css.currentJobField])}
                id="isMember"
                name="isMember"
                label="Ich bin aktuell Mitglied"
            />

            <footer>
                <Button
                    disabled={disabled || pristine}
                    inProgress={updateInProgress}
                    onClick={() =>
                        parentSubmit({
                            clubMembership: {
                                clubName,
                                clubUrl,
                                dateFrom: dateFrom,
                                dateTo: dateTo,
                                isMember: isMember,
                            },
                        })
                    }
                >
                    Verein hinzufügen
                </Button>
                {isEditFlow && (
                    <div
                        className={css.discardAction}
                        onClick={() => parentSubmit({ clubMembership: null })}
                    >
                        Löschen
                    </div>
                )}
            </footer>
        </section>
    );
};

export default ({ onSubmit, ...rest }) => (
    <FinalForm
        {...rest}
        onSubmit={onSubmit}
        render={fieldRenderProps => (
            <EditUserClubsForm {...fieldRenderProps} parentSubmit={onSubmit} />
        )}
    />
);
