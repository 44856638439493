import React, { useState, useEffect, useRef } from 'react';

import { compose } from 'redux';
import { FieldCheckbox, FieldTextInput } from '../../components';
import { IconCloseCircleL } from '../../icons';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { composeValidators, isEntryWithoutSpecChars } from '../../util/validators';

import css from './CustomFormField.css';
import classNames from 'classnames';
import IconCheckbox from '../../components/FieldCheckbox/IconCheckbox';

const CustomFormField = ({
    form,
    values,
    fieldName,
    options: optionsFromProps,
    nonCustomOptions,
    initialValues,
    maxCountAllowed,
    intl,
    children,
    customFieldPlaceholderId,
    fieldValueKey = 'label',
}) => {
    const [options, setOptions] = useState([]);
    const [customOptions, setCustomOptions] = useState([]);

    const optionsRef = useRef();
    const customOptionsRef = useRef();
    const customRef = useRef();

    const { getFieldState, change: changeFormField } = form;

    const optionsFormValue = (values || {})[fieldName] || [];

    const { value: customValue } = getFieldState('customOption') || {};

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        /**
         * re-assign options values to ref
         * so that event listener might grasp
         * up-to-date values
         */
        optionsRef.current = options;
        customOptionsRef.current = customOptions;
    });

    useEffect(() => {
        const initialOptions = (initialValues || {})[fieldName] || [];

        const customOptionsMaybe = initialOptions
            .filter(
                initialOption =>
                    !nonCustomOptions.some(entry => entry[fieldValueKey] === initialOption)
            )
            .map(optionLabel => ({ label: optionLabel, isCustom: true, id: optionLabel }));

        setCustomOptions([...customOptionsMaybe]);

        setOptions(
            Object.values(
                [...optionsFromProps, ...customOptionsMaybe].reduce(
                    (acc, item) => ({ ...acc, [item.id]: item }),
                    {}
                )
            )
        );
    }, [initialValues, optionsFromProps]);

    const customOptionAlreadyCreated = message => value =>
        !value
            ? undefined
            : options.some(
                  entry =>
                      (entry[fieldValueKey] || '').toLocaleLowerCase() ===
                      (value || '').toLocaleLowerCase()
              )
            ? message
            : undefined;

    const addOption = value => {
        const { value: optionsFormValue } = getFieldState(fieldName) || {};
        changeFormField(fieldName, optionsFormValue ? [...optionsFormValue, value] : [value]);
    };

    const addCustomOption = (option, callback) => {
        callback();

        setCustomOptions([...customOptionsRef.current, option]);
        setOptions([...optionsRef.current, option]);
    };

    const removeOption = value => {
        const filterByLabel = entry => /*entry[fieldValueKey]*/ entry.label !== value;
        const filterByValue = fieldValue => fieldValue !== value;

        const sanitizedOptions = optionsFormValue ? optionsFormValue.filter(filterByValue) : [];

        changeFormField(fieldName, sanitizedOptions);
        setOptions(options.filter(filterByLabel));
        setCustomOptions(prevValue => prevValue.filter(filterByLabel));
    };

    const handleBlur = () => {
        const { valid, value, blur: blurCustomField, change: changeCustomValue } =
            getFieldState('customOption') || {};

        blurCustomField();

        setTimeout(() => {
            if (!valid || !value) {
                return;
            }

            addCustomOption({ label: value, isCustom: true, id: value }, () => addOption(value));

            changeCustomValue('');

            const customOptionsNum =
                optionsRef.current.filter(({ isCustom }) => isCustom).length + 1;
            const customAllowed = !maxCountAllowed || customOptionsNum < maxCountAllowed;

            if (customAllowed) {
                form.resetFieldState('customOption');
            }
        }, 250);
    };

    const customFieldValidators = composeValidators(
        isEntryWithoutSpecChars(
            intl.formatMessage({
                id: 'CollectUserInfoWizard.onlyStringsAllowed',
            })
        ),
        customOptionAlreadyCreated(
            intl.formatMessage({
                id: 'CollectUserInfoWizard.fieldAlreadyExists',
            })
        )
    );

    const handleKeyDown = event => {
        const { keyCode } = event;
        const { getFieldState } = form;

        const { active: isFocused, dirty } = getFieldState('customOption') || { active: false };

        const isEnter = keyCode === 13;

        if ((isFocused || dirty) && isEnter) {
            event.preventDefault();

            handleBlur();
        }
    };

    const hasValue = !!customValue;
    const customAllowed = !maxCountAllowed || customOptions.length < maxCountAllowed;

    return (
        <div>
            {options.map((entry, i) => {
                const { label, isCustom } = entry;

                return (
                    <FieldCheckbox
                        key={i}
                        type="checkbox"
                        id={label}
                        name={fieldName}
                        label={label}
                        value={fieldValueKey ? entry[fieldValueKey] : label}
                        form={form}
                    >
                        {isCustom && (
                            <IconCloseCircleL
                                rootClassName={css.removeCustomIcon}
                                clickHandler={() => removeOption(label)}
                            />
                        )}
                    </FieldCheckbox>
                );
            })}
            {children}
            {customAllowed && (
                <div className={classNames([css.checkboxItem, css.customOptionHolder])}>
                    <IconCheckbox />
                    {!hasValue && (
                        <p>
                            <FormattedMessage
                                id={
                                    customFieldPlaceholderId ||
                                    'CollectUserInfoWizard.addNewCustomDiscipline'
                                }
                            />
                        </p>
                    )}
                    <FieldTextInput
                        wrapperClassName={css.customOption}
                        type="text"
                        id="customOption"
                        name="customOption"
                        inputRef={customRef}
                        onBlur={handleBlur}
                        validate={customFieldValidators}
                        placeholder={intl.formatMessage({
                            id:
                                customFieldPlaceholderId ||
                                'CollectUserInfoWizard.addNewCustomDiscipline',
                        })}
                    />
                </div>
            )}
        </div>
    );
};

export default compose(injectIntl)(CustomFormField);
