import { useEffect } from 'react';
import { updateInAppPurchasePackages, updateInAppPurchaseCredits } from '../util/api';
import {
    isNativeIOS,
    checkIAPTransactionMessage,
    checkIfCreditsProduct,
    getMarketplaceProductId,
    checkIfHorsePackagesProduct,
} from '../util/ios';

export const useNativeIosPurchasingEvent = ({
    errorHandler = () => null,
    paymentFailedHandler = () => null,
    onSuccessHandler = () => null,
    args = [],
}) => {
    const handleInAppPurchasing = async e => {
        const messageData = checkIAPTransactionMessage(e);

        if (!messageData) return;
        if (messageData.error) return errorHandler();

        const { productId, transactionId } = messageData;

        const marketplaceProductId = getMarketplaceProductId(productId);

        const isCreditsProduct = checkIfCreditsProduct(marketplaceProductId);
        const isHorsePackagesProduct = checkIfHorsePackagesProduct(marketplaceProductId);

        const creditsPurchasinghandler = id => updateInAppPurchaseCredits(id);
        const packagesPurchasinghandler = id => updateInAppPurchasePackages(id, ...args);

        const handler = isCreditsProduct
            ? creditsPurchasinghandler
            : isHorsePackagesProduct
            ? packagesPurchasinghandler
            : null;

        if (!handler) return errorHandler();

        const response = await handler(transactionId);
        const data = await response.json();

        if (data.error) return paymentFailedHandler();

        onSuccessHandler();
    };

    useEffect(() => {
        if (!isNativeIOS()) return;
        /** Listen to WebView messages */

        document.addEventListener('message', handleInAppPurchasing);
        window.addEventListener('message', handleInAppPurchasing);

        return function() {
            document.removeEventListener('message', handleInAppPurchasing);
            window.removeEventListener('message', handleInAppPurchasing);
        };
    }, []);
};
