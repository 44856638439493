import React from 'react';
import classNames from 'classnames';
import css from './Followers.css';

export default ({ rootClassName, width = 48, shiftPercentage = 31.25, children }) => (
    <div
        className={classNames(css.followersHolder, {
            [rootClassName]: !!rootClassName,
        })}
    >
        <div className={css.followers}>
            {Array.from({ length: 5 }, (_, index) => (
                <img
                    key={index}
                    src={`https://horsedeal.imgix.net/static/landingPage/social-proof-${index +
                        1}.webp`}
                    alt="our-customers"
                    style={{
                        width: `${width}px`,
                        height: `${width}px`,
                        left: `${(width / 100) * shiftPercentage * index * -1}px`,
                    }}
                />
            ))}
        </div>
        {children}
    </div>
);
