import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.0414 22C18.9905 20.9425 17.7408 20.1032 16.3643 19.5305C14.9878 18.9578 13.5116 18.663 12.0207 18.663C10.5298 18.663 9.05362 18.9578 7.67711 19.5305C6.30059 20.1032 5.05092 20.9425 4 22M18.1119 9.09123C18.1119 12.4553 15.3848 15.1825 12.0207 15.1825C8.65661 15.1825 5.92947 12.4553 5.92947 9.09123C5.92947 5.72714 8.65661 3 12.0207 3C15.3848 3 18.1119 5.72714 18.1119 9.09123Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
