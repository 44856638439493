import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getUserAddressInfo } from '../../services/ipinfo';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { getCurrentUserLocation } from '../../util/localStorage';
import { txIsDeclined, txIsExpired } from '../../util/transaction';
import { getUserWishlist } from '../FavoritePage/FavoritePage.duck';
import { fetchTransactionsData, txDataApiQueryParams } from '../InboxPage/InboxPage.duck';
import { insertStateEntity, deleteStateEntity } from '../../ducks/Assets.duck';
import { getUserExternalReviews, addUserExternalReviews } from '../../util/api';
import { showUsers } from '../../ducks/SdkDataAccessLayer.duck';
// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_EXTERNAL_REVIEW_REQUEST = 'app/ProfilePage/QUERY_EXTERNAL_REVIEW_REQUEST';
export const QUERY_EXTERNAL_REVIEW_SUCCESS = 'app/ProfilePage/QUERY_EXTERNAL_REVIEW_SUCCESS';
export const QUERY_EXTERNAL_REVIEW_ERROR = 'app/ProfilePage/QUERY_EXTERNAL_REVIEW_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const GET_USER_REVIEWS_SUCCESS = 'app/ProfilePage/GET_USER_REVIEWS_SUCCESS';
export const GET_USER_REVIEWS_FAILURE = 'app/ProfilePage/GET_USER_REVIEWS_FAILURE';

export const GET_LATEST_ORDER_TRANSACTION_SUCCESS =
    'app/ProfilePage/GET_LATEST_ORDER_TRANSACTION_SUCCESS';
export const GET_LATEST_ORDER_TRANSACTION_FAILURE =
    'app/ProfilePage/GET_LATEST_ORDER_TRANSACTION_FAILURE';

export const QUERY_TRANSACTIONS_REQUEST = 'app/ProfilePage/QUERY_TRANSACTIONS_REQUEST';
export const QUERY_TRANSACTIONS_SUCCESS = 'app/ProfilePage/QUERY_TRANSACTIONS_SUCCESS';
export const QUERY_TRANSACTIONS_ERROR = 'app/ProfilePage/QUERY_TRANSACTIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
    userId: null,
    userListingRefs: [],
    userShowError: null,
    userShowLoading: false,
    userShowRequested: false,
    queryListingsError: null,
    reviews: [],
    queryReviewsError: null,
    userRating: null,
    userRatingTotalCount: 0,
    latestOrderTransaction: null,
    externalReviewsRequests: {},
    externalReviewsData: {},
    externalReviewsErrors: {},
    activeTransactionsDataInProgress: false,
    activeTransactionsDataError: null,
    activeTransactionsData: [],
};

export default function profilePageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_STATE:
            return { ...initialState };
        case SHOW_USER_REQUEST:
            return {
                ...state,
                userShowError: null,
                userShowLoading: true,
                userShowRequested: false,
                userId: payload.userId,
            };
        case SHOW_USER_SUCCESS:
            return { ...state, userShowLoading: false, userShowRequested: true };
        case SHOW_USER_ERROR:
            return {
                ...state,
                userShowLoading: false,
                userShowError: payload,
                userShowRequested: true,
                userId: null,
            };

        case QUERY_LISTINGS_REQUEST:
            return {
                ...state,

                // Empty listings only when user id changes
                userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

                queryListingsError: null,
            };
        case QUERY_LISTINGS_SUCCESS:
            return { ...state, userListingRefs: payload.listingRefs };
        case QUERY_LISTINGS_ERROR:
            return { ...state, userListingRefs: [], queryListingsError: payload };
        case QUERY_REVIEWS_REQUEST:
            return { ...state, queryReviewsError: null };
        case QUERY_REVIEWS_SUCCESS:
            return { ...state, reviews: payload };
        case QUERY_REVIEWS_ERROR:
            return { ...state, reviews: [], queryReviewsError: payload };

        case QUERY_EXTERNAL_REVIEW_REQUEST:
            return {
                ...state,
                externalReviewsRequests:
                    payload.id &&
                    insertStateEntity(state, 'externalReviewsRequests', payload.id, true),
                externalReviewsErrors:
                    payload.id && deleteStateEntity(state, 'externalReviewsErrors', payload.id),
            };
        case QUERY_EXTERNAL_REVIEW_SUCCESS:
            return {
                ...state,
                externalReviewsData: insertStateEntity(
                    state,
                    'externalReviewsData',
                    payload.id,
                    payload.data
                ),
                externalReviewsRequests: deleteStateEntity(
                    state,
                    'externalReviewsRequests',
                    payload.id
                ),
                externalReviewsErrors: deleteStateEntity(
                    state,
                    'externalReviewsErrors',
                    payload.id
                ),
            };
        case QUERY_EXTERNAL_REVIEW_ERROR:
            return {
                ...state,
                externalReviewsRequests:
                    payload.id && deleteStateEntity(state, 'externalReviewsRequests', payload.id),
                externalReviewsErrors:
                    payload.data &&
                    payload.id &&
                    insertStateEntity(state, 'externalReviewsErrors', payload.id, payload.data),
            };

        case GET_USER_REVIEWS_SUCCESS:
            return { ...state, userRating: payload.average, userRatingTotalCount: payload.total };
        case GET_USER_REVIEWS_FAILURE:
            return { ...state, userRating: 0 };
        case GET_LATEST_ORDER_TRANSACTION_SUCCESS:
            return { ...state, latestOrderTransaction: payload };
        case GET_LATEST_ORDER_TRANSACTION_FAILURE:
            return { ...state, latestOrderTransaction: null };

        case QUERY_TRANSACTIONS_REQUEST:
            return {
                ...state,
                activeTransactionsDataInProgress: true,
                activeTransactionsDataError: null,
            };
        case QUERY_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                activeTransactionsData: payload,
                activeTransactionsDataInProgress: false,
                activeTransactionsDataError: null,
            };
        case QUERY_TRANSACTIONS_ERROR:
            return {
                ...state,
                activeTransactionsData: [],
                activeTransactionsDataInProgress: false,
                activeTransactionsDataError: payload,
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
    type: SHOW_USER_REQUEST,
    payload: { userId },
});

export const showUserSuccess = () => ({
    type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
    type: SHOW_USER_ERROR,
    error: true,
    payload: e,
});

export const queryListingsRequest = userId => ({
    type: QUERY_LISTINGS_REQUEST,
    payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
    type: QUERY_LISTINGS_SUCCESS,
    payload: { listingRefs },
});

export const queryListingsError = e => ({
    type: QUERY_LISTINGS_ERROR,
    error: true,
    payload: e,
});

export const queryReviewsRequest = () => ({
    type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
    type: QUERY_REVIEWS_SUCCESS,
    payload: reviews,
});

export const queryReviewsError = e => ({
    type: QUERY_REVIEWS_ERROR,
    error: true,
    payload: e,
});

export const getUserReviewSuccess = e => ({
    type: GET_USER_REVIEWS_SUCCESS,
    payload: e,
});

export const getUserReviewFailure = e => ({
    type: GET_USER_REVIEWS_FAILURE,
});

export const getLatestOrderTransactionSuccess = e => ({
    type: GET_LATEST_ORDER_TRANSACTION_SUCCESS,
    payload: e,
});

export const getLatestOrderTransactionFailure = e => ({
    type: GET_LATEST_ORDER_TRANSACTION_FAILURE,
});

export const queryExternalReviewsRequest = payload => ({
    type: QUERY_EXTERNAL_REVIEW_REQUEST,
    payload,
});

export const queryExternalReviewsSuccess = payload => ({
    type: QUERY_EXTERNAL_REVIEW_SUCCESS,
    payload,
});

export const queryExternalReviewsError = payload => ({
    type: QUERY_EXTERNAL_REVIEW_ERROR,
    payload,
});

export const queryTransactionsRequest = () => ({
    type: QUERY_TRANSACTIONS_REQUEST,
});

export const queryTransactionsSuccess = payload => ({
    type: QUERY_TRANSACTIONS_SUCCESS,
    payload,
});

export const queryTransactionsError = e => ({
    type: QUERY_TRANSACTIONS_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = userId => async (dispatch, getState, sdk) => {
    dispatch(queryListingsRequest(userId));
    return sdk.listings
        .query({
            author_id: userId,
            include: ['author', 'images'],
            'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        })
        .then(response => {
            // Pick only the id and type properties from the response listings
            const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
            dispatch(addMarketplaceEntities(response));
            dispatch(queryListingsSuccess(listingRefs));
            return response;
        })
        .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
    sdk.reviews
        .query({
            subject_id: userId,
            state: 'public',
            include: ['author', 'author.profileImage'],
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
        })
        .then(response => {
            const reviews = denormalisedResponseEntities(response);
            dispatch(queryReviewsSuccess(reviews));
        })
        .catch(e => dispatch(queryReviewsError(e)));
};

export const getPublicUser = userId => async (dispatch, getState, sdk) => {
    dispatch(showUserRequest(userId));

    try {
        const response = await dispatch(
            showUsers(userId.uuid, {
                id: userId,
                include: ['profileImage'],
                'fields.image': ['variants.square-small', 'variants.square-small2x'],
            })
        );
        dispatch(addMarketplaceEntities(response));
        dispatch(showUserSuccess());
        return response;
    } catch (e) {
        dispatch(showUserError(storableError(e)));
    }
};

export const getUserReview = userId => (dispatch, getState, sdk) => {
    return sdk.reviews
        .query({
            subjectId: userId,
        })
        .then(response => {
            const data = response.data.data.filter(s => typeof s.attributes.rating === 'number');

            const getRating = data => {
                let total = data.length;
                let sum = 0;
                data.forEach(r => (sum += r.attributes.rating ? r.attributes.rating : 0));
                return (!sum ? 0 : sum / total).toFixed(2);
            };

            const rating = data && data.length ? getRating(data) : 0;

            dispatch(getUserReviewSuccess({ average: rating, total: data.length }));
        })
        .catch(() => {
            dispatch(getUserReviewFailure());
        });
};

export const sendUserExternalReview = data => async (dispatch, getState, sdk) => {
    const userReviewedDataMaybe = data && data.user_reviewed_data;
    dispatch(
        queryExternalReviewsRequest({
            id: userReviewedDataMaybe,
        })
    );

    const userData = getCurrentUserLocation();

    let ip_address = null;

    try {
        if (!userData || !userData.ip) {
            const { error, message, ip } = await getUserAddressInfo();
            if (error || !ip) {
                return dispatch(
                    queryExternalReviewsError({
                        id: userReviewedDataMaybe,
                        data: { reason: 'location-unrecognized', message: message || '' },
                    })
                );
            }

            ip_address = ip;
        } else {
            ip_address = userData.ip;
        }

        const body = JSON.stringify({
            ...data,
            ip_address,
        });

        const response = await addUserExternalReviews(body);
        const responseData = await response.json();

        const { error, reason, message } = responseData;

        if (error || reason || message) {
            return dispatch(
                queryExternalReviewsError({
                    id: userReviewedDataMaybe,
                    data: { reason: reason || 'default', message },
                })
            );
        }

        return dispatch(
            queryExternalReviewsSuccess({
                id: userReviewedDataMaybe,
                data: null,
            })
        );
    } catch (e) {
        return dispatch(
            queryExternalReviewsError({
                id: userReviewedDataMaybe,
                data: { reason: 'default', message: e.message },
            })
        );
    }
};

export const getUserExternalReview = userReviewedId => async (dispatch, getState, sdk) => {
    dispatch(
        queryExternalReviewsRequest({
            id: userReviewedId,
        })
    );

    try {
        const response = await getUserExternalReviews(userReviewedId);
        const responseData = await response.json();

        const { error, reason, message, data } = responseData;

        if (error || reason || message) {
            return dispatch(
                queryExternalReviewsError({
                    id: userReviewedId,
                    data: { reason: reason || 'default', message: message || '' },
                })
            );
        }

        return dispatch(
            queryExternalReviewsSuccess({
                id: userReviewedId,
                data,
            })
        );
    } catch (e) {
        return dispatch(
            queryExternalReviewsError({
                id: userReviewedId,
                data: { reason: 'default', message: e.message },
            })
        );
    }
};

export const getLatestOrderTransaction = () => (dispatch, getState, sdk) => {
    sdk.transactions
        .query({
            only: 'order',
            page: 1,
            per_page: 1,
        })
        .then(response => {
            const tx = response.data.data && response.data.data[0];
            if (tx) {
                dispatch(getLatestOrderTransactionSuccess(tx));
            } else {
                throw new Error('[Warning] No order transaction found.');
            }
        })
        .catch(e => {
            console.error(e);
            dispatch(getLatestOrderTransactionFailure());
        });
};

export const fetchActiveTransactions = queryParamsProp => async (dispatch, getState, sdk) => {
    const { activeTransactionsDataInProgress } = getState().ProfilePage;
    /**
     * Maybe a check for queryParamsProp may be added
     * so the the "if" block is skipped in case the arguments
     * are not the same.
     */
    if (activeTransactionsDataInProgress) return;

    dispatch(queryTransactionsRequest());

    const queryParams = {
        ...txDataApiQueryParams,
    };
    /**
     * Do not include 'fields.user' data into queryParams
     * as it may bring an incorrect results for
     * addMarketplaceEntities, e.g. ProfileSettingsPage public user data
     * may be affected when addMarketplaceEntities is called
     */
    delete queryParams['fields.user'];

    return dispatch(fetchTransactionsData(queryParamsProp || queryParams))
        .then(response => {
            const {
                data: { data },
            } = response;

            dispatch(addMarketplaceEntities(response));

            return dispatch(
                queryTransactionsSuccess(
                    data.reduce((acc, tx) => {
                        if (txIsExpired(tx) || txIsDeclined(tx)) return acc;

                        const {
                            relationships: { listing, customer, provider },
                            attributes: {
                                protectedData: { listingSubstitutionId },
                            },
                        } = tx;

                        return [
                            ...acc,
                            {
                                ...tx,
                                listingId: listingSubstitutionId || listing.data.id.uuid,
                                customerId: customer?.data?.id?.uuid,
                                providerId: provider?.data?.id?.uuid,
                            },
                        ];
                    }, [])
                )
            );
        })
        .catch(e => dispatch(queryTransactionsError(storableError(e))));
};

export const loadData = userId => (dispatch, getState, sdk) => {
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());

    return Promise.all([
        dispatch(getUserReview(userId)),
        dispatch(fetchCurrentUser()),
        dispatch(getPublicUser(userId)),
        dispatch(queryUserListings(userId)),
        dispatch(queryUserReviews(userId)),
        dispatch(getLatestOrderTransaction()),
        dispatch(getUserWishlist()),
        dispatch(fetchActiveTransactions()),
    ]);
};
