import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trimDisplayNameLastWord } from '../../util/text';
import { openListing } from '../../containers/ManageListingsPage/ManageListingsPage.duck';
import config from '../../config';
import css from './ActivityFeed.css';

const { userTypeHorseowner } = config;

export default ({
    currentUser,
    currentListing: currentListingProp,
    otherParty,
    currentTransaction,
}) => {
    const dispatch = useDispatch();
    const onOpenListing = listingId => dispatch(openListing(listingId));

    const handleListingReactivating = async listingId => {
        await onOpenListing(listingId);
        // reload the page
        window.location.reload();
    };

    /** Get listing data from own entities in case the listing has been closed */
    const ownListings = useSelector(s => s.ManageListingsPage?.ownEntities?.ownListing);

    const { listingSubstitutionId } = currentTransaction.attributes.protectedData;
    const currentListing =
        currentListingProp && currentListingProp.id
            ? currentListingProp
            : (ownListings || {})[listingSubstitutionId];

    const { firstName: currentUserName } = currentUser.attributes.profile;

    const { attributes } = currentListing || { attributes: { deleted: true, state: '' } };

    const listingIsDeleted = attributes.deleted;
    const listingIsClosed = attributes.state === 'closed';
    const listingIsNotAvailable = Boolean(!currentListing?.id);
    const listingId = currentListing?.id?.uuid;

    const {
        displayName: otherPartyName,
        publicData: { userType: otherPartyType },
    } = otherParty.attributes.profile;

    const otherPartyIsOwner = otherPartyType === userTypeHorseowner;
    const listingOwnerName = otherPartyIsOwner
        ? trimDisplayNameLastWord(otherPartyName)
        : currentUserName;

    const getNotification = () => {
        if (otherPartyIsOwner && (listingIsClosed || listingIsDeleted || listingIsNotAvailable))
            return <span>{listingOwnerName} hat das Inserat pausiert oder gelöscht.</span>;

        if (!otherPartyIsOwner && listingIsClosed)
            return (
                <>
                    <span>Dein Inserat wurde pausiert.</span>
                    <br />
                    {listingId && (
                        <span
                            className={css.link}
                            onClick={() => handleListingReactivating(listingId)}
                        >
                            Reaktivieren
                        </span>
                    )}
                </>
            );
        if (!otherPartyIsOwner && listingIsDeleted)
            return <span>Dein Inserat wurde gelöscht.</span>;
        return null;
    };

    const notification = getNotification();

    return notification ? <li className={css.transitionItem}>{notification}</li> : null;
};
