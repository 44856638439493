import pickBy from 'lodash/pickBy';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import { pathByRouteName } from '../../util/routes';

export const redirectToURLWithModalState = (props, modalStateParam) => {
    const { history, location } = props;
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
    history.push(`${pathname}${searchString}`, state);
};

export const redirectToURLWithoutModalState = (props, modalStateParam) => {
    const { history, location } = props;
    const { pathname, search, state } = location;
    const queryParams = pickBy(parse(search), (v, k) => {
        return k !== modalStateParam;
    });
    const stringified = stringify(queryParams);
    const searchString = stringified ? `?${stringified}` : '';
    history.push(`${pathname}${searchString}`, state);
};

export const isCurrentPage = (page, currentPage, params, currentUser) => {
    const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isProfileSettingsPage = page === 'ProfileSettingsPage';
    const currentUserDefined = currentUser && currentUser.id;
    if (isProfileSettingsPage && !currentUserDefined) return false;

    const { id } = params;
    const userId = currentUserDefined ? currentUser.id.uuid : null;
    /**
     * when a user looks on another user profile
     */
    if (isProfileSettingsPage && id && userId && id !== userId) return false;

    return currentPage === page || isAccountSettingsPage; // ? css.currentPage : null;
};

export const isCurrentPageLoggedOut = (page, currentPage) => {
    const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);

    return currentPage === page || isAccountSettingsPage; // ? css.currentPage : null;
};

export const handleLogOutSuccess = history => {
    const path = pathByRouteName('LandingPage', routeConfiguration());

    // In production we ensure that data is really lost,
    // but in development mode we use stored values for debugging
    if (config.dev) {
        history.push(path);
    } else if (typeof window !== 'undefined') {
        window.location = path;
    }

    console.log('logged out'); // eslint-disable-line
};
