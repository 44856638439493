import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedMessage } from '../../util/reactIntl';
import { Button, ModalPortal } from '..';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDINGS_PER_WEEK_FLEXIBLE,
} from '../../marketplace-custom-config';
import { updateUserProfileInfo } from '../../ducks/user.duck';
import { reflectRirderAvailabilityStatusToListing } from '../../util/api';
import { stringifyToMarketplaceISO } from '../../util/dates';

import css from './RiderNotAvailableModal.css';
import { ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE } from '../InquiryModal/InquiryModal.helpers';

const { userTypeRider } = config;

/** RiderNotAvailableModal */

export default ({
    isOpen,
    onClose,
    horseownerName,
    riderNotAvailableStatus,
    notifyOnAvailabilityChange = () => null,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onUpdateProfile = (data, publicData) => dispatch(updateUserProfileInfo(data, publicData));

    const { currentUser } = useSelector(state => state.user);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const redirectToProfilePage =
        riderNotAvailableStatus === ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE;

    const redirectToProfilePageHandler = () =>
        history.push(
            createResourceLocatorString(
                'ProfileSettingsPage',
                routeConfiguration(),
                { userType: userTypeRider },
                {}
            )
        );

    const updateUserAvailabilityHandler = () => {
        setError(null);
        setLoading(true);
        /**
         * TODO compute rider availability, right now it
         * is set as flexible (RIDINGS_PER_WEEK_FLEXIBLE) by default
         */
        onUpdateProfile({
            publicData: {
                availability: RIDINGS_PER_WEEK_FLEXIBLE,
                availabilityStatus: RIDER_AVAILABILITY_CONFIRMED,
                availabilityStatusPrevious:
                    currentUser.attributes.profile.publicData.availabilityStatus || null,
                availabilityUpdatedAt: stringifyToMarketplaceISO(new Date()),
            },
        }).then(async () => {
            /**
             * make an api request to re-open rider listing
             * without waiting for an event to handle it
             */
            const currentUserId = currentUser.id.uuid;
            await reflectRirderAvailabilityStatusToListing(currentUserId)
                .then(async response => {
                    const { error, message } = await response.json();

                    if (error) {
                        throw new Error(message || 'Something went wrong, try later.');
                    }

                    notifyOnAvailabilityChange();
                })
                .catch(e => {
                    setError(e.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    return (
        <ModalPortal
            id="ListingPage.RiderNotAvailableModal"
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
            isOpen={isOpen}
            onClose={onClose}
        >
            <h3 className={css.externalListingModalHeading}>
                <FormattedMessage
                    id={`ListingPage.RiderNotAvailableModal.heading.${riderNotAvailableStatus}`}
                />
            </h3>
            <p className={css.externalListingModalNotification}>
                <FormattedMessage
                    id={`ListingPage.RiderNotAvailableModal.desc.${riderNotAvailableStatus}`}
                    values={{ horseownerName }}
                />
            </p>

            <Button
                disabled={error || loading}
                inProgress={loading}
                onClick={() =>
                    (redirectToProfilePage
                        ? redirectToProfilePageHandler
                        : updateUserAvailabilityHandler)()
                }
            >
                <FormattedMessage
                    id={`ListingPage.RiderNotAvailableModal.action.${riderNotAvailableStatus}`}
                />
            </Button>
            {error && <p className={css.error}>{error}</p>}
        </ModalPortal>
    );
};
