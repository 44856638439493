import { useEffect } from 'react';
import { getUserLocationUnitStr } from '../../util/location';
import { FILL_ADDRESS_STEP } from './helpers';
/**
 * Check if there is already location data,  and change step to editing address
 */
export const useInitialValuesToSetStep = ({
    initialValues,
    setCurrentStep,
    setLocationUnits,
    intl,
}) => {
    useEffect(() => {
        const { streetAddress, houseNumber, postalCode, city, country } = initialValues;
        /** country is taken from user id  */
        if (city && houseNumber && streetAddress && postalCode) {
            setLocationUnits(
                getUserLocationUnitStr({
                    streetAddress,
                    houseNumber,
                    postalCode,
                    city,
                    country,
                    intl,
                })
            );

            setCurrentStep(FILL_ADDRESS_STEP);
        }
    }, [initialValues]);
};
