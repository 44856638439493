import React from 'react';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { IconReviewStar, ProgressBarLevels } from '../../components';

import {
    ASSESSMENT_HORSE_DEALING,
    ASSESSMENT_RELIABILITY,
    ASSESSMENT_RIDING_SKILLS,
} from '../../forms/ExternalReviewForm/ExternalReviewForm';

import css from './RecommendationItem.css';

const assessments = [ASSESSMENT_HORSE_DEALING, ASSESSMENT_RELIABILITY, ASSESSMENT_RIDING_SKILLS];

/** Render external recommendations stored in mongoDb */

const RecommendationItem = ({
    abbreviatedName,
    recommendation,
    profileImage,
    recommendAs,
    firstName,
    lastName,
    fromYear,
    toYear,
    review,
    intl,
}) => (
    <div>
        <div className={css.root}>
            <div className={css.avatar}>
                {profileImage ? (
                    <img alt="profileImage" src={profileImage} />
                ) : (
                    <h5 className={css.abbreviatedName}>{abbreviatedName}</h5>
                )}
            </div>
            <div className={css.personalInfoSection}>
                <p>
                    {firstName} {lastName}
                </p>
                {recommendAs && (
                    <div>
                        {intl.formatMessage({
                            id: `ExternalReviewForm.recommendAs-${recommendAs}`,
                        })}
                    </div>
                )}
                <div>
                    {fromYear}-{toYear}
                </div>
            </div>
        </div>
        {review && (
            <aside className={css.assessment}>
                {assessments.map((assessment, i) =>
                    Number(review[assessment]) === 0 ? null : (
                        <div key={i}>
                            <p>
                                <FormattedMessage
                                    id={`ExternalReviewForm.assessment-${assessment}`}
                                />
                            </p>

                            <ProgressBarLevels
                                level={Number(review[assessment])}
                                editAvailable={false}
                                customComponent={<IconReviewStar />}
                                selectedFlag="isFilled"
                                total={5}
                            />
                        </div>
                    )
                )}
            </aside>
        )}
        <p className={css.recommendation}>{recommendation}</p>
    </div>
);

export default compose(injectIntl)(RecommendationItem);
