import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { ImagePlaceholderIcon, VideoPlaceholderIcon } from '../../icons';
import { GalleryCarouselWrapper, Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { getCardTitles, shouldRenderImageItem } from './helpers';
import { ClockIconTiny } from '../../icons';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import { useAssets } from '../../hooks/useAssets';
import config from '../../config';
import LikeIcon from '../../forms/ProfileSettingsForm/icons/LikeIcon';
import LocationIcon from './LocationIcon';
import DotIcon from './DotIcon';
import css from './IdentitySection.css';

const MODAL_BREAKPOINT = 1024;
const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

const genders = config.custom.genders;

export default ({
    currentListing,
    renderSizes,
    assetsPostponed,
    assetsLoadingRequests,
    identitySectionClassName,
    recommendationsNum,
    titleTagProp,
}) => {
    const paragraphEl = useRef(null);
    const [slideIndex, setSlideIndex] = useState();

    const { distanceFromSelectedPlace } = currentListing;
    const { attributes, author } = currentListing;
    const {
        publicAddress,
        gender,
        userRepresentationId,
        hasVideo,
        openForPartTimeProposals,
    } = attributes.publicData;
    const { title } = currentListing.attributes || '';

    const listingPublicAddress = publicAddress ? publicAddress.trim() : publicAddress;
    const isUserListing = !!userRepresentationId;
    const isTemporaryListing = !isUserListing && openForPartTimeProposals === 'temporary';

    const cardTitles = getCardTitles(title, genders, gender);
    const riderTitle = isUserListing ? (title || '').split(' ')[0] : title;

    const syntheticRiderListing = isUserListing
        ? {
              id: {
                  uuid: userRepresentationId,
              },
              attributes: {
                  publicData: {
                      userRepresentationId,
                  },
              },
              author: author || {},
          }
        : null;

    const loading = assetsPostponed || assetsLoadingRequests;

    const aspectWrapperClasses = classNames({
        [css.aspectWrapper]: true,
        [css.aspectWrapperNoBackground]: assetsLoadingRequests,
    });

    const locationHolderMaybe =
        listingPublicAddress ||
        distanceFromSelectedPlace ||
        isTemporaryListing ||
        recommendationsNum;

    const listingToRequestAssets = isUserListing ? syntheticRiderListing : currentListing;
    const TitleTag = titleTagProp || 'h3';

    /* useEffect */
    const images = useAssets({
        listing: assetsPostponed ? { id: { uuid: null } } : listingToRequestAssets,
        allowedTypes: ['image', 'video'],
        variant: SQUARE_SMALL_2X,
    });
    const isLastSlideItem = slideIndex === images.length;

    return (
        <section className={css.threeToTwoWrapper}>
            {!isLastSlideItem && (
                <section
                    className={classNames(css.identitySection, {
                        [identitySectionClassName]: !!identitySectionClassName,
                    })}
                >
                    <TitleTag className={css.title} ref={paragraphEl}>
                        {isUserListing ? riderTitle : cardTitles}
                    </TitleTag>
                    {locationHolderMaybe && (
                        <div className={css.locationHolder}>
                            {listingPublicAddress && (
                                <>
                                    <LocationIcon />
                                    <span>{listingPublicAddress}</span>
                                </>
                            )}
                            {!!distanceFromSelectedPlace && (
                                <div className={css.locationInfo}>
                                    <DotIcon />
                                    <span className={css.locationDisctance}>
                                        {distanceFromSelectedPlace} km
                                    </span>
                                </div>
                            )}
                            {isTemporaryListing && (
                                <div className={css.tempListing}>
                                    <ClockIconTiny />
                                    <FormattedMessage id="ListingCard.tempListing" />
                                </div>
                            )}
                            {recommendationsNum && isUserListing && (
                                <p className={css.recommendationsNumSection}>
                                    <LikeIcon /> <span>{recommendationsNum} </span>
                                    <FormattedMessage id="ProfileSettingsPage.recommendations" />
                                </p>
                            )}
                        </div>
                    )}
                </section>
            )}
            <div className={aspectWrapperClasses}>
                {loading ? (
                    <div className={css.loadingOverlay}>
                        {hasVideo ? <VideoPlaceholderIcon /> : <ImagePlaceholderIcon />}
                    </div>
                ) : images && images.length ? (
                    <GalleryCarouselWrapper
                        dragEnabled
                        pagination
                        touchEnabled={isMobileLayout}
                        items={images}
                        renderSizes={renderSizes}
                        notifyOnSlideChange={slideIndex => setSlideIndex(slideIndex)}
                        rootClassName={css.galleryCarouselWrapper}
                        actionItem={{
                            type: 'actionItem',
                            content: (
                                <div className={css.actionItem}>
                                    <Button>
                                        <FormattedMessage
                                            id={`ListingCard.actionItem-${
                                                isUserListing ? 'rider' : 'horse'
                                            }`}
                                        />
                                    </Button>
                                </div>
                            ),
                        }}
                        renderCarouselItem={item => {
                            const itemVariant = shouldRenderImageItem(item);

                            if (!itemVariant) return null;

                            return (
                                <div
                                    className={css.rootForImage}
                                    style={{
                                        backgroundImage: `url('${itemVariant}')`,
                                    }}
                                />
                            );
                        }}
                    >
                        {!isLastSlideItem && <code className={css.overlayBottom} />}
                    </GalleryCarouselWrapper>
                ) : null}
            </div>
        </section>
    );
};
