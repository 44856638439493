import React, { useState } from 'react';
import classNames from 'classnames';
import css from './SdkVideo.css';

const Video = ({ url, fitAllowed, ignoreWindowParamsOnFitting, fittingConfig, ...rest }) => {
    const [videoRef, setRef] = useState(null);
    const [fitAlready, setFitAlready] = useState(null);

    const fitVideoDimensions = () => {
        if (fitAlready) return;
        if (!videoRef) return;

        const { parentElement, videoHeight, videoWidth } = videoRef;

        if (!parentElement) return;

        const { clientHeight: parentHeight, clientWidth: parentWidth } = parentElement;
        const { innerHeight: windowHeight } = window;

        const isLandscape = videoWidth > videoHeight;
        const isPortrait = videoHeight > videoWidth;
        /**
         * parent is always considered to be landscape oriented;
         * if parent portrait orientation is needed to be used,
         * add an extra logic here
         */
        if (isLandscape) {
            const intrinsicDimensions = videoWidth / videoHeight;
            const coefficient = parentHeight * intrinsicDimensions;
            /**
             * TODO: check/fix/delete
             * the left extra styles brings the video elem far away left on mobile devices
             */
            // videoRef.style.left = `calc(50% - ${Math.round(coefficient / 2)}px)`;
            videoRef.style.width = `${coefficient}px`;
            /**
             * video width is increased and it's content - video - may overlap the next element
             */
            parentElement.style.overflow = 'hidden';
            videoRef.style.margin = 'auto';
        }
        if (isPortrait) {
            const { paddingTop = 0, paddingBottom = 0 } = fittingConfig;
            const intrinsicDimensions = videoHeight / videoWidth;
            const dimensionsComputed = parentWidth * intrinsicDimensions;
            const windowHeightOverlaps =
                !ignoreWindowParamsOnFitting && dimensionsComputed > windowHeight;
            const coefficient = windowHeightOverlaps ? windowHeight : dimensionsComputed;

            videoRef.style.top = `calc(50% - ${Math.round(coefficient / 2)}px + ${paddingTop}px)`;
            videoRef.style.height = `${coefficient - paddingTop - paddingBottom}px`;
        }

        setFitAlready(true);
    };

    return (
        <video
            {...rest}
            ref={setRef}
            onCanPlayThrough={() => {
                fitAllowed && fitVideoDimensions();
            }}
        >
            <source src={url} type="video/mp4" />
            <source src={url} type="video/ogg" />
            <source src={url} type="video/webm" />
            <source src={url} type="video/quicktime" />
            Your browser doesn't support HTML5 video tag.
        </video>
    );
};

const SdkVideo = ({
    entity,
    draggable = false,
    autoPlay = true,
    controls = true,
    loop = true,
    muted = true,
    rootClassName = '',
    onLoadedData = () => null,
    fitAllowed = true,
    playsInline = true,
    ignoreWindowParamsOnFitting = true,
    fittingConfig = {},
}) =>
    Object.values(entity.attributes.variants).reduce((src, { url }) => {
        if (src) return src;

        if (url) {
            return (
                <Video
                    className={classNames(css.root, {
                        [rootClassName]: !!rootClassName,
                    })}
                    onLoadedData={onLoadedData}
                    controls={controls}
                    draggable={draggable}
                    autoPlay={autoPlay}
                    loop={loop}
                    muted={muted}
                    url={url}
                    fitAllowed={fitAllowed}
                    playsInline={playsInline}
                    ignoreWindowParamsOnFitting={ignoreWindowParamsOnFitting}
                    fittingConfig={fittingConfig}
                />
            );
        }

        return null;
    }, null);

export default SdkVideo;
