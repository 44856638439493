import React, { useState } from 'react';

import EmailChangeStepChooseIdp from './EmailChangeStepChooseIdp';
import EmailChangeStepChangeInfo from './EmailChangeStepChangeInfo';

const STEP_SELECT_IDP = 'select_idp';
const STEP_CHANGE_INFO = 'change_info';

export default props => {
    const [step, setStep] = useState(STEP_SELECT_IDP);
    const [submittedValues, setSubmittedValues] = useState({});

    const stepsConfig = {
        [STEP_SELECT_IDP]: (
            <EmailChangeStepChooseIdp navigateToNextSteps={() => setStep(STEP_CHANGE_INFO)} />
        ),
        [STEP_CHANGE_INFO]: (
            <EmailChangeStepChangeInfo
                {...props}
                submittedValues={submittedValues}
                setSubmittedValues={setSubmittedValues}
            />
        ),
    };

    return stepsConfig[step];
};
