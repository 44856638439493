import { useEffect, useState } from 'react';
import { getPlaceDetailsFromString } from '../../util/geocoder';
import { getSearchConfigurations } from './helpers';
/**
 * Call google api with location Form fields string to get GEO data and show map preview
 */
export const useLocationPredictions = ({ form, locationUnits }) => {
    const [predictionInProgress, setPredictionInProgress] = useState(false);
    const [predictionSelected, setPredictionSelected] = useState(null);
    const [timeoutID, setTimeoutID] = useState(null);

    const { getFieldState } = form;

    const predictLocation = async () => {
        if (!locationUnits || predictionInProgress) {
            return;
        }
        setPredictionInProgress(true);

        if (form) {
            /** may be used to notify form on request being made */
            form.change('location', undefined);
        }

        try {
            const { value } = getFieldState('country') || {};

            const predictionData = await getPlaceDetailsFromString({
                locationUnitsStr: locationUnits,
                /** predict locations related to selected country only */
                searchConfigurationsProp: getSearchConfigurations(value),
            });

            if (!predictionData || !predictionData.origin) {
                throw new Error();
            }

            if (form) {
                form.change('location', predictionData.origin);
            }
            setPredictionSelected(predictionData);
            setPredictionInProgress(false);
        } catch (e) {
            setPredictionInProgress(false);
        }
    };

    useEffect(() => {
        if (timeoutID) clearTimeout(timeoutID);

        const newTimeoutID = setTimeout(() => {
            predictLocation(locationUnits);
        }, 350);

        setTimeoutID(newTimeoutID);

        return () => {
            setTimeoutID(null);
        };
    }, [locationUnits]);

    return { predictionInProgress, predictionSelected };
};
