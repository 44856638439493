import { useEffect } from 'react';

const getDaytime = () => {
    const h = new Date().getHours();

    if (h > 0 && h < 12) return 'Morgen';
    if (h >= 12 && h < 14) return 'Mittag';
    if (h >= 14 && h < 17) return 'Nachmittag';
    if (h >= 17 && h < 24) return 'Abend';
};

/** may be passed as a fieldValue argument for useInitialValues hook if needed */
const getDefaultRecommendationMessage = currentUser => {
    const { firstName } = currentUser.attributes.profile;

    return `Guten ${getDaytime()}

Ich hoffe, dir geht es gut.
    
Seit kurzem bin ich Mitglied bei HorseDeal. Um mein Reiter-Profil vertrauenswürdiger zu gestalten, würde ich mich sehr freuen, wenn Du dir kurz Zeit nehmen könntest, meine Reitkenntnisse mit einer persönlichen Empfehlung zu bestätigen.
    
Vielen Dank im Voraus für deine Zeit!

Viele Grüsse,
${firstName}`;
};

export const useInitialValues = ({ form, currentUser, fieldName = 'message' }) => {
    useEffect(() => {
        form.change(fieldName, getDefaultRecommendationMessage(currentUser));
    }, []);
};
