import React from 'react';
import { useListings } from '../../../../hooks/useListings';
import { useListingsWithDistance } from '../../../../hooks/useListingsWithDistance';
import { insertAuthorDataIntoListing } from '../../../../util/listings';
import { resolveUserLatLng } from '../../../../util/user';
import { getVisibleSlidesByViewport } from '../../../../components/ListingCardsCarousel/helpers';
import { IconSpinner, ListingCardsCarouselWithMatchingScore } from '../../../../components';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import config from '../../../../config';
import css from './SectionBestSuitsYouRiders.css';

const { listingTypeRider, listingTypeHorse } = config;

export default ({ currentUser }) => {
    const [isMobile, , computing, viewport] = useIsMobile(768);
    const {
        id: { uuid },
        attributes: {
            profile: {
                publicData: { mainHorseId },
            },
        },
    } = currentUser;
    const userLocationData = resolveUserLatLng(currentUser);

    const [riderListings, riderListingsLoading, included] = useListings({
        addDataToMarketplace: true,
        params: {
            per_page: 10,
            origin: userLocationData,
            pub_type: listingTypeRider,
            include: ['author', 'author.profileImage', 'images'],
            'fields.user': [
                'profile.displayName',
                'profile.abbreviatedName',
                'profile.bio',
                'profile.publicData.country',
            ],
            'fields.listing': ['title', 'state', 'geolocation', 'price', 'publicData', 'createdAt'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
                'variants.square-small',
            ],
        },
    });

    const horseListingsParams = {
        authorId: uuid,
        pub_type: listingTypeHorse,
    };

    if (mainHorseId) {
        Object.assign(horseListingsParams, {
            ids: [mainHorseId],
        });
    }

    const [horseListings, horseListingsLoading] = useListings({
        addDataToMarketplace: true,
        params: {
            ...horseListingsParams,
        },
    });

    const riderListingsDataAvailable = Array.isArray(riderListings) && riderListings.length > 0;
    const horseListingDataAvailable = Array.isArray(horseListings) && horseListings.length > 0;
    const dataAvailable = viewport && riderListingsDataAvailable && horseListingDataAvailable;
    const horseListingId = dataAvailable ? horseListings[0].id.uuid : null;
    const includedDataAvailable = !!Array.isArray(included) && included.length > 0;
    const isLoading = riderListingsLoading || horseListingsLoading;

    const listingsWithDistance = useListingsWithDistance(
        !isLoading && dataAvailable ? riderListings : [],
        userLocationData
    );

    if (isLoading || computing) return <IconSpinner />;

    const applyAuthDataInserting = listings =>
        includedDataAvailable
            ? listings.map(listing => insertAuthorDataIntoListing(listing, included))
            : listings;

    const carouselHolderClass =
        dataAvailable && listingsWithDistance.length === 2 ? css.carouselHolderTwoColumn : null;

    return dataAvailable ? (
        <section className={css.root}>
            <ListingCardsCarouselWithMatchingScore
                isMobile={isMobile}
                listings={applyAuthDataInserting(listingsWithDistance)}
                riderListing={{ id: { uuid: horseListingId } }}
                minMatchingRate={69}
                matchRiderListing={false}
                sliderClassName={css.sliderRoot}
                isLoggedIn
                hideButtonsOnEndStart
                sliderVisibleSlides={getVisibleSlidesByViewport(viewport).visibleSlidesByViewport}
                carouselHolderClassName={carouselHolderClass}
                header={
                    <>
                        <h2 className={css.heading}>Passen am besten zu dir</h2>
                        <p>Bei diesen Pferdemenschen hast Du die besten Chancen.</p>
                    </>
                }
            />
        </section>
    ) : null;
};
