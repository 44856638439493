import Decimal from 'decimal.js';
import { ensureTransaction } from './data';
import { types as sdkTypes } from './sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION } from './types';
import config from '../config';
import { anonymizeListingAuthor } from './listings';
const { userTypeRider, userTypeHorseowner } = config;

const { UUID, Money } = sdkTypes;
const PLATFORM_COMMISSION = config.platformCommission;
const CURRENCY = config.currency;

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// A customer can initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';

export const TRANSITION_ENQUIRY_ACCEPT = 'transition/accept-enquiry';
export const TRANSITION_ENQUIRY_DECLINE = 'transition/decline-enquiry';
export const TRANSITION_ENQUIRY_EXPIRE = 'transition/expire-enquiry';

export const TRANSITION_ENQUIRY_ACCEPT_OWNER = 'transition/owner-accept-enquiry';
export const TRANSITION_ENQUIRY_DECLINE_OWNER = 'transition/owner-decline-enquiry';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
    TX_TRANSITION_ACTOR_CUSTOMER,
    TX_TRANSITION_ACTOR_PROVIDER,
    TX_TRANSITION_ACTOR_SYSTEM,
    TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';

const STATE_ENQUIRY_ACCEPTED = 'enquiry-accepted';
const STATE_ENQUIRY_DECLINED = 'enquiry-declined';
const STATE_ENQUIRY_EXPIRED = 'enquiry-expired';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
    // id is defined only to support Xstate format.
    // However if you have multiple transaction processes defined,
    // it is best to keep them in sync with transaction process aliases.
    id: 'preauth-with-nightly-booking/release-1',

    // This 'initial' state is a starting point for new transaction
    initial: STATE_INITIAL,

    // States
    states: {
        [STATE_INITIAL]: {
            on: {
                [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
            },
        },
        [STATE_ENQUIRY]: {
            on: {
                [TRANSITION_ENQUIRY_ACCEPT]: STATE_ENQUIRY_ACCEPTED,
                [TRANSITION_ENQUIRY_ACCEPT_OWNER]: STATE_ENQUIRY_ACCEPTED,
                [TRANSITION_ENQUIRY_DECLINE]: STATE_ENQUIRY_DECLINED,
                [TRANSITION_ENQUIRY_DECLINE_OWNER]: STATE_ENQUIRY_DECLINED,
                [TRANSITION_ENQUIRY_EXPIRE]: STATE_ENQUIRY_EXPIRED,
            },
        },
        [STATE_ENQUIRY_EXPIRED]: { type: 'final' },
        [STATE_ENQUIRY_DECLINED]: { type: 'final' },
        [STATE_ENQUIRY_ACCEPTED]: { type: 'final' },
    },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
    const stateNames = Object.keys(states);

    const transitionsReducer = (transitionArray, name) => {
        const stateTransitions = states[name] && states[name].on;
        const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
        return [
            ...transitionArray,
            ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
        ];
    };

    return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
    t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
    getTransitions(statesFromStateDescription(stateDesc))
        .filter(t => t.value === state)
        .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
    getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsExpired = tx =>
    getTransitionsToState(STATE_ENQUIRY_EXPIRED).includes(txLastTransition(tx));

export const txIsDeclined = tx =>
    getTransitionsToState(STATE_ENQUIRY_DECLINED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
    getTransitionsToState(STATE_ENQUIRY_ACCEPTED).includes(txLastTransition(tx));

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
    return [
        TRANSITION_ENQUIRY_ACCEPT,
        TRANSITION_ENQUIRY_ACCEPT_OWNER,
        TRANSITION_ENQUIRY_DECLINE,
        TRANSITION_ENQUIRY_DECLINE_OWNER,
        TRANSITION_ENQUIRY_EXPIRE,
        TRANSITION_APPOINTMENT_DECLINE,
        TRANSITION_APPOINTMENT_CANCEL,
        TRANSITION_APPOINTMENT_ACCEPT,
    ].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
    const tx = ensureTransaction(transaction);
    const customer = tx.customer;
    if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
        // user can be either customer or provider
        return currentUserId.uuid === customer.id.uuid
            ? TX_TRANSITION_ACTOR_CUSTOMER
            : TX_TRANSITION_ACTOR_PROVIDER;
    } else {
        throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
    }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

export const shapeSimulatedTransaction = (tx, booking, bookingData) => {
    const commission = vl => (vl / 100) * PLATFORM_COMMISSION;

    return {
        ...tx,
        booking: {
            ...booking,
            attributes: {
                end: new Date(booking.attributes.end),
                start: new Date(booking.attributes.start),
            },
            id: new UUID('estimated-transaction'),
        },
        attributes: {
            ...tx.attributes,
            payoutTotal: new Money(
                bookingData.unitPrice.amount - commission(bookingData.unitPrice.amount),
                CURRENCY
            ),
            payinTotal: new Money(
                bookingData.unitPrice.amount + commission(bookingData.unitPrice.amount),
                CURRENCY
            ),
            lineItems: [
                {
                    includeFor: ['customer', 'provider'],
                    code: bookingData.unitType,
                    quantity: new Decimal(1),
                    unitPrice: new Money(bookingData.unitPrice.amount, CURRENCY),
                    lineTotal: new Money(bookingData.unitPrice.amount, CURRENCY),
                    reversal: false,
                },
                {
                    code: LINE_ITEM_CUSTOMER_COMMISSION,
                    includeFor: ['customer'],
                    percentage: new Decimal(10),
                    unitPrice: new Money(commission(bookingData.unitPrice.amount), CURRENCY),
                    lineTotal: new Money(commission(bookingData.unitPrice.amount), CURRENCY),
                    reversal: false,
                },
                {
                    code: LINE_ITEM_PROVIDER_COMMISSION,
                    includeFor: ['provider'],
                    percentage: new Decimal(10),
                    unitPrice: new Money(commission(bookingData.unitPrice.amount), CURRENCY),
                    lineTotal: new Money(-commission(bookingData.unitPrice.amount), CURRENCY),
                    reversal: false,
                },
            ],
            protectedData: {
                ...tx.attributes.protectedData,
                bookingData,
            },
        },
    };
};

export const hasOrderOrSaleTransactions = (tx, user) => {
    if (!user || !user.id || !tx) {
        return;
    }

    const isOrdersTab = user.attributes.profile.publicData.userType === userTypeRider;

    return isOrdersTab
        ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
        : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
};

export const anonymizeOtherParty = otherParty => currentTransaction => {
    try {
        const { listing } = currentTransaction;
        const { anonymousListing: isAnonymListing } = listing.attributes.metadata || {};
        const isRelevantTx =
            txIsEnquired(currentTransaction) ||
            txIsExpired(currentTransaction) ||
            txIsDeclined(currentTransaction);

        const isHorseowner =
            otherParty.attributes.profile.publicData.userType === userTypeHorseowner;

        const shouldBeAnonymized = isAnonymListing && isRelevantTx && isHorseowner;

        return shouldBeAnonymized ? anonymizeListingAuthor(otherParty) : otherParty;
    } catch (e) {
        return otherParty;
    }
};

/**
 * resolve other party
 * @param {*} currentUser sdk user
 * @param {*} customer  sdk user
 * @param {*} provider  sdk user
 * @returns  is customer or is provider, and is owner or is rider
 */
export const resolveOtherPartyData = (currentUser, customer, provider) => {
    const {
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
        id,
    } = currentUser;

    const {
        id: { uuid: customerId },
    } = customer;

    const { uuid: currentUserId } = id || { uuid: null };

    const isRider = userType === userTypeRider;
    const isCustomer = currentUserId === customerId;
    const txRole = isCustomer ? TX_TRANSITION_ACTOR_PROVIDER : TX_TRANSITION_ACTOR_CUSTOMER;
    const otherPartyType = isRider ? userTypeHorseowner : userTypeRider;
    const otherParty = isCustomer ? provider : customer;

    if (!otherParty.attributes.profile || !otherParty.attributes.profile.publicData) {
        /**
         * user deleted or banned
         */
        return {
            ...otherParty,
            attributes: {
                ...otherParty.attributes,
                banned: true,
                deleted: true,
                profile: {
                    displayName: '',
                    abbreviatedName: '',
                    bio: '',
                    publicData: {
                        userType: otherPartyType,
                        txRole,
                    },
                },
            },
        };
    }

    otherParty.attributes.profile.publicData.userType = otherPartyType;
    otherParty.attributes.profile.publicData.txRole = txRole;

    return otherParty;
};

/**
 * helper text content for different transaction stages
 */
export const FILE_MESSAGE_TEXT_CONTENT = '__file';

/**
 * these appointments statuses should
 * correspond to the backend-side ones
 * located at server/routes/appointment.js file
 */
export const APPOINTMENT_PENDING_STATUS = 'pending';
export const APPOINTMENT_DECLINED_STATUS = 'declined';
export const APPOINTMENT_CANCELED_STATUS = 'canceled';
export const APPOINTMENT_ACCEPTED_STATUS = 'accepted';
/**
 * appointments actions
 */
export const INITIATE_APPOINTMENT_REQUEST = 'initiateAppointment';
export const ACCEPT_APPOINTMENT_REQUEST = 'approveAppointment';
export const DECLINE_APPOINTMENT_REQUEST = 'declineAppointment';
export const CANCEL_APPOINTMENT_REQUEST = 'cancelAppointment';
/**
 * appointments are not included into flex tr process
 * and are handled via api and db,
 * so fake transitions have to be made for them
 */
export const TRANSITION_APPOINTMENT_DECLINE = 'transition-appointment-decline';
export const TRANSITION_APPOINTMENT_CANCEL = 'transition-appointment-cancel';
export const TRANSITION_APPOINTMENT_ACCEPT = 'transition-appointment-accept';

export const isMessage = item => item && item.type === 'message';
export const isAppointment = item => item && item.type === 'appointment';
export const isTransition = item => !isMessage(item) && !isAppointment(item);

export const TRANSACTION_STATES_STATUS_ABSENT = 'absent';
export const TRANSACTION_STATES_STATUS_ACCEPTED = 'accepted';
export const TRANSACTION_STATES_STATUS_DECLINED = 'declined';
export const TRANSACTION_STATES_STATUS_ENQUIRED = 'enquired';
export const TRANSACTION_STATES_STATUS_EXPIRED = 'expired';

export const getActionButtonStateFromTx = txs => {
    /** no transactions yet */
    if (!txs || txs.length === 0 || !txs[0]) {
        return { text: 'Anfrage senden', state: TRANSACTION_STATES_STATUS_ABSENT };
    }
    if (txs.some(tx => txIsDeclined(tx))) {
        return { text: 'Zur Konversation', state: TRANSACTION_STATES_STATUS_DECLINED };
    }
    if (txs.some(tx => txIsAccepted(tx))) {
        return { text: 'Zur Konversation', state: TRANSACTION_STATES_STATUS_ACCEPTED };
    }
    if (txs.some(tx => txIsEnquired(tx))) {
        return { text: 'Anfrage ausstehend', state: TRANSACTION_STATES_STATUS_ENQUIRED };
    }
    if (txs.some(tx => txIsExpired(tx))) {
        return { text: 'Neue Anfrage senden', state: TRANSACTION_STATES_STATUS_EXPIRED };
    }
    return { text: 'Anfrage senden', state: 'default' };
};
