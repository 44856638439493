import React, { useState } from 'react';
import { Slide } from 'pure-react-carousel';
import { NamedLink, ModalPortal, ImageCarousel, SdkVideo } from '../../../../components';
import Carousel from '../../../../components/ListingCardsCarousel/Carousel';
import css from './SectionTestimonials.css';

const variant = 'landscape-crop2x';

const testimonials = [
    'florence-hofmann',
    'pauline-roeder',
    'anja-k',
    'claudia-makabu',
    'alessia-koenig',
    'ruby-walliser',
    'patrizia-rothe',
    'joelle-wenczel',
];

const getVideoPath = (userName, format) =>
    `https://horsedeal-videos.imgix.net/testimonials/${userName}/${format}/testimonial-${userName}-${format}.mp4`;

const getPosterPath = (userName, format) =>
    `https://horsedeal-videos.imgix.net/testimonials/${userName}/${format}/testimonial-${userName}-${format}-thumbnail.webp`;

const getTestimonialsEntities = f => t => ({
    type: 'video',
    attributes: {
        variants: {
            [variant]: { url: getVideoPath(t, f) },
        },
    },
});

export default ({ isMobile }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    return (
        <>
            <section className={css.root}>
                <header className={css.header}>
                    <h3 className={css.heading}>Erfolgsgeschichten</h3>
                </header>
                <div className={css.testimonialsHolder}>
                    <Carousel
                        isMobile={isMobile}
                        items={testimonials}
                        touchEnabled={isMobile}
                        sliderVisibleSlides={isMobile ? 1 : 3}
                        sliderClassName={css.slider}
                        hideButtonsOnEndStart
                        naturalSlideWidth={9}
                        naturalSlideHeight={16}
                        showDots
                        rendererCarousel={(t, i) => {
                            const format = '9x16'; // isMobile ? '9x16' : '16x9';
                            const path = getVideoPath(t, format);
                            const poster = getPosterPath(t, format);
                            const list = ['video/mp4', 'video/ogg', 'video/webm'];

                            return (
                                <Slide
                                    index={i}
                                    key={i}
                                    className={css.videoHolder}
                                    onClick={e => {
                                        e.preventDefault();
                                        setSelectedImageIndex(i);
                                        setModalOpen(true);
                                    }}
                                >
                                    <video poster={poster}>
                                        {list.map(type => (
                                            <source src={path} type={type} />
                                        ))}
                                        Your browser doesn't support HTML5 video tag.
                                    </video>
                                </Slide>
                            );
                        }}
                    />
                </div>
                <footer className={css.footer}>
                    <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                        Kostenlos registrieren
                    </NamedLink>
                </footer>
            </section>

            {modalOpen && (
                <ModalPortal
                    id="ListingPage.imageCarousel"
                    scrollLayerClassName={css.carouselModalScrollLayer}
                    containerClassName={css.carouselModalContainer}
                    contentClassName={css.modalContent}
                    lightCloseButton
                    isOpen
                    onClose={() => setModalOpen(false)}
                >
                    <ImageCarousel
                        images={testimonials.map(
                            getTestimonialsEntities(isMobile ? '9x16' : '16x9')
                        )}
                        selectedImageIndex={selectedImageIndex}
                        variants={[variant]}
                        renderVideoElem={({ image: videoEl, ...rest }) => (
                            <SdkVideo
                                {...rest}
                                entity={videoEl}
                                ignoreWindowParamsOnFitting={false}
                                muted={false}
                                fittingConfig={{
                                    paddingTop: 56,
                                    paddingBottom: 0,
                                }}
                            />
                        )}
                    />
                </ModalPortal>
            )}
        </>
    );
};
