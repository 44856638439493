import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { Tooltip, IconSpinner, InquiryModal } from '../../../components';
import { ConditionalWrapper } from '../../../util/common';

import { addIncludedDataToListing, navigateToLatestTx } from '../helpers';
import { useListings } from '../../../hooks/useListings';
import config from '../../../config';
import { ensureCurrentUser } from '../../../util/data';

import css from './AcceptInquirySection.css';
import { useDispatch, useSelector } from 'react-redux';
import AcceptInquiryButton from './AcceptInquiryButton';
import LastActivityCaption from './LastActivityCaption';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routeConfiguration';
import { useLatestTransactionListingId } from '../useLatestTransactionListingId';
import { sendEnquiry } from '../../../containers/ListingPage/ListingPage.duck';
import {
    TRANSACTION_STATES_STATUS_ACCEPTED,
    TRANSACTION_STATES_STATUS_DECLINED,
} from '../../../util/transaction';
import { ERROR_ACTIVE_TX_ALREADY_EXISTS } from '../../../components/InquiryModal/InquiryModal.helpers';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../../marketplace-custom-config';

const { userTypeRider, userTypeHorseowner } = config;

export default ({
    user,
    userName,
    rootClassName,
    disabled,
    isSameUser,
    riderPage,
    isLoggedOut,
    riderListingId,
    transactionDataInProgress,
}) => {
    const [inquiryModalVisible, setInquiryModalVisibility] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux store data */
    const currentUser = useSelector(s => s.user.currentUser);
    const sendEnquiryError = useSelector(s => s.ListingPage.sendEnquiryError);
    const sendEnquiryInProgress = useSelector(s => s.ListingPage.sendEnquiryInProgress);
    const ownListings = useSelector(s => {
        try {
            return Object.values(s.ManageListingsPage.ownEntities?.ownListing || {});
        } catch (e) {
            return [];
        }
    });

    const onSendEnquiry = values => dispatch(sendEnquiry(values));

    const {
        id: { uuid: publicUserId },
        attributes: {
            profile: {
                publicData: { userType: publicUserType, availabilityStatus },
            },
        },
    } = user;

    const {
        id,
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = ensureCurrentUser(currentUser);

    const { uuid: currentUserId } = id || {};

    const isRider = currentUserType === userTypeRider;
    const isHorseowner = currentUserType === userTypeHorseowner;

    const riderIsNotAvailable =
        riderPage && availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;

    const ridersViews = isRider && publicUserType === userTypeRider;
    const ownersViews = isHorseowner && publicUserType === userTypeHorseowner;

    const riderToOwnerPage = isRider && !riderPage;
    const ownerToRiderPage = isHorseowner && riderPage;

    const authorId =
        isSameUser || ridersViews
            ? null
            : riderToOwnerPage
            ? publicUserId
            : ownerToRiderPage
            ? currentUserId
            : null;

    const [listings, horseownerListingsInProgress, included] = useListings({
        params: {
            authorId,
        },
        allowed: !!authorId && isHorseowner,
    });

    const horseownerListings =
        listings && included ? addIncludedDataToListing(listings, included) : null;

    const noListingsForHorseowner =
        isHorseowner && !ownersViews && ((ownListings && ownListings.length === 0) || !ownListings);

    const noPublishedListingsForHorseowner =
        isHorseowner &&
        !ownersViews &&
        ownListings &&
        ownListings.length > 0 &&
        ownListings.every(l => l.attributes.state !== 'published');

    const inquiryNotAllowed =
        riderIsNotAvailable ||
        (isLoggedOut
            ? false
            : (disabled && !isSameUser) ||
              noListingsForHorseowner ||
              noPublishedListingsForHorseowner ||
              ridersViews ||
              ownersViews);

    const actionDisabed =
        riderIsNotAvailable ||
        (isLoggedOut
            ? false
            : disabled ||
              isSameUser ||
              noListingsForHorseowner ||
              noPublishedListingsForHorseowner ||
              ridersViews ||
              ownersViews);
    const resolveInquiryNotAllowedKey = () => {
        if (riderIsNotAvailable) return 'inquiryToRiderIsNotAllowedForHorseowner';
        if (isSameUser) return 'isSameUser';
        if (!ridersViews && noPublishedListingsForHorseowner)
            return 'noPublishedListingsForHorseowner';
        if (!ridersViews && noListingsForHorseowner) return 'noListingsForHorseowner';
        if (inquiryNotAllowed) return 'inquiryNotAllowedForRider';
    };

    const inquiryNotAllowedKey = resolveInquiryNotAllowedKey();
    const latestTransactionId = useLatestTransactionListingId(user?.id?.uuid);

    const loading = horseownerListingsInProgress || transactionDataInProgress;

    const onInquiryProcessInitiating = async transactionsState => {
        if (isLoggedOut) {
            return history.push(
                createResourceLocatorString(
                    'SignupHorseowner',
                    routeConfiguration(),
                    {},
                    riderListingId ? { listingId: riderListingId } : {}
                )
            );
        }

        const isDeclinedStatus = transactionsState === TRANSACTION_STATES_STATUS_DECLINED;
        const isAcceptedStatus = transactionsState === TRANSACTION_STATES_STATUS_ACCEPTED;

        if (isAcceptedStatus || isDeclinedStatus) {
            /**
             * ERROR_ACTIVE_TX_ALREADY_EXISTS is set artificially,
             * just to pass the call check for code param
             */
            navigateToLatestTx(ERROR_ACTIVE_TX_ALREADY_EXISTS, history, latestTransactionId);
        } else {
            setInquiryModalVisibility(true);
        }
    };

    return (
        <>
            {inquiryModalVisible && (
                <InquiryModal
                    isOpen
                    onClose={() => setInquiryModalVisibility(false)}
                    publicUser={user}
                    onSendEnquiry={onSendEnquiry}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    ridingListingAuthorId={isRider ? currentUserId : publicUserId}
                    notifyOnError={code => navigateToLatestTx(code, history, latestTransactionId)}
                />
            )}
            {loading ? (
                <IconSpinner />
            ) : (
                <aside
                    className={classNames(css.acceptInquirySection, {
                        [rootClassName]: !!rootClassName,
                    })}
                >
                    <LastActivityCaption
                        currentUser={currentUser}
                        publicUser={user}
                        isSameUser={isSameUser}
                    />
                    <ConditionalWrapper
                        condition={inquiryNotAllowed}
                        wrapper={children => (
                            <Tooltip
                                position="top"
                                tooltipClassName={css.inquiryDisabledTooltip}
                                content={
                                    <div>
                                        <p>Anfragen nicht möglich</p>
                                        <p>
                                            <FormattedMessage
                                                id={`Inquiry.action.disabled.desc.${inquiryNotAllowedKey}`}
                                                values={{ userName }}
                                            />
                                        </p>
                                    </div>
                                }
                            >
                                {children}
                            </Tooltip>
                        )}
                    >
                        <AcceptInquiryButton
                            onInquiryProcessInitiating={onInquiryProcessInitiating}
                            disabled={actionDisabed}
                            publicUser={user}
                        />
                    </ConditionalWrapper>
                </aside>
            )}
        </>
    );
};
