/**
 * CurrencyInput renders an input field that format it's value according to currency formatting rules
 * onFocus: renders given value in unformatted manner: "9999,99"
 * onBlur: formats the given input: "9 999,99 €"
 */
import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { ValidationError } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { roundUp } from '../../util/currency';
import {
    isSafeNumber,
    unitDivisor,
    convertUnitToSubUnit,
    convertMoneyToNumber,
    ensureDotSeparator,
    ensureSeparator,
    truncateToSubUnitPrecision,
    formatPriceWithApostropheSeparators,
} from '../../util/currency';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './FieldCurrencyInput.css';
import { IconCloseCircleL } from '../../icons';

const { Money } = sdkTypes;

const allowedInputProps = allProps => {
    // Strip away props that are not passed to input element (or are overwritten)
    // eslint-disable-next-line no-unused-vars
    const {
        currencyConfig,
        defaultValue,
        intl,
        input,
        meta,
        inputClasses,
        className,
        hasError,
        suppressClearAction,
        ...inputProps
    } = allProps;
    return {
        ...inputProps,
        valid: String(inputProps.valid || ''),
        active: String(inputProps.active || 'false'),
    };
};

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue, currencyConfig) => {
    const isEmptyString = unformattedValue === '';
    try {
        return isEmptyString
            ? null
            : new Money(
                  convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency)),
                  currencyConfig.currency
              );
    } catch (e) {
        return null;
    }
};

const CurrencyInputComponent = props => {
    const {
        currencyConfig,
        defaultValue,
        input,
        placeholder,
        valid,
        active,
        hasError,
        intl,
        suppressClearAction = false,
        withCurrency = false, // TODO
    } = props;

    const [formattedValue, setFormattedValue] = useState();
    const [unformattedValue, setUnformattedValue] = useState();
    const [value, setValue] = useState();
    const [usesComma, setUsesComma] = useState();

    useEffect(() => {
        const initialValueIsMoney = input.value instanceof Money;

        if (initialValueIsMoney && input.value.currency !== currencyConfig.currency) {
            console.warn('Value currency different from marketplace currency');
        }

        const initialValue = initialValueIsMoney ? convertMoneyToNumber(input.value) : defaultValue;
        const hasInitialValue = typeof initialValue === 'number' && !isNaN(initialValue);

        const currencyNumberFormatConfig = {
            CHF: 'ch-CH',
            EUR: 'de-DE',
        };
        const currencyNumberFormat = currencyNumberFormatConfig[currencyConfig.currency];

        if (!currencyNumberFormat) {
            throw new Error(
                'Currency formatting is not implemented. Add corresponding data to currencyNumberFormatConfig variable.'
            );
        }

        // We need to handle number format - some locales use dots and some commas as decimal separator
        // TODO Figure out if this could be digged from React-Intl directly somehow
        const testSubUnitFormat = new Intl.NumberFormat(
            currencyNumberFormat,
            currencyConfig
        ).format('1.1');

        const usesComma = testSubUnitFormat.indexOf('.') >= 0;

        try {
            // whatever is passed as a default value, will be converted to currency string
            // Unformatted value is digits + localized sub unit separator ("9,99")
            const unformattedValue = hasInitialValue
                ? truncateToSubUnitPrecision(
                      ensureSeparator(initialValue.toString(), usesComma),
                      unitDivisor(currencyConfig.currency),
                      usesComma
                  )
                : '';
            // Formatted value fully localized currency string ("$1,000.99")
            const formattedValue = hasInitialValue
                ? formatPriceWithApostropheSeparators(
                      intl.formatNumber(ensureDotSeparator(unformattedValue), currencyConfig)
                  )
                : '';

            setFormattedValue(formattedValue);
            setUnformattedValue(unformattedValue);
            setValue(
                // withCurrency
                //     ? new Intl.NumberFormat(currencyNumberFormat, currencyConfig).format(
                //           unformattedValue
                //       )
                //     :
                unformattedValue
            );
            setUsesComma(usesComma);
        } catch (e) {
            log.error(e, 'currency-input-init-failed', {
                currencyConfig,
                defaultValue,
                initialValue,
            });
            throw e;
        }
    }, [input.value]);

    const updateValues = event => {
        try {
            const targetValue = event.target.value.trim();
            const isEmptyString = targetValue === '';
            const valueOrZero = isEmptyString ? '0' : targetValue;

            const targetDecimalValue = isEmptyString
                ? null
                : new Decimal(ensureDotSeparator(targetValue));

            const isSafeValue =
                isEmptyString ||
                (targetDecimalValue.isPositive() && isSafeNumber(targetDecimalValue));
            if (!isSafeValue) {
                throw new Error(`Unsafe money value: ${targetValue}`);
            }

            // truncate decimals to subunit precision: 10000.999 => 10000.99
            const truncatedValueString = truncateToSubUnitPrecision(
                valueOrZero,
                unitDivisor(currencyConfig.currency),
                usesComma
            );

            const unformattedValue = !isEmptyString ? truncatedValueString : '';
            const truncatedValueRounded = roundUp(Number(unformattedValue));

            setFormattedValue(unformattedValue);
            setValue(unformattedValue);
            setUnformattedValue(String(truncatedValueRounded));

            return {
                formattedValue: unformattedValue,
                value: truncatedValueRounded,
                unformattedValue,
            };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);

            // If an error occurs while filling input field, use previous values
            // This ensures that string like '12.3r' doesn't end up to a state.
            return { formattedValue, unformattedValue, value };
        }
    };

    const onInputChange = event => {
        event.preventDefault();
        event.stopPropagation();
        // Update value strings on state

        const { unformattedValue } = updateValues(event);
        const unformattedValueRounded = roundUp(Number(unformattedValue));
        const unformattedValueWithMinThreshold =
            Number(unformattedValue) < 1 ? '0' : unformattedValueRounded;
        // Notify parent component about current price change
        const price = getPrice(
            ensureDotSeparator(String(unformattedValueWithMinThreshold)),
            currencyConfig
        );

        input.onChange(price);
    };

    const onInputBlur = event => {
        event.preventDefault();
        event.stopPropagation();

        const { onBlur } = input;

        const unformattedValueRounded = roundUp(Number(unformattedValue));
        const unformattedValueUpd = String(unformattedValueRounded);

        if (onBlur) {
            // If parent component has provided onBlur function, call it with current price.
            const price = getPrice(ensureDotSeparator(unformattedValueUpd), currencyConfig);
            onBlur(price);
        }

        setValue(unformattedValueUpd);
    };

    const onInputFocus = event => {
        event.preventDefault();
        event.stopPropagation();
        const { onFocus } = input;

        if (onFocus) {
            // If parent component has provided onFocus function, call it with current price.
            const price = getPrice(ensureDotSeparator(unformattedValue), currencyConfig);
            onFocus(price);
        }

        setValue(unformattedValue);
    };

    // useEffect(() => {
    //     const { value: priceIsSet } = input;
    //     if (!!priceIsSet) {
    //         /** if the currency field is changed, emulate onChange & onBlur callbacks */
    //         const amount = input.value.amount || 0;
    //         const unformattedValue = String(roundUp(amount / 100));

    //         /** the event is triggered seculatively, so there is no
    //          * event object data, use mock instead with the value converted
    //          * from Money instance to the initial string
    //          */
    //         const speculativeEvent = {
    //             target: { value: unformattedValue },
    //             preventDefault: () => null,
    //             stopPropagation: () => null,
    //         };
    //         /** onChange handler emulated */
    //         updateValues(speculativeEvent);

    //         const price = getPrice(ensureDotSeparator(unformattedValue), currencyConfig);
    //         input.onChange(price);
    //         /** onBlur handler emulated */
    //         onInputBlur(speculativeEvent);
    //     }
    // }, [currencyConfig.currency]);

    const hasValue = !!value;
    const inputClasses = classNames(css.input, {
        [css.inputActive]: active,
        [css.inputSuccess]: valid && hasValue,
        [css.inputError]: hasError,
    });

    const placeholderText = placeholder || intl.formatNumber(defaultValue, currencyConfig);
    const showPlaceHolder = !hasValue && !active;
    const labelColor = active
        ? '#8F2593'
        : hasError
        ? '#ff0000'
        : hasValue && valid
        ? '#1E9F55'
        : '#1A2B49';

    return (
        <div className={inputClasses}>
            <div className={css.innerInputWrapper}>
                <div>
                    {showPlaceHolder ? null : (
                        <div className={css.priceInputLabel} style={{ color: labelColor }}>
                            {placeholderText}
                        </div>
                    )}

                    <input
                        {...allowedInputProps(props)}
                        value={value}
                        onChange={onInputChange}
                        onBlur={onInputBlur}
                        onFocus={onInputFocus}
                        type="number"
                        placeholder={showPlaceHolder ? placeholderText : ''}
                    />
                </div>
                {!suppressClearAction && (
                    <IconCloseCircleL
                        rootClassName={active ? css.closeIcon : css.hiddenCloseIcon}
                        clickHandler={() => {
                            const event = { target: { value: '' } };
                            updateValues(event);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const { func, oneOfType, number, shape, string, object } = PropTypes;

CurrencyInputComponent.propTypes = {
    className: string,
    currencyConfig: propTypes.currencyConfig.isRequired,
    defaultValue: number,
    intl: intlShape.isRequired,
    input: shape({
        value: oneOfType([string, propTypes.money, null]),
        onBlur: func,
        onChange: func.isRequired,
        onFocus: func,
    }).isRequired,

    placeholder: string,
};

export const CurrencyInput = injectIntl(CurrencyInputComponent);

const FieldCurrencyInputComponent = props => {
    const {
        rootClassName,
        className,
        id,
        label,
        input,
        meta,
        hasError: hasErrorProp,
        ...rest
    } = props;

    if (label && !id) {
        throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error, active } = meta;
    const hasError = touched && invalid && error;

    const inputProps = { id, input, valid, active, ...rest };
    const classes = classNames(rootClassName, className);

    return (
        <div className={classes}>
            <CurrencyInput {...inputProps} hasError={hasError} />
            <ValidationError fieldMeta={meta} />
        </div>
    );
};

FieldCurrencyInputComponent.propTypes = {
    rootClassName: string,
    className: string,

    // Label is optional, but if it is given, an id is also required so
    // the label can reference the input in the `for` attribute
    id: string,
    label: string,

    // Generated by final-form's Field component
    input: object.isRequired,
    meta: object.isRequired,
};

const FieldCurrencyInput = props => {
    return <Field component={FieldCurrencyInputComponent} {...props} />;
};

export default FieldCurrencyInput;
