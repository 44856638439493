import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { archieveChat } from '../../InboxPage/InboxPage.duck';

/** Dearchieve the chat on a new message received in the chat */
export const useDearchieveChatOnNewMessage = ({
    hasArchieveFilterEnabled,
    unreadMessages,
    isSelected,
    setShowItem,
    currentUser,
    tx,
}) => {
    const dispatch = useDispatch();

    const onArchieveChat = (txId, action) => dispatch(archieveChat(txId, action));

    const { archievedTransactions = {} } = currentUser.attributes.profile.protectedData;
    const { uuid } = tx.id;

    const isArchived = archievedTransactions[uuid];

    useEffect(() => {
        if (unreadMessages && isArchived) {
            const dearchieveChat = unreadMessages.some(({ attributes: { createdAt } }) => {
                const createdAtDate = new Date(createdAt);
                const archievedTransactionDate = new Date(isArchived.updatedAt);

                return createdAtDate > archievedTransactionDate;
            });
            if (dearchieveChat) {
                onArchieveChat(uuid, 'remove');
            }
        }

        setShowItem(isSelected || (hasArchieveFilterEnabled ? isArchived : !isArchived));
    }, [isSelected, isArchived, unreadMessages, hasArchieveFilterEnabled]);
};
