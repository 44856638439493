import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../../../util/reactIntl';
import { MenuItem, NamedLink } from '../../..';
import css from './ProfileMenuContent.css';

export default ({ currentPageClass, notificationCount }) => (
    <MenuItem
        key="InboxPage"
        rootClassName={classNames(
            css.topBarMenuItem,
            css.mobileOnly,
            currentPageClass('OrderMessagesPage'),
            currentPageClass('OrderDetailsPage'),
            currentPageClass('InboxPage')
        )}
    >
        <NamedLink className={css.topBarMenuLink} name="OrderMessagesPage">
            <p>
                <FormattedMessage id="TopbarDesktop.inbox" />
                {!!notificationCount && <code className={css.notificationDot} />}
            </p>
        </NamedLink>
        {!!notificationCount && (
            <div className={css.topBarUnreadMessageBadge}>
                <FormattedMessage
                    id="TopbarDesktop.newMessageBadge"
                    values={{
                        messageNum: notificationCount,
                    }}
                />
            </div>
        )}
    </MenuItem>
);
