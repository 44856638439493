import React from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form } from '../../components';
import { InviteReferralsForm } from '../../forms';

import css from './CollectUserInfoWizard.css';
import { Footer } from './Footer';

const CollectRecommendationsForm = ({
    navigateToPreviousForm,
    updateInfoInProgress,
    currentUser,
    children,
    intl,
    ...restProps
}) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const { form, handleSubmit, values } = fieldRenderProps;
            const { recipientsList, message } = values;

            const { value: emailValue, valid: emailValid } = form.getFieldState('email') || {};

            const listIsNonEmpty = Array.isArray(recipientsList) && recipientsList.length > 0;
            const disabled = listIsNonEmpty ? !message || (emailValue && !emailValid) : false;

            return (
                <>
                    <Form onSubmit={handleSubmit} className={css.form}>
                        <h2 className={css.collectInfoHeader}>
                            <FormattedMessage id="CollectUserInfoWizard.recommendationsHeader" />
                        </h2>

                        <InviteReferralsForm
                            {...fieldRenderProps}
                            messageRequired={listIsNonEmpty}
                        />
                    </Form>
                    <Footer
                        disabled={disabled}
                        navigateToPreviousForm={navigateToPreviousForm}
                        updateInfoInProgress={updateInfoInProgress}
                        isLast
                        currentUser={currentUser}
                        values={values}
                        handleSubmit={() => {
                            restProps.onSubmit(values);
                        }}
                    />
                </>
            );
        }}
    />
);

CollectRecommendationsForm.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

export default compose(injectIntl)(CollectRecommendationsForm);
