import React from 'react';

import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import { Button } from '../../components';

import { useSingleListing } from './useSingleListing';
import { SEND_INQUIRY_STEP } from './InquiryModal.helpers';
import ListingRadioButton from './ListingRadioButton';
import css from './InquiryModal.css';
import { useInquiryModalContext } from './useInquiryModalContext';

export default ({ listings, publicUserName, disabled, inProgress, ...rest }) => {
    const { setStep } = useInquiryModalContext();
    const { riderListing } = rest;

    const isSingleListing = listings && listings.length === 1;
    const [matchingMatrixLoading, matchingScore, fulfilled] = useMatchingMatrix({
        riderListingId:
            isSingleListing && riderListing && riderListing.id ? riderListing.id.uuid : null,
        horseOwnerListingId: isSingleListing ? listings[0].id.uuid : null,
    });

    useSingleListing({
        isSingleListing,
        matchingScore,
        fulfilled,
        listings,
        ...rest,
    });

    const showListings = Array.isArray(listings) && listings.length > 0;

    return (
        <>
            <section className={css.listingsDataHolder}>
                {showListings &&
                    listings.map(listing => (
                        <ListingRadioButton key={listing.id.uuid} listing={listing} {...rest} />
                    ))}
            </section>
            <footer>
                <Button
                    type="button"
                    disabled={disabled}
                    inProgress={inProgress || matchingMatrixLoading}
                    onClick={() => setStep(SEND_INQUIRY_STEP)}
                >
                    Weiter
                </Button>
            </footer>
        </>
    );
};
