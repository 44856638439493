import { updateUserProfileInfo } from '../../ducks/user.duck';
import { updateListingDataOnWishlistAction } from '../../util/api';
import { insertAuthorDataIntoListing } from '../../util/listings';
import { userIsNotAvailable } from '../../util/user';

export const DISCARD_WISHLIST_LISTINGS = 'app/FavoritePage/DISCARD_WISHLIST_LISTINGS';
export const SET_WISHLIST_LISTINGS = 'app/FavoritePage/SET_WISHLIST_LISTINGS';
export const SET_WISHLIST_LISTINGS_ERROR = 'app/FavoritePage/SET_WISHLIST_LISTINGS_ERROR';
export const SET_WISHLIST_LISTINGS_REQUEST = 'app/FavoritePage/SET_WISHLIST_LISTINGS_REQUEST';
export const CHANGE_WISHLIST_LISTINGS_STATUS = 'app/FavoritePage/CHANGE_WISHLIST_LISTINGS_STATUS';

const initialState = {
    wishlistListings: null,
    wishlistRequestInProgress: false,
    wishlistError: null,
    wishlistListingStatusChanged: false,
    wishlistUnavailableListings: [],
};

const favoritePageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case SET_WISHLIST_LISTINGS_REQUEST:
            return {
                ...state,
                wishlistError: null,
                wishlistRequestInProgress: true,
            };
        case SET_WISHLIST_LISTINGS_ERROR:
            return {
                ...state,
                wishlistError: payload,
                wishlistRequestInProgress: false,
            };
        case DISCARD_WISHLIST_LISTINGS:
            return {
                ...state,
                wishlistListings: null,
            };
        case SET_WISHLIST_LISTINGS:
            return {
                ...state,
                wishlistListings: payload,
                wishlistRequestInProgress: false,
                wishlistError: null,
            };
        case CHANGE_WISHLIST_LISTINGS_STATUS:
            return {
                ...state,
                wishlistListingStatusChanged: payload,
            };
        default:
            return state;
    }
};

export default favoritePageReducer;

export const discardWishlistListings = () => ({
    type: DISCARD_WISHLIST_LISTINGS,
});

export const setWishlistListings = data => ({
    type: SET_WISHLIST_LISTINGS,
    payload: data,
});

export const setWishlistListingsError = error => ({
    type: SET_WISHLIST_LISTINGS_ERROR,
    payload: error,
});

export const setWishlistListingsRequest = () => ({
    type: SET_WISHLIST_LISTINGS_REQUEST,
});

export const changeWishlistListingsStatus = newStatus => ({
    type: CHANGE_WISHLIST_LISTINGS_STATUS,
    payload: newStatus,
});

export const queryWishlistListings = ids => async (dispatch, getState, sdk) => {
    dispatch(discardWishlistListings());
    dispatch(setWishlistListingsRequest());
    dispatch(changeWishlistListingsStatus(false));

    return sdk.listings
        .query({
            ids,
            include: ['author', 'author.profileImage', 'images'],
            'fields.user': [
                'profile.displayName',
                'profile.abbreviatedName',
                'profile.bio',
                'profile.publicData.country',
            ],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
                'variants.square-small',
            ],
        })
        .then(({ data: { data: listings, included } }) => {
            /**
             * show only published listings (not draft, deleted, closed, etc)
             */
            const listingsPublished = listings.reduce((acc, listing) => {
                const { state } = listing.attributes;
                const isPublished = state === 'published';

                if (!isPublished) {
                    return acc;
                }

                const listingDataWithAuthor = insertAuthorDataIntoListing(listing, included);
                if (listingDataWithAuthor) {
                    return [...acc, listingDataWithAuthor];
                }

                return acc;
            }, []);

            if (ids.length !== listingsPublished.length) {
                /**
                 * if some of the pre-selected listings have been closed, deleted, etc.
                 * or their subscription status become active,
                 * show notification or (handle this case in any other way)
                 */
                dispatch(changeWishlistListingsStatus(true));
            }

            dispatch(setWishlistListings(listingsPublished));
        })
        .catch(e => setWishlistListingsError(true));
};

export const removeUnavailableListings = () => (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
        FavoritePage: { wishlistListings: listingsPublished },
    } = getState();

    const {
        attributes: {
            profile: {
                publicData: { userType },
                protectedData: { wishlist },
            },
        },
    } = currentUser;

    const ids = wishlist[userType];
    /**
     * remove those ids which listings have been deleted/closed etc
     */
    const publishedIdsDictionary = listingsPublished.reduce(
        (acc, { id: { uuid } }) => ({ ...acc, [uuid]: true }),
        {}
    );

    ids.filter(id => !publishedIdsDictionary[id]).forEach(idToDelete =>
        dispatch(deleteWishlistItem(idToDelete))
    );
};

export const getUserWishlist = () => (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();

    if (userIsNotAvailable(currentUser)) return;

    const {
        attributes: {
            profile: {
                publicData: { userType },
                protectedData: { wishlist },
            },
        },
    } = currentUser;

    if (!wishlist || !Array.isArray(wishlist[userType]) || wishlist[userType].length === 0) {
        return;
    }
    /**
     * wishlist
     * [userType]: [id1, id2]
     */

    dispatch(queryWishlistListings(wishlist[userType]));
};

const extractWishlistParamsUpdated = (currentUser, listingId, action) => {
    try {
        const {
            protectedData: { wishlist: existingWishlist },
            publicData: { userType },
        } = currentUser.attributes.profile;

        const wishlistDataExists =
            existingWishlist &&
            existingWishlist[userType] &&
            Array.isArray(existingWishlist[userType]);

        const extractUserWishlistData = () => {
            if (action === 'add') {
                return wishlistDataExists
                    ? [...existingWishlist[userType], listingId]
                    : [listingId];
            }

            if (action === 'remove') {
                return wishlistDataExists
                    ? existingWishlist[userType].filter(id => id !== listingId)
                    : [];
            }
        };

        return {
            protectedData: {
                wishlist: {
                    ...(existingWishlist || {}),
                    [userType]: extractUserWishlistData(),
                },
            },
        };
    } catch (e) {
        return null;
    }
};

export const addWishlistItem = listingId => (dispatch, getState, sdk) => {
    const action = 'add';

    const {
        user: { currentUser },
    } = getState();

    const params = extractWishlistParamsUpdated(currentUser, listingId, action);

    if (!params) {
        return dispatch(setWishlistListingsError(true));
    }

    const currentUserDataUpd = { ...currentUser };
    currentUserDataUpd.attributes.profile.protectedData.wishlist = {
        ...params.protectedData.wishlist,
    };

    return dispatch(updateUserProfileInfo(params, currentUserDataUpd))
        .then(() => updateListingDataOnWishlistAction(listingId, currentUser.id.uuid, 'add'))
        .catch(e => dispatch(setWishlistListingsError(true)));
};

export const deleteWishlistItem = listingId => (dispatch, getState, sdk) => {
    const action = 'remove';

    const {
        user: { currentUser },
    } = getState();

    const params = extractWishlistParamsUpdated(currentUser, listingId, action);

    if (!params) {
        return dispatch(setWishlistListingsError(true));
    }

    const currentUserDataUpd = { ...currentUser };
    currentUserDataUpd.attributes.profile.protectedData.wishlist = {
        ...params.protectedData.wishlist,
    };

    return dispatch(updateUserProfileInfo(params, currentUserDataUpd))
        .then(() => updateListingDataOnWishlistAction(listingId, currentUser.id.uuid, action))
        .then(() => dispatch(discardWishlistListings()))
        .then(() => dispatch(getUserWishlist()))
        .catch(e => dispatch(setWishlistListingsError(true)));
};

export const loadData = () => (dispatch, getState, sdk) => {
    return Promise.all([dispatch(getUserWishlist())]);
};
