import React from 'react';
/** icon-eye-m */
/** icon-eye */
export default ({ clickHandler = () => null }) => (
    <svg
        onClick={clickHandler}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M7.520 3.519 C 6.520 3.587,5.571 3.864,4.504 4.400 C 3.347 4.980,2.281 5.764,1.298 6.756 C 0.831 7.228,0.691 7.413,0.590 7.692 C 0.530 7.855,0.521 7.923,0.522 8.200 C 0.522 8.761,0.644 8.984,1.315 9.660 C 2.672 11.027,4.266 12.046,5.840 12.554 C 6.575 12.791,7.194 12.887,8.000 12.887 C 9.209 12.887,10.237 12.630,11.440 12.029 C 12.632 11.434,13.707 10.647,14.699 9.646 C 15.357 8.981,15.478 8.758,15.478 8.200 C 15.479 7.635,15.364 7.422,14.687 6.741 C 12.941 4.982,10.776 3.795,8.893 3.562 C 8.478 3.511,7.906 3.493,7.520 3.519 M8.840 4.558 C 10.225 4.762,11.720 5.498,13.151 6.680 C 13.549 7.008,14.374 7.821,14.445 7.954 C 14.474 8.007,14.492 8.101,14.492 8.202 C 14.494 8.419,14.445 8.488,13.925 9.008 C 12.577 10.352,10.941 11.346,9.454 11.723 C 8.548 11.953,7.452 11.953,6.546 11.723 C 5.050 11.344,3.407 10.344,2.060 8.993 C 1.552 8.484,1.506 8.418,1.508 8.202 C 1.508 8.101,1.526 8.007,1.555 7.953 C 1.581 7.906,1.796 7.671,2.034 7.431 C 3.554 5.902,5.420 4.837,7.040 4.575 C 7.414 4.515,7.411 4.515,7.973 4.511 C 8.364 4.508,8.580 4.520,8.840 4.558 M7.680 5.389 C 6.654 5.490,5.720 6.218,5.351 7.205 C 5.215 7.570,5.187 7.745,5.189 8.213 C 5.191 8.597,5.200 8.686,5.260 8.910 C 5.526 9.904,6.306 10.685,7.284 10.934 C 8.268 11.186,9.346 10.886,10.023 10.173 C 10.523 9.647,10.784 9.044,10.818 8.333 C 10.872 7.232,10.307 6.235,9.330 5.709 C 8.841 5.445,8.257 5.332,7.680 5.389 M8.581 6.462 C 8.882 6.569,9.061 6.683,9.294 6.918 C 9.655 7.282,9.826 7.692,9.824 8.187 C 9.821 8.709,9.656 9.113,9.294 9.482 C 9.116 9.663,9.038 9.720,8.827 9.823 C 8.507 9.979,8.317 10.027,8.020 10.027 C 7.469 10.027,7.000 9.819,6.631 9.411 C 6.463 9.225,6.298 8.922,6.225 8.667 C 6.151 8.402,6.155 7.964,6.236 7.707 C 6.342 7.368,6.468 7.155,6.705 6.919 C 7.110 6.516,7.479 6.370,8.053 6.384 C 8.330 6.391,8.420 6.405,8.581 6.462 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
