import React from 'react';
import {
    AuthenticationPage,
    CheckoutPage,
    ContactDetailsPage,
    EditListingPage,
    EmailVerificationPage,
    InboxPage,
    LandingPage,
    LandingPageMatchingAlgorithm,
    LandingPageAboutUs,
    LandingPageCareer,
    LandingPageForCompanies,
    LandingPageForClubs,
    LandingPageSuperDeals,
    ListingPage,
    ManageListingsPage,
    NotFoundPage,
    PasswordChangePage,
    DeleteAccountPage,
    FavoritePage,
    PasswordRecoveryPage,
    PasswordResetPage,
    PayoutPreferencesPage,
    PersonalInfoPage,
    PaymentMethodsPage,
    PrivacyPolicyPage,
    ProfilePage,
    ProfileSettingsPage,
    SearchPage,
    TermsOfServicePage,
    KodexPage,
    CommunityPage,
    InseratePage,
    TransactionPage,
    ImpressumPage,
    OnboardingPage,
    ConfirmationPage,
    EditUserInfoPage,
    AuthIdpDefaultReturnPage,
    FindListingsPage,
    BalancePage,
    ReferralPage,
    VisitorsPage,
    NotificationsControlPage,
    /** admin pages */
    AdminPanelsPage,
    AdminReviewsPage,
    AdminPageSuperDeals,
    AdminPageClubsOffers,
    AdminAvatarsPage,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

import collectingSeoLandingPagesRoutes from './containers/SeoLandingPages/SeoLandingPagesRoutes';
// import { Redirect } from 'react-router-dom';

export const ACCOUNT_SETTINGS_PAGES = [
    'ContactDetailsPage',
    'PersonalInfoPage',
    'PasswordChangePage',
    'PayoutPreferencesPage',
    'PaymentMethodsPage',
    'DeleteAccountPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
    return [
        ...collectingSeoLandingPagesRoutes,
        {
            path: '/',
            name: 'LandingPage',
            component: props => <LandingPage {...props} />,
            loadData: LandingPage.loadData,
        },
        {
            path: '/ueber-uns',
            name: 'AboutUs',
            component: () => <LandingPageAboutUs />,
        },
        {
            path: '/impressum',
            name: 'ImpressumPage',
            component: () => <ImpressumPage />,
        },
        {
            path: '/subscription',
            name: 'ImpressumPage',
            component: ImpressumPage,
        },
        {
            path: '/s',
            name: 'SearchPage',
            component: props => <SearchPage {...props} />,
            loadData: SearchPage.loadData,
        },
        {
            path: '/s/delete-search-group/:searchGroupIdToDelete',
            name: 'DeleteSearchGroupPage',
            component: props => <SearchPage {...props} />,
            loadData: SearchPage.loadData,
        },
        {
            path: '/s/filters',
            name: 'SearchFiltersPage',
            component: props => <SearchPage {...props} tab="filters" />,
            loadData: SearchPage.loadData,
        },
        {
            path: '/s/listings',
            name: 'SearchListingsPage',
            component: props => <SearchPage {...props} tab="listings" />,
            loadData: SearchPage.loadData,
        },
        {
            path: '/s/map',
            name: 'SearchMapPage',
            component: props => <SearchPage {...props} tab="map" />,
            // loadData: SearchPage.loadData,
        },
        {
            path: '/l',
            name: 'ListingBasePage',
            component: RedirectToLandingPage,
        },
        {
            path: '/l/:slug/:id',
            name: 'ListingPage',
            component: props => <ListingPage {...props} />,
            loadData: ListingPage.loadData,
        },
        // {
        //   path: '/l/:slug/:id/bewertung',
        //   name: 'Add External Review Listing',
        //   component: props => <ListingPage addExtReview={true} {...props} />,
        //   loadData: ListingPage.loadData,
        // },
        {
            path: '/l/:slug/:id/checkout',
            name: 'CheckoutPage',
            auth: true,
            component: props => <CheckoutPage {...props} />,
            setInitialValues: CheckoutPage.setInitialValues,
        },
        {
            path: '/l/:slug/:id/:variant',
            name: 'ListingPageVariant',
            auth: true,
            authPage: 'LoginPage',
            component: props => <ListingPage {...props} />,
            loadData: ListingPage.loadData,
        },
        {
            path: '/l/new',
            name: 'NewListingPage',
            // auth: true,
            component: () => (
                <NamedRedirect
                    name="EditListingPage"
                    params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'overview' }}
                />
            ),
        },
        {
            path: '/l/:slug/:id/external/:type/:tab',
            name: 'EditListingPageExternal',
            auth: true,
            component: props => <EditListingPage {...props} externalListing={true} />,
            loadData: EditListingPage.loadData,
        },
        {
            path: '/l/:slug/:id/:type/:tab',
            name: 'EditListingPage',
            // auth: true,
            component: props => <EditListingPage {...props} />,
            loadData: EditListingPage.loadData,
        },
        // Canonical path should be after the `/l/new` path since they
        // conflict and `new` is not a valid listing UUID.
        {
            path: '/l/:slug/:id',
            name: 'ListingPageCanonical',
            component: props => <ListingPage {...props} />,
            loadData: ListingPage.loadData,
        },
        {
            path: '/u',
            name: 'ProfileBasePage',
            component: RedirectToLandingPage,
        },
        {
            path: '/profile/:userType/public/:id',
            name: 'ProfilePage',
            component: props => <ProfileSettingsPage isPublic {...props} />,
            loadData: ProfilePage.loadData,
        },
        {
            path: '/profile/:userType/preview/:id',
            name: 'ProfilePagePreview',
            component: props => <ProfileSettingsPage isPublic {...props} />,
            loadData: ProfilePage.loadData,
        },
        {
            path: '/ext-reviews-profile-preview/:id',
            name: 'ExternalReviewProfilePreviewPage',
            component: props => <ProfileSettingsPage isPublic {...props} />,
            loadData: ProfilePage.loadData,
        },
        {
            path: '/profile/:userType/edit',
            name: 'ProfileSettingsPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <ProfileSettingsPage {...props} />,
        },
        {
            path: '/login',
            name: 'LoginPage',
            component: props => <AuthenticationPage {...props} tab="login" />,
        },
        {
            path: '/signup',
            name: 'SignupPage',
            component: props => <AuthenticationPage {...props} tab="signup" />,
        },
        {
            path: '/onboarding/:userType/:tab',
            name: 'OnboardingPage',
            component: props => <OnboardingPage {...props} />,
        },
        {
            path: '/confirm',
            name: 'ConfirmPage',
            component: props => <AuthenticationPage {...props} tab="confirm" />,
        },
        // after onboarding flow is done
        {
            path: '/confirmation/:userType',
            name: 'ConfirmationPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <ConfirmationPage {...props} />,
        },
        {
            path: '/signup/email-verification',
            name: 'EmailVerification',
            component: props => <AuthenticationPage {...props} tab="email-verification" />,
        },
        {
            path: '/signup/rider',
            name: 'SignupRider',
            component: props => <AuthenticationPage {...props} tab="signup-rider" />,
        },
        {
            path: '/signup/horseowner',
            name: 'SignupHorseowner',
            component: props => <AuthenticationPage {...props} tab="signup-horse-owner" />,
        },
        {
            path: '/recover-password',
            name: 'PasswordRecoveryPage',
            component: props => <PasswordRecoveryPage {...props} />,
        },
        {
            path: '/recover-password/confirmation',
            name: 'PasswordRecoveryPageConfirmation',
            component: props => <PasswordRecoveryPage {...props} step="confirmation" />,
        },
        {
            path: '/inbox',
            name: 'InboxPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <InboxPage {...props} />,
            loadData: InboxPage.loadData,
        },
        {
            path: '/rider/:id',
            name: 'OrderPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />
            ),
        },
        {
            path: '/horseowner/:id',
            name: 'SalePage',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />
            ),
        },
        {
            path: '/messages/:id',
            name: 'OrderDetailsPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <TransactionPage {...props} />,
            loadData: (params, searchParams) => TransactionPage.loadData(params, searchParams),
            setInitialValues: TransactionPage.setInitialValues,
        },
        {
            path: '/messages',
            name: 'OrderMessagesPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <TransactionPage {...props} /* transactionRole="customer" */ />,
            loadData: TransactionPage.loadData,
            setInitialValues: TransactionPage.setInitialValues,
        },
        {
            path: '/listings',
            name: 'ManageListingsPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <ManageListingsPage {...props} />,
            loadData: ManageListingsPage.loadData,
        },
        {
            path: '/account',
            name: 'AccountSettingsPage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <NamedRedirect name="ContactDetailsPage" />,
        },
        {
            path: '/account/settings/contact-details',
            name: 'ContactDetailsPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <ContactDetailsPage {...props} />,
            loadData: ContactDetailsPage.loadData,
        },
        {
            path: '/account/settings/notifications',
            name: 'NotificationsControlPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <NotificationsControlPage {...props} />,
        },
        {
            path: '/account/settings/login',
            name: 'PasswordChangePage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <PasswordChangePage {...props} />,
        },
        {
            path: '/account/settings/account',
            name: 'DeleteAccountPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <DeleteAccountPage {...props} />,
        },
        {
            path: '/account/settings/cookies',
            name: 'CookiesPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <PasswordChangePage {...props} cookiesPage />,
        },
        {
            path: '/favorites',
            name: 'FavoritePage',
            auth: true,
            authPage: 'LoginPage',
            loadData: FavoritePage.loadData,
            component: props => <FavoritePage {...props} />,
        },
        {
            path: '/account/payments',
            name: 'PayoutPreferencesPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <PayoutPreferencesPage {...props} />,
            loadData: PayoutPreferencesPage.loadData,
        },
        {
            path: '/account/payments/stripe-account-verification-failed',
            name: 'StripeAccountVerificationErrorPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <PayoutPreferencesPage {...props} stripeAccountVerificationFailed />
            ),
            loadData: PayoutPreferencesPage.loadData,
        },
        {
            path: '/account/settings/address',
            name: 'PersonalInfoPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <PersonalInfoPage {...props} />,
            loadData: PersonalInfoPage.loadData,
        },
        {
            path: '/account/payment-methods',
            name: 'PaymentMethodsPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <PaymentMethodsPage {...props} />,
            loadData: PaymentMethodsPage.loadData,
        },
        {
            path: '/agb',
            name: 'TermsOfServicePage',
            component: props => <TermsOfServicePage {...props} />,
        },
        {
            path: '/verhaltenskodex',
            name: 'KodexPage',
            component: props => <KodexPage {...props} />,
        },
        {
            path: '/community-richtlinien',
            name: 'CommunityPage',
            component: props => <CommunityPage {...props} />,
        },
        {
            path: '/inserate-richtlinien',
            name: 'InseratePage',
            component: props => <InseratePage {...props} />,
        },
        {
            path: '/datenschutz',
            name: 'PrivacyPolicyPage',
            component: props => <PrivacyPolicyPage {...props} />,
        },
        {
            path: '/notfound',
            name: 'NotFoundPage',
            component: props => <NotFoundPage {...props} />,
        },

        // Do not change this path!
        //
        // The API expects that the application implements /reset-password endpoint
        {
            path: '/reset-password',
            name: 'PasswordResetPage',
            component: props => <PasswordResetPage {...props} />,
        },

        // Do not change this path!
        //
        // The API expects that the application implements /verify-email endpoint
        {
            path: '/verify-email',
            name: 'EmailVerificationPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <EmailVerificationPage {...props} />,
        },
        {
            path: '/abonnement',
            name: 'AbonnementPage',
            component: props => <NotFoundPage {...props} />,
        },
        {
            path: '/profile/:userType/edit/:section',
            name: 'EditUserInfo',
            auth: true,
            authPage: 'LoginPage',
            component: props => <EditUserInfoPage {...props} />,
        },
        {
            path: '/profile/:userType/recommend/:userNameToRecommend/:userId',
            name: 'AddExternalReview',
            component: props => <EditUserInfoPage {...props} />,
            loadData: EditUserInfoPage.loadData,
        },
        {
            path: '/matching',
            name: 'MatchingPage',
            component: () => <LandingPageMatchingAlgorithm />,
        },
        {
            path: '/unternehmen',
            name: 'ForCompaniesPage',
            component: () => <LandingPageForCompanies />,
        },
        {
            path: '/vereine',
            name: 'ForClubs',
            component: () => <LandingPageForClubs />,
        },
        {
            path: '/superdeals',
            name: 'SuperDeals',
            component: () => <LandingPageSuperDeals />,
        },
        {
            path: '/idp-auth-success',
            name: 'IdpAuthSuccessPage',
            auth: true,
            component: () => <AuthIdpDefaultReturnPage />,
        },
        {
            path: '/pferdemensch-finden',
            name: 'FindRiderPage',
            component: () => <FindListingsPage type="listing-rider" />,
        },
        {
            path: '/pferd-finden',
            name: 'FindHorsePage',
            component: () => <FindListingsPage type="horse" />,
        },
        {
            path: '/unternehmen',
            name: 'BecomePartnerPage',
            component: () => <NotFoundPage />,
        },
        {
            path: '/karriere',
            name: 'CareerPage',
            component: () => <LandingPageCareer />,
        },
        {
            path: '/support',
            name: 'SupportPage',
            component: () => <NotFoundPage />,
        },
        {
            path: '/presse',
            name: 'PressPage',
            component: () => <NotFoundPage />,
        },
        {
            path: '/blog',
            name: 'BlogPage',
            component: () => <NotFoundPage />,
        },
        {
            path: '/balance',
            name: 'BalancePage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <BalancePage />,
        },
        {
            path: '/empfehlen',
            name: 'ReferralPage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <ReferralPage />,
        },
        {
            path: '/visitors',
            name: 'VisitorsPage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <VisitorsPage />,
        },
        {
            path: '/zufriedenheitsgarantie',
            name: 'GuaranteePage',
            component: () => <NotFoundPage />,
        },
        // Admin pages
        {
            path: '/admin-index',
            name: 'AdminPanelsPage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <AdminPanelsPage />,
        },
        {
            path: '/ext-reviews-admin-page',
            name: 'AdminReviewsPage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <AdminReviewsPage />,
        },
        {
            path: '/super-deals-admin-page',
            name: 'AdminPageSuperDeals',
            auth: true,
            authPage: 'LoginPage',
            component: () => <AdminPageSuperDeals />,
        },
        {
            path: '/clubs-offers-admin-page',
            name: 'AdminPageClubsOffers',
            auth: true,
            authPage: 'LoginPage',
            component: () => <AdminPageClubsOffers />,
        },
        {
            path: '/user-avatar-admin-page',
            name: 'AdminAvatarsPage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <AdminAvatarsPage />,
        },
    ];
};

export default routeConfiguration;
