import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ensureCurrentUser, ensureUser } from '../../../../util/data';
import { trimDisplayNameLastWord } from '../../../../util/text';
import { AvatarMedium, InquiryModal, RiderNotAvailableModal } from '../../../../components';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routeConfiguration';
import ExternalHorseModal from '../../components/ExternalHorseModal';
import css from './SectionContact.css';
import { sendEnquiry } from '../../ListingPage.duck';
import { isLocalhost } from '../../../../util/urlHelpers';
import ContactUserSection from './ContactUserSection';
import {
    ERROR_INQUIRY_DISABLED_FOR_RIDER_NOT_AVAILABLE,
    ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE,
} from '../../../../components/InquiryModal/InquiryModal.helpers';

const windowDefined = typeof window !== 'undefined';

const SectionContact = ({
    isAnonymousListing,
    setRetriggerKey,
    formattedPrice,
    currentListing,
    listingAuthor,
    isOwnListing,
    riderListing,
    loading,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux store data */
    const currentUserSelector = useSelector(s => s.user.currentUser);
    const sendEnquiryError = useSelector(s => s.ListingPage.sendEnquiryError);

    const onSendEnquiry = values => dispatch(sendEnquiry(values));

    const [externalListingModalOpen, setExternalListingModalOpen] = useState();
    const [riderNotAvailableStatus, setRiderNotAvailableStatus] = useState(null);
    const [inquiryModalOpen, setInquiryModalOpen] = useState();

    const currentUser = ensureCurrentUser(currentUserSelector);
    const currentUserId = currentUser?.id?.uuid;

    const { package2, package3, externalSourceLink, externalSourceName, externalListing } =
        currentListing.attributes.publicData || {};

    const multiPackage =
        (package2 && package2.active && package2.price) ||
        (package3 && package3.active && package3.price);

    const userIsCurrentUser = listingAuthor && listingAuthor.type === 'currentUser';

    const ensuredUser = userIsCurrentUser
        ? ensureCurrentUser(listingAuthor)
        : ensureUser(listingAuthor);

    const { displayName } = ensuredUser.attributes.profile;

    const joiningDate = listingAuthor
        ? new Date(listingAuthor.attributes?.createdAt).getFullYear()
        : 'N/A';

    const authorDisplayName = isAnonymousListing
        ? 'Besitzer:in'
        : displayName
        ? trimDisplayNameLastWord(displayName)
        : '';

    const ridingListingAuthorId = isOwnListing || !currentUserId ? null : currentUserId;

    const onSubmitEnquiry = async values => {
        const txId = await onSendEnquiry(values);

        if (txId && txId.uuid) {
            setInquiryModalOpen(false);

            history.push(
                createResourceLocatorString(
                    'OrderDetailsPage',
                    routeConfiguration(),
                    { id: txId.uuid },
                    {}
                )
            );
        }
    };

    const onSubmitEnquiryDecorator = values => {
        if (isLocalhost()) return onSubmitEnquiry(values);

        if (windowDefined) {
            window.checkRecaptchaBeforeSubmit(() => onSubmitEnquiry(values));
        }
    };

    const handleInquiryError = code => {
        if (
            code === ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE ||
            code === ERROR_INQUIRY_DISABLED_FOR_RIDER_NOT_AVAILABLE
        ) {
            setInquiryModalOpen(false);
            return setRiderNotAvailableStatus(code);
        }
    };

    return (
        <>
            {externalListing ? (
                <ExternalHorseModal
                    onCloseModal={() => setExternalListingModalOpen(false)}
                    isModalOpen={externalListingModalOpen}
                    externalSourceLink={externalSourceLink}
                    externalSourceName={externalSourceName}
                />
            ) : null}

            {!!riderNotAvailableStatus && (
                <RiderNotAvailableModal
                    isOpen
                    onClose={() => setRiderNotAvailableStatus(null)}
                    horseownerName={authorDisplayName}
                    riderNotAvailableStatus={riderNotAvailableStatus}
                    notifyOnAvailabilityChange={() => {
                        setRetriggerKey(new Date().getTime());
                        setRiderNotAvailableStatus(null);
                    }}
                />
            )}

            {inquiryModalOpen && (
                <InquiryModal
                    isOpen
                    onClose={() => setInquiryModalOpen(false)}
                    publicUser={listingAuthor}
                    initialListing={currentListing}
                    sendEnquiryError={sendEnquiryError}
                    ridingListingAuthorId={ridingListingAuthorId}
                    onSendEnquiry={values => onSubmitEnquiryDecorator(values)}
                    notifyOnError={handleInquiryError}
                />
            )}

            <div className={css.root}>
                <div className={css.priceContainer}>
                    <span className={css.price}>
                        {multiPackage ? <span>Ab </span> : null}
                        {formattedPrice}
                    </span>
                    <div className={css.priceBottomText}>Monatliche Kosten</div>
                </div>

                <div className={css.profileContainer}>
                    <AvatarMedium
                        className={css.avatar}
                        user={listingAuthor}
                        disableProfileLink={isAnonymousListing}
                    />
                    <div>
                        <span className={css.userName}>{authorDisplayName}</span>
                        <p className={css.userBottomText}>Mitglied seit {joiningDate}</p>
                    </div>
                </div>

                <ContactUserSection
                    loading={loading}
                    currentUser={currentUser}
                    riderListing={riderListing}
                    isOwnListing={isOwnListing}
                    listingAuthor={listingAuthor}
                    currentListing={currentListing}
                    authorDisplayName={authorDisplayName}
                    userIsCurrentUser={userIsCurrentUser}
                    isAnonymousListing={isAnonymousListing}
                    setInquiryModalOpen={setInquiryModalOpen}
                    setRiderNotAvailableStatus={setRiderNotAvailableStatus}
                    setExternalListingModalOpen={setExternalListingModalOpen}
                />
            </div>
        </>
    );
};

export default SectionContact;
