import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser, sendVerificationEmail } from '../../ducks/user.duck';
import {
    LayoutSideNavigation,
    LayoutWrapperMain,
    LayoutWrapperAccountSettingsSideNav,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    Footer,
    Page,
} from '../../components';
import { ContactDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    saveContactDetails,
    saveContactDetailsClear,
    resetPassword,
} from './ContactDetailsPage.duck';
import css from './ContactDetailsPage.css';
import { format } from '../../components/FieldPhoneNumberInput/fiFormatter';
import VerifyEmailModal from './VerifyEmailModal';

export const ContactDetailsPageComponent = ({
    currentUser,
    discardErrors,
    saveEmailError,
    scrollingDisabled,
    savePhoneNumberError,
    contactDetailsChanged,
    saveContactDetailsInProgress,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSubmitContactDetails,
    sendVerificationOtpInProgress,
    sendVerificationOtpError,
    intl,
    onResetPassword,
    resetPasswordInProgress,
    resetPasswordError,
}) => {
    const user = ensureCurrentUser(currentUser);
    const {
        attributes: {
            profile: { protectedData = {} },
            email: currentEmail = '',
        },
    } = user;
    const currentPhoneNumber = (protectedData.phoneNumber || '').toString();

    return (
        <>
            <VerifyEmailModal />
            <Page
                title={intl.formatMessage({ id: 'ContactDetailsPage.title' })}
                scrollingDisabled={scrollingDisabled}
            >
                <LayoutSideNavigation
                    rootClassName={css.sideNavigationLayout}
                    joinContainerClasses
                    containerClassName={css.containerClassName}
                >
                    <LayoutWrapperTopbar>
                        <TopbarContainer
                            currentPage="ContactDetailsPage"
                            desktopClassName={css.desktopTopbar}
                            mobileClassName={css.mobileTopbar}
                        />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperAccountSettingsSideNav currentTab="ContactDetailsPage" />
                    <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                        <div className={css.content}>
                            <h1 className={css.title}>
                                <FormattedMessage id="ContactDetailsPage.heading" />
                            </h1>
                            {user.id ? (
                                <ContactDetailsForm
                                    className={css.form}
                                    initialValues={{
                                        email: currentEmail,
                                        phoneNumber: currentPhoneNumber.substring(3),
                                    }}
                                    saveEmailError={saveEmailError}
                                    savePhoneNumberError={savePhoneNumberError}
                                    currentUser={currentUser}
                                    onResendVerificationEmail={onResendVerificationEmail}
                                    resetPasswordInProgress={resetPasswordInProgress}
                                    resetPasswordError={resetPasswordError}
                                    onResetPassword={onResetPassword}
                                    onSubmit={values => {
                                        const phoneNumber =
                                            values.phoneNumber && values.countryCode
                                                ? `${values.countryCode}${format(
                                                      values.phoneNumber,
                                                      values.countryCode
                                                  )}`
                                                : null;

                                        onSubmitContactDetails({
                                            ...values,
                                            phoneNumber,
                                            currentEmail,
                                            currentPhoneNumber,
                                        });
                                    }}
                                    discardErrors={discardErrors}
                                    inProgress={saveContactDetailsInProgress}
                                    ready={contactDetailsChanged}
                                    sendVerificationEmailInProgress={
                                        sendVerificationEmailInProgress
                                    }
                                    sendVerificationEmailError={sendVerificationEmailError}
                                    sendVerificationOtpError={sendVerificationOtpError}
                                    sendVerificationOtpInProgress={sendVerificationOtpInProgress}
                                    savedCountryCode={currentPhoneNumber.substring(0, 3)}
                                />
                            ) : null}
                        </div>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter className={css.footer}>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSideNavigation>
            </Page>
        </>
    );
};

const { bool, func } = PropTypes;

ContactDetailsPageComponent.propTypes = {
    saveEmailError: propTypes.error,
    savePhoneNumberError: propTypes.error,
    saveContactDetailsInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    contactDetailsChanged: bool.isRequired,
    onSubmitContactDetails: func.isRequired,
    scrollingDisabled: bool.isRequired,
    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    onResendVerificationEmail: func.isRequired,
    esetPasswordInProgress: bool,
    resetPasswordError: propTypes.error,
    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    // Topbar needs user info.
    const { currentUser, sendVerificationEmailError, sendVerificationEmailInProgress } = state.user;

    return {
        currentUser,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        scrollingDisabled: isScrollingDisabled(state),
        ...state.ContactDetailsPage,
    };
};

const mapDispatchToProps = dispatch => ({
    discardErrors: () => dispatch(saveContactDetailsClear()),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
    onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
    onResetPassword: values => dispatch(resetPassword(values)),
});

const ContactDetailsPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ContactDetailsPageComponent);

ContactDetailsPage.loadData = () => {
    // Since verify email happens in separate tab, current user's data might be updated
    return fetchCurrentUser();
};

export default ContactDetailsPage;
