import React, { useState } from 'react';
import { IconCrossM } from '../../icons';
import Tooltip from '../Tooltip/Tooltip';
import { FormattedMessage } from '../../util/reactIntl';
import css from './CollectUserInfoWizard.css';
import { useIsMobile } from '../../hooks/useIsMobile';

const TooltipSectionInfo = ({ headingId, descriptionId, containerRef }) => {
    const [isMobile, , computing] = useIsMobile();
    const [tooltipVisible, setTooltipVisibility] = useState(false);

    if (computing) return null;

    return (
        <Tooltip
            tooltipClassName={css.levelTooltip}
            suppressOutsideClick
            isStatic
            staticTooltipState={tooltipVisible}
            staticTooltipStateHandler={() => setTooltipVisibility(false)}
            parentContainerRef={containerRef}
            padding={isMobile ? 24 : 0}
            content={
                <>
                    <p>
                        <FormattedMessage id={headingId} />
                    </p>
                    <p>
                        <FormattedMessage id={descriptionId} />
                    </p>
                    <IconCrossM clickHandler={() => setTooltipVisibility(false)} />
                </>
            }
        >
            <img
                onClick={() => setTooltipVisibility(prevState => !prevState)}
                src="https://horsedeal.imgix.net/static/icons/icon-info-m-Ground-Ground-040.svg"
            />
        </Tooltip>
    );
};

export default TooltipSectionInfo;
