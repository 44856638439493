import React from 'react';
import { CropperPreview } from 'react-advanced-cropper';
import { FormattedMessage } from '../../util/reactIntl';
import { Avatar } from '../../components';
import ChangeAvatarIcon from './ChangeAvatarIcon';
import PositionIcon from './PositionIcon';
import css from './UploadUserAvatar.css';

export default ({
    setSrc,
    setOpen,
    setZoom,
    profielImageId,
    avatarComponentMaybe,
    previewRef,
    previewState,
    previewAllowed,
    currentUser,
}) => {
    const avatarComponent = avatarComponentMaybe ? (
        <Avatar
            className={css.avatar}
            renderSizes="(max-width: 767px) 96px, 240px"
            user={currentUser}
            disableProfileLink
            previewAllowed={previewAllowed}
        />
    ) : null;
    return (
        <>
            <div className={css.avatarContainer}>
                {avatarComponentMaybe ? (
                    avatarComponent
                ) : (
                    <CropperPreview ref={previewRef} className="preview" {...previewState} />
                )}
            </div>
            <aside className={css.changeAvatarSection}>
                <div
                    className={css.changeAvatar}
                    onClick={e => {
                        if (profielImageId) {
                            e.stopPropagation();
                            e.preventDefault();
                            const { variants } = currentUser.profileImage.attributes;
                            const squareSmall2x = variants['square-small2x'];
                            setZoom(1);
                            setSrc(squareSmall2x.url);
                            setOpen(true);
                        }
                    }}
                >
                    <PositionIcon />
                    <FormattedMessage id="ProfileSettingsForm.positionAvatar" />
                </div>
                <div className={css.changeAvatar}>
                    <ChangeAvatarIcon />
                    <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
            </aside>
        </>
    );
};
