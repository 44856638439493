import React from 'react';
import css from './EditUserData.css';
import { IconNewsletterXL } from '../../icons';
import { SocialLoginButtons as SLB } from '../../components';

export default ({ navigateToNextSteps }) => {
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showAppleLogin = !!process.env.REACT_APP_APPLE_CLIENT_ID;

    return (
        <div>
            <h3 className={css.title}>Anmeldedaten ändern</h3>
            <p className={css.description}>
                Wähle deine bevorzugte Methode für die Anmeldung in dein Konto aus.
            </p>

            <SLB buttonsHolderClassName={css.idpsHolder}>
                {showGoogleLogin && (
                    <SLB.GoogleLoginButton>
                        <SLB.GoogleLogo />
                        <p>Google</p>
                    </SLB.GoogleLoginButton>
                )}
                {showFacebookLogin && (
                    <SLB.FacebookLoginButton>
                        <SLB.FacebookLogo />
                        <p>Facebook</p>
                    </SLB.FacebookLoginButton>
                )}
                {showAppleLogin && (
                    <SLB.AppleLoginButton>
                        <SLB.AppleLogo />
                        <p>Apple</p>
                    </SLB.AppleLoginButton>
                )}
                <button type="button" onClick={navigateToNextSteps}>
                    <IconNewsletterXL />
                    <p>E-Mail-Adresse</p>
                </button>
            </SLB>
        </div>
    );
};
