import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.403 20.6715C10.5155 21.055 10.7492 21.3916 11.0691 21.6311C11.389 21.8706 11.7779 22 12.1775 22C12.5771 22 12.9659 21.8706 13.2858 21.6311C13.6057 21.3916 13.8394 21.055 13.952 20.6715M12.1752 4.05636V2.06254M12.1752 4.05636C13.9379 4.05636 15.6283 4.75657 16.8747 6.00295C18.1211 7.24933 18.8213 8.93978 18.8213 10.7024C18.8213 16.9462 20.1505 18.0131 20.1505 18.0131H4.19995C4.19995 18.0131 5.52917 16.3153 5.52917 10.7024C5.52917 8.93978 6.22937 7.24933 7.47575 6.00295C8.72213 4.75657 10.4126 4.05636 12.1752 4.05636Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
