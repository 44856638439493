import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, NamedLink } from '../../components';
import css from './ConfirmSignupForm.css';
import config from '../../config';
import { capitalize, capitalizeFormEntry } from '../../util/text';

const { userTypeRider } = config;

const ConfirmSignupFormComponent = props => (
    <FinalForm
        {...props}
        initialValues={{
            ...(props.initialValues || {}),
            userType: props.userType || userTypeRider,
        }}
        render={fieldRenderProps => {
            const {
                rootClassName,
                className,
                formId,
                handleSubmit,
                inProgress,
                invalid,
                intl,
                authInfo,
                form,
            } = fieldRenderProps;

            // email
            const emailLabel = intl.formatMessage({
                id: 'ConfirmSignupForm.emailLabel',
            });
            const emailPlaceholder = intl.formatMessage({
                id: 'ConfirmSignupForm.emailPlaceholder',
            });
            const emailRequiredMessage = intl.formatMessage({
                id: 'ConfirmSignupForm.emailRequired',
            });
            const emailRequired = validators.required(emailRequiredMessage);
            const emailInvalidMessage = intl.formatMessage({
                id: 'ConfirmSignupForm.emailInvalid',
            });
            const emailValid = validators.emailFormatValid(emailInvalidMessage);

            // firstName
            const firstNameLabel = intl.formatMessage({
                id: 'ConfirmSignupForm.firstNameLabel',
            });
            const firstNamePlaceholder = intl.formatMessage({
                id: 'ConfirmSignupForm.firstNamePlaceholder',
            });
            const firstNameRequiredMessage = intl.formatMessage({
                id: 'ConfirmSignupForm.firstNameRequired',
            });
            const firstNameRequired = validators.required(firstNameRequiredMessage);

            // lastName
            const lastNameLabel = intl.formatMessage({
                id: 'ConfirmSignupForm.lastNameLabel',
            });
            const lastNamePlaceholder = intl.formatMessage({
                id: 'ConfirmSignupForm.lastNamePlaceholder',
            });
            const lastNameRequiredMessage = intl.formatMessage({
                id: 'ConfirmSignupForm.lastNameRequired',
            });

            const lastNameRequired = validators.required(lastNameRequiredMessage);

            const classes = classNames(rootClassName || css.root, className);
            const submitDisabled = invalid || inProgress;

            // If authInfo is not available we should not show the ConfirmForm
            if (!authInfo) {
                return;
            }

            // Initial values from idp provider
            const { email, firstName, lastName } = authInfo;

            const privacyPolicyLink = (
                <NamedLink name="PrivacyPolicyPage">
                    <FormattedMessage id="SignupForm.privacyPolicyLink" />
                </NamedLink>
            );

            const termsOfUseLink = (
                <NamedLink name="TermsOfServicePage">
                    <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
                </NamedLink>
            );

            return (
                <Form className={classes} onSubmit={handleSubmit}>
                    <div>
                        <div className={css.name}>
                            <FieldTextInput
                                className={css.firstNameRoot}
                                type="text"
                                id={formId ? `${formId}.firstName` : 'firstName'}
                                name="firstName"
                                autoComplete="given-name"
                                label={firstNameLabel}
                                placeholder={firstNamePlaceholder}
                                initialValue={capitalize(firstName || '')}
                                validate={firstNameRequired}
                                handleBlur={() => capitalizeFormEntry('firstName', form)}
                            />
                            <FieldTextInput
                                className={css.lastNameRoot}
                                type="text"
                                id={formId ? `${formId}.lastName` : 'lastName'}
                                name="lastName"
                                autoComplete="family-name"
                                label={lastNameLabel}
                                placeholder={lastNamePlaceholder}
                                initialValue={capitalize(lastName || '')}
                                validate={lastNameRequired}
                                handleBlur={() => capitalizeFormEntry('lastName', form)}
                            />
                        </div>
                        <FieldTextInput
                            type="email"
                            id={formId ? `${formId}.email` : 'email'}
                            name="email"
                            autoComplete="email"
                            label={emailLabel}
                            placeholder={emailPlaceholder}
                            initialValue={email}
                            validate={validators.composeValidators(emailRequired, emailValid)}
                        />
                    </div>

                    <div className={css.bottomWrapper}>
                        <Button type="submit" inProgress={inProgress} disabled={submitDisabled}>
                            <FormattedMessage id="ConfirmSignupForm.submit" />
                        </Button>
                        <h4 className={css.bottomWrapperText}>
                            <FormattedMessage
                                id="SignupForm.privacyPolicyTermsNotification"
                                values={{ privacyPolicyLink, termsOfUseLink }}
                            />
                        </h4>
                    </div>
                </Form>
            );
        }}
    />
);

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
    inProgress: bool,

    onOpenTermsOfService: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
