import React, { useEffect, useState } from 'react';
import { func, number, string, shape, object } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';

import { Provider, useStore } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getMapBounds, getMapCenter, isMapsLibLoaded } from './SearchMap.helpers.js';
import SearchMapWithGoogleMap from './SearchMapWithGoogleMap';
import ReusableMapContainer from './components/ReusableMapContainer';
import css from './SearchMap.css';
import { compose } from 'redux';

const REUSABLE_MAP_HIDDEN_HANDLE = 'reusableMapHidden';

export const SearchMapComponent = ({
    onCloseAsModal,
    className,
    rootClassName,
    reusableContainerClassName,
    mapRootClassName,
    onActivateListing,
    history,
    intl,
    ...rest
}) => {
    const [mapReattachmentCount, setMapReattachmentCount] = useState(0);

    const store = useStore();

    useEffect(() => {
        setMapReattachmentCount(window.mapReattachmentCount || 0);
    }, []);

    const createURLToListing = listing => {
        const routes = routeConfiguration();

        const id = listing.id.uuid;
        const slug = createSlug(listing);
        const pathParams = { id, slug };

        return createResourceLocatorString('ListingPage', routes, pathParams, {});
    };

    const onListingInfoCardClicked = listing => {
        onCloseAsModal && onCloseAsModal();
        // To avoid full page refresh we need to use internal router
        history.push(createURLToListing(listing));
    };

    const forceUpdateHandler = () => {
        // Update global reattachement count
        window.mapReattachmentCount += 1;
        // Initiate rerendering
        setMapReattachmentCount(window.mapReattachmentCount);
    };

    const classes = classNames(rootClassName || css.root, className);

    // When changing from default map provider to Google Maps, you should use the following
    // component instead of SearchMapWithMapbox:
    return isMapsLibLoaded() ? (
        <ReusableMapContainer
            className={reusableContainerClassName}
            reusableMapHiddenHandle={REUSABLE_MAP_HIDDEN_HANDLE}
            onReattach={forceUpdateHandler}
            messages={intl.messages}
        >
            {/**
             * Add store + router for ListingCard rendered inside map
             */}
            <Provider store={store}>
                <BrowserRouter>
                    <SearchMapWithGoogleMap
                        containerElement={<div id="search-map-container" className={classes} />}
                        mapElement={<div className={mapRootClassName || css.mapRoot} />}
                        mapComponentRefreshToken={mapReattachmentCount}
                        createURLToListing={createURLToListing}
                        onListingInfoCardClicked={onListingInfoCardClicked}
                        onActivateListing={onActivateListing}
                        {...rest}
                        history={history}
                    />
                </BrowserRouter>
            </Provider>
        </ReusableMapContainer>
    ) : (
        <div className={classes} />
    );
};

SearchMapComponent.propTypes = {
    className: string,
    rootClassName: string,
    mapRootClassName: string,
    reusableContainerClassName: string,
    bounds: propTypes.latlngBounds,
    center: propTypes.latlng,
    location: shape({
        search: string.isRequired,
    }).isRequired,
    onCloseAsModal: func,
    onMapMoveEnd: func.isRequired,
    zoom: number,
    mapsConfig: object,
    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,
};

const SearchMap = compose(injectIntl)(SearchMapComponent);

SearchMap.getMapBounds = getMapBounds;
SearchMap.getMapCenter = getMapCenter;

export default SearchMap;
