import React, { useState } from 'react';
import { ModalPortal, PreviewButton, RecommendationItem } from '../../components';
import {
    ASSESSMENT_HORSE_DEALING,
    ASSESSMENT_RELIABILITY,
    ASSESSMENT_RIDING_SKILLS,
} from '../../forms/ExternalReviewForm/ExternalReviewForm';
import css from './PreviewInviteModal.css';

export default ({ currentUser }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { firstName } = currentUser.attributes.profile;

    return (
        <>
            <div className={css.preview} datatype="preview-button">
                <PreviewButton onClickHandler={() => setIsOpen(true)} />
            </div>
            <ModalPortal
                id="PreviewInviteModal"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                containerClassName={css.modalContainer}
                contentClassName={css.modalContent}
                scrollLayerClasses={css.scrollLayer}
                containerClassNameJoined
            >
                <h4>Empfehlungen</h4>
                <RecommendationItem
                    abbreviatedName="PS"
                    recommendation={`${firstName} ist eine erfahrene und einfühlsame Person im Umgang mit Pferden, die mit grosser Ruhe und Feingefühl auf jedes Tier eingeht. Die reiterlichen Kenntnisse von ${firstName} sind beeindruckend und gegenüber mir zeigte ${firstName} stets ein sicheres Gespür für das Wohl der Tiere. Mit der zuverlässigen und herzlichen Art schafft ${firstName} ein harmonisches Miteinander und ich empfehle ${firstName} gerne uneingeschränkt.`}
                    firstName="Peter"
                    lastName="Schmid"
                    fromYear={2023}
                    toYear={2023}
                    review={{
                        [ASSESSMENT_RELIABILITY]: 3,
                        [ASSESSMENT_RIDING_SKILLS]: 5,
                        [ASSESSMENT_HORSE_DEALING]: 3,
                    }}
                />
                <div className={css.delimiter} />
                <RecommendationItem
                    abbreviatedName="MM"
                    recommendation={`Ich empfehle ${firstName} als engagierten und einfühlsamen Pferdemenschen, der die Pferde mit Geduld und Fachwissen fördert. Der Reitstil ist geprägt von Eleganz und Präzision und ${firstName} meistert auch schwierige Situationen mit Gelassenheit. ${firstName} ist eine Bereicherung für jedes Stallteam und ein hervorragender Reitpartner!`}
                    profileImage="/static/preview-invite-modal/marianne-mueller.png"
                    firstName="Marianne"
                    lastName="Müller"
                    fromYear={2020}
                    toYear={2023}
                />
            </ModalPortal>
        </>
    );
};
