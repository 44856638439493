import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { EditComponent } from '..';
import { DirectionsMap, IconClose } from '../../../../components';
import css from './SectionLocation.css';
import ScoreBadge from '../../../../forms/ProfileSettingsForm/ScoreBadge';
import { CheckmarkIcon } from '../../../../icons';
import { getPlaceDetailsFromString } from '../../../../util/geocoder';
import { getCurrentUserLocation } from '../../../../util/localStorage';
import { excludeTypes } from '../../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import { MOBILITY_DRIVING, MOBILITY_TWO_WHEELER } from '../../../../marketplace-custom-config';
import { resolveUserLatLng } from '../../../../util/user';
import { gmLatLng } from '../../../../util/googleMaps';

const SectionLocation = ({ publicData, isOwnListing, editParams, currentUser, scoreData }) => {
    const [error, setError] = useState(null);
    const [currentUserLocation, setCurrentUserLocation] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [distance, setDistance] = useState(null);
    const [duration, setDuration] = useState(null);
    const [directions, setDirections] = useState(null);

    const { addressDetails, mobility: mobilityPB } = publicData || {};
    const { postalCode, location } = addressDetails || {};

    const locationUnitsStr = `${postalCode} ${location}`;
    const mobility = !!(mobilityPB && mobilityPB.length > 0);

    const currentUserDefined = currentUser && currentUser.id;
    const userMobility = currentUserDefined
        ? currentUser.attributes.profile.publicData.mobility
        : {};
    const preferredTransport =
        userMobility && userMobility.transport ? userMobility.transport : MOBILITY_DRIVING;

    useEffect(() => {
        /**
         * Resolve user location lat lng
         * either from userLocation PB field
         * or by ipInfo field stored in LS
         */

        if (currentUserDefined) {
            const [lat, lng] = resolveUserLatLng(currentUser).split(',');

            return setCurrentUserLocation({
                lat,
                lng,
            });
        }
        const { loc } = getCurrentUserLocation() || {};

        if (loc) {
            const [lat, lng] = loc.split(',');

            setCurrentUserLocation({
                lat: Number(lat),
                lng: Number(lng),
            });
        }
    }, [currentUserDefined]);

    useEffect(() => {
        if (!locationUnitsStr) return;
        /** Get origin for the map circle */
        getPlaceDetailsFromString({
            locationUnitsStr: `${postalCode} ${location}`,
            excludeTypes,
        })
            .then(({ origin }) => {
                if (!origin) {
                    throw new Error();
                }
                setOrigin(origin);
            })
            .catch(e => {
                // do nothing
            });
    }, [locationUnitsStr]);

    useEffect(() => {
        /**
         * Approx. distance between listing & user
         */
        if (!origin) {
            return;
        }
        const { maps } = window.google;

        if (!currentUserLocation || !maps) return;

        const { computeDistanceBetween } = maps.geometry.spherical;

        const listingLatLng = gmLatLng(origin.lat, origin.lng);
        const currentUserLocationLng = gmLatLng(currentUserLocation.lat, currentUserLocation.lng);

        setDistance(
            (computeDistanceBetween(currentUserLocationLng, listingLatLng) / 1000).toFixed(0)
        );
    }, [origin]);

    const resolveDurationByTravelModeSuccess = (result, status) => {
        const isOk = status === window.google.maps.DirectionsStatus.OK;
        if (isOk) {
            setDirections(result);
            setDuration(result.routes[0].legs[0].duration.text);
        } else {
            setError('Error fetching directions', result);
        }
    };

    const resolveDurationByTravelMode = () => {
        const { lat, lng } = currentUserLocation;

        if (!lat || !lng) return null;

        const from = gmLatLng(Number(lat), Number(lng));
        const directionsService = new window.google.maps.DirectionsService();

        directionsService.route(
            {
                origin: from,
                destination: gmLatLng(origin.lat, origin.lng),
                travelMode:
                    preferredTransport === MOBILITY_TWO_WHEELER
                        ? MOBILITY_DRIVING
                        : preferredTransport,
            },
            resolveDurationByTravelModeSuccess
        );
    };

    if (!origin || !origin.lat || !origin.lng) {
        return null;
    }

    return (
        <div className={css.root}>
            {scoreData && scoreData.baseScoreBreakdown && (
                <div className={css.matchingContainer}>
                    <ScoreBadge scoreData={scoreData.baseScoreBreakdown.distanceAndMobilityScore} />
                </div>
            )}
            <h2 className={css.title}>
                <FormattedMessage id="SectionLocation.title" />

                {isOwnListing && (
                    <EditComponent pageName="stable-location" editParams={editParams} />
                )}
            </h2>

            {error && (
                <p className={css.error}>
                    <FormattedMessage id="SectionLocation.calculateTimeForTravelError" />
                </p>
            )}

            <div>
                <div className={css.mobility}>
                    {mobility ? (
                        <CheckmarkIcon rootClassName={css.selectedIcon} />
                    ) : (
                        <IconClose className={css.notSelectedIcon} />
                    )}
                    <p className={!mobility && css.disabled}>
                        <FormattedMessage id="SectionLocation.labelMobility" />
                    </p>
                </div>

                <div className={css.flexWrapper}>
                    {postalCode && location && (
                        <div className={css.flexItem}>
                            <div className={css.subTitle}>
                                <FormattedMessage id="SectionLocation.locationSubTitle" />
                            </div>
                            <p>
                                {postalCode} {location}
                            </p>
                        </div>
                    )}
                    {distance && (
                        <div className={css.flexItem}>
                            <div className={css.subTitle}>
                                <FormattedMessage id="SectionLocation.distanceSubTitle" />
                            </div>
                            <p>{distance} km</p>
                        </div>
                    )}

                    {preferredTransport && (
                        <div className={css.flexItem}>
                            <div className={css.subTitle}>
                                <FormattedMessage id="SectionLocation.calculateTimeForTravelOption" />{' '}
                                <FormattedMessage
                                    id={`EditUserMobilitySection.mobility-${preferredTransport}`}
                                />
                            </div>
                            {duration ? (
                                <p>{duration}</p>
                            ) : (
                                <p
                                    className={css.calculateTimeAction}
                                    onClick={resolveDurationByTravelMode}
                                >
                                    <FormattedMessage id="SectionLocation.calculateTimeForTravel" />
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <DirectionsMap
                    geolocation={origin}
                    directions={directions}
                    mapElement={<div className={css.mapDiv} />}
                    containerElement={<div className={css.containerDiv} />}
                />
            </div>
        </div>
    );
};

export default SectionLocation;
