import React from 'react';
/** icon-cross-m */
/** icon-cross */
export default ({ clickHandler = () => null, rootClassName = null, ...rest }) => (
    <svg
        onClick={clickHandler}
        className={rootClassName}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        {...rest}
    >
        <path
            d="M1.267 0.817 C 1.175 0.834,1.076 0.893,0.984 0.984 C 0.811 1.158,0.768 1.337,0.858 1.518 C 0.895 1.592,1.230 1.935,2.764 3.471 L 4.625 5.333 2.764 7.196 C 1.231 8.730,0.895 9.074,0.858 9.149 C 0.767 9.331,0.813 9.518,0.992 9.693 C 1.159 9.856,1.336 9.899,1.510 9.817 C 1.576 9.786,1.959 9.412,3.463 7.910 L 5.333 6.042 7.204 7.910 C 9.235 9.939,9.144 9.856,9.333 9.856 C 9.458 9.856,9.562 9.804,9.683 9.683 C 9.804 9.562,9.856 9.458,9.856 9.333 C 9.856 9.144,9.939 9.235,7.910 7.204 L 6.042 5.333 7.910 3.462 C 9.939 1.431,9.856 1.522,9.856 1.333 C 9.856 1.089,9.579 0.813,9.333 0.813 C 9.141 0.813,9.228 0.734,7.196 2.764 L 5.333 4.625 3.471 2.764 C 1.530 0.825,1.541 0.835,1.383 0.814 C 1.351 0.809,1.299 0.811,1.267 0.817 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
