// ================ Action types ================ //

import { getCreditsNotificationData } from '../credits-config';
import { getCurrentUserUpdates } from '../util/api';
import { userIsNotAvailable } from '../util/user';
import { insertNotificationModalData } from './UI.duck';

export const LOCATION_CHANGED = 'app/Routing/LOCATION_CHANGED';
export const CURRENT_USER_CHECK_REQUEST = 'app/Routing/CURRENT_USER_CHECK_REQUEST';

// ================ Reducer ================ //

const initialState = {
    currentLocation: null,
    currentCanonicalUrl: null,
    currentUserCheckInProgess: false,
};

export default function routingReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case LOCATION_CHANGED:
            return {
                ...state,
                currentLocation: payload.location,
                currentCanonicalUrl: payload.canonicalUrl,
            };
        case CURRENT_USER_CHECK_REQUEST:
            return {
                ...state,
                currentUserCheckInProgess: Boolean(payload),
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const locationChanged = (location, canonicalUrl) => ({
    type: LOCATION_CHANGED,
    payload: { location, canonicalUrl },
});

export const currentUserCheckRequest = payload => ({
    type: CURRENT_USER_CHECK_REQUEST,
    payload,
});

export const checkCurrentUserUpdates = () => async (dispatch, getState) => {
    const {
        user: { currentUser },
        Routing: { currentUserCheckInProgess },
    } = getState();

    if (userIsNotAvailable(currentUser)) return;
    if (currentUserCheckInProgess) return;

    const { infoCollected } = currentUser.attributes.profile.publicData;

    if (!infoCollected) return;

    dispatch(currentUserCheckRequest(true));

    const updatesReducer = ({ type, payload }) => {
        switch (type) {
            case 'credits-recent-event':
                dispatch(insertNotificationModalData(getCreditsNotificationData(payload)));
            default:
                return null;
        }
    };

    try {
        const response = await getCurrentUserUpdates();
        const changes = await response.json();

        if (Array.isArray(changes) && changes.length > 0) {
            changes.map(updatesReducer);
        }
    } catch (e) {
        // do nothing
    } finally {
        dispatch(currentUserCheckRequest(false));
    }
};
