import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { getUserCountrySpecInfo } from '../../util/location';
import { ensureCurrentUser } from '../../util/data';
import { WarningIcon, SuccessIcon, IconLock } from '../../icons';
import { isChangeEmailTakenError, isChangeEmailWrongPassword } from '../../util/errors';
import { FieldPhoneNumberInput, Form, Button, FieldTextInput, FieldSelect } from '../../components';

import css from './ContactDetailsForm.css';
import config from '../../config';
import { countryCodesFormatterConfig } from '../../components/FieldPhoneNumberInput/fiFormatter';
import VerifyPhoneNumberBlock from './VerifyPhoneNumberBlock';

const {
    maps: { supportedCountries },
} = config;

const SHOW_EMAIL_SENT_TIMEOUT = 2000;

const windowDefined = typeof window !== 'undefined';
const isMobile = windowDefined && window.innerWidth < 768;
// TODO: change to functional component
class ContactDetailsFormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVerificationEmailSentMessage: false,
            showVerificationOtpSent: false,
            countries: [],
            selectedOption: this.initPhoneCode(),
            verifyPhoneNumberModalVisible: false,
        };
        this.emailSentTimeoutId = null;
        this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
        this.submittedValues = {};
    }

    initPhoneCode() {
        const { savedCountryCode, currentUser } = this.props;
        const { country } = currentUser.attributes.profile.publicData;
        const { phoneCode } = getUserCountrySpecInfo(country, {
            ...supportedCountries,
            AT: {
                //   Austria
                name: 'Austria',
                code: 'AT',
                phoneCode: '43',
            },
        });

        return savedCountryCode || `+${phoneCode}`;
    }
    componentWillUnmount() {
        window.clearTimeout(this.emailSentTimeoutId);
    }

    handleResendVerificationEmail() {
        this.setState({ showVerificationEmailSentMessage: true });

        this.props.onResendVerificationEmail().then(() => {
            // show "verification email sent" text for a bit longer.
            this.emailSentTimeoutId = window.setTimeout(() => {
                this.setState({ showVerificationEmailSentMessage: false });
            }, SHOW_EMAIL_SENT_TIMEOUT);
        });
    }

    render() {
        return (
            <FinalForm
                {...this.props}
                initialValues={{
                    ...(this.props.initialValues || {}),
                    countryCode: this.initPhoneCode(),
                }}
                render={fieldRenderProps => {
                    const {
                        rootClassName,
                        className,
                        saveEmailError,
                        savePhoneNumberError,
                        currentUser,
                        inProgress,
                        intl,
                        values,
                        sendVerificationOtpError,
                        sendVerificationOtpInProgress,
                        form,
                    } = fieldRenderProps;

                    const { valid: phoneValid, dirty: phoneDirty } = form.getFieldState(
                        'phoneNumber'
                    ) || {
                        valid: false,
                    };
                    const { valid: emailValid } = form.getFieldState('email') || { valid: false };
                    const { valid: currentPasswordValid } = form.getFieldState(
                        'currentPassword'
                    ) || {
                        valid: false,
                    };

                    const { verifyPhoneNumberModalVisible } = this.state;

                    const { email, phoneNumber, countryCode, currentPassword } = values || {};

                    const user = ensureCurrentUser(currentUser);

                    if (!user.id) {
                        return null;
                    }

                    const { email: currentEmail, emailVerified, profile } = user.attributes;
                    const { phoneVerified } = profile.protectedData || {};

                    const emailChanged = currentEmail !== email;

                    const emailPlaceholder = intl.formatMessage({
                        id: 'ContactDetailsForm.emailLabel',
                    });

                    const emailRequiredMessage = intl.formatMessage({
                        id: 'ContactDetailsForm.emailRequired',
                    });

                    const fieldRequiredMessage = intl.formatMessage({
                        id: 'ContactDetailsForm.fieldRequired',
                    });

                    const emailRequired = validators.required(emailRequiredMessage);
                    const emailInvalidMessage = intl.formatMessage({
                        id: 'ContactDetailsForm.emailInvalid',
                    });
                    const emailValidValidator = validators.emailFormatValid(emailInvalidMessage);

                    const emailTouched = this.submittedValues.email !== values.email;
                    const emailTakenErrorText = isChangeEmailTakenError(saveEmailError)
                        ? intl.formatMessage({ id: 'ContactDetailsForm.emailTakenError' })
                        : null;

                    // phone
                    const protectedData = profile.protectedData || {};
                    const currentPhoneNumber =
                        protectedData.phoneNumber &&
                        protectedData.phoneNumber.replace(values.countryCode, '');

                    // has the phone number changed
                    const phoneNumberChanged = phoneNumber && currentPhoneNumber !== phoneNumber;

                    const phonePlaceholder = intl.formatMessage({
                        id: 'ContactDetailsForm.phonePlaceholder',
                    });

                    const passwordFailedMessage = intl.formatMessage({
                        id: 'ContactDetailsForm.passwordFailed',
                    });

                    const passwordErrorText = isChangeEmailWrongPassword(saveEmailError)
                        ? passwordFailedMessage
                        : null;

                    const confirmClasses = classNames(css.confirmChangesSection, {
                        [css.confirmChangesSectionVisible]: emailChanged,
                    });

                    // generic error
                    const isGenericEmailError =
                        saveEmailError && !(emailTakenErrorText || passwordErrorText);

                    let genericError = null;

                    if (isGenericEmailError && savePhoneNumberError) {
                        genericError = (
                            <span className={css.error}>
                                <FormattedMessage id="ContactDetailsForm.genericFailure" />
                            </span>
                        );
                    } else if (isGenericEmailError) {
                        genericError = (
                            <span className={css.error}>
                                <FormattedMessage id="ContactDetailsForm.genericEmailFailure" />
                            </span>
                        );
                    } else if (savePhoneNumberError) {
                        genericError = (
                            <span className={css.error}>
                                <FormattedMessage id="ContactDetailsForm.genericPhoneNumberFailure" />
                            </span>
                        );
                    }
                    let otpError = null;
                    if (sendVerificationOtpError) {
                        otpError = (
                            <span className={css.error}>
                                We couldn't send the verification code to this number, please change
                                the number and try again.
                            </span>
                        );
                    }

                    const classes = classNames(rootClassName || css.root, className);
                    const submittedOnce = Object.keys(this.submittedValues).length > 0;
                    const pristineSinceLastSubmit =
                        submittedOnce && isEqual(values, this.submittedValues);
                    /** password field should be considered when an email field has been changed */
                    const passwordIsNotValid =
                        emailChanged && (!currentPassword || !currentPasswordValid);
                    const emailIsNotValid = emailChanged && !emailValid;
                    const phoneIsNotValid = phoneNumberChanged && !phoneValid;
                    const pristine = !phoneNumberChanged && !emailChanged;
                    const submitDisabled =
                        pristine ||
                        pristineSinceLastSubmit ||
                        inProgress ||
                        /** password field is visible when email has been changed */
                        (emailChanged && passwordIsNotValid) ||
                        emailIsNotValid ||
                        phoneIsNotValid;

                    return (
                        <Form className={classes} onSubmit={() => null}>
                            <div className={css.contactDetailsSection}>
                                <div className={css.phoneInfoHolder}>
                                    <FieldSelect
                                        name="countryCode"
                                        id="countryCode"
                                        validate={validators.required(fieldRequiredMessage)}
                                        placeholder="Vorwahl"
                                        form={form}
                                        optionsList={[
                                            { label: '+41', value: '+41' },
                                            { label: '+49', value: '+49' },
                                            { label: '+43', value: '+43' },
                                        ]}
                                        notifyOnChange={() => {
                                            const maxLengthDefined =
                                                countryCodesFormatterConfig[countryCode];

                                            if (phoneDirty && maxLengthDefined) {
                                                form.change(
                                                    'phoneNumber',
                                                    phoneNumber.slice(0, maxLengthDefined)
                                                );
                                            }
                                        }}
                                    />

                                    <FieldPhoneNumberInput
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        type={isMobile ? 'tel' : 'text'}
                                        placeholder={phonePlaceholder}
                                        validate={validators.required(fieldRequiredMessage)}
                                        countryCode={countryCode}
                                        onBlur={() => {
                                            if (!phoneNumber) return;

                                            const precedingZeroMissed = !/^0/.test(phoneNumber);

                                            if (precedingZeroMissed) {
                                                form.change('phoneNumber', '0' + phoneNumber);
                                            }
                                            /**
                                             * Clear errors & loadings
                                             */
                                            this.props.discardErrors();
                                        }}
                                    />
                                </div>
                                <p
                                    className={classNames({
                                        [css.verificationBlock]: true,
                                        [css.verified]: !!phoneVerified,
                                    })}
                                >
                                    {phoneVerified ? (
                                        <SuccessIcon />
                                    ) : (
                                        <WarningIcon rootClassName={css.warningIcon} />
                                    )}
                                    <div>
                                        <FormattedMessage
                                            id={
                                                phoneVerified
                                                    ? 'ContactDetailsForm.phoneVerifiedMessage'
                                                    : 'ContactDetailsForm.phoneNotVerifiedMessage'
                                            }
                                        />

                                        <VerifyPhoneNumberBlock
                                            onSubmitContactDetails={this.props.onSubmit}
                                            currentUser={currentUser}
                                            phoneNumber={phoneNumber}
                                            countryCode={countryCode}
                                            phoneVerified={phoneVerified}
                                        />
                                    </div>
                                </p>

                                <FieldTextInput
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder={emailPlaceholder}
                                    validate={validators.composeValidators(
                                        emailRequired,
                                        emailValidValidator
                                    )}
                                    handleBlur={() => {
                                        /**
                                         * Clear errors & loadings
                                         */
                                        this.props.discardErrors();
                                    }}
                                    customErrorText={emailTouched ? null : emailTakenErrorText}
                                />
                                <p
                                    className={`${css.verificationBlock} ${emailVerified &&
                                        css.verified}`}
                                >
                                    {emailVerified ? (
                                        <SuccessIcon />
                                    ) : (
                                        <WarningIcon rootClassName={css.warningIcon} />
                                    )}
                                    <div>
                                        <FormattedMessage
                                            id={
                                                emailVerified
                                                    ? 'ContactDetailsForm.emailVerifiedMessage'
                                                    : this.state.showVerificationEmailSentMessage
                                                    ? 'ContactDetailsForm.emailSent'
                                                    : 'ContactDetailsForm.emailNotVerifiedMessage'
                                            }
                                        />
                                        {!emailVerified && (
                                            <p
                                                className={css.verificationLink}
                                                onClick={this.handleResendVerificationEmail}
                                            >
                                                <FormattedMessage id="ContactDetailsForm.verifyEmailLink" />
                                            </p>
                                        )}
                                    </div>
                                </p>
                                {sendVerificationOtpInProgress && (
                                    <span className={css.emailVerified}>
                                        Verifizierungscode senden
                                    </span>
                                )}
                                {otpError}
                            </div>

                            <div className={confirmClasses}>
                                <FieldTextInput
                                    className={css.password}
                                    type="password"
                                    id="currentPassword"
                                    name="currentPassword"
                                    autoComplete="current-password"
                                    placeholder="Passwort"
                                    validate={validators.composeValidators(
                                        validators.requiredStringNoTrim(
                                            intl.formatMessage({
                                                id: 'PasswordChangeForm.passwordRequired',
                                            })
                                        ),
                                        validators.minLength(
                                            intl.formatMessage(
                                                {
                                                    id: 'PasswordChangeForm.passwordTooShort',
                                                },
                                                {
                                                    minLength: validators.PASSWORD_MIN_LENGTH,
                                                }
                                            ),
                                            validators.PASSWORD_MIN_LENGTH
                                        ),
                                        validators.maxLength(
                                            intl.formatMessage(
                                                {
                                                    id: 'PasswordChangeForm.passwordTooLong',
                                                },
                                                {
                                                    maxLength: validators.PASSWORD_MAX_LENGTH,
                                                }
                                            ),
                                            validators.PASSWORD_MAX_LENGTH
                                        )
                                    )}
                                    customErrorText={passwordErrorText}
                                />
                            </div>

                            <p className={css.dataProtectionInfo}>
                                <IconLock />
                                <FormattedMessage id="ContactDetailsForm.dataProtectionInfo" />
                            </p>

                            <div className={css.bottomWrapper}>
                                {genericError}
                                <Button
                                    type="button"
                                    inProgress={inProgress}
                                    ready={pristineSinceLastSubmit}
                                    disabled={submitDisabled}
                                    onClick={() => {
                                        this.submittedValues = values;
                                        this.props.onSubmit(values);
                                    }}
                                >
                                    <FormattedMessage id="ContactDetailsForm.saveChanges" />
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            />
        );
    }
}

const { bool, func, string } = PropTypes;

ContactDetailsFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    formId: string,
    saveEmailError: propTypes.error,
    savePhoneNumberError: propTypes.error,
    inProgress: bool,
    intl: intlShape.isRequired,
    onResendVerificationEmail: func.isRequired,
    ready: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    sendVerificationEmailInProgress: bool,

    resetPasswordInProgress: bool,
    resetPasswordError: propTypes.error,
};

const ContactDetailsForm = compose(injectIntl)(ContactDetailsFormComponent);

ContactDetailsForm.displayName = 'ContactDetailsForm';

export default ContactDetailsForm;
