import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import classNames from 'classnames';

import { MenuItem, NamedLink } from '../../..';

import css from './ProfileMenuContent.css';

export default ({ isAllowed, currentPageClass, hasDraftListing }) =>
    isAllowed ? (
        <MenuItem
            key="ManageListingsPage"
            rootClassName={classNames(
                css.topBarMenuItem,
                css.mobileOnly,
                currentPageClass('ManageListingsPage')
            )}
        >
            <NamedLink className={css.topBarMenuLink} name="ManageListingsPage">
                <p>
                    <FormattedMessage id="TopbarDesktop.yourListingsLink" />
                    {hasDraftListing && <code className={css.notificationDot} />}
                </p>
            </NamedLink>
        </MenuItem>
    ) : null;
