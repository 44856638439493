import { useEffect } from 'react';
import { FILL_ADDRESS_STEP, findByType, PREDICT_LOCATION_STEP } from './helpers';

/** Set values from address_components when a user selects prediction */
export const useAddressComponentsData = ({ form, values, currentStep, setCurrentStep }) => {
    const isLocation = currentStep === PREDICT_LOCATION_STEP;

    const { getFieldState, change } = form;
    const { location } = values;
    const { valid } = getFieldState('location') || {};

    useEffect(() => {
        if (isLocation && location && valid) {
            const { address_components = [] } = location.selectedPlace;
            const changeFieldIfAny = (key, value) => value && change(key, value);

            changeFieldIfAny('streetAddress', findByType('route', address_components));
            changeFieldIfAny('houseNumber', findByType('street_number', address_components));
            changeFieldIfAny('postalCode', findByType('postal_code', address_components));
            changeFieldIfAny('city', findByType('locality', address_components));

            setCurrentStep(FILL_ADDRESS_STEP);
        }
    }, [isLocation, location, valid]);
};
