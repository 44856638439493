import { useSelector } from 'react-redux';
/**
 * Find the latest active transaction
 *
 * e.g. the id may be used in case all listings of an horseowner are requested by a rider
 */
export const useLatestTransactionListingId = userId => {
    const allMarketplaceTransactions = useSelector(s => s.marketplaceData?.entities?.transaction);

    const latestTransaction = Object.values(allMarketplaceTransactions || {})
        .filter(
            tx =>
                tx?.relationships?.customer?.data?.id?.uuid === userId ||
                tx?.relationships?.provider?.data?.id?.uuid === userId
        )
        .sort((a, b) => b.attributes.lastTransitionedAt - a.attributes.lastTransitionedAt)[0];

    return latestTransaction?.id?.uuid;
};
