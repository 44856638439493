import React, { useState } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { injectIntl } from '../../util/reactIntl';
import {
    CROSS_COUNTRY_RIDING,
    DRESSAGE,
    FREE_TIME_RIDING,
    GAITED_HORSE_RIDING,
    JOURNEY,
    JUMPING,
    RIDING,
    VERSATILE_DISCIPLINE,
    GROUND_AND_LUNGE_WORK,
    WORKING_EQUITATION,
} from '../../marketplace-custom-config';
import { Tooltip } from '..';
import { IconCrossL } from '../../icons';
import config from '../../config';

import css from './LevelInfo.css';
import { experienceAllowedOptions as options } from '../../marketplace-custom-config';

const { userTypeRider, userTypeHorseowner } = config;

const LevelInfo = ({
    userType,
    name,
    containerRef,
    levelInfoRootClassName,
    disciplineLabel: disciplineLabelProp,
    values,
    intl,
}) => {
    const [tooltipVisible, setTooltipVisibility] = useState(false);

    const levelConfing = getLevelContent(userType);

    const disciplineLabel = levelConfing[disciplineLabelProp] ? disciplineLabelProp : 'custom';
    /**
     * levelValue e.g. beginner
     */
    const levelValue = values[name];

    if (!levelValue) {
        return null;
    }

    const { label } = options.find(({ value }) => value === levelValue) || {};
    /** e.g. from 'Boden- und Longenarbeit' into 'Boden-und-Longenarbeit' */
    const labelFormatted = disciplineLabel.replace(/- /g, '-').replace(/ /g, '-');
    const optionKey = `levelInfo-${userType}-${labelFormatted}-${levelValue}-option`;
    const tooltipOptionKey = `levelInfo-${userType}-${labelFormatted}-${levelValue}-tooltip-option`;

    /**
     * disciplineLabel e.g. Freizeit
     */
    const currentLevelConfig = levelConfing[disciplineLabel];
    const currentLevelInfo = currentLevelConfig[levelValue];
    const [levelOptionsNum, tooltipOptionsNum] = currentLevelInfo;

    const List = ({ optionsNum, intlKey, rootClassName }) => (
        <ul className={rootClassName}>
            {Array.from({ length: optionsNum }, (_, option) => (
                <li key={option}>
                    <p>
                        {intl.formatMessage({
                            id: `EditListingDescriptionForm.${intlKey}-${option + 1}`,
                        })}
                    </p>
                </li>
            ))}
        </ul>
    );

    return (
        <>
            <div className={css.levelInfoWrapper}>
                <p className={css.levelInfoHeading}>{label}</p>

                <Tooltip
                    tooltipClassName={classNames(css.tooltip, {
                        [levelInfoRootClassName]: !!levelInfoRootClassName,
                    })}
                    parentContainerRef={containerRef}
                    rootClassName={classNames({
                        [css.tooltipWrapper]: tooltipVisible,
                    })}
                    key={tooltipOptionKey}
                    suppressOutsideClick
                    isStatic
                    staticTooltipState={tooltipVisible}
                    staticTooltipStateHandler={() => setTooltipVisibility(false)}
                    position="bottom"
                    content={
                        <div className={css.tooltipContent}>
                            <h4>
                                {intl.formatMessage({
                                    id: `EditListingDescriptionForm.${tooltipOptionKey}-heading`,
                                })}
                            </h4>

                            <List optionsNum={tooltipOptionsNum} intlKey={tooltipOptionKey} />
                            <IconCrossL clickHandler={() => setTooltipVisibility(false)} />
                        </div>
                    }
                >
                    <img
                        onClick={() => setTooltipVisibility(prevState => !prevState)}
                        src="https://horsedeal.imgix.net/static/icons/icon-info-m-Ground-Ground-040.svg"
                    />
                </Tooltip>
            </div>
            <List optionsNum={levelOptionsNum} intlKey={optionKey} rootClassName={css.levelList} />
        </>
    );
};

export default compose(injectIntl)(LevelInfo);

export const levelConfing = {
    [userTypeRider]: {
        custom: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
        [FREE_TIME_RIDING]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
        [CROSS_COUNTRY_RIDING]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
        [DRESSAGE]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
        [JUMPING]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [4, 3],
            veryExperienced: [3, 3],
        },
        [VERSATILE_DISCIPLINE]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [4, 2],
            veryExperienced: [3, 3],
        },
        [RIDING]: {
            beginner: [4, 4],
            advanced: [2, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
        [GAITED_HORSE_RIDING]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
        [JOURNEY]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
        [GROUND_AND_LUNGE_WORK]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
        [WORKING_EQUITATION]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 3],
            veryExperienced: [3, 3],
        },
    },
    [userTypeHorseowner]: {
        custom: {
            beginner: [3, 3],
            advanced: [3, 4],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
        [FREE_TIME_RIDING]: {
            beginner: [3, 3],
            advanced: [3, 4],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
        [CROSS_COUNTRY_RIDING]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
        [DRESSAGE]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
        [JUMPING]: {
            beginner: [3, 2],
            advanced: [3, 4],
            experienced: [4, 2],
            veryExperienced: [3, 3],
        },
        [VERSATILE_DISCIPLINE]: {
            beginner: [3, 2],
            advanced: [3, 4],
            experienced: [3, 1],
            veryExperienced: [4, 3],
        },
        [RIDING]: {
            beginner: [3, 3],
            advanced: [4, 3],
            experienced: [2, 2],
            veryExperienced: [3, 2],
        },
        [GAITED_HORSE_RIDING]: {
            beginner: [3, 3],
            advanced: [3, 4],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
        [JOURNEY]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
        [GROUND_AND_LUNGE_WORK]: {
            beginner: [3, 3],
            advanced: [3, 2],
            experienced: [3, 1],
            veryExperienced: [3, 3],
        },
        [WORKING_EQUITATION]: {
            beginner: [3, 3],
            advanced: [3, 3],
            experienced: [3, 2],
            veryExperienced: [3, 3],
        },
    },
};

function getLevelContent(userType) {
    return levelConfing[userType];
}
