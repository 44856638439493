import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Logo.css';

const Logo = props => {
    const { className, format = 'desktop', ...rest } = props;
    const mobileClasses = classNames(css.logoMobile, className);
    return (
        <img
            className={format === 'desktop' ? className : mobileClasses}
            alt="HorseDeal"
            src="https://horsedeal.imgix.net/static/logos/horsedeal.svg"
            {...rest}
        />
    );
};

const { oneOf, string } = PropTypes;

Logo.propTypes = {
    className: string,
    format: oneOf(['desktop', 'mobile']),
};

export default Logo;
