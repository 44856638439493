import React, { useState } from 'react';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
    IconArrowHead,
    InlineTextButton,
    Menu,
    MenuLabel,
    MenuItem,
    MenuContent,
} from '../../components';
import css from './SelectPaymentGateway.css';
import { any, arrayOf, func, shape, string } from 'prop-types';
import { IconCheckmark } from '../../icons';

const SelectPaymentGateway = props => {
    const [menuOpen, setMenuOpen] = useState(false);

    const {
        rootClassName,
        className,
        gatewayList,
        selectedPaymentGateway,
        selectPaymentGateway,
    } = props;

    const classes = classNames(rootClassName || css.root, className);

    const onToggleActive = isOpen => {
        setMenuOpen(isOpen);
    };

    const handleClick = gatewayId => {
        selectPaymentGateway(gatewayId);
        onToggleActive(false);
    };

    const iconArrowClassName = menuOpen ? css.IconArrowAnimation : null;

    const wrongPaymentGateway = { listItem: <p className={css.error}>Unknown payment gateway.</p> };

    const selectedGatewayMaybe = selectedPaymentGateway ? (
        (gatewayList.find(g => g.id === selectedPaymentGateway) || wrongPaymentGateway).listItem
    ) : (
        <p className={css.paymentGatewayPlaceholder}>
            <FormattedMessage id="SelectPaymentGateway.selectPaymentGatewayPlaceholder" />
        </p>
    );

    return (
        <div className={classes}>
            <h3 className={css.paymentHeading}>
                <FormattedMessage id="SelectPaymentGateway.payWithHeading" />
            </h3>
            <Menu
                className={css.menu}
                isOpen={menuOpen}
                onToggleActive={onToggleActive}
                useArrow={false}
            >
                <MenuLabel className={css.menuLabel}>
                    <div className={css.menuLabelWrapper}>
                        {selectedGatewayMaybe}
                        <span>
                            <IconArrowHead
                                direction="down"
                                size="small"
                                rootClassName={css.iconArrow}
                                className={iconArrowClassName}
                            />
                        </span>
                    </div>
                </MenuLabel>

                <MenuContent rootClassName={css.menuContent}>
                    {gatewayList.map(({ id, listItem }) =>
                        listItem ? (
                            <MenuItem
                                key={id}
                                className={css.menuItem}
                                onClick={() => handleClick(id)}
                            >
                                <IconCheckmark
                                    size="small"
                                    className={
                                        selectedPaymentGateway === id
                                            ? css.iconCheckmark
                                            : css.iconCheckmarkHidden
                                    }
                                />
                                <InlineTextButton className={css.menuTextReplaceCard}>
                                    {listItem}
                                </InlineTextButton>
                            </MenuItem>
                        ) : null
                    )}
                </MenuContent>
            </Menu>
        </div>
    );
};

SelectPaymentGateway.propTypes = {
    selectedPaymentGateway: string,
    selectPaymentGateway: func,
    gatewayList: arrayOf(
        shape({
            id: string,
            content: any,
        })
    ),
};

export default injectIntl(SelectPaymentGateway);
