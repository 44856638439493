import React from 'react';
/** icon-pen-l */
/** icon-pen */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M19.019 0.281 C 18.274 0.355,17.586 0.618,16.920 1.082 C 16.702 1.233,14.644 3.266,9.373 8.535 C 5.387 12.520,2.089 15.843,2.043 15.920 C 1.998 15.997,1.960 16.102,1.960 16.154 C 1.960 16.206,1.579 17.727,1.114 19.534 C 0.209 23.051,0.179 23.202,0.342 23.427 C 0.460 23.589,0.775 23.736,0.997 23.733 C 1.098 23.731,2.623 23.359,4.386 22.905 C 6.149 22.451,7.686 22.062,7.800 22.040 C 7.929 22.015,8.079 21.948,8.194 21.863 C 8.296 21.787,11.612 18.489,15.562 14.533 C 23.003 7.081,22.951 7.136,23.275 6.490 C 24.191 4.669,23.713 2.458,22.130 1.196 C 21.269 0.509,20.123 0.172,19.019 0.281 M20.097 1.820 C 20.574 1.921,21.008 2.167,21.399 2.559 C 21.979 3.140,22.198 3.624,22.231 4.404 C 22.246 4.755,22.234 4.944,22.182 5.176 C 22.104 5.528,21.865 6.027,21.663 6.260 L 21.524 6.420 19.552 4.448 L 17.580 2.475 17.732 2.341 C 17.950 2.151,18.389 1.930,18.729 1.841 C 19.088 1.747,19.707 1.737,20.097 1.820 M18.500 5.520 L 20.460 7.480 14.100 13.840 L 7.740 20.200 5.760 18.220 L 3.780 16.240 10.120 9.900 C 13.607 6.413,16.478 3.560,16.500 3.560 C 16.522 3.560,17.422 4.442,18.500 5.520 M6.263 20.870 C 6.244 20.890,2.065 21.958,2.053 21.946 C 2.048 21.941,2.285 20.992,2.581 19.838 C 2.877 18.684,3.119 17.731,3.120 17.720 C 3.120 17.709,3.830 18.411,4.698 19.278 C 5.567 20.146,6.271 20.863,6.263 20.870 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
