import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import TrainingIcon from '../icons/TrainingIcon';
import { IconSpinner } from '../../../components';
import { TRAININGS_DOC } from '../../../marketplace-custom-config';
import { TRAININGS_ID } from '../NavigationBar';
import { USER_TRAININGS } from '../config';

import css from '../ProfileSettingsForm.css';
import sectionCss from './AwardsSection.css';
import LogoutOverlay from '../LogoutOverlay';

const TrainingsSection = ({
    renderEditPencilIcon,
    uploadDocumentsInProgress,
    userDocuments,
    handleEditSection,
    setModalParams,
    trainings,
    isPublic,
    isLoggedOut,
    userName,
    setPreviewModalOpen,
    setPreviewDoc,
    switchUserRoleAction,
    pageParams,
}) => {
    const noTrainingsData = !trainings || trainings.length === 0;

    if (isPublic && noTrainingsData) {
        return null;
    }

    const logoutOverlayVisible = isLoggedOut || switchUserRoleAction;

    const classes = classNames({
        [css.infoBlock]: true,
        [css.noInfoBlock]: noTrainingsData,
        [css.viewProfileRequestWrapper]: logoutOverlayVisible,
        [sectionCss.viewProfileRequestWrapper]: logoutOverlayVisible,
    });

    return (
        <div className={classes} id={TRAININGS_ID}>
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.trainingsHeading" />
            </h2>
            {!noTrainingsData ? (
                <section>
                    {trainings.map(
                        (
                            {
                                trainingName,
                                organizationName,
                                trainingStart,
                                trainingEnd,
                                description,
                                isCurrentJob,
                            },
                            i
                        ) => {
                            const document =
                                userDocuments &&
                                userDocuments[TRAININGS_DOC] &&
                                userDocuments[TRAININGS_DOC].find(
                                    ({ name, trainingstart, trainingsend, organization }) =>
                                        name === trainingName &&
                                        organization === organizationName &&
                                        String(trainingStart) === trainingstart &&
                                        String(trainingEnd) === trainingsend
                                );

                            return (
                                <React.Fragment
                                    key={`${i}-${trainingName}${organizationName}${trainingStart}${trainingEnd}`}
                                >
                                    {i > 0 && <div className={sectionCss.divider} />}
                                    <div className={sectionCss.awardsHolder}>
                                        <div className={sectionCss.awardInfoHolder}>
                                            <TrainingIcon />
                                            <div className={sectionCss.awardInfo}>
                                                <p>{trainingName}</p>
                                                <p>{organizationName}</p>
                                                <p>
                                                    {isCurrentJob
                                                        ? `${trainingStart}-Heute`
                                                        : `${trainingStart}-${trainingEnd}`}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={css.editBlock}
                                            onClick={() =>
                                                setModalParams({
                                                    trainingName,
                                                    organizationName,
                                                    trainingStart,
                                                    trainingEnd,
                                                    description,
                                                    isCurrentJob,
                                                })
                                            }
                                        >
                                            {renderEditPencilIcon(
                                                USER_TRAININGS,
                                                null,
                                                <p className={css.editText}>
                                                    <FormattedMessage id="ProfileSettingsForm.editText" />
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <p className={sectionCss.description}>{description}</p>

                                    {uploadDocumentsInProgress ? (
                                        <IconSpinner />
                                    ) : (
                                        <p
                                            className={classNames(sectionCss.docBlock, {
                                                [sectionCss.docExists]: !!document,
                                            })}
                                        >
                                            {document ? (
                                                <span
                                                    className={css.previewLink}
                                                    onClick={() => {
                                                        setPreviewModalOpen(true);
                                                        setPreviewDoc(document);
                                                    }}
                                                >
                                                    <FormattedMessage id="ProfileSettingsForm.sectionDoc" />
                                                </span>
                                            ) : (
                                                <FormattedMessage id="ProfileSettingsForm.noQualificationDocuments" />
                                            )}
                                        </p>
                                    )}
                                </React.Fragment>
                            );
                        }
                    )}
                </section>
            ) : (
                <div className={`${sectionCss.placeholderWrapper} ${css.placeholderWrapper}`}>
                    <main className={css.flex}>
                        <div className={css.placeholderAvatar} />
                        <div className={css.placeholderUserInfo}>
                            <div className={css.placeholderItem} />
                            <div className={css.placeholderItem} />
                            <div className={css.placeholderItem} />
                        </div>
                    </main>
                    <footer>
                        <div className={css.placeholderItem} />
                        <div className={css.placeholderItem} />
                        <div className={css.placeholderItem} />
                    </footer>
                </div>
            )}
            {!isPublic && (
                <button
                    className={`${css.actionItem} ${css.actionBtn}`}
                    onClick={() => {
                        handleEditSection(USER_TRAININGS);
                        setModalParams({});
                    }}
                >
                    <FormattedMessage id="ProfileSettingsForm.trainingsAction" />
                </button>
            )}
            <LogoutOverlay
                userName={userName}
                isLoggedOut={isLoggedOut}
                rootClassName={sectionCss.viewProfileRequestAction}
                sectionName={USER_TRAININGS}
                switchUserRoleAction={switchUserRoleAction}
                pageParams={pageParams}
            />
        </div>
    );
};

export default TrainingsSection;
