import React, { useState, useEffect } from 'react';
import { useStore } from 'react-redux';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useOneSignal } from '../../services/oneSignal';
import { ensureCurrentUser } from '../../util/data';
import { isNonNativeIOS, isNativeIOS, checkIOS } from '../../util/ios';
import { ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT } from '../../util/localStorage';
import { useOneSignalInitEvents } from './useOneSignalInitEvents';
import { useOneSignalLoginEvents } from './useOneSignalLoginEvents';
import PushNotificationsModalContent from './PushNotificationsModalContent';

export default () => {
    const [isMobile, _, computing] = useIsMobile();
    const [showPushNotification, setShowPushNotification] = useState(false);

    const store = useStore();
    const oneSignal = useOneSignal();

    const { currentUser } = store.getState().user;

    const userEnsured = ensureCurrentUser(currentUser);
    const {
        id,
        attributes: {
            profile: {
                publicData: { infoCollected },
            },
        },
    } = userEnsured;
    const { uuid: currentUserId } = id || {};

    const close = () => setShowPushNotification(false);
    const open = () => setShowPushNotification(true);

    const initOneSignal = () => oneSignal.init();

    /** TODO: handle login/logout flow on user type changing */
    const modalAllowed = infoCollected && !computing && currentUserId;

    useOneSignalInitEvents({ allowed: modalAllowed, errorHandler: close });

    useOneSignalLoginEvents({
        allowed: modalAllowed,
        /** for both scenarios success/failed, just close the modal */
        callback: () => close(),
        errorHandler: () => close(),
    });

    useEffect(() => {
        const timeoutId = setTimeout(async () => {
            const {
                UI: { notificationModal },
                user: { currentUser },
            } = store.getState();

            const notificationModalIsVisible = Boolean(notificationModal && notificationModal.show);

            if (notificationModalIsVisible || !currentUser || !currentUser.id) return;
            const { isNative, iOS, webkit } = checkIOS();
            if ((iOS && webkit) || isNative) {
                /**
                 * iOS && webkit
                 *
                 * OneSignal: SDK is not compatible with safari browsers;
                 * do not initiate OneSignal for browser, only for native apps
                 *
                 * isNative
                 * No need to show the custom prompt for the native app
                 */
                return;
            }
            /** show a push notifications prompt only once per session */
            const shownPerSession = oneSignal.checkPerSessionPrompt();
            window.sessionStorage.setItem(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT, true);

            const { isEnabled } = await oneSignal.isSubscriptionsEnabled();

            const skip = shownPerSession || isEnabled;

            if (skip) return;

            open();
        }, 7000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [modalAllowed, isMobile]);

    return showPushNotification ? (
        <PushNotificationsModalContent isOpen onInitialize={initOneSignal} onAbort={close} />
    ) : null;
};
