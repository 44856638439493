import React from 'react';
/** icon-integrated-newsletter-xl */
/** icon-integrated-newsletter */
export default ({ rootClassName = '' }) => (
    <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={rootClassName}
    >
        <path
            d="M33.3333 28V38.6667M28 33.3333H38.6667M17.3333 30.6667H6.66667C5.95942 30.6667 5.28115 30.3857 4.78105 29.8856C4.28095 29.3855 4 28.7072 4 28V6.66667C4 5.95942 4.28095 5.28115 4.78105 4.78105C5.28115 4.28095 5.95942 4 6.66667 4H38.6667C39.3739 4 40.0522 4.28095 40.5523 4.78105C41.0524 5.28115 41.3333 5.95942 41.3333 6.66667V18.6667M40.7306 4.97743L26.2524 16.1134C25.2244 16.9043 23.9637 17.3331 22.6666 17.3331C21.3696 17.3331 20.1089 16.9043 19.0809 16.1134L4.60265 4.97743M22.6667 33.3333C22.6667 36.1623 23.7905 38.8754 25.7909 40.8758C27.7912 42.8762 30.5044 44 33.3333 44C36.1623 44 38.8754 42.8762 40.8758 40.8758C42.8762 38.8754 44 36.1623 44 33.3333C44 30.5044 42.8762 27.7912 40.8758 25.7909C38.8754 23.7905 36.1623 22.6667 33.3333 22.6667C30.5044 22.6667 27.7912 23.7905 25.7909 25.7909C23.7905 27.7912 22.6667 30.5044 22.6667 33.3333Z"
            stroke="#8F2593"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
