import { excludeTypes as excludeTypesCommon } from '../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import Geocoder from '../components/LocationAutocompleteInput/GeocoderGoogleMaps';

export const getPlacePredictions = async ({
    locationUnitsStr,
    excludeTypes,
    searchConfigurationsProp,
}) => {
    try {
        const geocoder = new Geocoder();
        /** this give an array with 4 countries code, pass such config as prop instead */
        // const searchConfigurations = searchConfigurationsProp || {
        //     componentRestrictions: {
        //         country: Object.values(ultimateGoogleMapsCountriesConfig).map(({ abbr }) => abbr),
        //     },
        // };

        if (!locationUnitsStr) return null;

        const { predictions } = await geocoder.getPlacePredictions(
            locationUnitsStr,
            searchConfigurationsProp || {},
            excludeTypes || [
                'locality',
                'country',
                'administrative_area_level_1',
                'postal_code',
                ...excludeTypesCommon,
            ]
        );

        return predictions;
    } catch (e) {
        return null;
    }
};

export const getPlaceDetails = async prediction => {
    try {
        const geocoder = new Geocoder();
        const predictionData = await geocoder.getPlaceDetails(prediction);
        if (!predictionData) {
            throw new Error();
        }
        return predictionData;
    } catch (e) {
        return null;
    }
};

/**
 * Resolve place details from search string
 *
 * @param {*} locationUnitsStr string
 * @returns {Promise<util.propTypes.place | null>} place details from the
 * 1st google api autocomplete prediction
 */
export const getPlaceDetailsFromString = async ({
    locationUnitsStr,
    excludeTypes,
    searchConfigurationsProp,
}) => {
    try {
        if (!locationUnitsStr) return null;

        const predictions = await getPlacePredictions({
            locationUnitsStr,
            excludeTypes,
            searchConfigurationsProp,
        });

        if (!predictions || predictions.length === 0) {
            throw new Error();
        }

        const predictionData = await getPlaceDetails(predictions[0]);

        if (!predictionData) {
            throw new Error();
        }

        return predictionData;
    } catch (e) {
        return null;
    }
};
