import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { ensureCurrentUser } from '../../util/data';
import {
    useOneSignal,
    EVENT_OS_NOTIFICATIONS_INIT_FAILED,
    EVENT_OS_NOTIFICATIONS_INIT_SUCCESS,
} from '../../services/oneSignal';
/**
 * Listen to OneSignal init event;
 * login to OS system on successful init;
 * call error callback on init failed;
 */
export const useOneSignalInitEvents = ({ allowed, errorHandler }) => {
    const store = useStore();
    const oneSignal = useOneSignal();

    useEffect(() => {
        if (!allowed) return;

        const { currentUser } = store.getState().user;

        const user = ensureCurrentUser(currentUser);

        const initSuccessCallback = () => oneSignal.login(user);
        const initErrorCallback = () => errorHandler();

        // array of unsubscribe callbacks
        const unsubscribes = [
            [EVENT_OS_NOTIFICATIONS_INIT_SUCCESS, initSuccessCallback],
            [EVENT_OS_NOTIFICATIONS_INIT_FAILED, initErrorCallback],
        ].map(([e, c]) => oneSignal.subscribe(e, c));

        return () => unsubscribes.forEach(c => c()); // Cleanup on unmount
    }, [allowed]);
};
