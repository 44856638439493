import React from 'react';
import { useSelector } from 'react-redux';

import css from './AcceptDeclineRequestButtonsMaybe.css';

import { ERROR_STATUS_NOT_ENOUGH_CREDITS } from '../../containers/TransactionPage/TransactionPage.duck';

export default () => {
    const inquiryError = useSelector(s => s.TransactionPage.inquiryError);

    if (!inquiryError) return;

    const inquiryErrorDefaultMessage = `Entschuldigen Sie, etwas ist schiefgelaufen. Versuchen Sie, die Seite neu zu
                    laden oder wenden Sie sich an den Support.`;

    const errorStatusesConfig = {
        [ERROR_STATUS_NOT_ENOUGH_CREDITS]:
            'Nicht genügend Credits. Überprüfen Sie Ihr Guthaben und kaufen Sie mindestens 30 Credits, um das Gespräch zu beginnen.',
    };

    return (
        <span className={css.error}>
            {errorStatusesConfig[inquiryError.status] || inquiryErrorDefaultMessage}
        </span>
    );
};
