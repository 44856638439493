import { useEffect } from 'react';

export const useEnquiryError = ({
    sendEnquiryError,
    activeTxDataError: activeTransactionsDataError,
    setError,
}) => {
    useEffect(() => {
        if (sendEnquiryError || activeTransactionsDataError) {
            const { apiErrors, status } = sendEnquiryError || activeTransactionsDataError || {};
            const { code } = (apiErrors && apiErrors[0]) || {};
            setError(code || status);
        }
    }, [sendEnquiryError, activeTransactionsDataError]);
};
