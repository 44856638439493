import React from 'react';
import classNames from 'classnames';
import css from '../MapWithGoogleMap.css';
/** icon-control-bold-m */
export default ({ setZoom, zoom, maxZoom = 2 }) => {
    const disabled = typeof maxZoom === 'number' && maxZoom >= zoom;

    return (
        <code
            className={classNames(css.zoomControlElemOut, {
                [css.disabled]: disabled,
            })}
            onClick={() => {
                if (disabled) return;
                setZoom(zoom - 1);
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    d="M2.514 7.029 C 2.183 7.078,1.977 7.225,1.844 7.508 C 1.778 7.649,1.771 7.698,1.771 8.004 C 1.771 8.311,1.778 8.360,1.844 8.500 C 1.981 8.792,2.178 8.928,2.539 8.981 C 2.796 9.018,14.667 9.019,14.952 8.982 C 15.481 8.913,15.735 8.596,15.735 8.004 C 15.735 7.412,15.481 7.095,14.952 7.027 C 14.695 6.993,2.743 6.996,2.514 7.029 "
                    stroke="none"
                    fillRule="evenodd"
                    fill="#1A2B49"
                />
            </svg>
        </code>
    );
};
