import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { capitalize, trimDisplayNameLastWord } from '../../util/text';
import { storableError } from '../../util/errors';
import {
    TRANSITION_ENQUIRY_ACCEPT,
    TRANSITION_ENQUIRY_ACCEPT_OWNER,
    TRANSITION_ENQUIRY_DECLINE,
    TRANSITION_ENQUIRY_DECLINE_OWNER,
    FILE_MESSAGE_TEXT_CONTENT,
    APPOINTMENT_DECLINED_STATUS,
    APPOINTMENT_CANCELED_STATUS,
    APPOINTMENT_ACCEPTED_STATUS,
    INITIATE_APPOINTMENT_REQUEST,
    DECLINE_APPOINTMENT_REQUEST,
    CANCEL_APPOINTMENT_REQUEST,
    ACCEPT_APPOINTMENT_REQUEST,
    TX_TRANSITION_ACTOR_CUSTOMER,
    TX_TRANSITION_ACTOR_PROVIDER,
    resolveOtherPartyData,
} from '../../util/transaction';

import { denormalisedResponseEntities } from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
    fetchCurrentUser,
    markUnreadMessageAsViewed,
    updateUserProfileInfo,
} from '../../ducks/user.duck';
import { loadData as inboxLoadData } from '../InboxPage/InboxPage.duck';
import {
    createAppointment,
    getAppointment,
    initiateUnlocking,
    sendSGEmail,
    updateAppointment,
    withdrawUserCredits,
} from '../../util/api';
import { parse } from '../../util/urlHelpers';

const { UUID } = sdkTypes;
const { canonicalRootURL, userTypeRider, userTypeHorseowner } = config;

export const CREDITS_NUM_TO_UNLOCK_PAGE = 30;
export const CHAT_ENTITY_TYPE_MESSAGE = 'message';
export const CHAT_ENTITY_TYPE_TRANSITION = 'transition';
export const CHAT_ENTITY_TYPE_APPOINTMENT = 'appointment';

const getAppointmentTemplatesConfig = (action, currentUser) => {
    const { userType } = currentUser.attributes.profile.publicData;

    const appointmentTemplatesConfig = {
        [INITIATE_APPOINTMENT_REQUEST]: {
            status: 'initial',
            emailTemplateName: `appointmentRequestedAs${capitalize(userType)}`,
        },
        [ACCEPT_APPOINTMENT_REQUEST]: {
            status: APPOINTMENT_ACCEPTED_STATUS,
            emailTemplateName: `appointmentAcceptedAs${capitalize(userType)}`,
        },
        [DECLINE_APPOINTMENT_REQUEST]: {
            status: APPOINTMENT_DECLINED_STATUS,
            emailTemplateName: `appointmentDeclinedAs${capitalize(userType)}`,
        },
        [CANCEL_APPOINTMENT_REQUEST]: {
            status: APPOINTMENT_CANCELED_STATUS,
            emailTemplateName: `appointmentCanceledAs${capitalize(userType)}`,
        },
    };

    return appointmentTemplatesConfig[action];
};

export const ERROR_STATUS_NOT_ENOUGH_CREDITS = 'not-enough-credits';

const MESSAGES_PAGE_SIZE = 100;
const IMAGE_VARIANTS = {
    'fields.image': [
        // Profile images
        'variants.square-small',
        'variants.square-small2x',

        // Listing images:
        'variants.landscape-crop',
        'variants.landscape-crop2x',
    ],
};

const getEmailFromActionName = (action, userType) => {
    const templateNameConfig = {
        transactionInitiated: {
            [userTypeRider]: 'transactionInitiatedRider',
            [userTypeHorseowner]: 'transactionInitiatedHorseowner',
        },
        transactionNewMessage: {
            [userTypeRider]: 'transactionNewMessageRider',
            [userTypeHorseowner]: 'transactionNewMessageHorseowner',
        },
    };
    return templateNameConfig[action][userType];
};

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_FILE_REQUEST = 'app/TransactionPage/SEND_FILE_REQUEST';
export const SEND_FILE_SUCCESS = 'app/TransactionPage/SEND_FILE_SUCCESS';
export const SEND_FILE_ERROR = 'app/TransactionPage/SEND_FILE_ERROR';

export const HANDLE_INQUIRY_REQUEST = 'app/TransactionPage/HANDLE_INQUIRY_REQUEST';
export const HANDLE_INQUIRY_SUCCESS = 'app/TransactionPage/HANDLE_INQUIRY_SUCCESS';
export const HANDLE_INQUIRY_ACCEPT = 'app/TransactionPage/HANDLE_INQUIRY_ACCEPT';
export const HANDLE_INQUIRY_ERROR = 'app/TransactionPage/HANDLE_INQUIRY_ERROR';

export const SEND_APPOINTMENT_REQUEST = 'app/TransactionPage/SEND_APPOINTMENT_REQUEST';
export const SEND_APPOINTMENT_SUCCESS = 'app/TransactionPage/SEND_APPOINTMENT_SUCCESS';
export const SEND_APPOINTMENT_UPDATE_SUCCESS =
    'app/TransactionPage/SEND_APPOINTMENT_UPDATE_SUCCESS';
export const SET_APPOINTMENT_DATA_TO_BE_FILLED =
    'app/TransactionPage/SET_APPOINTMENT_DATA_TO_BE_FILLED';

export const SEND_APPOINTMENT_ERROR = 'app/TransactionPage/SEND_APPOINTMENT_ERROR';
export const SET_APPOINTMENT_FETCH_ERROR = 'app/TransactionPage/SET_APPOINTMENT_FETCH_ERROR';
export const SET_APPOINTMENT_FETCH_SUCCESS = 'app/TransactionPage/SET_APPOINTMENT_FETCH_SUCCESS';
export const SET_APPOINTMENT_FETCH_IN_PROGRESS =
    'app/TransactionPage/SET_APPOINTMENT_FETCH_IN_PROGRESS';
export const ADD_APPOINTMENT_ENTITY = 'app/TransactionPage/ADD_APPOINTMENT_ENTITY';
export const MERGE_APPOINTMENT_ENTITY = 'app/TransactionPage/MERGE_APPOINTMENT_ENTITY';
export const INIT_APPOINTMENT_ENTITIES = 'app/TransactionPage/INIT_APPOINTMENT_ENTITIES';

export const PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL =
    'app/TransactionPage/PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL';
export const PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL =
    'app/TransactionPage/PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL';

// ================ Reducer ================ //

const initialState = {
    fetchMessagesError: null,
    totalMessages: 0,
    totalMessagePages: 0,
    oldestMessagePageFetched: 0,
    messages: [],
    initialMessageFailedToTransaction: null,
    savePaymentMethodFailed: false,
    sendMessageError: null,
    sendFileError: null,
    sendReviewError: null,
    timeSlots: null,
    fetchTimeSlotsError: null,
    fetchTransitionsError: null,
    processTransitions: null,
    appointmentError: null,
    appointmentFetchError: null,
    appointmentFetchInProgress: false,
    appointmentEntities: {},
    inquiryError: null,
    fetchMessagesInProgress: false,
    transactionRequestInProgress: false,
    sendMessageInProgress: false,
    sendFileInProgress: false,
    sendAppointmentInProgress: false,
    sendReviewInProgress: false,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
    return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SET_INITAL_VALUES:
            return { ...initialState, ...payload };

        case FETCH_TRANSITIONS_REQUEST:
            return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
        case FETCH_TRANSITIONS_SUCCESS:
            return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
        case FETCH_TRANSITIONS_ERROR:
            return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

        case HANDLE_INQUIRY_REQUEST:
            return { ...state, transactionRequestInProgress: true, inquiryError: null };
        case HANDLE_INQUIRY_SUCCESS:
            return { ...state, transactionRequestInProgress: false };
        case HANDLE_INQUIRY_ERROR:
            return { ...state, transactionRequestInProgress: false, inquiryError: payload };

        case FETCH_MESSAGES_REQUEST:
            return {
                ...state,
                messages: [],
                totalMessages: 0,
                totalMessagePages: 0,
                oldestMessagePageFetched: 0,
                fetchMessagesInProgress: true,
                fetchMessagesError: null,
            };
        case FETCH_MESSAGES_SUCCESS: {
            const oldestMessagePageFetched =
                state.oldestMessagePageFetched > payload.page
                    ? state.oldestMessagePageFetched
                    : payload.page;
            return {
                ...state,
                fetchMessagesInProgress: false,
                messages: mergeEntityArrays(state.messages, payload.messages),
                totalMessages: payload.totalItems,
                totalMessagePages: payload.totalPages,
                oldestMessagePageFetched,
            };
        }
        case FETCH_MESSAGES_ERROR:
            return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

        case SEND_MESSAGE_REQUEST:
            return {
                ...state,
                sendMessageInProgress: true,
                sendMessageError: null,
                initialMessageFailedToTransaction: null,
            };
        case SEND_MESSAGE_SUCCESS:
            return { ...state, sendMessageInProgress: false };
        case SEND_MESSAGE_ERROR:
            return { ...state, sendMessageInProgress: false, sendMessageError: payload };

        case SEND_FILE_REQUEST:
            return {
                ...state,
                sendFileInProgress: true,
                sendFileError: null,
            };
        case SEND_FILE_SUCCESS:
            return { ...state, sendFileInProgress: false };
        case SEND_FILE_ERROR:
            return { ...state, sendFileInProgress: false, sendFileError: payload };

        case SEND_APPOINTMENT_REQUEST:
            return { ...state, sendAppointmentInProgress: true, appointmentError: null };
        case SEND_APPOINTMENT_SUCCESS:
            return { ...state, sendAppointmentInProgress: false, appointmentError: null };
        case SEND_APPOINTMENT_UPDATE_SUCCESS:
            return { ...state, sendAppointmentInProgress: false, appointmentError: null };
        case SEND_APPOINTMENT_ERROR:
            return { ...state, sendAppointmentInProgress: false, appointmentError: payload };
        case SET_APPOINTMENT_FETCH_ERROR:
            return { ...state, appointmentFetchError: payload };
        case SET_APPOINTMENT_FETCH_IN_PROGRESS:
            return { ...state, appointmentFetchError: null, appointmentFetchInProgress: true };
        case SET_APPOINTMENT_FETCH_SUCCESS:
            return { ...state, appointmentFetchError: null, appointmentFetchInProgress: false };

        case ADD_APPOINTMENT_ENTITY:
            return {
                ...state,
                appointmentEntities: {
                    ...state.appointmentEntities,
                    [payload.transactionId]: [
                        ...(state.appointmentEntities[payload.transactionId] || []),
                        payload.entity,
                    ],
                },
            };
        case MERGE_APPOINTMENT_ENTITY:
            return {
                ...state,
                appointmentEntities: {
                    ...state.appointmentEntities,
                    [payload.transactionId]: [
                        ...(state.appointmentEntities[payload.transactionId] || []).map(entity =>
                            entity._id === payload._id ? payload : entity
                        ),
                    ],
                },
            };
        case INIT_APPOINTMENT_ENTITIES:
            return {
                ...state,
                appointmentEntities: {
                    ...state.appointmentEntities,
                    [payload.transactionId]: [...payload.entities],
                },
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
    type: SET_INITAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
    type: FETCH_TRANSITIONS_SUCCESS,
    payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
    type: FETCH_MESSAGES_SUCCESS,
    payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = txId => ({ type: SEND_MESSAGE_SUCCESS, payload: txId });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendFileRequest = () => ({ type: SEND_FILE_REQUEST });
const sendFileSuccess = txId => ({ type: SEND_FILE_SUCCESS, payload: txId });
const sendFileError = e => ({ type: SEND_FILE_ERROR, error: true, payload: e });

const sendAppointmentRequest = () => ({ type: SEND_APPOINTMENT_REQUEST });
const sendAppointmentSuccess = txId => ({ type: SEND_APPOINTMENT_SUCCESS, payload: txId });
const updateAppointmentSuccess = txId => ({ type: SEND_APPOINTMENT_UPDATE_SUCCESS, payload: txId });
const sendAppointmentError = error => ({ type: SEND_APPOINTMENT_ERROR, payload: error });

const setAppointmentFetchSuccess = () => ({ type: SET_APPOINTMENT_FETCH_SUCCESS });
const setAppointmentFetchInProgress = () => ({ type: SET_APPOINTMENT_FETCH_IN_PROGRESS });
const setAppointmentFetchError = error => ({ type: SET_APPOINTMENT_FETCH_ERROR, payload: error });

const addAppointmentEntity = payload => ({ type: ADD_APPOINTMENT_ENTITY, payload });
const mergeAppointmentEntity = payload => ({ type: MERGE_APPOINTMENT_ENTITY, payload });
const initAppointmentEntities = payload => ({ type: INIT_APPOINTMENT_ENTITIES, payload });

const inquiryHandlingInProgress = () => ({ type: HANDLE_INQUIRY_REQUEST });
const inquiryHandlingSuccess = txId => ({ type: HANDLE_INQUIRY_SUCCESS, payload: txId });
const inquiryHandlingAccept = () => ({ type: HANDLE_INQUIRY_ACCEPT });
const inquiryHandlingError = e => ({ type: HANDLE_INQUIRY_ERROR, payload: e });

export const consultChatRequestMixpanel = txId => ({
    type: PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL,
    payload: { txId },
});
const answerChatRequestMixpanel = payload => ({
    type: PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL,
    payload,
});

// ================ Thunks ================ //

export const prepareAndSendSGMessage = ({
    txId,
    action,
    emailTemplate: emailTemplateProp,
    dataParams = {},
}) => (_, getState) => {
    const {
        marketplaceData: {
            entities: { transaction, user: users, listing: listings },
        },
        user: { currentUser },
    } = getState();

    const currentTransaction = transaction[txId];
    const customerId = currentTransaction.relationships?.customer?.data?.id?.uuid;
    const providerId = currentTransaction.relationships?.provider?.data?.id?.uuid;
    const listingId = currentTransaction.relationships?.listing?.data?.id?.uuid;
    const listingSubstitutionId =
        currentTransaction.attributes?.protectedData?.listingSubstitutionId;

    const currentListing = listings[listingSubstitutionId || listingId];
    const currentUserType = currentUser.attributes.profile.publicData.userType;
    const otherParty = resolveOtherPartyData(currentUser, users[customerId], users[providerId]);

    const otherPartyType = currentUserType === userTypeRider ? userTypeHorseowner : userTypeRider;
    const emailTemplate = emailTemplateProp || getEmailFromActionName(action, otherPartyType);
    const {
        attributes: { profile: otherPartyProfile },
    } = otherParty;

    const {
        firstName: otherPartyFirstName,
        displayName: otherPartyDisplayName,
    } = otherPartyProfile;

    sendSGEmail(
        JSON.stringify({
            recipientId: otherParty.id.uuid,
            emailTemplate,
            groupIdName: 'userTransactionChatsGroupId',
            data: {
                canonicalRootURL,
                chatPageUrl: `${canonicalRootURL}/messages/${txId}`,
                otherPartyName: currentUser.attributes.profile.firstName,
                recipientFirstName:
                    otherPartyFirstName || trimDisplayNameLastWord(otherPartyDisplayName || ''),
                listingTitle: currentListing?.attributes?.title || 'Pferd',
                riderProfilePage: `${canonicalRootURL}/profile/rider/public/${currentUser.id.uuid}`,
                horseownerProfilePage: `${canonicalRootURL}/profile/horseowner/public/${currentUser.id.uuid}`,
                ...dataParams,
            },
        })
    );
};

export const fetchFiles = (txId, messages) => async dispatch => {
    try {
        const { uuid } = txId;
        const filesJson = await fetch(`/api/transactions/${uuid}/messages`);

        const filesData = await filesJson.json();
        const files = (filesData && filesData.data && filesData.data.data) || null;

        if (files && messages) {
            messages.forEach(s => {
                const id = s.id.uuid;
                const fileHolder = files.filter(f => f.id.uuid === id)[0];
                if (fileHolder && fileHolder.attributes && fileHolder.attributes.file) {
                    s.attributes.file = fileHolder.attributes.file;
                }
                return s;
            });
        }
    } catch (error) {
        console.error('The following error occured at fetchMessages method: ', error);
    }
};

export const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
    const paging = { page, per_page: MESSAGES_PAGE_SIZE };
    dispatch(fetchMessagesRequest());

    return sdk.messages
        .query({
            transaction_id: txId,
            include: ['sender', 'sender.profileImage'],
            ...IMAGE_VARIANTS,
            ...paging,
        })
        .then(async response => {
            const messages = denormalisedResponseEntities(response);

            await dispatch(fetchFiles(txId, messages));
            // await dispatch(fetchAppointments(txId, messages));

            const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
            const pagination = { totalItems, totalPages, page: fetchedPage };
            const totalMessages = getState().TransactionPage.totalMessages;

            // Original fetchMessages call succeeded
            dispatch(fetchMessagesSuccess(messages, pagination));

            // Check if totalItems has changed between fetched pagination pages
            // if totalItems has changed, fetch first page again to include new incoming messages.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            if (totalItems > totalMessages && page > 1) {
                dispatch(fetchMessages(txId, 1))
                    .then(() => {
                        // Original fetch was enough as a response for user action,
                        // this just includes new incoming messages
                    })
                    .catch(() => {
                        // Background update, no need to to do anything atm.
                    });
            }
        })
        .catch(e => {
            dispatch(fetchMessagesError(storableError(e)));
            throw e;
        });
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
    dispatch(sendMessageRequest());

    return sdk.messages
        .send({ transactionId: txId, content: message })
        .then(response => {
            const messageId = response.data.data.id;
            // send a message to other party
            dispatch(
                prepareAndSendSGMessage({
                    action: 'transactionNewMessage',
                    txId: txId.uuid,
                    dataParams: { message },
                })
            );
            // We fetch the first page again to add sent message to the page data
            // and update possible incoming messages too.
            // TODO if there're more than 100 incoming messages,
            // this should loop through most recent pages instead of fetching just the first one.
            return dispatch(fetchMessages(txId, 1))
                .then(() => {
                    dispatch(sendMessageSuccess(txId));
                    return messageId;
                })
                .catch(() => dispatch(sendMessageSuccess(txId)));
        })
        .catch(e => {
            dispatch(sendMessageError(storableError(e)));
            // Rethrow so the page can track whether the sending failed, and
            // keep the message in the form for a retry.
            throw e;
        });
};

export const sendFile = (transaction, file) => (dispatch, getState, sdk) => {
    const transactionUuid = transaction.id.uuid;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('content', FILE_MESSAGE_TEXT_CONTENT);

    dispatch(sendFileRequest());

    return fetch(`/api/transactions/${transactionUuid}/messages?page=1`, {
        method: 'POST',
        body: formData,
    })
        .then(res => res.json())
        .then(res => {
            const { status, message } = res;

            if (status !== 200) {
                throw new Error(message || 'Failed to send file.');
            }

            return dispatch(fetchMessages(transaction.id, 1)).then(() => {
                dispatch(sendFileSuccess(transaction.id));
                return res;
            });
        })
        .catch(error => {
            error.type = 'error';
            dispatch(sendFileError(error));
            dispatch(sendMessageError(storableError(error)));
        });
};

/** Notify an admin about an unlock being made */
const unlockTransaction = async transactionId => {
    try {
        const res = await initiateUnlocking(transactionId);
        if (res.status !== 200) {
            throw new Error('Unable to send data');
        }
        return {};
    } catch (error) {
        return { error: error.message };
    }
};
/** Accept or decline an inquiry */
export const respondOnCustomerInquiry = (txData, { declineMessage, reason }) => async (
    dispatch,
    getState,
    sdk
) => {
    const { currentUser } = getState().user;
    const { transaction } = getState().marketplaceData.entities;
    const { txId, otherParty } = txData;
    const { isSponsored } = transaction[txId.uuid].attributes.protectedData || {};
    const { txRole: otherPartyRole } = otherParty.attributes.profile.publicData;

    dispatch(inquiryHandlingInProgress());

    const isDeclineAction = declineMessage && reason;

    const transitionDictionary = {
        [TX_TRANSITION_ACTOR_PROVIDER]: {
            accept_transition: TRANSITION_ENQUIRY_ACCEPT_OWNER,
            decline_transition: TRANSITION_ENQUIRY_DECLINE_OWNER,
        },
        [TX_TRANSITION_ACTOR_CUSTOMER]: {
            accept_transition: TRANSITION_ENQUIRY_ACCEPT,
            decline_transition: TRANSITION_ENQUIRY_DECLINE,
        },
    };

    const { accept_transition, decline_transition } = transitionDictionary[otherPartyRole];

    const transition = isDeclineAction ? decline_transition : accept_transition;
    const shouldWithdrawCredits =
        !isSponsored &&
        transition !== TRANSITION_ENQUIRY_DECLINE &&
        transition !== TRANSITION_ENQUIRY_DECLINE_OWNER;

    try {
        if (shouldWithdrawCredits) {
            /**
             * If a tx is sponsored, the credits are withdrawn on inquiry step
             */
            const userId = currentUser.id.uuid;
            const response = await withdrawUserCredits(userId, CREDITS_NUM_TO_UNLOCK_PAGE);
            const data = await response.json();
            const { error, message, status } = data;

            if (error) {
                return dispatch(
                    inquiryHandlingError({
                        message,
                        status,
                    })
                );
            }

            dispatch(fetchCurrentUser());
        }

        await unlockTransaction(txId.uuid);
    } catch (e) {
        return dispatch(inquiryHandlingError(e));
    }

    const params = isDeclineAction
        ? {
              protectedData: {
                  isDeclined: true,
                  reason,
              },
          }
        : {};

    return sdk.transactions
        .transition(
            {
                id: txId.uuid,
                transition,
                params,
            },
            { expand: true }
        )
        .then(response => {
            dispatch(answerChatRequestMixpanel({ txId, accepted: !isDeclineAction }));
            dispatch(addMarketplaceEntities(response));

            if (declineMessage) {
                dispatch(sendMessage(txId, declineMessage));
            } else {
                dispatch(inquiryHandlingAccept());
            }

            dispatch(inquiryHandlingSuccess(txId));

            return response;
        })
        .catch(error => {
            const isSdkError = error && error.data && Array.isArray(error.data.errors);
            if (isSdkError) {
                return dispatch(inquiryHandlingError(error.data.errors[0].title || error));
            }
            dispatch(inquiryHandlingError(error));
        });
};

const isNonEmpty = value =>
    typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
    dispatch(fetchTransitionsRequest());

    return sdk.processTransitions
        .query({ transactionId: id })
        .then(res => {
            dispatch(fetchTransitionsSuccess(res.data.data));
        })
        .catch(e => {
            dispatch(fetchTransitionsError(storableError(e)));
        });
};

export const fetchAppointments = transactions => dispatch => {
    dispatch(setAppointmentFetchInProgress());

    return Promise.all(
        transactions.map(async ({ id: { uuid } }) => {
            const response = await getAppointment(uuid);
            const { error, message, appointments, transitions } = await response.json();

            if (error) {
                return dispatch(setAppointmentFetchError(message));
            }

            dispatch(
                initAppointmentEntities({
                    transactionId: uuid,
                    entities: [...appointments, ...transitions],
                })
            );
        })
    )
        .then(() => dispatch(setAppointmentFetchSuccess()))
        .catch(error => dispatch(setAppointmentFetchError(error.message)));
};

const sendAppointmentEmail = (data, params = {}) => {
    const { appointmentData, otherParty, currentUser, currentListing, intl } = data;

    sendSGEmail(
        JSON.stringify({
            recipientId: otherParty.id.uuid,
            groupIdName: 'userTransactionChatsGroupId',
            data: {
                /**
                 * displayName is used for cases when
                 *
                 * 1) otherParty is currentUser
                 * 2) currentUser is otherParty
                 *
                 * in this case the otherParty does not contain first & last names
                 */
                recipientFirstName: trimDisplayNameLastWord(
                    currentUser.attributes.profile.displayName || ''
                ),
                otherPartyName: trimDisplayNameLastWord(
                    otherParty.attributes.profile.displayName || ''
                ),
                listingTitle: currentListing.attributes.title,
                date: intl.formatDate(appointmentData.data.date, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }),
                time: appointmentData.data.time,
                location: appointmentData.data.location,
                chatPageUrl: window.location.href,
            },
            ...params,
        })
    );
};

export const sendAppointment = data => async dispatch => {
    const { otherParty, currentUser, currentListing, intl, ...appointmentData } = data;

    dispatch(sendAppointmentRequest());
    const defaultErrorMessage = 'Failed to send a message for appointment.';

    return createAppointment(JSON.stringify(appointmentData))
        .then(data => {
            const { error, message } = data;

            if (error) {
                throw new Error(message || defaultErrorMessage);
            }
            /**
             * see interceptSendMessage for uuid use
             */
            const { transactionId } = appointmentData;
            dispatch(sendAppointmentSuccess({ uuid: transactionId }));

            const sgData = { appointmentData, currentListing, intl };

            const { emailTemplateName } = getAppointmentTemplatesConfig(
                INITIATE_APPOINTMENT_REQUEST,
                currentUser
            );

            sendAppointmentEmail(
                { ...sgData, otherParty: currentUser, currentUser: otherParty },
                { emailTemplate: emailTemplateName, recipientId: otherParty.id.uuid }
            );

            dispatch(
                addAppointmentEntity({
                    transactionId,
                    entity: data,
                })
            );
            return {};
        })
        .catch(error => dispatch(sendAppointmentError(error.message)));
};

export const proceedWithAppointment = (data, action) => dispatch => {
    const { otherParty, currentUser, currentListing, intl, appointmentData } = data;
    const { transactionId } = appointmentData;

    const actionMaybe = getAppointmentTemplatesConfig(action, currentUser);
    const wrongActionMaybe = !action || !actionMaybe;

    if (wrongActionMaybe) throw new Error(`Unexpected transaction action ${action}.`);
    if (!transactionId) throw new Error(`Unexpected transaction id ${transactionId}.`);

    dispatch(sendAppointmentRequest());
    /**
     * update an appointment object data and create
     * a new transition status concerning the appointment
     * */
    const { status: statusUpdated, emailTemplateName } = actionMaybe;

    return updateAppointment({ transactionId, statusUpdated })
        .then(res => res.json())
        .then(data => {
            const { error, message, appointment, transition } = data;

            if (error) throw new Error(message);

            if (!appointment || !transition) {
                const failedData = !appointment ? 'appointment.data' : 'transition.data';
                throw new Error(`Some data failed to update: ${failedData}.`);
            }
            /**
             * see interceptSendMessage for uuid use
             */
            dispatch(mergeAppointmentEntity(appointment));
            dispatch(updateAppointmentSuccess({ uuid: transactionId }));
            dispatch(addAppointmentEntity({ transactionId, entity: transition }));

            const sgData = { appointmentData, currentListing, intl };
            sendAppointmentEmail(
                { ...sgData, otherParty: currentUser, currentUser: otherParty },
                { emailTemplate: emailTemplateName, recipientId: otherParty.id.uuid }
            );

            return {};
        })
        .catch(error => dispatch(sendAppointmentError(error.message)));
};

export const updateChatLastActivity = txId => (dispatch, getState, sdk) => {
    const {
        user: { currentUser },
    } = getState();

    if (!currentUser) {
        return;
    }

    const {
        attributes: { profile },
    } = currentUser;

    const { publicData } = profile;
    const { uuid } = txId;

    const today = new Date();
    const chatLastActivity = {
        ...publicData.chatLastActivity,
        [uuid]: { lastVisit: today.toString() },
    };

    const params = {
        publicData: {
            chatLastActivity,
        },
    };

    const currentUserDataUpd = { ...currentUser };
    currentUserDataUpd.attributes.profile.publicData.chatLastActivity = chatLastActivity;

    return dispatch(updateUserProfileInfo(params, currentUserDataUpd));
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = (params, searchParams) => async (dispatch, getState) => {
    const { per_page } = parse(searchParams);

    // const inboxSearch = '';
    const inboxParams = {};

    if (per_page) inboxParams.per_page = per_page;

    const txId = new UUID(params.id);
    const state = getState().TransactionPage;
    const txRef = state.transactionRef;
    // const txRole = params.transactionRole;

    // In case a transaction reference is found from a previous
    // data load -> clear the state. Otherwise keep the non-null
    // and non-empty values which may have been set from a previous page.
    const initialValues = txRef ? {} : pickBy(state, isNonEmpty);

    dispatch(setInitialValues(initialValues));
    /**
     * inboxLoadData - fetch user's transactions;
     *
     * inboxLoadData goes first to collect all txs data;
     * after ild is successfull, the corresponding
     * tx is found inside tx page
     *
     * otherwise inboxLoadData erases this specific fields
     * in the marketplace entities data
     */
    await dispatch(inboxLoadData(inboxParams));
    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
        // dispatch(inboxLoadData(inboxParams, inboxSearch)),
        // dispatch(fetchTransaction(txId, txRole)),
        dispatch(fetchMessages(txId, 1)),
        /**
         * appointments are fetched at InboxPage.duck
         * as soon as transactions data is received
         */
        dispatch(fetchNextTransitions(txId)),
        dispatch(updateChatLastActivity(txId)),
    ]).then(() => dispatch(markUnreadMessageAsViewed(txId.uuid)));
};
