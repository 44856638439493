import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import WalkingManIcon from '../icons/WalkingManIcon';
import { IconSearch, WarningIcon } from '../../../icons';
import { USER_MOBILITY } from '../config';
import { IconSpinner, NamedLink } from '../../../components';
import css from '../ProfileSettingsForm.css';
import config from '../../../config';
import ScoreBadge from '../ScoreBadge';
import classNames from 'classnames';

const { userTypeHorseowner } = config;

const LocationAndMobilitySection = ({
    renderEditPencilIcon,
    postalCode,
    city,
    mobility,
    isPublic,
    handleEditSection,
    setModalParams,
    userType,
    scoreData,
    scoreReqInProgress,
}) => {
    const noMobilityData = !mobility;
    const noLocationData = !city && !postalCode;

    const noData = noMobilityData && noLocationData;

    if (isPublic && noData) {
        return null;
    }
    const isHorseowner = userType === userTypeHorseowner;

    return (
        <div className={css.infoBlock}>
            {scoreData && scoreData.baseScoreBreakdown && (
                <ScoreBadge scoreData={scoreData.baseScoreBreakdown.distanceAndMobilityScore} />
            )}
            {scoreReqInProgress && <IconSpinner />}
            <h2 className={css.infoHeading}>
                <FormattedMessage id={`ProfileSettingsForm.locationMobilityHeading-${userType}`} />
                {noData ? (
                    <WarningIcon rootClassName={css.noInfoWarningIcon} />
                ) : (
                    <div className={css.editBlock} onClick={() => setModalParams({ isHorseowner })}>
                        {renderEditPencilIcon(USER_MOBILITY)}
                    </div>
                )}
            </h2>
            {!noLocationData ? (
                <div className={css.infoLine}>
                    <IconSearch />
                    <div>
                        <h5>
                            <FormattedMessage id="ProfileSettingsForm.locationSubHeading" />
                        </h5>
                        <p className={classNames(css.infoAncient, css.mB0)}>
                            {postalCode} {city}
                        </p>
                    </div>
                </div>
            ) : (
                <NamedLink name="PersonalInfoPage">
                    <button type="button" className={`${css.actionItem} ${css.actionBtn}`}>
                        <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-userLocation" />
                    </button>
                </NamedLink>
            )}
            {mobility && !isHorseowner && (
                <div className={css.infoLine}>
                    <WalkingManIcon />
                    <div>
                        <h5>
                            <FormattedMessage id="ProfileSettingsForm.mobilitySubHeading" />
                        </h5>
                        <p className={`${css.infoAncient} ${css.mB0}`}>
                            <FormattedMessage
                                id={`EditUserMobilitySection.mobility-${mobility.transport}`}
                            />
                            <br />
                            <FormattedMessage
                                id="ProfileSettingsForm.mobilityMaxDistance"
                                values={{ radius: mobility.radius }}
                            />
                        </p>
                    </div>
                </div>
            )}
            {!mobility && !isHorseowner && !isPublic && (
                <button
                    type="button"
                    onClick={() => {
                        handleEditSection(USER_MOBILITY);
                    }}
                    className={`${css.actionItem} ${css.actionBtn} ${css.mT8}`}
                >
                    <FormattedMessage
                        id={`ProfileSettingsForm.editUserInfoAction-${
                            isHorseowner ? 'userLocationOnly' : 'userMobilityOnly'
                        }`}
                    />
                </button>
            )}
        </div>
    );
};

export default LocationAndMobilitySection;
