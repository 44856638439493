import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { USER_AVAILABILITY } from '../config';
import EditUserAvailabilityStepPreference from './EditUserAvailabilityStepPreference';
import EditUserAvailabilityStepReasonToClose from './EditUserAvailabilityStepReasonToClose';

export default ({ modalParams, setModalParams, step, ...restProps }) => {
    const isPreferenceStep = step === USER_AVAILABILITY;
    const commonProps = {
        isConfirm: (modalParams || {}).isConfirm,
        parentSubmit: restProps.onSubmit,
    };
    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps =>
                isPreferenceStep ? (
                    <EditUserAvailabilityStepPreference {...fieldRenderProps} {...commonProps} />
                ) : (
                    <EditUserAvailabilityStepReasonToClose {...fieldRenderProps} {...commonProps} />
                )
            }
        />
    );
};
