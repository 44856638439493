import React from 'react';
/**
 * icon-close-circle-m
 * icon-close-circle
 */
export default ({ clickHandler = () => null, rootClassName = '' }) => (
    <svg
        onClick={clickHandler}
        className={rootClassName}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M7.374 0.893 C 4.450 1.263,2.048 3.377,1.316 6.223 C 1.158 6.839,1.100 7.318,1.099 8.004 C 1.099 8.689,1.157 9.166,1.316 9.785 C 2.056 12.661,4.484 14.775,7.441 15.119 C 7.919 15.175,8.866 15.159,9.322 15.088 C 11.805 14.702,13.889 13.073,14.858 10.761 C 15.094 10.198,15.243 9.655,15.347 8.982 C 15.418 8.525,15.417 7.482,15.347 7.027 C 15.097 5.410,14.424 4.058,13.317 2.945 C 12.247 1.869,10.842 1.157,9.322 0.919 C 8.906 0.854,7.797 0.839,7.374 0.893 M8.919 1.872 C 10.003 1.991,11.070 2.413,11.949 3.070 C 12.327 3.353,12.895 3.919,13.170 4.286 C 13.861 5.209,14.279 6.290,14.393 7.448 C 14.539 8.931,14.093 10.490,13.170 11.723 C 12.895 12.090,12.327 12.656,11.949 12.938 C 10.180 14.260,7.853 14.537,5.819 13.669 C 3.907 12.852,2.525 11.099,2.167 9.035 C 2.064 8.444,2.064 7.566,2.166 6.976 C 2.632 4.296,4.757 2.254,7.466 1.886 C 7.815 1.839,8.551 1.831,8.919 1.872 M5.397 4.864 C 5.115 5.013,4.996 5.292,5.114 5.527 C 5.141 5.581,5.697 6.160,6.349 6.814 L 7.536 8.005 6.373 9.166 C 5.733 9.805,5.177 10.382,5.137 10.449 C 5.053 10.591,5.044 10.762,5.116 10.875 C 5.283 11.137,5.519 11.246,5.743 11.162 C 5.787 11.145,6.368 10.588,7.034 9.923 L 8.245 8.713 9.442 9.907 C 10.100 10.564,10.680 11.121,10.730 11.146 C 10.832 11.197,10.956 11.205,11.059 11.165 C 11.166 11.124,11.331 10.968,11.392 10.848 C 11.467 10.700,11.461 10.601,11.365 10.453 C 11.320 10.384,10.759 9.805,10.118 9.166 L 8.954 8.005 10.169 6.786 C 11.202 5.751,11.390 5.550,11.419 5.450 C 11.460 5.313,11.445 5.233,11.355 5.101 C 11.190 4.858,10.935 4.759,10.736 4.859 C 10.683 4.886,10.100 5.445,9.442 6.101 L 8.245 7.295 7.034 6.086 C 6.368 5.421,5.787 4.863,5.743 4.847 C 5.631 4.805,5.497 4.811,5.397 4.864 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
