import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FieldCheckbox, IconSpinner } from '../../components';
import { disabledGroupsConfig, getGroupFormKey, sanitizeItemByType } from './helpers';
import css from './NotificationsControlForm.css';
import {
    addSGSuppressionByGroupId,
    deleteSGSuppressionByGroupId,
    getUnsubscribeGroupsInfo,
} from '../../util/api';
import { isDev } from '../../util/urlHelpers';

export default ({ currentUser, values, form }) => {
    const [unsubscribeGroups, setUnsubscribeGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { email } = currentUser.attributes;
    const { userType } = currentUser.attributes.profile.publicData;

    const suppress = async values => {
        try {
            const response = await addSGSuppressionByGroupId(
                JSON.stringify({ groupId: Number(values.id) })
            );
            if (response.error) {
                throw new Error(response.message);
            }

            form.change(getGroupFormKey(values), false);
        } catch (e) {
            if (isDev()) console.log(`updateUnsubscribeGroupsInfo.error.${e.message}`);
            setError(true);
        }
    };

    const unsuppress = async values => {
        try {
            const response = await deleteSGSuppressionByGroupId(Number(values.id));
            const data = await response.json();
            if (data.error) {
                throw new Error(data.message);
            }
            form.change(getGroupFormKey(values), true);
        } catch (e) {
            if (isDev()) console.log(`updateUnsubscribeGroupsInfo.error.${e.message}`);
            setError(true);
        }
    };

    const updateUnsubscribeGroupsInfo = async (values, shouldBeSuppressed) => {
        setLoading(true);

        try {
            (shouldBeSuppressed ? suppress : unsuppress)(values);
        } catch (e) {
            if (isDev()) console.log(`updateUnsubscribeGroupsInfo.error.${e.message}`);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const requestUnsubscribeGroups = async () => {
        setLoading(true);
        try {
            const response = await getUnsubscribeGroupsInfo(email);
            const { data, error, message } = await response.json();

            if (error) {
                if (isDev()) console.log(`requestUnsubscribeGroups.error.${message}`);
                return setError(true);
            }

            const suppressions = data?.suppressions;

            suppressions.forEach(group => {
                /** set initial values */
                form.change(getGroupFormKey(group), !group.suppressed);
            });

            setUnsubscribeGroups((data?.suppressions || []).map(s => ({ ...s, id: String(s.id) })));
        } catch (e) {
            if (isDev()) console.log(`requestUnsubscribeGroups.error.${e.message}`);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (email) requestUnsubscribeGroups();
    }, [email]);

    const dataAvailable = Boolean(Array.isArray(unsubscribeGroups) && unsubscribeGroups.length);

    if (!dataAvailable) return null;

    const fieldsDisabled = loading || error;

    return (
        <div className={css.contentBox}>
            {loading && <IconSpinner />}
            {error && (
                <p className={css.error}>
                    Etwas ist schiefgelaufen. Versuchen Sie es später noch einmal oder wenden Sie
                    sich an den Support.
                </p>
            )}
            {unsubscribeGroups
                ?.filter(sanitizeItemByType(userType))
                ?.map(({ id, name, description }) => (
                    <FieldCheckbox
                        reversePosition
                        togglerMode
                        type="checkbox"
                        form={form}
                        key={id}
                        id={getGroupFormKey({ id, name })}
                        name={getGroupFormKey({ id, name })}
                        disabled={disabledGroupsConfig[id] || fieldsDisabled}
                        className={css.formField}
                        label={
                            <p className={css.formLabel}>
                                <span className={css.labelHeading}>{name}</span>
                                <span>{description}</span>
                            </p>
                        }
                        onChange={() =>
                            updateUnsubscribeGroupsInfo(
                                { id, name },
                                values[getGroupFormKey({ id, name })]
                            )
                        }
                    />
                ))}
            <FieldCheckbox
                type="button"
                form={form}
                id="blank"
                name="blank"
                reversePosition
                className={classNames(css.formField, css.blank)}
                label={
                    <p className={css.formLabel}>
                        <span>Newsletter</span>
                        <span>Updates, Gewinnspiele und Umfragen</span>
                        <span className={css.formLink}>
                            <a href="/kontakt">Kontaktiere unseren Support</a>, um dich abzumelden.
                        </span>
                    </p>
                }
            />
        </div>
    );
};
