import React from 'react';
import { IconEyeL } from '../../icons';

import css from './PreviewButton.css';

export default ({ onClickHandler = () => null }) => (
    <div className={css.root} onClick={onClickHandler}>
        <IconEyeL />
        <span>Vorschau ansehen</span>
    </div>
);
