import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { Form, Button } from '../../components';
import { InviteReferralsForm } from '../../forms';

import css from './EditUserInfoPage.css';

export default ({ navigateToPreviousForm, updateInfoInProgress, children, ...restProps }) => (
    <FinalForm
        {...restProps}
        render={fieldRenderProps => {
            const { handleSubmit, values } = fieldRenderProps;
            const { recipientsList, message } = values;
            const disabled = !recipientsList || recipientsList.length === 0 || !message;

            return (
                <>
                    <Form onSubmit={handleSubmit} className={css.form}>
                        <h3 className={css.collectInfoHeader}>Empfehlungen erhalten</h3>
                        <InviteReferralsForm {...fieldRenderProps} messageRequired>
                            <footer className={css.sendRecommendationFtr}>
                                <Button
                                    disabled={disabled}
                                    onClick={() => restProps.onSubmit(values)}
                                >
                                    Einladung senden
                                </Button>
                            </footer>
                        </InviteReferralsForm>
                    </Form>
                </>
            );
        }}
    />
);
