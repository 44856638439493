import React from 'react';
import classNames from 'classnames';

import css from '../ProfileSettingsForm.css';
import sectionCss from './ClubsSection.css';
import { USER_CLUB } from '../config';
import { IconTeamXL } from '../../../icons';

const Wrapper = ({ link, children }) =>
    link ? (
        <a
            href={link}
            target="_blank"
            rel="noopener nofollow"
            className={classNames(css.flex, css.editBlock, sectionCss.item, sectionCss.linkItem)}
        >
            {children}
        </a>
    ) : (
        <div className={classNames(css.flex, css.editBlock, sectionCss.item)}>{children}</div>
    );

export default ({
    isPublic,
    publicData,
    setModalParams,
    handleEditSection,
    renderEditPencilIcon,
}) => {
    const { clubMembership, country } = publicData || {};
    const noData = !clubMembership || !clubMembership.clubName;
    const allowedCountries = ['CH', 'DE'];
    const notValidCountry = !allowedCountries.includes(country);

    if (notValidCountry) return null;
    if (isPublic && noData) return null;

    const { clubName, clubUrl, dateFrom, dateTo, isMember } = clubMembership || {};

    return (
        <div className={css.infoBlock}>
            <h2 className={css.infoHeading}>
                <span>Verein</span>
                {isPublic || noData ? (
                    <div className={sectionCss.badge}>Neu</div>
                ) : (
                    <div className={css.editBlock} onClick={() => setModalParams(clubMembership)}>
                        {renderEditPencilIcon(USER_CLUB)}
                    </div>
                )}
            </h2>
            {!noData && (
                <Wrapper link={clubUrl}>
                    <IconTeamXL />
                    <div>
                        <p>{clubName}</p>
                        <p>
                            {dateFrom}-{isMember ? 'Heute' : dateTo}
                        </p>
                    </div>
                </Wrapper>
            )}
            {!isPublic && noData && (
                <button
                    type="button"
                    className={classNames(css.actionItem, css.actionBtn)}
                    onClick={() => handleEditSection(USER_CLUB)}
                >
                    Verein hinzufügen
                </button>
            )}
        </div>
    );
};
