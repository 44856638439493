import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldCheckbox, IconSpinner } from '../../components';
import { checkIOS, isNonNativeIOS, notifyIosAppOnLogInNoSubscription } from '../../util/ios';
import { isDev } from '../../util/urlHelpers';
import { useOneSignalInitEvents } from '../../components/PushNotificationsSubscriptionModal/useOneSignalInitEvents';
import { useOneSignalLoginEvents } from '../../components/PushNotificationsSubscriptionModal/useOneSignalLoginEvents';
import { updateUserProfileInfo } from '../../ducks/user.duck';
import { useOneSignal } from '../../services/oneSignal';
import { useIosWvPermissionGranted } from '../LandingPage/useIosWvPermissionGranted';
import PushNotificationsModalContent from '../../components/PushNotificationsSubscriptionModal/PushNotificationsModalContent';
import css from './NotificationsControlForm.css';

const FIELD_NAME = 'push';

export default ({ currentUser, form }) => {
    const oneSignal = useOneSignal();

    const [modalOpen, setModalOpen] = useState(false);
    const [_loading, setLoading] = useState(false);
    const [initiated, setInitiated] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const updateInProgress = useSelector(s => s.user.updateUserProfileInfoInProgress);

    const loading = _loading || updateInProgress || initiated;

    const { isNative } = checkIOS();

    const {
        email,
        profile: {
            privateData: { suppressPushNotifications },
        },
    } = currentUser.attributes;

    const shouldNotify = email === 'benjamin.kroeni@horsedeal.com';

    const initOneSignal = () => {
        if (isNative) {
            /** the native app banner has 5 sec delay */
            setInitiated(true);

            setTimeout(() => {
                setInitiated(false);
            }, 5000);

            /** isNative - send a message to both IOS & Android native apps */
            notifyIosAppOnLogInNoSubscription();
        } else {
            oneSignal.init();
        }
    };
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const checkIfNoSubscriptions = ({ isEnabled, isNotFound, subscriptions }) =>
        Array.isArray(subscriptions) || subscriptions.length === 0 || !isEnabled || isNotFound;

    /**
     * Request info about a user subscription in the
     * OS system and set the initial value for push field
     */
    const requestOneSignalSubscriptions = async () => {
        if (suppressPushNotifications) {
            /** set initial values */
            if(shouldNotify) {
                alert('suppressPushNotifications')
            }
            return form.change(FIELD_NAME, false);
        }

        setLoading(true);

        try {
            const response = await oneSignal.isSubscriptionsEnabled();
            const noSubscriptions = checkIfNoSubscriptions(response);

            if(shouldNotify) {
                alert('noSubscriptions:' + String(noSubscriptions));
                alert(JSON.stringify(response));
            }
            
            if (noSubscriptions) {
                return form.change(FIELD_NAME, false);
            }

            const { isEnabled, error, message } = response;
            if(shouldNotify) {
                alert('isEnabled:' + String(isEnabled));
                alert('message:' + String(message));
            }
            if (error) {
                if (isDev()) console.log(`requestOneSignalSubscriptions.error.${message}`);
                return setError(
                    'The service returned an error while requesting initial subscription info.'
                );
            }

            /** set initial values */
            form.change(FIELD_NAME, isEnabled);
        } catch (e) {
            if (isDev()) console.log(`requestOneSignalSubscriptions.error.${e.message}`);
            setError('The service failed to provide initial subscription info.');
        } finally {
            setLoading(false);
        }
    };
    /**
     * Update sdk user OS suppression field
     * @param {boolean} shouldSuppress - true/false flag
     */
    const manageOsSuppression = async shouldSuppress => {
        setLoading(true);

        try {
            const params = {
                privateData: { suppressPushNotifications: shouldSuppress || null },
            };

            await dispatch(updateUserProfileInfo(params));

            form.change(FIELD_NAME, !shouldSuppress);
        } catch (e) {
            setError('The service failed to manage subscription info.');
        } finally {
            setLoading(false);
        }
    };

    /**
     * If a user is not found, trigger inititalization process via modal;
     * If a user already exists, re-allow the subscriptions via OS service layer
     */
    const handleSuppression = async () => {
        setLoading(true);

        try {
            /** check if there are any subscriptions... */
            const response = await oneSignal.isSubscriptionsEnabled();
            const noSubscriptions = checkIfNoSubscriptions(response);

            /** if a user is not found, initialize the OS process... */
            if (noSubscriptions) {
                closeModal();
                initOneSignal();
                return;
            }

            const { error, message } = response;

            if (error) {
                if (isDev()) console.log(`OS.handleSuppression.error.${message}`);
                setError('The service returned an error while handling subscription.');

                closeModal();
                return;
            }

            /** ...otherwise, re-allow the subscriptions  */
            manageOsSuppression(false);
            closeModal();
        } catch (e) {
            if (isDev()) console.log(`OS.handleSuppression.error.${e.message}`);

            setError('The service failed to handling subscription.');
            closeModal();
        } finally {
            setLoading(false);
        }
    };

    const onFieldChange = () => {
        const { value } = form.getFieldState(FIELD_NAME) || { value: '' };
        /** user already subscribed, suppress the pushes for both web & mob app */
        if (value) return manageOsSuppression(true);
        /**
         * not subscribed yet, handleSuppression calls initOneSignal, then notifyIosAppOnLogIn;
         * pushes suppressed,  re-allow the subscriptions via manageOsSuppression
         */
        if (!value && isNative) return handleSuppression();

        /** !value && isNative */
        openModal();
    };

    const callback = () => {
        closeModal();
        setLoading(false);
    };

    useOneSignalInitEvents({ allowed: !isNative, errorHandler: closeModal });

    useOneSignalLoginEvents({
        allowed: !isNative,
        /** for both scenarios success/failed, just close the modal */
        callback,
        errorHandler: callback,
    });
    /** Listen to a permission granted event dispatched from Ios app */
    useIosWvPermissionGranted({
        callback: () => {
            setLoading(false);
            form.change(FIELD_NAME, true);
        },
        errorHandler: callback,
    });

    useEffect(() => {
        if (email) requestOneSignalSubscriptions();
    }, [email]);

    return (
        <>
            <div className={css.contentBox}>
                {loading && <IconSpinner />}
                <FieldCheckbox
                    isSingleElem
                    reversePosition
                    togglerMode
                    type="checkbox"
                    form={form}
                    id={FIELD_NAME}
                    name={FIELD_NAME}
                    disabled={isNonNativeIOS() || error || loading}
                    onChange={e => {
                        e.preventDefault();
                        onFieldChange();
                    }}
                    label={
                        <p className={css.formLabel}>
                            <span>Alle</span>
                        </p>
                    }
                />
                {error && (
                    <p className={css.error}>
                        {typeof error === 'string'
                            ? error
                            : 'Etwas ist schiefgelaufen. Versuchen Sie es später noch einmal oder wenden Sie sich an den support.'}
                    </p>
                )}
            </div>
            {modalOpen && (
                <PushNotificationsModalContent
                    isOpen
                    onInitialize={handleSuppression}
                    onAbort={closeModal}
                />
            )}
        </>
    );
};
