import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NamedLink,
    SecondaryButton,
    RecommendationItem,
} from '../../components';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { isAdmin } from '../../util/user';

import css from './AdminReviewsPage.css';

import classNames from 'classnames';
import { getAllUsersExternalReviews, handleHttpAction } from '../../util/api';

const formatDateConfig = {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

const AdminReviewsPage = ({ title, currentUser, history, intl }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState(null);
    const [filterBy, setFilterBy] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [total, setTotal] = useState(0);
    const [approved, setApproved] = useState(0);
    const [pending, setPending] = useState(0);

    const userIsAdmin = isAdmin(currentUser);
    const currentUserId = currentUser.id.uuid;

    const request = async (handler, callback) => {
        setError(false);
        setLoading(true);

        try {
            const response = await handler();
            const { message, error, data } = await response.json();

            if (error) {
                throw new Error(message || error);
            }

            callback && callback(data);
        } catch (e) {
            setError(e ? e.error || e.message : 'Something went wrong, try later...');
        } finally {
            setLoading(false);
        }
    };

    const getReviews = () => {
        const handler = () => getAllUsersExternalReviews(currentUserId);
        const callback = data => {
            setReviews(data);
            setTotal(data.length);

            const { length: approvedNum } = data.filter(({ status }) => status === 'approved');

            setApproved(approvedNum);
            setPending(data.length - approvedNum);
        };

        request(handler, callback);
    };

    const updateStatus = (id, status) => {
        const handler = () =>
            handleHttpAction(
                `/api/reviews/external/${currentUserId}/${id}/${status}/update-status`,
                {
                    method: 'PATCH',
                }
            );

        request(handler, getReviews);
    };

    const deleteReview = id => {
        const handler = () =>
            handleHttpAction(`/api/reviews/external/${currentUserId}/${id}/delete`, {
                method: 'DELETE',
            });

        request(handler, getReviews);
    };

    useEffect(() => {
        if (!userIsAdmin) {
            history.push(createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}));
        }
        getReviews();
    }, [userIsAdmin]);

    const reviewsDataExists = reviews && reviews.length > 0 ? reviews : null;

    const applyFilters = reviews =>
        !filterBy ? reviews : reviews.filter(({ status }) => status === filterBy);

    const applySort = reviews =>
        !sortBy ? reviews : reviews.sort((a, b) => new Date(b[sortBy]) - new Date(a[sortBy]));

    const commonButtonProps = {
        disabled: loading,
        inProgress: loading,
    };

    const ActionButton = ({ children, ...rest }) => (
        <SecondaryButton {...rest} {...commonButtonProps} type="button">
            {children}
        </SecondaryButton>
    );

    return (
        <StaticPage
            title={title}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'AdminReviewsPage',
                description: 'Admin external reviews page',
                name: 'Admin external reviews page',
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain className={css.staticPageWrapper}>
                    <h1 className={css.pageTitle}>Review Console</h1>
                    {loading && <IconSpinner />}
                    {error && <p>{error}</p>}

                    <div className={css.contentWrapper}>
                        {reviewsDataExists && (
                            <>
                                <aside className={css.controls}>
                                    <ActionButton
                                        className={classNames({
                                            [css.selected]: !filterBy,
                                        })}
                                        onClick={() => setFilterBy(null)}
                                        type="button"
                                    >
                                        Show all ({total})
                                    </ActionButton>
                                    <ActionButton
                                        className={classNames({
                                            [css.selected]: filterBy === 'approved',
                                        })}
                                        onClick={() => setFilterBy('approved')}
                                        type="button"
                                    >
                                        Show approved ({approved})
                                    </ActionButton>
                                    <ActionButton
                                        className={classNames({
                                            [css.selected]: filterBy === 'pending',
                                        })}
                                        onClick={() => setFilterBy('pending')}
                                        type="button"
                                    >
                                        Show pending ({pending})
                                    </ActionButton>
                                </aside>
                                <aside className={css.controls}>
                                    <ActionButton
                                        className={classNames({
                                            [css.selected]: !sortBy || sortBy === 'updatedAt',
                                        })}
                                        onClick={() => setSortBy('updatedAt')}
                                        type="button"
                                    >
                                        Sort by Updated at
                                    </ActionButton>
                                    <ActionButton
                                        className={classNames({
                                            [css.selected]: sortBy === 'createdAt',
                                        })}
                                        onClick={() => setSortBy('createdAt')}
                                        type="button"
                                    >
                                        Sort by Created at
                                    </ActionButton>
                                </aside>
                            </>
                        )}
                        {reviewsDataExists &&
                            applySort(applyFilters(reviews)).map(
                                (
                                    {
                                        _id,
                                        status,
                                        review,
                                        reviewer_data,
                                        updatedAt,
                                        createdAt,
                                        reviewer_data: { id: reviewerId, email: reviewerEmail },
                                        user_reviewed_data: { id },
                                    },
                                    i
                                ) => (
                                    <div key={i} className={css.infoBlock}>
                                        <section className={css.reviewDateReviewrInfo}>
                                            <div>
                                                <p className={css.status}>
                                                    Status:{' '}
                                                    <span
                                                        className={classNames({
                                                            [css[status]]: !!css[status],
                                                        })}
                                                    >
                                                        {status}
                                                    </span>
                                                </p>
                                                <p className={css.profilePreview}>
                                                    User reviewed:{' '}
                                                    <NamedLink
                                                        name="ExternalReviewProfilePreviewPage"
                                                        params={{ id }}
                                                    >
                                                        Profile
                                                    </NamedLink>
                                                </p>
                                                {reviewerId && (
                                                    <p className={css.profilePreview}>
                                                        Reviewer:{' '}
                                                        <NamedLink
                                                            name="ExternalReviewProfilePreviewPage"
                                                            params={{ id: reviewerId }}
                                                        >
                                                            Profile
                                                        </NamedLink>
                                                    </p>
                                                )}
                                                {!reviewerId && reviewerEmail && (
                                                    <p className={css.profilePreview}>
                                                        Reviewer: {reviewerEmail}
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                {createdAt && (
                                                    <p>
                                                        Created at:{' '}
                                                        {intl.formatDate(
                                                            new Date(createdAt),
                                                            formatDateConfig
                                                        )}
                                                    </p>
                                                )}
                                                {updatedAt && (
                                                    <p>
                                                        Updated at:{' '}
                                                        {intl.formatDate(
                                                            new Date(updatedAt),
                                                            formatDateConfig
                                                        )}
                                                    </p>
                                                )}
                                            </div>
                                        </section>
                                        <RecommendationItem
                                            {...review}
                                            {...reviewer_data}
                                            review={review}
                                        />
                                        <aside className={css.controls}>
                                            {status === 'pending' && (
                                                <ActionButton
                                                    onClick={() => updateStatus(_id, 'approved')}
                                                    type="button"
                                                >
                                                    Approve
                                                </ActionButton>
                                            )}
                                            {status === 'approved' && (
                                                <ActionButton
                                                    onClick={() => updateStatus(_id, 'pending')}
                                                    type="button"
                                                >
                                                    Set as pending
                                                </ActionButton>
                                            )}
                                            <ActionButton
                                                onClick={() => deleteReview(_id)}
                                                type="button"
                                                className={css.deleteAction}
                                            >
                                                Delete
                                            </ActionButton>
                                        </aside>
                                    </div>
                                )
                            )}
                    </div>
                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

const mapStateToProps = state => {
    const { currentUser } = state.user;

    return {
        currentUser,
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(AdminReviewsPage);
