export const CHOOSE_HORSE_STEP = 'chooseHorse';
export const SEND_INQUIRY_STEP = 'sendInquiry';

export const ERROR_ACTIVE_TX_ALREADY_EXISTS = 'activeRequestAlreadyExists';
export const ERROR_NO_AVAILABLE_LISTINGS_FOR_NEW_TX = 'noAvailableListingsForNewTx';
export const ERROR_INQUIRY_DISABLED_FOR_OWNER = 'inquiryDisabledForOwner';
export const ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE =
    'inquiryDisabledForRider.profileIncomplete';
export const ERROR_INQUIRY_DISABLED_FOR_RIDER_NOT_AVAILABLE =
    'inquiryDisabledForRider.riderNotAvailable';
