import React from 'react';
import css from './SponsoredChatLabel.css';
import { IconPresentL } from '../../icons';
import { trimDisplayNameLastWord } from '../../util/text';

export default ({ otherParty, isCustomer }) => {
    const { displayName } = otherParty.attributes.profile;

    const otherPartyName = trimDisplayNameLastWord(displayName);

    return (
        <div className={css.root}>
            <div className={css.roundedBadge}>
                <IconPresentL />
            </div>
            <h4>Geschenkter Treffer</h4>
            {isCustomer ? (
                <p>
                    Wenn {otherPartyName} deine Anfrage nicht innert 14 Tagen akzeptiert, erstatten
                    wir dir 30 Credits vollständig zurück.{' '}
                    <a
                        href="https://support.horsedeal.com/hc/de/articles/23435125893010"
                        target="_blank"
                        rel="nofollow"
                    >
                        Mehr erfahren
                    </a>
                </p>
            ) : (
                <p>
                    Wenn Du diese Anfrage akzeptierst, schenkt dir {otherPartyName} die Credits für
                    den Treffer. Klingt toll, oder?{' '}
                    <a
                        href="https://support.horsedeal.com/hc/de/articles/23435125893010"
                        target="_blank"
                        rel="nofollow"
                    >
                        Mehr erfahren
                    </a>
                </p>
            )}
        </div>
    );
};
