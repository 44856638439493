import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Button, ModalPortal } from '../../components';
import { IconMenu, IconShevronLeft } from '../../icons';
import { withViewport } from '../../util/contextHelpers';
import { authenticationInProgress, logout } from '../../ducks/Auth.duck';
import { ensureCurrentUser } from '../../util/data';
import { isProfileComplete } from '../../util/user';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { getOwnListingsById } from '../../containers/ManageListingsPage/ManageListingsPage.duck';
import { MAX_MOBILE_SCREEN_WIDTH } from '../Topbar/Topbar';
import { parse } from '../../util/urlHelpers';
import {
    handleLogOutSuccess,
    redirectToURLWithModalState,
    redirectToURLWithoutModalState,
} from '../Topbar/Topbar.helpers';
import TopbarMobileMenuModal from './TopbarMobileMenuModal';
import config from '../../config';
import css from './BurgerMenu.css';

const { userTypeRider } = config;

const BurgerMenu = ({ rootClassName, showBackButtonIcon, viewport, currentPage }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onLogout = () => dispatch(logout());
    /** Redux store data */
    const isAuthenticated = useSelector(s => s.Auth.isAuthenticated);
    const currentUser = useSelector(s => s.user.currentUser);
    const unreadMessagesRider = useSelector(s => s.user.currentUserUnreadMessagesRider);
    const unreadMessagesOwner = useSelector(s => s.user.currentUserUnreadMessagesOwner);
    const store = useStore();

    const statusChanged = useSelector(s => s.FavoritePage.wishlistListingStatusChanged);
    const currentPageResultIds = useSelector(s => s.ManageListingsPage.currentPageResultIds);
    const ownEntities = useSelector(s => s.ManageListingsPage.ownEntities);

    const {
        attributes: {
            profile: {
                publicData: { userType, availabilityStatus },
            },
        },
    } = ensureCurrentUser(currentUser);

    const { goBack, location } = history;
    const { mobilemenu } = parse(location.search);

    const userIsRider = userType === userTypeRider;
    const unreadMessages = userIsRider ? unreadMessagesRider : unreadMessagesOwner;
    const notificationCount = isAuthenticated && unreadMessages;
    const authInProgress = authenticationInProgress(store.getState());

    const isMobileLayout = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';

    const { isPrimaryFieldsComplete } = currentUser ? isProfileComplete(currentUser) : {};

    const profileNotComplete =
        (!isPrimaryFieldsComplete || availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE) &&
        userIsRider;

    const hasDraftListing = (
        getOwnListingsById({ ManageListingsPage: { ownEntities } }, currentPageResultIds) || [
            { attributes: { state: null } },
        ]
    ).some(({ attributes }) => attributes.state === 'draft');

    const showNotificationDot =
        (notificationCount && notificationCount > 0) ||
        profileNotComplete ||
        statusChanged ||
        hasDraftListing;

    const handleMobileMenuOpen = () =>
        redirectToURLWithModalState({ history, location }, 'mobilemenu');
    const handleMobileMenuClose = () =>
        redirectToURLWithoutModalState({ history, location }, 'mobilemenu');
    const handleLogOut = () => onLogout().then(() => handleLogOutSuccess(history));
    return (
        <>
            <Button
                rootClassName={classNames(css.menu, {
                    [rootClassName]: !!rootClassName,
                })}
                onClick={showBackButtonIcon ? goBack : handleMobileMenuOpen}
                title="Menu öffnen"
            >
                {showBackButtonIcon ? (
                    <IconShevronLeft />
                ) : (
                    <>
                        <IconMenu rootClassName={css.menuIcon} />
                        {isAuthenticated && showNotificationDot && (
                            <code className={css.notificationDot} />
                        )}
                    </>
                )}{' '}
            </Button>
            <ModalPortal
                id="TopbarMobileMenuModal"
                isOpen={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
                containerClassName={css.modalContainerMobile}
                className={css.topBarModalRoot}
                stickyRefNode="layerRef"
                isSticky
            >
                {!authInProgress && (
                    <TopbarMobileMenuModal
                        isAuthenticated={isAuthenticated}
                        wishlistListingStatusChanged={statusChanged}
                        currentUser={currentUser}
                        onLogout={handleLogOut}
                        currentUserUnreadMessagesRider={unreadMessagesRider}
                        currentUserUnreadMessagesOwner={unreadMessagesOwner}
                        currentPage={currentPage}
                        hasDraftListing={hasDraftListing}
                        profileNotComplete={profileNotComplete}
                        notificationCount={notificationCount}
                    />
                )}
            </ModalPortal>
        </>
    );
};

export default compose(withViewport)(BurgerMenu);
