import React from 'react';
import { Button, SecondaryButton } from '../../components';
import config from '../../config';
import css from './Footer.css';

const { userTypeRider } = config;

export default ({ values, currentUser, commonProps }) => {
    const { recipientsList, bio } = values;
    const { attributes, profileImage } = currentUser;
    const { userType } = attributes.profile.publicData;

    const profileImageDefined = Boolean(profileImage?.id?.uuid);

    const isRider = userType === userTypeRider;
    const isNonEmpty = isRider
        ? Array.isArray(recipientsList) && recipientsList.length > 0
        : !!bio || profileImageDefined;
    /**
     * isLast + recipientsList combination is used for riders;
     * isLast + bio or profileImage combination is used for horseowners;
     */
    return isNonEmpty ? (
        <Button {...commonProps} className={css.lastStepButton}>
            {isRider ? 'Einladung senden' : 'Los geht’s!'}
        </Button>
    ) : (
        <SecondaryButton {...commonProps} className={css.lastStepButton}>
            Überspringen
        </SecondaryButton>
    );
};
