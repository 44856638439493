import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './EditProfileBar.css';
import { EditPencilIcon } from '../../icons';
import classNames from 'classnames';
import config from '../../config';
import { useScrollY } from '../../hooks/useScrollY';

const { userTypeRider, userTypeHorseowner } = config;

const EditProfileBar = ({ scrolled, userType, emailNotVerified }) => {
    const isHorseowner = userType === userTypeHorseowner;

    return (
        <nav
            className={classNames({
                [css.root]: true,
                /** no NavigationBar for horseowner */
                [css.scrolled]: scrolled && !isHorseowner,
                [css.actionBarEmailUnverifiedRider]: !isHorseowner && emailNotVerified,
                [css.actionBarEmailUnverifiedOwner]: isHorseowner && emailNotVerified,
            })}
        >
            <p>
                <FormattedMessage id="ProfileEditRiderPage.editProfileDesc" />
            </p>
            <NamedLink
                className={css.editProfileDesktop}
                name="ProfileSettingsPage"
                params={{ userType: userType || userTypeRider }}
            >
                <EditPencilIcon />
                Bearbeiten
            </NamedLink>
        </nav>
    );
};

export default EditProfileBar;
