import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { compose } from 'redux';

import config from '../../config';
import { fetchOwnerMainListing, updateUserProfileInfo } from '../../ducks/user.duck';
import { ensureCurrentUser } from '../../util/data';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ModalPortal, OutsideClickHandler, Form } from '../../components';
import { Badge } from './Badge';
import { HorseRadio } from './HorseRadio';
import css from './SwitchHorse.css';

const { userTypeHorseowner } = config;

const SwitchHorse = ({
    ownListings,
    currentUser,
    rootClassName,
    onFetchOwnerMainListing,
    onUpdateUserProfileInfo,
    ...rest
}) => {
    const [isMobile] = useIsMobile();
    const [isOpen, setIsOpen] = useState(false);

    const {
        attributes: {
            profile: {
                publicData: { userType, mainHorseId },
            },
        },
    } = ensureCurrentUser(currentUser);

    const userIsOwner = userType === userTypeHorseowner;

    const dataMissed = !userIsOwner || !Array.isArray(ownListings) || ownListings.length === 0;

    if (dataMissed) {
        return null;
    }

    const selectedHorseId = mainHorseId || ownListings[0].id.uuid;
    const mainHorseSelected =
        mainHorseId &&
        Array.isArray(ownListings) &&
        ownListings.length > 0 &&
        ownListings.some(({ id: { uuid } }) => uuid === mainHorseId);

    const mainListing = mainHorseId
        ? ownListings.find(({ id: { uuid } }) => uuid === mainHorseId) || ownListings[0]
        : ownListings[0];

    const handleHorseSelect = ({ dirty, values: { mainHorseId: id } }) => {
        if (!dirty) return;
        if (!id) return;

        const isSelected = selectedHorseId === id;
        if (isSelected) return setIsOpen(false);

        const currentUserDataUpd = { ...currentUser };
        currentUserDataUpd.attributes.profile.publicData.mainHorseId = id;

        onUpdateUserProfileInfo({ publicData: { mainHorseId: id } }, currentUserDataUpd).then(() =>
            onFetchOwnerMainListing()
        );
    };

    const renderWrapperComponent = children =>
        isMobile ? (
            <ModalPortal
                id="switch-horse"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                containerClassName={css.modalContainer}
                contentClassName={css.modalContent}
                containerClassNameJoined
            >
                <h4>Pferd auswählen</h4>
                <p>
                    Wähle das Pferd aus, für welches Du gerade eine:n Reiter:in oder Aushilfe
                    suchst.
                </p>
                {children}
            </ModalPortal>
        ) : (
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)} className={css.overlay}>
                <section className={css.popup}>{children}</section>
            </OutsideClickHandler>
        );

    return (
        <section
            className={classNames(css.root, {
                [rootClassName]: !!rootClassName,
            })}
        >
            <Badge
                mainListing={mainListing}
                isSelected={mainHorseSelected}
                onClick={() => setIsOpen(prevState => !prevState)}
                {...rest}
            />

            {isOpen &&
                renderWrapperComponent(
                    <FinalForm
                        onSubmit={() => null}
                        initialValues={{ mainHorseId: selectedHorseId }}
                        render={({ form, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormSpy
                                    onChange={handleHorseSelect}
                                    subscription={{ values: true, dirty: true }}
                                />
                                <div className={css.selectedHorseForm}>
                                    {ownListings.map(listing => (
                                        <HorseRadio
                                            key={`${selectedHorseId}-${listing.id.uuid}`}
                                            listing={listing}
                                            form={form}
                                            {...rest}
                                        />
                                    ))}
                                </div>
                            </Form>
                        )}
                    />
                )}
        </section>
    );
};
const mapStateToProps = ({ ManageListingsPage, Assets, user: { currentUser } }) => {
    const ownListingsData = ManageListingsPage.ownEntities;
    const { assetsLoadingRequests } = Assets;

    const ownListings = Object.values(ownListingsData?.ownListing || {})
        ?.filter(({ attributes }) => attributes?.state === 'published')
        .map(listing => ({
            ...listing,
            image: ownListingsData?.image?.[listing?.relationships?.images?.data?.[0]?.id?.uuid],
        }));

    return {
        ownListings,
        currentUser,
        assetsLoadingRequests,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    onUpdateUserProfileInfo: (params, dataToInsert) =>
        dispatch(updateUserProfileInfo(params, dataToInsert)),
    onFetchOwnerMainListing: () => dispatch(fetchOwnerMainListing()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SwitchHorse);
