import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../../../components';

import css from './AcceptInquirySection.css';
import { getActionButtonStateFromTx, txIsAccepted, txIsEnquired } from '../../../util/transaction';

const organizeTxAsListingId = (ownListings = {}) => (acc, tx) => {
    const {
        relationships: { listing },
        attributes: {
            protectedData: { listingSubstitutionId },
        },
    } = tx;
    const listingId = listingSubstitutionId || listing.data.id.uuid;

    if (!ownListings[listingId]) return acc;

    if (!acc[listingId]) acc[listingId] = [];

    return { ...acc, [listingId]: [...acc[listingId], tx] };
};

export default ({ disabled, onInquiryProcessInitiating, publicUser }) => {
    /** Redux store data */
    const allTransactions = useSelector(s => s.marketplaceData.entities.transaction);
    const ownListings = useSelector(s => s.ManageListingsPage?.ownEntities?.ownListing);
    /**
     * Draft or pending approval listing cannot have a transaction,
     * such transactions will give truthful result for
     * noTransactionsListing check, which is not correct;
     *
     * closed listing potentially can participate in previously inquired transactions;
     */
    const ownListingsWithTxPotential = Object.entries(ownListings || {}).reduce(
        (acc, [listingId, listing]) =>
            listing?.attributes?.state === 'published' || listing?.attributes?.state === 'closed'
                ? { ...acc, [listingId]: listing }
                : acc,
        {}
    );
    /**
     * Take into account only those transactions where
     * the public user (rider) is either customer or provider
     */
    const publicUserId = publicUser?.id?.uuid;
    const publicUserTransactions =
        typeof allTransactions === 'object'
            ? Object.entries(allTransactions).reduce(
                  (acc, [txId, tx]) =>
                      tx?.relationships?.customer?.data?.id?.uuid === publicUserId ||
                      tx?.relationships?.provider?.data?.id?.uuid === publicUserId
                          ? { ...acc, [txId]: tx }
                          : acc,
                  {}
              )
            : null;
    /** [listingId]: [tx1, tx2, ...] */
    const allListingsInTxsDictionary =
        typeof allTransactions === 'object'
            ? Object.values(publicUserTransactions).reduce(
                  organizeTxAsListingId(ownListingsWithTxPotential),
                  {}
              )
            : {};
    /** Listing that have no accepted/enquired/declined/expired  transactions yet */
    const noTransactionsListing = Object.values(allListingsInTxsDictionary).reduce(
        (acc, listingTransactions) => (acc ? acc : listingTransactions.length === 0),
        false
    );
    /** Listings that an owner may initiate a request to rider with */
    const spareListings = Object.entries(allListingsInTxsDictionary).reduce(
        (acc, [listingId, txs]) =>
            txs.some(tx => txIsEnquired(tx) || txIsAccepted(tx))
                ? acc
                : { ...acc, [listingId]: txs },
        {}
    );
    /**
     * Enquired or accepted transactions;
     * here we ignore expired transactions and
     * expect a user to be redirected to the latest tx
     */
    const allTransactionsAreEnquired = Object.values(spareListings).length === 0;

    const recentTransaction = allTransactionsAreEnquired
        ? Object.values(allListingsInTxsDictionary)
              .flatMap(s => s)
              .sort((a, b) => b.attributes.lastTransitionedAt - a.attributes.lastTransitionedAt)[0]
        : null;

    const isSingleListing = Object.values(ownListingsWithTxPotential).length === 1;

    const transactions = isSingleListing
        ? Object.values(allListingsInTxsDictionary)[0]
        : noTransactionsListing
        ? []
        : [recentTransaction];

    const { text, state } = getActionButtonStateFromTx(transactions);

    return (
        <Button
            type="button"
            disabled={disabled}
            onClick={() => onInquiryProcessInitiating(state)}
            className={css[state]}
        >
            {text}
        </Button>
    );
};
