import React from 'react';
/** icon-eye-l */
/** icon-eye */
export default ({ clickHandler = () => null }) => (
    <svg
        onClick={clickHandler}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M11.280 5.282 C 8.970 5.434,6.385 6.564,4.009 8.461 C 3.044 9.232,1.862 10.385,1.590 10.824 C 1.143 11.542,1.152 12.520,1.612 13.204 C 1.798 13.480,2.418 14.138,2.985 14.660 C 5.256 16.754,7.931 18.195,10.293 18.599 C 11.907 18.875,13.559 18.750,15.160 18.232 C 17.497 17.476,20.163 15.701,21.984 13.689 C 22.578 13.033,22.731 12.679,22.730 11.959 C 22.729 11.289,22.551 10.915,21.903 10.219 C 20.108 8.288,17.594 6.603,15.360 5.833 C 14.453 5.521,13.585 5.344,12.640 5.280 C 12.007 5.238,11.957 5.238,11.280 5.282 M13.492 6.874 C 14.445 7.059,15.236 7.336,16.260 7.842 C 17.904 8.655,19.518 9.858,20.797 11.225 C 21.163 11.617,21.241 11.755,21.238 12.009 C 21.236 12.259,21.178 12.357,20.782 12.784 C 19.045 14.660,16.605 16.241,14.439 16.894 C 13.535 17.167,13.144 17.219,12.000 17.219 C 11.111 17.219,10.920 17.207,10.513 17.130 C 8.585 16.761,6.471 15.670,4.540 14.046 C 4.001 13.593,2.885 12.462,2.818 12.301 C 2.743 12.122,2.744 11.875,2.820 11.694 C 2.898 11.508,4.025 10.383,4.660 9.857 C 6.558 8.285,8.692 7.197,10.493 6.881 C 11.147 6.767,11.140 6.767,12.140 6.779 C 12.876 6.788,13.146 6.807,13.492 6.874 M11.380 7.961 C 10.792 8.052,10.080 8.350,9.590 8.710 C 9.220 8.983,9.007 9.193,8.749 9.539 C 7.617 11.059,7.650 13.090,8.831 14.563 C 9.885 15.878,11.704 16.402,13.317 15.855 C 15.044 15.269,16.185 13.569,16.065 11.760 C 15.946 9.968,14.734 8.501,13.005 8.053 C 12.572 7.941,11.796 7.897,11.380 7.961 M12.660 9.505 C 13.317 9.674,13.956 10.181,14.268 10.782 C 14.485 11.200,14.569 11.534,14.570 11.984 C 14.571 12.418,14.515 12.682,14.340 13.066 C 13.912 14.003,13.023 14.576,12.000 14.573 C 10.616 14.568,9.541 13.554,9.418 12.138 C 9.362 11.491,9.667 10.690,10.157 10.200 C 10.834 9.523,11.745 9.270,12.660 9.505 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
