import React from 'react';
import css from './LastActivityCaption.css';
import { NamedLink, Tooltip } from '../../../components';
import { IconClockS, IconLockS } from '../../../icons';
import { ConditionalWrapper } from '../../../util/common';
import { datediff } from '../../../util/time';

export default ({ currentUser, publicUser, isSameUser }) => {
    const isLoggedIn = currentUser && currentUser.id;

    const calculateActivityState = () => {
        const { lastVisitedAt } = publicUser.attributes.profile.publicData;

        const { hours, days } = datediff(new Date(lastVisitedAt), new Date());
        const totalHours = hours + days * 24;

        if (totalHours <= 24) {
            return { text: 'Heute', className: 'justNow' };
        }
        if (totalHours > 24 && totalHours <= 48) {
            return { text: 'Gestern', className: 'justNow' };
        }
        if (totalHours > 48 && totalHours <= 168) {
            return { text: 'Diese Woche', className: 'justNow' };
        }
        if (totalHours > 168 && totalHours <= 336) {
            return { text: 'Letzte Woche', className: 'recently' };
        }
        if (totalHours > 336 && totalHours <= 672) {
            return { text: 'Diesen Monat', className: 'recently' };
        }
        if (totalHours > 672 && totalHours <= 1344) {
            return { text: 'Letzten Monat', className: 'recently' };
        }
        return { text: 'Mehr als 1 Monat', className: 'longTimeAgo' };
    };

    const renderCaptionContent = () => {
        if (!isLoggedIn) {
            return (
                <NamedLink name="SignupHorseowner" className={css.loggedOutLink}>
                    <IconLockS /> Letzte Aktivität ansehen
                </NamedLink>
            );
        }

        const { text, className } = calculateActivityState();

        return (
            <>
                <IconClockS /> Letzte Aktivität: <span className={css[className]}>{text}</span>
            </>
        );
    };

    return (
        // <ConditionalWrapper
        //     condition={isSameUser}
        //     wrapper={children => (
        //         <Tooltip
        //             position="top"
        //             content={
        //                 <>
        //                     <p>Anfragen nicht möglich</p>
        //                     <p>Du kannst dir selbst keine Anfrage senden.</p>
        //                 </>
        //             }
        //         >
        //             {children}
        //         </Tooltip>
        //     )}
        // >
        <p className={css.root}>{renderCaptionContent()}</p>
        //  </ConditionalWrapper>
    );
};
