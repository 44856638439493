import React, { useState } from 'react';
import classNames from 'classnames';

import { ActivityFeed, ModalPortal, ImageCarousel, IconSpinner } from '../../components';
import { txIsEnquired } from '../../util/transaction';

import css from './FeedSection.css';

// Functional component as a helper to build ActivityFeed section
export default ({
    oldestMessagePageFetched,
    fetchMessagesInProgress,
    initialMessageFailed,
    fetchMessagesError,
    currentTransaction,
    totalMessagePages,
    rootClassName,
    className,
    messages,
    ...rest
}) => {
    const [imageCarouselVisible, setImageCarouselVisibility] = useState(false);
    const [imageCarouselSrc, setImageCarouselSrc] = useState(null);

    const handleImageCarouselVisibility = src => {
        if (typeof src === 'string') {
            setImageCarouselSrc(src);
            setImageCarouselVisibility(true);
        }
    };

    const classes = classNames(rootClassName, className);
    const feedClasses = classNames(css.feed, {
        [css.enquired]: txIsEnquired(currentTransaction),
    });

    const selectedImageIndex = 0; /** one image per message, so index is always zero */
    const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;
    const txTransitions = currentTransaction.attributes.transitions
        ? currentTransaction.attributes.transitions
        : [];

    const showFeed =
        messages.length > 0 ||
        txTransitions.length > 0 ||
        initialMessageFailed ||
        fetchMessagesError;

    if (fetchMessagesInProgress) {
        return <IconSpinner />;
    }

    if (!showFeed) {
        return null;
    }

    return (
        <div className={classes}>
            {initialMessageFailed ? (
                <p className={css.messageError}>
                    Whoops, es konnte keine Nachricht gesendet werden.
                </p>
            ) : null}
            {fetchMessagesError ? (
                <p className={css.messageError}>
                    Da ist etwas beim Laden der Nachrichten schief gelaufen. Bitte lade die Seite
                    neu und versuche es nochmals.{' '}
                </p>
            ) : null}
            <ActivityFeed
                className={feedClasses}
                messages={messages}
                transaction={currentTransaction}
                hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
                fetchMessagesInProgress={fetchMessagesInProgress}
                handleImageCarouselVisibility={handleImageCarouselVisibility}
                {...rest}
            />
            <ModalPortal
                id="FeedSection.imageCarousel"
                scrollLayerClassName={css.carouselModalScrollLayer}
                containerClassName={css.carouselModalContainer}
                onClose={() => setImageCarouselVisibility(false)}
                isOpen={imageCarouselVisible}
                lightCloseButton
            >
                {imageCarouselVisible && (
                    <ImageCarousel
                        selectedImageIndex={selectedImageIndex}
                        showIndex={false}
                        images={[imageCarouselSrc]}
                    />
                )}
            </ModalPortal>
        </div>
    );
};
