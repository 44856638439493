import React, { useEffect, useState } from 'react';
import { Slide } from 'pure-react-carousel';

import { IconSpinner, ListingCardsCarousel, NamedLink } from '../../../../components';
import { getRecentTransactions } from '../../../../util/api';
import { getTimeDiffRepresentation } from '../../../../util/time';

import css from './SectionAcceptedTransactions.css';

const today = new Date();

const TransactionCard = ({ transaction, isDesktop, isLast }) => {
    const {
        lastTransitionedAt,
        customer: {
            id: { uuid: customerId },
            attributes: {
                publicData: { userType: customerUserType },
            },
        },
        rider: {
            attributes: { firstName: riderName, profileImage },
        },
        horseowner: {
            attributes: { firstName: ownerName },
        },
        listing: {
            attributes: {
                title,
                publicData: { mainListingImage },
            },
        },
    } = transaction;

    const { days, hours, minutes } = getTimeDiffRepresentation(
        new Date(lastTransitionedAt),
        today,
        {
            prexif: 'Vor',
        }
    );

    const riderImageVariants = profileImage?.attributes?.variants || {};
    const riderProfileImage = (Object.values(riderImageVariants)[0] || {}).url;
    const riderPlaceholder = (riderName || '').split('')[0];
    const horsePlaceholder = (title || '').split('')[0];

    const showDelimiter = Boolean(isDesktop && !isLast);

    return (
        <div className={css.cardWrapper}>
            <NamedLink
                isNotRouterLink
                name="ProfilePagePreview"
                params={{ id: customerId, userType: customerUserType }}
            >
                <div className={css.cardContainer}>
                    <div className={css.cardImagesHolder}>
                        {riderProfileImage ? (
                            <img className={css.riderImage} src={riderProfileImage} />
                        ) : (
                            <div className={css.riderImagePlaceholder}>{riderPlaceholder}</div>
                        )}

                        {mainListingImage ? (
                            <img className={css.horseImage} src={mainListingImage} />
                        ) : (
                            <div className={css.horseImagePlaceholder}>{horsePlaceholder}</div>
                        )}
                    </div>
                    <p className={css.cardMatchBadge}>Treffer</p>
                    <p className={css.cardMatchParties}>
                        {riderName} und {ownerName}
                    </p>
                    <p className={css.cardMatchDate}>
                        {' '}
                        {(days || hours || minutes || '').replace(/Tage/, 'Tagen')}
                    </p>
                </div>
            </NamedLink>
            {showDelimiter && <div className={css.delimiter} />}
        </div>
    );
};

const SectionContent = ({ sliderVisibleSlides, isMobile, isTablet, transactions }) => (
    <section className={css.root}>
        <div className={css.content}>
            <h2 className={css.heading}>Neue Treffer</h2>
            <p>Akzeptierte Anfragen der letzten 30 Tage.</p>
        </div>
        <ListingCardsCarousel
            showDots={sliderVisibleSlides < 3}
            sliderVisibleSlides={sliderVisibleSlides}
            sliderClassName={css.sliderOuterContainer}
            naturalSlideWidth={284}
            listings={transactions}
            isMobile={isMobile}
            key={isMobile}
            hideButtonsOnEndStart
            minListingsCount={1}
            rendererSingleItem={tx => <TransactionCard key="single" transaction={tx} />}
            rendererCarousel={(tx, index) => (
                <Slide index={index} key={index}>
                    <TransactionCard
                        transaction={tx}
                        isLast={index === transactions.length - 1}
                        isDesktop={Boolean(!isMobile && !isTablet)}
                        total={transactions.length}
                    />
                </Slide>
            )}
        />
    </section>
);

export default ({ isMobile, isTablet, computing }) => {
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState(null);

    const requestRecentTransactions = async () => {
        setLoading(true);

        try {
            const response = await getRecentTransactions();
            const { transactions } = await response.json();
            setTransactions(
                transactions.sort(
                    (a, b) => new Date(b.lastTransitionedAt) - new Date(a.lastTransitionedAt)
                )
            );
        } catch (e) {
            // do nothing
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        requestRecentTransactions();
    }, []);

    if (loading || computing) {
        return <IconSpinner />;
    }

    const dataAvailable = Array.isArray(transactions) && transactions.length > 0;

    const sliderVisibleSlides = isMobile ? 1 : isTablet ? 2 : 4;

    return dataAvailable ? (
        <SectionContent
            transactions={transactions}
            isMobile={isMobile}
            isTablet={isTablet}
            sliderVisibleSlides={sliderVisibleSlides}
        />
    ) : null;
};
