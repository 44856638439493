import React, { useState } from 'react';
import { compose } from 'redux';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Alert, Form, PromptUserInfo, UploadUserAvatar } from '../../components';
import config from '../../config';
import { EditUserDescriptionForm } from '../../forms';

import css from './CollectUserInfoWizard.css';
import { Footer } from './Footer';

const { userTypeHorseowner } = config;

const CollectUserAboutForm = ({
    promptUserBio,
    navigateToPreviousForm,
    updateInfoInProgress,
    uploadImageError,
    uploadInProgress,
    onImageUpload,
    currentUser,
    intl,
    profileImage: profileImageFromStore,
    promptUserBioInProgress,
    onUpdateProfile,
    onPromptUserBio,
    ...restProps
}) => {
    const [generated, setGenerated] = useState(false);

    return (
        <FinalForm
            {...restProps}
            render={fieldRenderProps => {
                const { handleSubmit, form, values } = fieldRenderProps;
                const { profileImage } = currentUser;
                const { bio, profileImage: profileImageFieldValue } = values;
                const { valid: bioValid } = form.getFieldState('bio') || {};
                const {
                    attributes: {
                        profile: {
                            publicData: { userType },
                        },
                    },
                } = currentUser;

                const isHorseOwner = userType === userTypeHorseowner;
                const disabled = isHorseOwner
                    ? bio
                        ? !bioValid
                        : false
                    : !values ||
                      (bio && !bioValid) ||
                      (!profileImageFieldValue && !profileImage) ||
                      (isHorseOwner ? false : !bio);

                return (
                    <>
                        <Form onSubmit={handleSubmit} className={css.form}>
                            <h2 className={css.collectInfoHeader}>
                                <FormattedMessage
                                    id={`CollectUserInfoWizard.aboutHeader${
                                        isHorseOwner ? '-optional' : ''
                                    }`}
                                />
                            </h2>
                            <p className={css.collectInfoDescription}>
                                <FormattedMessage id="CollectUserInfoWizard.aboutDescription" />
                            </p>
                            <div className={css.uploadSection}>
                                <UploadUserAvatar
                                    form={form}
                                    currentUser={currentUser}
                                    profileImage={profileImageFromStore}
                                    onImageUpload={onImageUpload}
                                    onUpdateProfile={onUpdateProfile}
                                    uploadInProgress={uploadInProgress}
                                    uploadImageError={uploadImageError}
                                    previewAllowed
                                />
                            </div>
                            {generated && !promptUserBioInProgress && (
                                <Alert
                                    rootClassName={css.generateTextAlert}
                                    header="CollectUserInfoWizard.aboutTextAlertHeading"
                                    description="CollectUserInfoWizard.aboutTextAlertDesc"
                                    type="success"
                                    closeAllowed
                                />
                            )}
                            <div className={css.generateTextSection}>
                                <EditUserDescriptionForm
                                    bio={bio}
                                    disabled={promptUserBioInProgress}
                                />
                            </div>
                            {!isHorseOwner && (
                                <PromptUserInfo
                                    form={form}
                                    bio={bio}
                                    onPromptUserBio={onPromptUserBio}
                                    promptUserBio={promptUserBio}
                                    promptUserBioInProgress={promptUserBioInProgress}
                                    notifyOnChange={value => setGenerated(value)}
                                />
                            )}
                        </Form>
                        <Footer
                            disabled={disabled}
                            navigateToPreviousForm={navigateToPreviousForm}
                            updateInfoInProgress={updateInfoInProgress || promptUserBioInProgress}
                            handleSubmit={() => restProps.onSubmit(values)}
                            values={values}
                            isLast={isHorseOwner}
                            currentUser={currentUser}
                        />
                    </>
                );
            }}
        />
    );
};

CollectUserAboutForm.propTypes = {
    rootClassName: string,
    className: string,
    onSubmit: func.isRequired,
};

export default compose(injectIntl)(CollectUserAboutForm);
