import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import config from '../../config';
import { Alert, Button, FieldTextInputHighlighted } from '../../components';
import {
    composeValidators,
    emailRegExp,
    hyperlinkRegExp,
    noExternalContactDetails,
    phoneNumRegExp,
    required,
} from '../../util/validators';
import css from './StepSendInquiryForm.css';
import SponsoredTransactionBlock from './SponsoredTransactionBlock';

const mapper = {
    insufficient: 'failure',
    sufficient: 'warning',
    total: 'success',
};

const { allowedDomains } = config;

const regExpArray = [hyperlinkRegExp, emailRegExp, phoneNumRegExp, /www\./g].concat(
    allowedDomains.map(domain => new RegExp(`[\\d\\w]{1,}\\.${domain}`, 'gi'))
);

export default ({
    publicUserName,
    matchingScore,
    inProgress,
    onSubmit,
    disabled,
    values,
    form,
}) => {
    const [submitted, setSubmitted] = useState(false);
    // to prevent multiple submit on quick tap
    const debouncedSubmit = debounce(onSubmit, 1000, { leading: false });

    return (
        <>
            <Alert
                header={`InquiryModal.alertHeading-${matchingScore}`}
                description={`InquiryModal.alertDesc-${matchingScore}`}
                closeAllowed={false}
                type={mapper[matchingScore]}
                rootClassName={css.alert}
            />
            <SponsoredTransactionBlock
                form={form}
                publicUserName={publicUserName}
                disabled={submitted}
            />
            <aside>
                <FieldTextInputHighlighted
                    id="comment"
                    name="comment"
                    type="textarea"
                    placeholder="Nachricht"
                    disabled={submitted}
                    maxLength={1000}
                    field={values.comment || ''}
                    regExpArray={regExpArray}
                    validate={composeValidators(
                        required('Dieses Feld wird benötigt'),
                        noExternalContactDetails(
                            'Entferne externe Links, Telefonnummern und E-Mail-Adressen.'
                        )
                    )}
                />
            </aside>
            <footer>
                <p className={css.sidenote}>
                    {publicUserName} kann deine Anfrage innert 14 Tagen akzeptieren oder ablehnen.
                </p>
                <Button
                    type="button"
                    disabled={disabled || submitted}
                    inProgress={inProgress || submitted}
                    onClick={() => {
                        setSubmitted(true);
                        debouncedSubmit();
                    }}
                >
                    Anfrage senden
                </Button>
            </footer>
        </>
    );
};
