import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, SecondaryButton, ReviewModal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './AcceptDeclineRequestButtonsMaybe.css';
import { parse } from '../../util/urlHelpers';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FILTER_SEARCH_PARAM } from '../../containers/TransactionPage/components/Filters';
import { useParamFilters } from '../../hooks/useParamFilters';
import { trimDisplayNameLastWord } from '../../util/text';
import { IconClockL } from '../../icons';
import AcceptDeclineRequestButtonsError from './AcceptDeclineRequestButtonsError';

const AcceptDeclineRequestButtonsMaybe = ({
    onRespondOnCustomerInquiry,
    inquiryError,
    revieweeName,
    currentUser,
    currentListing,
    otherParty,
    match,
    location,
    history,
}) => {
    const transactionRequestInProgress = useSelector(
        s => s.TransactionPage.transactionRequestInProgress
    );
    const { id } = match.params;
    const {
        attributes: {
            profile: {
                displayName,
                publicData: { userType: otherPartyUserType },
            },
        },
    } = otherParty;
    const { feedback: declineModalVisible } = parse(location.search) || {};

    const otherPartyName = trimDisplayNameLastWord(displayName);

    const filters = useParamFilters(FILTER_SEARCH_PARAM, location);
    const hasFilters = filters && filters.length > 0;

    const setDeclineModalVisibility = () => {
        if (declineModalVisible) {
            history.goBack();
        } else {
            const pagename = id ? 'OrderDetailsPage' : 'OrderMessagesPage';

            history.push(
                createResourceLocatorString(
                    pagename,
                    routeConfiguration(),
                    { id },
                    hasFilters
                        ? {
                              [FILTER_SEARCH_PARAM]: filters.join(encodeURIComponent(',')),
                              feedback: 'true',
                          }
                        : { feedback: 'true' }
                )
            );
        }
    };

    return (
        <div className={css.acceptDeclineBtnSection}>
            <div className={css.roundedBadge}>
                <IconClockL />
            </div>
            <h4>Ausstehende Anfrage von {otherPartyName}</h4>
            <p>
                Du hast 14 Tage Zeit die Anfrage zu akzeptieren. Sobald Du die Anfrage akzeptiert
                hast, kannst Du {otherPartyName} antworten.
            </p>
            <aside className={css.buttonsSection}>
                <Button
                    onClick={() => onRespondOnCustomerInquiry()}
                    inProgress={transactionRequestInProgress}
                >
                    <FormattedMessage id="TransactionPanel.acceptInquiryByProvider" />
                </Button>
                <SecondaryButton
                    onClick={() => setDeclineModalVisibility(true)}
                    inProgress={transactionRequestInProgress}
                >
                    <FormattedMessage id="TransactionPanel.declineInquiryByProvider" />
                </SecondaryButton>
            </aside>
            <ReviewModal
                id="ReviewOrderModal"
                isOpen={declineModalVisible}
                onClose={setDeclineModalVisibility}
                onRespondOnCustomerInquiry={async ({ feedback, reason }) => {
                    await onRespondOnCustomerInquiry(feedback, reason);
                    setDeclineModalVisibility();
                }}
                revieweeName={revieweeName}
                otherPartyUserType={otherPartyUserType}
                otherParty={otherParty}
                currentUser={currentUser}
                currentListing={currentListing}
                inquiryError={inquiryError}
            />
            <AcceptDeclineRequestButtonsError />
        </div>
    );
};

export default compose(withRouter)(AcceptDeclineRequestButtonsMaybe);
