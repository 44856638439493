import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { sendVerificationCode, updateCreditsHistory } from '../../util/api';
import { CREDITS_ACTION_PROFILE_VERIFIED } from '../../credits-config';

// ================ Action types ================ //

export const SEND_VERIFICATION_OTP_REQUEST = 'app/ContactDetailsPage/SEND_VERIFICATION_OTP_REQUEST';
export const SEND_VERIFICATION_OTP_SUCCESS = 'app/ContactDetailsPage/SEND_VERIFICATION_OTP_SUCCESS';
export const SEND_VERIFICATION_OTP_ERROR = 'app/ContactDetailsPage/SEND_VERIFICATION_OTP_ERROR';

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ContactDetailsPage/SAVE_PHONE_NUMBER_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

export const RESET_PASSWORD_REQUEST = 'app/ContactDetailsPage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/ContactDetailsPage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/ContactDetailsPage/RESET_PASSWORD_ERROR';

// ================ Reducer ================ //

const initialState = {
    saveEmailError: null,
    savePhoneNumberError: null,
    saveContactDetailsInProgress: false,
    contactDetailsChanged: false,
    resetPasswordInProgress: false,
    resetPasswordError: null,
    sendVerificationOtpInProgress: false,
    sendVerificationOtpError: null,
    verificationCode: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SAVE_CONTACT_DETAILS_REQUEST:
            return {
                ...state,
                saveContactDetailsInProgress: true,
                saveEmailError: null,
                savePhoneNumberError: null,
                contactDetailsChanged: false,
            };
        case SAVE_CONTACT_DETAILS_SUCCESS:
            return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
        case SAVE_EMAIL_ERROR:
            return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
        case SAVE_PHONE_NUMBER_ERROR:
            return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };

        case SAVE_CONTACT_DETAILS_CLEAR:
            return {
                ...state,
                saveContactDetailsInProgress: false,
                sendVerificationOtpInProgress: false,
                saveEmailError: null,
                savePhoneNumberError: null,
                contactDetailsChanged: false,
                sendVerificationOtpError: null,
            };

        case RESET_PASSWORD_REQUEST:
            return { ...state, resetPasswordInProgress: true, resetPasswordError: null };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, resetPasswordInProgress: false };
        case RESET_PASSWORD_ERROR:
            console.error(payload); // eslint-disable-line no-console
            return { ...state, resetPasswordInProgress: false, resetPasswordError: payload };
        case SEND_VERIFICATION_OTP_REQUEST:
            return {
                ...state,
                sendVerificationOtpInProgress: true,
                sendVerificationOtpError: null,
            };
        case SEND_VERIFICATION_OTP_SUCCESS:
            return {
                ...state,
                sendVerificationOtpInProgress: false,
                verificationCode: payload.code,
            };
        case SEND_VERIFICATION_OTP_ERROR:
            return {
                ...state,
                sendVerificationOtpInProgress: false,
                sendVerificationOtpError: payload.error,
            };
        default:
            return state;
    }
}

// ================ Action creators ================ //
export const verificationOtpSendingInProgress = s => s.user.sendVerificationOtpInProgress;

export const sendVerificationOtpRequest = () => ({
    type: SEND_VERIFICATION_OTP_REQUEST,
});

export const sendVerificationOtpSuccess = code => ({
    type: SEND_VERIFICATION_OTP_SUCCESS,
    payload: { code },
});

export const sendVerificationOtpError = error => ({
    type: SEND_VERIFICATION_OTP_ERROR,
    error: true,
    payload: { error },
});

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveEmailError = error => ({
    type: SAVE_EMAIL_ERROR,
    payload: error,
    error: true,
});

export const savePhoneNumberError = error => ({
    type: SAVE_PHONE_NUMBER_ERROR,
    payload: error,
    error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

export const resetPasswordRequest = () => ({ type: RESET_PASSWORD_REQUEST });

export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });

export const resetPasswordError = e => ({
    type: RESET_PASSWORD_ERROR,
    error: true,
    payload: e,
});

// ================ Thunks ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */
const requestSavePhoneNumber = params => (dispatch, getState, sdk) => {
    const phoneNumber = params.phoneNumber;
    const phoneVerified = params.phoneVerified;

    return sdk.currentUser
        .updateProfile(
            {
                protectedData: { phoneNumber, phoneVerified },
                /* here we double set phoneVerified field in both protectedData & publicData
          in order for other users may see that current user has his phone number virified
          e.g. on ProfilePage
        */
                publicData: { phoneVerified },
            },
            {
                expand: true,
                include: ['profileImage'],
                'fields.image': ['variants.square-small', 'variants.square-small2x'],
            }
        )
        .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
                throw new Error(
                    'Expected a resource in the sdk.currentUser.updateProfile response'
                );
            }

            const currentUser = entities[0];
            return currentUser;
        })
        .catch(e => {
            dispatch(savePhoneNumberError(storableError(e)));
            // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
            // action will not be fired
            throw e;
        });
};

/**
 * Make a email update request to the API and return the current user.
 */
export const requestSaveEmail = params => (dispatch, getState, sdk) => {
    const { email, currentPassword } = params;

    return sdk.currentUser
        .changeEmail(
            { email, currentPassword },
            {
                expand: true,
                include: ['profileImage'],
                'fields.image': ['variants.square-small', 'variants.square-small2x'],
            }
        )
        .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
                throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
            }

            const currentUser = entities[0];
            return currentUser;
        })
        .catch(e => {
            dispatch(saveEmailError(storableError(e)));
            // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
            // action will not be fired
            throw e;
        });
};

/**
 * Save email and update the current user.
 */
const saveEmail = params => (dispatch, getState, sdk) => {
    return dispatch(requestSaveEmail(params))
        .then(user => {
            dispatch(currentUserShowSuccess(user));
            dispatch(saveContactDetailsSuccess());
        })
        .catch(e => {
            dispatch(saveEmailError(e.message || 'Something went wrong. Try later.'));
        });
};

/**
 * Save phone number and update the current user.
 */
const savePhoneNumber = params => (dispatch, getState, sdk) => {
    return dispatch(requestSavePhoneNumber(params))
        .then(user => {
            dispatch(currentUserShowSuccess(user));
            dispatch(saveContactDetailsSuccess());
            return;
        })
        .catch(e => {
            dispatch(savePhoneNumberError(e.message || 'Something went wrong. Try later.'));
        });
};

/**
 * Save email and phone number and update the current user.
 */
const saveEmailAndPhoneNumber = params => (dispatch, getState, sdk) => {
    const { email, phoneNumber, currentPassword } = params;

    // order of promises: 1. email, 2. phone number
    const promises = [
        dispatch(requestSaveEmail({ email, currentPassword })),
        dispatch(requestSavePhoneNumber({ phoneNumber })),
    ];

    return Promise.all(promises)
        .then(values => {
            // Array of two user objects is resolved
            // the first one is from the email update
            // the second one is from the phone number update

            const saveEmailUser = values[0];
            const savePhoneNumberUser = values[1];

            // merge the protected data from the user object returned
            // by the phone update operation
            const protectedData = savePhoneNumberUser.attributes.profile.protectedData;
            const phoneNumberMergeSource = { attributes: { profile: { protectedData } } };

            const currentUser = merge(saveEmailUser, phoneNumberMergeSource);
            dispatch(currentUserShowSuccess(currentUser));
            dispatch(saveContactDetailsSuccess());
        })
        .catch(e => {
            dispatch(saveEmailError(e.message || 'Something went wrong. Try later.'));
            dispatch(savePhoneNumberError(e.message || 'Something went wrong. Try later.'));
        });
};

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => (dispatch, getState, sdk) => {
    dispatch(saveContactDetailsRequest());

    const { email, currentEmail, phoneNumber, currentPhoneNumber, currentPassword } = params;

    const emailChanged = email && email !== currentEmail;
    const phoneNumberChanged = phoneNumber && phoneNumber !== currentPhoneNumber;

    if (emailChanged && phoneNumberChanged) {
        return dispatch(
            saveEmailAndPhoneNumber({ email, currentPassword, phoneNumber, phoneVerified: false })
        );
    } else if (emailChanged) {
        return dispatch(saveEmail({ email, currentPassword }));
    } else if (phoneNumberChanged) {
        return dispatch(savePhoneNumber({ phoneNumber, phoneVerified: false }));
    }
};

export const resetPassword = email => (dispatch, getState, sdk) => {
    dispatch(resetPasswordRequest());
    return sdk.passwordReset
        .request({ email })
        .then(() => dispatch(resetPasswordSuccess()))
        .catch(e => dispatch(resetPasswordError(storableError(e))));
};

export const verifyPhoneNumber = params => async (dispatch, getState, sdk) => {
    dispatch(saveContactDetailsRequest());
    const {
        user: {
            currentUser: {
                id: { uuid: userId },
                attributes: { emailVerified, email },
            },
        },
    } = getState();

    try {
        if (email && emailVerified) {
            await updateCreditsHistory(
                JSON.stringify({
                    userId,
                    eventName: CREDITS_ACTION_PROFILE_VERIFIED,
                })
            );
        }
    } catch (e) {
        // Event profile_verified is not recurrent.
        // catch block is needed to handle this case
    }

    const { phoneNumber, phoneVerified } = params;

    return dispatch(savePhoneNumber({ phoneNumber, phoneVerified }));
};

export const sendVerificationOtp = phoneNumber => (dispatch, getState, sdk) => {
    if (verificationOtpSendingInProgress(getState())) {
        return Promise.reject(new Error('Verification otp sending already in progress'));
    }

    let otp = Math.floor(100000 + Math.random() * 900000);

    dispatch(sendVerificationOtpRequest());

    const body = {
        to: phoneNumber,
        message: `Dein Bestätigungscode für HorseDeal lautet: ${otp}`,
    };

    return sendVerificationCode(JSON.stringify(body))
        .then(data => {
            if (data.success) {
                dispatch(sendVerificationOtpSuccess(otp));
                return otp;
            } else {
                dispatch(sendVerificationOtpError(data.error));
                throw data.error;
            }
        })
        .catch(e => {
            dispatch(sendVerificationOtpError(e));
            throw e.message || e.data || e;
        });
};
