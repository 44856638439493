import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { Button, Form, FieldPhoneNumberInput, FieldSelect } from '../../components';

import css from './StepEnterPhoneNumber.css';
import { Form as FinalForm } from 'react-final-form';
import { required, composeValidators, isNumber } from '../../util/validators';
import { ProtectionInfoIcon } from '../../icons';
import { countryCodes } from './helpers';
import { useIsMobile } from '../../hooks/useIsMobile';

export default ({
    missingPhoneNumberDescriptionId,
    handleSubmitPhoneNumberForm,
    submitInProgress,
    countryCode,
    phoneNumber,
    phoneSubmitError,
}) => {
    const [isMobile] = useIsMobile();

    return (
        <div className={css.completeProfileWrapper}>
            <h3 className={classNames(css.completeProfileHeader)}>Verifiziere deine Mobilnummer</h3>
            <p className={css.completeProfileParagraph}>
                <FormattedMessage
                    id={`MissingPhoneNumberModal.${missingPhoneNumberDescriptionId}`}
                />
            </p>
            <div className={classNames(css.modalFormBody, css.modalFormBodyFullHeight)}>
                <FinalForm
                    onSubmit={handleSubmitPhoneNumberForm}
                    initialValues={{
                        phoneNumber,
                        countryCode,
                    }}
                    render={fieldRenderProps => {
                        const { handleSubmit, invalid, values, form } = fieldRenderProps;

                        const submitDisabled = !!(invalid || submitInProgress);
                        const { countryCode } = values || {};

                        return (
                            <Form onSubmit={handleSubmit}>
                                <div className={css.contactDetailsSection}>
                                    <FieldSelect
                                        id="countryCode"
                                        name="countryCode"
                                        minLength={5}
                                        validate={required('Dieses Feld wird benötigt.')}
                                        form={form}
                                        placeholder="Auswählen"
                                        optionsList={countryCodes.map(({ label, value }) => ({
                                            label,
                                            value,
                                        }))}
                                    />
                                    <FieldPhoneNumberInput
                                        countryCode={countryCode}
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        type={isMobile ? 'tel' : 'text'}
                                        placeholder="Mobilnummer"
                                        validate={composeValidators(
                                            required('Dieses Feld wird benötigt.'),
                                            isNumber('Es sind nur Zahlen erlaubt.')
                                        )}
                                    />
                                    {phoneSubmitError && (
                                        <p className={css.error}>{phoneSubmitError}</p>
                                    )}
                                </div>

                                <footer>
                                    <p className={css.completeProfileProtectionMessage}>
                                        <ProtectionInfoIcon />
                                        Deine Mobilnummer ist nicht öffentlich sichtbar.
                                    </p>
                                    <Button
                                        type="submit"
                                        inProgress={submitInProgress}
                                        disabled={submitDisabled}
                                    >
                                        Code anfordern
                                    </Button>
                                </footer>
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    );
};
