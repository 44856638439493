import React, { useRef } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import { IconSpinner, NamedLink, RecommendationItem, Tooltip } from '../../../components';
import { GET_RECOMMENDATIONS_FORM } from '../../../containers/EditUserInfoPage/EditUserInfoPage';
import css from '../ProfileSettingsForm.css';
import { RECOMMENDATIONS_ID } from '../NavigationBar';
import sectionCss from './RecommendationsSection.css';

import LogoutOverlay from '../LogoutOverlay';
import { ConditionalWrapper } from '../../../util/common';

const RecommendationsSection = ({
    recommendations: recommendationsFromProps,
    currentUser,
    isPublic,
    externalReviewsRequests,
    isLoggedOut,
    userName,
    isSameUser,
    switchUserRoleAction,
    pageParams,
}) => {
    const {
        attributes: {
            profile: {
                publicData: { userType },
                displayName,
            },
        },
        id: { uuid },
    } = currentUser;

    const externalReviewsRequest = externalReviewsRequests && externalReviewsRequests[uuid];
    const recommendations = (recommendationsFromProps || []).filter(r => r.status === 'approved');
    const noRecommendations = Boolean(!recommendations || recommendations.length === 0);

    const containerRef = useRef();

    const [firstName, lastNameAbbr] = (displayName || '').split(' ');

    const logoutOverlayVisible = isLoggedOut || switchUserRoleAction;

    const classes = classNames({
        [css.infoBlock]: true,
        [css.noInfoBlock]: noRecommendations,
        [sectionCss.noBackBlock]: isPublic,
        [css.viewProfileRequestWrapper]: logoutOverlayVisible,
        [sectionCss.viewProfileRequestWrapper]: logoutOverlayVisible,
    });

    const redirectDisabled = isSameUser;

    const linkClasses = classNames({
        [sectionCss.link]: true,
        [sectionCss.linkDisabled]: redirectDisabled,
    });

    const renderAddNewReview = className =>
        isPublic ? (
            <ConditionalWrapper
                condition={redirectDisabled}
                wrapper={children => (
                    <Tooltip
                        parentContainerRef={containerRef}
                        tooltipClassName={sectionCss.redirectDisabledTooltip}
                        content={
                            <div>
                                <FormattedMessage id="ProfileSettingsForm.recommendationsRedirectDisabled" />
                            </div>
                        }
                    >
                        {children}
                    </Tooltip>
                )}
            >
                <NamedLink
                    name="AddExternalReview"
                    params={{
                        userNameToRecommend: `${firstName}-${lastNameAbbr}`,
                        userId: uuid,
                        userType,
                    }}
                    className={classNames([linkClasses, className])}
                    isNotRouterLink={redirectDisabled}
                    disabled={redirectDisabled}
                >
                    <FormattedMessage
                        id="ProfileSettingsForm.recommendationsAction"
                        values={{ userName: firstName }}
                    />
                </NamedLink>
            </ConditionalWrapper>
        ) : null;

    return externalReviewsRequest ? (
        <IconSpinner />
    ) : (
        <div className={classes} id={RECOMMENDATIONS_ID} ref={containerRef}>
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.recommendationsHeading" />
                {renderAddNewReview(css.desktop)}
            </h2>

            {!noRecommendations && (
                <main className={sectionCss.recommendationsHolder}>
                    {recommendations.map(({ review, reviewer_data }, i) => (
                        <RecommendationItem
                            key={i}
                            {...review}
                            {...reviewer_data}
                            review={review}
                        />
                    ))}
                </main>
            )}
            <section>
                {isPublic && noRecommendations && (
                    <p className={sectionCss.description}>
                        <FormattedMessage
                            id="ProfileSettingsForm.recommendationsEmpty"
                            values={{ userName: firstName }}
                        />
                    </p>
                )}
                {!isPublic && noRecommendations && (
                    <div className={`${sectionCss.placeholderWrapper} ${css.placeholderWrapper}`}>
                        <main className={css.flex}>
                            <div className={css.placeholderAvatar} />
                            <div className={css.placeholderUserInfo}>
                                <div className={css.placeholderItem} />
                                <div className={css.placeholderItem} />
                                <div className={css.placeholderItem} />
                            </div>
                        </main>
                        <footer>
                            <div className={css.placeholderItem} />
                            <div className={css.placeholderItem} />
                            <div className={css.placeholderItem} />
                        </footer>
                    </div>
                )}
                {!isPublic && (
                    <NamedLink
                        name="EditUserInfo"
                        params={{
                            section: GET_RECOMMENDATIONS_FORM,
                            userType: userType || 'rider',
                        }}
                        className={sectionCss.link}
                    >
                        <button className={`${css.actionItem} ${css.actionBtn}`}>
                            <FormattedMessage id="ProfileSettingsForm.recommendationsReferal" />
                        </button>
                    </NamedLink>
                )}
            </section>
            {renderAddNewReview(sectionCss.mobLink)}
            <LogoutOverlay
                userName={userName}
                isLoggedOut={
                    isLoggedOut && recommendationsFromProps && recommendationsFromProps.length > 0
                }
                rootClassName={sectionCss.viewProfileRequestAction}
                sectionName="userRecommendation"
                switchUserRoleAction={switchUserRoleAction}
                pageParams={pageParams}
            />
        </div>
    );
};

export default RecommendationsSection;
