import React from 'react';
import { useSelector } from 'react-redux';
import { FavoriteIcon, Tooltip } from '../../components';
import { HeartIcon, IconMailCheckM, IconMailClockM, IconMailCrossM } from '../../icons';
import { FormattedMessage } from '../../util/reactIntl';
import {
    TRANSITION_ENQUIRE,
    TRANSITION_ENQUIRY_ACCEPT,
    TRANSITION_ENQUIRY_DECLINE,
    TRANSITION_ENQUIRY_EXPIRE,
    TRANSITION_ENQUIRY_ACCEPT_OWNER,
    TRANSITION_ENQUIRY_DECLINE_OWNER,
} from '../../util/transaction';
import classNames from 'classnames';
import css from './FavoriteBadge.css';

const txToListingRelationConfig = {
    [TRANSITION_ENQUIRE]: {
        icon: <IconMailClockM />,
        label: 'Ausstehend',
        state: 'inquired',
    },
    [TRANSITION_ENQUIRY_EXPIRE]: {
        icon: <IconMailClockM />,
        label: 'Abgelaufen',
        state: 'expired',
    },
    [TRANSITION_ENQUIRY_ACCEPT]: {
        icon: <IconMailCheckM />,
        label: 'Akzeptiert',
        state: 'accepted',
    },
    [TRANSITION_ENQUIRY_ACCEPT_OWNER]: {
        icon: <IconMailCheckM />,
        label: 'Akzeptiert',
        state: 'accepted',
    },
    [TRANSITION_ENQUIRY_DECLINE]: {
        icon: <IconMailCrossM />,
        label: 'Abgelehnt',
        state: 'declined',
    },
    [TRANSITION_ENQUIRY_DECLINE_OWNER]: {
        icon: <IconMailCrossM />,
        label: 'Abgelehnt',
        state: 'declined',
    },
};

const findTxForHorseListing = currentListingId => tx =>
    tx.attributes.protectedData.listingSubstitutionId === currentListingId ||
    tx.relationships?.listing?.data?.id?.uuid === currentListingId;

const findTxForRiderListing = (currentListing, currentUser) => tx =>
    currentListing?.attributes?.publicData?.userRepresentationId ===
        tx.relationships?.customer?.data?.id?.uuid &&
    currentUser?.id?.uuid === tx.relationships?.provider?.data?.id?.uuid;

export default ({
    currentListing,
    currentTransaction,
    noFavorite,
    isSameUser,
    noListingsFavorForOwners,
    cardRef,
}) => {
    const currentUser = useSelector(s => s.user.currentUser);
    const transactions = useSelector(s => s.marketplaceData.entities.transaction);

    const favoriteDisabled = Boolean(isSameUser || noListingsFavorForOwners);
    const currentUserDefined = Boolean(currentUser?.id?.uuid);
    const currentListingId = currentListing.id.uuid;
    const { title } = currentListing.attributes || '';

    if (noFavorite) return null;

    const txRelatedToListing =
        currentTransaction ||
        (currentUserDefined && transactions && currentListingId
            ? Object.values(transactions).find(
                  tx =>
                      findTxForHorseListing(currentListingId)(tx) ||
                      findTxForRiderListing(currentListing, currentUser)(tx)
              )
            : null);

    if (txRelatedToListing && !isSameUser) {
        const { lastTransition } = txRelatedToListing.attributes;
        const { icon, label, state } = txToListingRelationConfig[lastTransition];

        return (
            <div
                className={classNames(css.txBadge, {
                    [css[state]]: !!css[state],
                })}
            >
                {icon}
                <span>{label}</span>
            </div>
        );
    }

    return (
        <FavoriteIcon currentListing={currentListing} disabled={favoriteDisabled}>
            {favoriteDisabled ? (
                <Tooltip
                    rootClassName={css.tooltip}
                    tooltipClassName={css.tooltipContent}
                    parentContainerRef={cardRef}
                    content={
                        <>
                            <p>
                                <FormattedMessage
                                    id={`ListingCard.tooltipHeading-${isSameUser ||
                                        noListingsFavorForOwners}`}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={`ListingCard.tooltipDesc-${isSameUser ||
                                        noListingsFavorForOwners}`}
                                    values={{ listingTitle: title }}
                                />
                            </p>
                        </>
                    }
                >
                    <HeartIcon />
                </Tooltip>
            ) : null}
        </FavoriteIcon>
    );
};
