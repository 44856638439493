/**
 * Check is a user has a specific event in credits history that corresponds to his current role
 *
 * @param {object} user - sdk user current or public
 * @param {string} eventToCheck - event name to check, see credits-config.js
 * @returns boolean
 */
export const checkIfCreditsEventExists = (user, eventToCheck) => {
    const {
        publicData: { userType },
        privateData: { creditsHistory },
    } = user.attributes.profile;

    const creditsByUserType = creditsHistory && creditsHistory[userType];

    const creditsHistoryAvailable =
        Array.isArray(creditsByUserType) && creditsByUserType.length > 0 ? creditsByUserType : null;

    return creditsHistoryAvailable
        ? creditsHistoryAvailable.some(({ eventName }) => eventName === eventToCheck)
        : false;
};
