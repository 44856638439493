import { useContext } from 'react';
import { InquiryModalContext } from './InquiryModal';

export const useInquiryModalContext = () => {
    const context = useContext(InquiryModalContext);

    if (!context) {
        throw new Error('useInquiryModalContext must be used within InquiryModal.');
    }

    return context;
};
