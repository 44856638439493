import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSpinner } from '../../components';
import { isUploadImageOverLimitError } from '../../util/errors';
import UploadUserAvatarModal from './UploadUserAvatarModal';
import AvatarPlaceholder from './AvatarPlaceholder';
import AvatarWithImage from './AvatarWithImage';
import css from './UploadUserAvatar.css';

const ACCEPT_IMAGES = 'image/*';
const initialState = { state: null, image: null, transitions: null };

const UploadUserAvatar = forwardRef(
    (
        {
            uploadImageError,
            uploadInProgress,
            currentUser,
            onImageUpload,
            onUpdateProfile,
            rootClassName,
            form,
            profileImage,
            uploadDisabled = false,
            previewAllowed = false,
        },
        refForwarded
    ) => {
        const [open, setOpen] = useState(false);
        const [zoom, setZoom] = useState(0.5);
        const [src, setSrc] = useState(null); /** non-cropped */
        const [cropError, setCropError] = useState(null);
        const [key, setKey] = useState(new Date().toString()); // trigger file input update
        const [previewState] = useState(initialState);

        const previewRef = useRef();
        const labelRef = useRef();

        const profielImageId =
            currentUser && currentUser.profileImage ? currentUser.profileImage.id : null;
        const avatarComponentMaybe = uploadDisabled || (profielImageId && !uploadInProgress);
        const avatarOrPreviewIsSet =
            !uploadImageError && !uploadInProgress && (avatarComponentMaybe || previewState.state);

        useImperativeHandle(refForwarded, () => ({
            openAvatarModal() {
                if (profielImageId) {
                    const { variants } = currentUser.profileImage.attributes;
                    const squareSmall2x = variants['square-small2x'];
                    setZoom(1);
                    setSrc(squareSmall2x.url);
                    setOpen(true);
                } else {
                    labelRef.current && labelRef.current.click();
                }
            },
        }));

        useEffect(() => {
            // ??? TODO: add comments or remove
            if (profileImage && profileImage.imageId && profileImage.file) {
                onUpdateProfile({ profileImageId: profileImage.imageId }, {});
            }
        }, [profileImage]);

        const onChange = e => {
            e.preventDefault();

            let files;

            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }

            setSrc(URL.createObjectURL(files[0]));
            setCropError(null);

            form.change(`profileImage`, files[0]);
            form.blur(`profileImage`);

            setOpen(true);
        };

        const onClose = () => {
            if (!profielImageId) {
                form.change('profileImage', undefined);
            }
            setOpen(false);
            setKey(new Date().toString());
        };

        return (
            <>
                <Field
                    accept={ACCEPT_IMAGES}
                    id="profileImage"
                    name="profileImage"
                    type="file"
                    key={key}
                >
                    {fieldProps => {
                        const { accept, id, input } = fieldProps;
                        const { name, type, onChange: inputValueOnchage, ...rest } = input;

                        const sizeLimitError =
                            isUploadImageOverLimitError(uploadImageError) &&
                            'ProfileSettingsForm.imageUploadFailedFileTooLarge';
                        const uploadError =
                            Boolean(uploadImageError || cropError) &&
                            'ProfileSettingsForm.imageUploadFailed';

                        const error =
                            sizeLimitError || uploadError ? (
                                <div className={css.error}>
                                    <FormattedMessage id={sizeLimitError || uploadError} />
                                </div>
                            ) : null;

                        return (
                            <>
                                <div className={rootClassName}>
                                    <label
                                        className={css.label}
                                        htmlFor="profileImage"
                                        ref={labelRef}
                                    >
                                        {uploadInProgress && (
                                            <div className={css.uploadingImageOverlay}>
                                                <IconSpinner />
                                            </div>
                                        )}
                                        {Boolean(!uploadInProgress && avatarOrPreviewIsSet) && (
                                            <AvatarWithImage
                                                profielImageId={profielImageId}
                                                avatarComponentMaybe={avatarComponentMaybe}
                                                previewAllowed={previewAllowed}
                                                previewRef={previewRef}
                                                previewState={previewState}
                                                setSrc={setSrc}
                                                setOpen={setOpen}
                                                setZoom={setZoom}
                                                currentUser={currentUser}
                                            />
                                        )}
                                        {Boolean(!uploadInProgress && !avatarOrPreviewIsSet) && (
                                            <AvatarPlaceholder
                                                uploadImageError={uploadImageError}
                                            />
                                        )}
                                    </label>
                                    <input
                                        accept={accept}
                                        id={id}
                                        name={name}
                                        className={css.uploadAvatarInput}
                                        disabled={uploadInProgress || uploadDisabled}
                                        onChange={onChange}
                                        type={type}
                                    />
                                    {error}
                                </div>
                            </>
                        );
                    }}
                </Field>
                <UploadUserAvatarModal
                    src={src}
                    open={open}
                    zoom={zoom}
                    setZoom={setZoom}
                    onClose={onClose}
                    onImageUpload={onImageUpload}
                />
            </>
        );
    }
);

export default UploadUserAvatar;
