import React from 'react';
/** icon-profil-xxl */
/** icon-profil */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M34.44 12.071c-3.425.409-6.245 1.503-8.78 3.406-3.488 2.619-5.785 6.328-6.654 10.743-.29 1.471-.292 4.509-.004 6 .772 4.008 2.661 7.351 5.647 9.993 4.445 3.934 10.831 5.28 16.511 3.482 6.145-1.945 10.598-7.014 11.837-13.475.282-1.466.285-4.404.007-5.915-1.301-7.06-6.69-12.574-13.64-13.956-1.101-.219-4.032-.384-4.924-.278m5.073 3.397c4.377 1.106 8.024 4.286 9.686 8.448 1.763 4.415 1.228 9.37-1.441 13.354-1.21 1.805-3.37 3.707-5.338 4.7-3.411 1.72-7.505 1.99-11.073.732-2.154-.76-3.757-1.77-5.378-3.391-4.07-4.071-5.301-10.052-3.168-15.395 1.877-4.701 6.349-8.158 11.381-8.8 1.487-.189 3.788-.037 5.331.352M32.64 52.692c-6.597.739-13.128 3.774-17.777 8.261-1.031.994-1.065 1.044-1.063 1.567.001.642.347 1.275.788 1.443.169.064.519.117.777.117.42 0 .591-.11 1.644-1.05 2.471-2.207 4.135-3.345 6.811-4.658 3.954-1.941 7.735-2.812 12.199-2.812 4.41 0 8.232.884 12.161 2.812 2.676 1.313 4.34 2.451 6.811 4.658 1.053.94 1.224 1.05 1.644 1.05 1.056 0 1.616-.585 1.528-1.598-.058-.68-.377-1.051-2.303-2.681-4.647-3.931-10.558-6.457-16.645-7.115-1.346-.145-5.256-.142-6.575.006"
            fill="#1A2B49"
            fillRule="evenodd"
        />
    </svg>
);
