import React from 'react';
/** icon-warning-circle-xxl */
/** icon-warning-circle */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M32.400 1.697 C 24.589 2.500,17.331 5.964,11.693 11.580 C 9.450 13.814,7.943 15.725,6.394 18.300 C -0.110 29.109,-0.111 42.864,6.392 53.700 C 12.991 64.695,25.214 71.190,37.878 70.431 C 45.744 69.960,52.711 67.136,58.620 62.024 C 72.074 50.386,74.409 30.248,63.975 15.840 C 58.145 7.790,49.246 2.659,39.429 1.690 C 37.912 1.540,33.888 1.544,32.400 1.697 M40.680 4.851 C 53.238 6.799,63.468 16.069,66.515 28.264 C 67.238 31.157,67.378 32.416,67.378 36.000 C 67.378 38.497,67.331 39.489,67.156 40.620 C 65.133 53.726,55.388 64.071,42.469 66.827 C 38.464 67.681,33.536 67.681,29.531 66.827 C 17.784 64.321,8.442 55.385,5.520 43.860 C 4.764 40.876,4.622 39.631,4.622 36.000 C 4.622 32.369,4.764 31.124,5.520 28.140 C 8.311 17.131,17.127 8.316,28.140 5.520 C 29.130 5.268,30.588 4.977,31.380 4.872 C 32.172 4.766,32.982 4.658,33.180 4.631 C 34.084 4.507,39.484 4.666,40.680 4.851 M34.965 20.951 C 34.152 21.351,34.184 20.939,34.224 30.687 L 34.260 39.475 34.582 39.797 C 35.045 40.259,36.035 40.338,36.525 39.952 C 37.216 39.406,37.200 39.627,37.200 30.480 C 37.200 21.213,37.220 21.468,36.457 21.003 C 35.985 20.715,35.478 20.697,34.965 20.951 M35.039 45.586 C 33.471 46.095,32.711 47.636,33.345 49.020 C 33.977 50.396,35.495 50.936,36.818 50.255 C 38.604 49.336,38.608 46.742,36.825 45.832 C 36.287 45.558,35.472 45.445,35.039 45.586 "
            fill="#1A2B49"
            stroke="none"
            fillRule="evenodd"
        />
    </svg>
);
