import React from 'react';

export default () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
            <path
                id="Vector"
                d="M6.59961 14.3057L9.21294 18.0145C9.31207 18.1626 9.44513 18.2848 9.60105 18.3711C9.75696 18.4574 9.93123 18.5052 10.1093 18.5105C10.2874 18.5158 10.4643 18.4785 10.625 18.4017C10.7858 18.3249 10.926 18.2108 11.0337 18.0689L19.3996 7.48438"
                stroke="#8F2593"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Vector_2"
                d="M1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13V13Z"
                stroke="#8F2593"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
