import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { IconCrossL } from '../../icons';
import { useDispatch, useSelector } from 'react-redux';
import { discardUserErrors, hasCurrentUserErrors } from '../../ducks/user.duck';
import css from './Topbar.css';

export default () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { location } = history;
    /** Redux store data */
    const logoutError = useSelector(s => s.Auth.logoutError);
    const user = useSelector(s => s.user);
    const FavoritePage = useSelector(s => s.FavoritePage);

    const hasGenericError = !!(logoutError || hasCurrentUserErrors({ user, FavoritePage }));

    const urlSearchParams = new URLSearchParams(location.search);
    const errorMessage = urlSearchParams.get('errorMessage');

    const show = errorMessage || hasGenericError;

    const classes = classNames(css.genericError, {
        [css.genericErrorVisible]: show,
    });

    const onGenericErrorDiscard = () => dispatch(discardUserErrors());

    return (
        <div className={classes}>
            <div className={css.genericErrorContent}>
                <code onClick={onGenericErrorDiscard}>
                    <IconCrossL />
                </code>

                <p className={css.genericErrorText}>
                    {errorMessage ||
                        'Whoops, da ist etwas schief gelaufen. Bitte überprüfe deine Netzwerkverbindung und versuche es nochmals.'}
                </p>
            </div>
        </div>
    );
};
