import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureCurrentUser, checkMarketplaceCurrentUser } from '../../util/data';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { priceData } from '../../util/currency';
import { ensureListing } from '../../util/data';
import config from '../../config';
import { somePeriodAgo } from '../../util/dates';
import { queryRiderListing } from '../../ducks/riderListings.duck';
import { AvailabilityBadge } from '../../components';
import css from './ListingCard.css';
import { SuccessIcon, UpdateCircleIcon, CheckmarkIcon } from '../../icons';
import { getUserExternalReview } from '../../containers/ProfilePage/ProfilePage.duck';
import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import {
    defaultRenderSizes,
    formatDate,
    handleListingCardClick,
    resolveInfoItemsData,
    findListingForRider,
    findListingForOwner,
} from './helpers';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import FavoriteBadge from './FavoriteBadge';
import IdentitySection from './IdentitySection';

const { userTypeRider, userTypeHorseowner } = config;
const NEW_LISTING_NUMBER_OF_DAYS = 2;

export const ListingCard = ({
    className,
    rootClassName,
    cardWrapperClassName,
    listingRefClassName,
    intl,
    listing,
    renderSizes = defaultRenderSizes,
    setActiveListing = () => null,
    postponed = {} /** postpone e.g. images loading */,
    noFavorite,
    favoriteComponent = null,
    currentUser,
    currentUserMainListing,
    assetsLoadingRequests,
    recommendationsNum,
    onGetUserExternalReview,
    identitySectionClassName,
    notifyOnScoreChange = () => null,
    handleListingCardClick: handleListingCardClickProp,
    onQueryRiderListing,
    matchingScoreListingId,
    listingAuthorId,
    currentUserDefined,
    isRider,
    titleTag: titleTagProp,
    history,
}) => {
    const {
        assets: assetsPostponed,
        riderListing: riderListingPostponed,
        matching: matchingPostponed,
    } = postponed;
    const cardRef = useRef(null);

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);

    const { attributes, author } = currentListing;
    const { price, publicData, createdAt } = attributes;

    const {
        userRepresentationId,
        updatedAt,
        availability: user_availability,
        availabilityStatus: user_availabilityStatus,
    } = publicData;

    const past = somePeriodAgo(NEW_LISTING_NUMBER_OF_DAYS, 'day');
    const createdAtFormatted = createdAt && formatDate(new Date(createdAt));
    const updatedAtFormatted = updatedAt && formatDate(new Date(updatedAt));
    const isUserListing = !!userRepresentationId;
    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const isNewListing = !isUserListing && createdAt && past < createdAt;
    const currentUserId = currentUserDefined ? currentUser.id.uuid : null;
    const currentListingId = currentListing?.id?.uuid;

    const { formattedPrice, priceTitle } = isUserListing ? {} : priceData(price, intl);

    const isSameUser =
        currentUserDefined && listingAuthorId && currentUserId === listingAuthorId
            ? `sameUser-${isUserListing ? 'rider' : 'horse'}`
            : null;
    const isHorseOwner =
        currentUserDefined &&
        currentUser.attributes.profile.publicData.userType === userTypeHorseowner;
    const noListingsFavorForOwners =
        isHorseOwner && !isUserListing ? 'noListingsFavorForOwners' : null;

    const unitTranslationKey = isNightly
        ? 'ListingCard.perNight'
        : isDaily
        ? 'ListingCard.perDay'
        : 'ListingCard.perUnit';

    const syntheticUser = isUserListing
        ? {
              ...(author || {}),
              attributes: {
                  ...(author ? author.attributes : {}),
                  profile: {
                      ...(author && author.attributes ? author.attributes.profile : {}),
                      publicData: {
                          ...(author && author.attributes
                              ? author.attributes.profile.publicData
                              : {}),
                          availability: user_availability,
                          availabilityStatus: user_availabilityStatus,
                      },
                  },
              },
          }
        : null;

    /**
     * a horseowner looks on a horse listing;
     * not allowed, edge case is handled
     */
    const ownersView = isHorseOwner && !isUserListing;
    /**
     * a rider looks on a rider-listing;
     * not allowed, edge case is handled
     */
    const ridersView = isRider && isUserListing;
    const authorId = Boolean(isSameUser || ridersView || ownersView) ? null : currentUserId;
    /* useEffect */
    const riderListingRequestAllowed = !ridersView && !ownersView && currentUserDefined;

    useEffect(() => {
        if (!riderListingRequestAllowed || riderListingPostponed) return;

        onQueryRiderListing(authorId);
    }, [riderListingPostponed, authorId, riderListingRequestAllowed]);

    /**
     * no matching
     * - when rider searches for other riders
     * - when owner searches for other horses
     */
    const riderViewsRider = isRider && isUserListing;
    const matchingAllowed = Boolean(
        !matchingPostponed && !riderViewsRider && !ownersView && currentUserDefined
    );

    const riderMatchingParams = {
        riderListingId: matchingAllowed ? matchingScoreListingId : null,
        horseOwnerListingId: currentListingId,
    };

    const ownerMatchingParams = {
        riderListingId: matchingAllowed ? currentListingId : null,
        horseOwnerListingId: matchingScoreListingId,
    };

    /* useEffect */
    const [matchingMatrixLoading, matchingScore] = useMatchingMatrix(
        isRider ? riderMatchingParams : ownerMatchingParams
    );

    useEffect(() => {
        if (isUserListing) {
            onGetUserExternalReview(userRepresentationId);
        }
    }, [isUserListing]);

    const { finalScore, scorePalette } = matchingScore || {};
    const matchingMatrixAllowed = currentUserDefined && currentListingId && matchingScoreListingId;

    useEffect(() => {
        scorePalette && notifyOnScoreChange(scorePalette);
    }, [scorePalette]);

    const { discipline, disciplineLevel, ridings } = resolveInfoItemsData({
        currentListing,
        currentUser,
        currentUserMainListing,
    });

    const infoItems = [discipline, ridings, disciplineLevel]
        .filter(i => !!i && i.content)
        .map(({ content, matching }, index) => {
            const noMatchForRiders =
                currentUserDefined &&
                currentUser.attributes.profile.publicData.userType === userTypeRider &&
                isUserListing;

            const matchForRidersUnavailable =
                !isUserListing &&
                isRider &&
                currentUser.attributes.profile.publicData.availabilityStatus ===
                    RIDER_AVAILABILITY_NOT_AVAILABLE;

            const listingMatches = !noMatchForRiders && !matchForRidersUnavailable && matching;

            return (
                <div
                    key={index}
                    className={classNames({
                        [css.infoItem]: true,
                        [css.infoItemMatches]: listingMatches,
                    })}
                >
                    {listingMatches && <CheckmarkIcon />}
                    {content}
                </div>
            );
        });

    const infoLineMaybe = infoItems.length > 0;

    const additionalInfoSection = discipline &&
        discipline.content &&
        disciplineLevel &&
        disciplineLevel.content && (
            <div className={css.additionalInfoItem}>
                <FormattedMessage id="ListingCard.additionalInfo" />
            </div>
        );

    return (
        <div className={classes} id={`ListingCard-${currentListingId}`}>
            {favoriteComponent ? (
                React.cloneElement(favoriteComponent, {
                    cardRef,
                })
            ) : (
                <FavoriteBadge
                    currentListing={currentListing}
                    noFavorite={noFavorite}
                    noListingsFavorForOwners={noListingsFavorForOwners}
                    isSameUser={isSameUser}
                    cardRef={cardRef}
                />
            )}

            {isNewListing && (
                <div className={css.newListingNotification}>
                    <FormattedMessage id="ListingCard.newListing" />
                </div>
            )}

            <div
                className={classNames(css.cardWrapper, {
                    [cardWrapperClassName]: !!cardWrapperClassName,
                })}
                onClick={e =>
                    typeof handleListingCardClickProp === 'function'
                        ? handleListingCardClickProp(e)
                        : handleListingCardClick(e, {
                              history,
                              currentListing,
                              isUserListing,
                              userRepresentationId,
                          })
                }
                onMouseEnter={() => setActiveListing(currentListing.id)}
                onMouseLeave={() => setActiveListing(null)}
            >
                <main
                    className={classNames(css.listingRef, {
                        [listingRefClassName]: !!listingRefClassName,
                    })}
                    ref={cardRef}
                >
                    <IdentitySection
                        currentListing={currentListing}
                        renderSizes={renderSizes}
                        assetsPostponed={assetsPostponed}
                        assetsLoadingRequests={assetsLoadingRequests}
                        identitySectionClassName={identitySectionClassName}
                        recommendationsNum={recommendationsNum}
                        titleTagProp={titleTagProp}
                    />

                    {currentUserId && matchingMatrixAllowed && (
                        <code
                            className={classNames({
                                [css.progressBar]: true,
                                [css[scorePalette]]: !!scorePalette,
                            })}
                        >
                            <mark style={{ width: `${finalScore || 0}%` }} />
                        </code>
                    )}

                    <section className={css.listingInfoSection}>
                        {matchingMatrixLoading && <code className={css.mmLoadingIndicator} />}
                        {!matchingMatrixLoading &&
                            typeof finalScore === 'number' &&
                            matchingMatrixAllowed && (
                                <p className={css.matchingScore}>
                                    {finalScore}% <FormattedMessage id="ListingCard.finalScore" />
                                </p>
                            )}
                        {infoLineMaybe && (
                            <>
                                <div className={css.infoLine}>
                                    {infoItems.map(i => i)}
                                    {additionalInfoSection}
                                </div>
                                {!isUserListing && <div className={css.divider} />}
                            </>
                        )}
                        {!isUserListing && (
                            <aside className={css.horseListingSection}>
                                {createdAtFormatted && (
                                    <div className={css.listingCreationInfo}>
                                        {updatedAtFormatted ? (
                                            <UpdateCircleIcon />
                                        ) : (
                                            <SuccessIcon rootClassName={css.createdAtIcon} />
                                        )}
                                        {updatedAtFormatted || createdAtFormatted}
                                    </div>
                                )}
                                {formattedPrice && (
                                    <div className={css.price}>
                                        <div
                                            className={css.priceValue}
                                            title={priceTitle && priceTitle}
                                        >
                                            {formattedPrice}
                                        </div>
                                        <div className={css.perUnit}>
                                            <FormattedMessage id={unitTranslationKey} />
                                        </div>
                                    </div>
                                )}
                            </aside>
                        )}
                    </section>
                    {isUserListing && syntheticUser && (
                        <div className={css.availabilityBadgeHolder}>
                            <AvailabilityBadge
                                user={syntheticUser}
                                editAllowed={false}
                                parentContainerRef={cardRef}
                                helpTextAllowed
                                isPublic
                            />
                        </div>
                    )}
                </main>
            </div>
        </div>
    );
};

ListingCard.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    listing: propTypes.listing.isRequired,
    noFavorite: bool,
    // Responsive image sizes hint
    renderSizes: string,
    setActiveListing: func,
};

const mapStateToProps = (state, ownProps) => {
    const { listing } = ownProps;
    const { id, author, attributes } = listing || {};
    const { uuid: listingId } = id || { uuid: null };
    const {
        publicData: { userRepresentationId },
    } = attributes || { publicData: { userRepresentationId: null } };
    const currentUser = ensureCurrentUser(checkMarketplaceCurrentUser(state));
    const { Assets, ProfilePage, user, riderListings } = state;
    const { currentUserMainListing } = user;
    const { assetsLoadingRequests } = Assets;
    const { externalReviewsRequests, externalReviewsData } = ProfilePage;
    const { riderListings: listings } = riderListings;

    const userListingsDataAvailable = Object.values(listings || {})[0];
    const currentUserDefined = currentUser && currentUser.id;
    const userListings = userListingsDataAvailable
        ? getListingsById(state, Object.values(userListingsDataAvailable))
        : null;

    const {
        attributes: {
            profile: {
                publicData: { mainHorseId },
            },
        },
    } = currentUser;
    const isRider =
        currentUserDefined && currentUser.attributes.profile.publicData.userType === userTypeRider;

    const listingAuthorId = author && author.id && author.id.uuid;
    /**
     * listing representation for rider;
     * main horse for owner;
     */
    const matchingScoreListing = Array.isArray(userListings)
        ? userListings.find(isRider ? findListingForRider : findListingForOwner(mainHorseId))
        : null;

    const matchingScoreListingId =
        matchingScoreListing && matchingScoreListing.id ? matchingScoreListing.id.uuid : null;

    const externalReviews = externalReviewsData && externalReviewsData[listingAuthorId];

    const externalReviewsRequest =
        externalReviewsRequests && externalReviewsRequests[listingAuthorId];

    const externalReviewsDataAvailable =
        !externalReviewsRequest && Array.isArray(externalReviews) && externalReviews.length > 0
            ? externalReviews
            : null;

    const recommendationsApproved = externalReviewsDataAvailable
        ? externalReviewsDataAvailable.filter(({ status }) => status === 'approved').length
        : null;

    return {
        currentUser,
        currentUserMainListing,
        assetsLoadingRequests: Boolean(
            assetsLoadingRequests[listingId] || assetsLoadingRequests[userRepresentationId]
        ),
        externalReviews,
        matchingScoreListingId,
        currentUserDefined,
        listingAuthorId,
        isRider,
        recommendationsNum: recommendationsApproved,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUserExternalReview: userReviewedId => dispatch(getUserExternalReview(userReviewedId)),
        onQueryRiderListing: authorId => dispatch(queryRiderListing(authorId)),
        dispatch,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(ListingCard);
