const typeConfig = {
    'image/png': 'image',
    'image/jpeg': 'image',
    'video/mp4': 'video',
    'video/mov': 'video',
    'video/quicktime': 'video',
    'application/pdf': 'document',
};

/** convert s3 files into sdk format */
export const sdkImagesDto = (
    { Key, publicPath, position, description, gallerytype, fileName, originalname, mimetype, type },
    useDecoding = false
) => {
    const getDecodedDesc = desc => {
        try {
            return atob(desc);
        } catch (e) {
            return desc;
        }
    };

    return {
        attributes: {
            variants: {
                'landscape-crop': {
                    height: 267,
                    width: 400,
                    name: 'landscape-crop',
                    url: publicPath,
                },
                'landscape-crop2x': {
                    height: 533,
                    width: 800,
                    name: 'landscape-crop2x',
                    url: publicPath,
                },
            },
        },
        id: {
            uuid: Key,
        },
        type: typeConfig[mimetype],
        mimetype,
        position: Number(position),
        description: useDecoding && description ? getDecodedDesc(description) : description,
        gallerytype,
        fileName: fileName || originalname,
        __type: type,
    };
};
