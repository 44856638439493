import React from 'react';
import classNames from 'classnames';
import { MapMarkerIcon, IconClockL } from '../../icons';
import { CURRENT_LOCATION_ID } from '../../util/googleMaps';
import { getTouchCoordinates } from './LocationAutocompleteInputImpl.helper';
import IconCompass from './IconCompass';
import css from './LocationAutocompleteInput.css';

// Renders the autocompletion prediction results in a list
export default ({
    rootClassName,
    className,
    predictions,
    geocoder,
    highlightedIndex,
    onSelectStart,
    onSelectMove,
    onSelectEnd,
}) => {
    if (predictions.length === 0) return null;

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    const item = (prediction, index) => {
        const isHighlighted = index === highlightedIndex;
        const predictionId = geocoder.getPredictionId(prediction);
        const isCurrentLocation = predictionId === CURRENT_LOCATION_ID;
        const isPresaved = prediction.isPresaved;

        const renderIcon = () => {
            if (isCurrentLocation) return <IconCompass />;
            if (isPresaved) return <IconClockL />;
            return <MapMarkerIcon />;
        };

        return (
            <li
                className={classNames({
                    [css.highlighted]: !!isHighlighted,
                    [css.currentLocation]: isCurrentLocation,
                })}
                key={predictionId}
                onTouchStart={e => {
                    e.preventDefault();
                    onSelectStart(getTouchCoordinates(e.nativeEvent));
                }}
                onMouseDown={e => {
                    e.preventDefault();
                    onSelectStart();
                }}
                onTouchMove={e => {
                    e.preventDefault();
                    onSelectMove(getTouchCoordinates(e.nativeEvent));
                }}
                onTouchEnd={e => {
                    e.preventDefault();
                    onSelectEnd(prediction);
                }}
                onMouseUp={e => {
                    e.preventDefault();
                    onSelectEnd(prediction);
                }}
            >
                {renderIcon()}
                {isCurrentLocation ? 'Mein Standort' : geocoder.getPredictionAddress(prediction)}
            </li>
        );
    };
    /* eslint-enable jsx-a11y/no-static-element-interactions */

    const classes = classNames(rootClassName || css.predictionsRoot, className);

    return (
        <div className={classes}>
            <ul className={css.predictions}>{predictions.map(item)}</ul>
        </div>
    );
};
