import React from 'react';
import classNames from 'classnames';
import { Button, Logo, NamedLink, BurgerMenu } from '../../../../components';
import SearchIcon from './SearchIcon';
import css from './TopbarMobile.css';

export default ({
    handleMobileSearchOpen,
    mobileRootClassName,
    mobileClassName,
    currentPage,
    intl,
}) => {
    return (
        <div className={classNames(mobileRootClassName || css.mobile, mobileClassName)}>
            <BurgerMenu currentPage={currentPage} />
            <NamedLink name="LandingPage" title={intl.formatMessage({ id: 'Topbar.logoIcon' })}>
                <Logo format="mobile" />
            </NamedLink>
            <div>
                <Button
                    rootClassName={css.searchMenu}
                    onClick={handleMobileSearchOpen}
                    title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
                >
                    <SearchIcon className={css.searchMenuIcon} />
                </Button>
            </div>
        </div>
    );
};
