import { searchPageAllowedUriUnits } from '../../../../containers/SearchPage/SearchPage.helpers';

export const constructUriUnit = (key, value) => (value ? `${key}=${value}` : '');

export const encodeBoundsToStr = (bounds, applyEncode = true) => {
    const isStringified = typeof bounds === 'string';

    if (isStringified || !bounds) return bounds;

    const {
        ne: { lat: neLat, lng: neLng },
        sw: { lat: swLat, lng: swLng },
    } = bounds;

    const uri = [neLat, neLng, swLat, swLng].join(',');

    return applyEncode ? encodeURIComponent(uri) : uri;
};

export const encodeOriginToStr = origin => {
    const isStringified = typeof origin === 'string';

    if (isStringified) return origin;

    return encodeURIComponent([origin.lat, origin.lng].join(','));
};

export const constructParamsFromSelectedPlace = (selectedPlace, urlParams = {}) => {
    const { bounds, origin, address, distance, listingType } = selectedPlace;

    // Update search URL for submitting to find listing
    const encodeURI = encodeBoundsToStr(bounds);
    const encodeAddress = encodeURIComponent(/*location.selectedPlace.*/ address);

    const distanceNum = parseInt(distance);
    const distanceIsValid = typeof distanceNum === 'number' && !Number.isNaN(distanceNum);

    const params = {
        ...urlParams,
        address: encodeAddress,
        bounds: encodeURI,
        distance: distanceIsValid ? distance : null,
        listingType,
    };

    if (origin) {
        const encodeOrigin = encodeOriginToStr(origin);
        return { ...params, origin: encodeOrigin };
    } else {
        return params;
    }
};

export const constructUriSearchStr = (props, intl) =>
    searchPageAllowedUriUnits(intl).reduce((acc, uriEntry) => {
        const uriUnit = constructUriUnit(uriEntry, props[uriEntry]);
        const uriDivider = !!acc ? '&' : '';

        return uriUnit ? [acc, uriDivider, uriUnit].join('') : acc;
    }, '');
