import {
    SEND_MESSAGE_SUCCESS,
    SEND_FILE_SUCCESS,
    SEND_APPOINTMENT_SUCCESS,
    SEND_APPOINTMENT_UPDATE_SUCCESS,
    HANDLE_INQUIRY_SUCCESS,
} from '../containers/TransactionPage/TransactionPage.duck';
import config from '../config';
import { SEND_ENQUIRY_SUCCESS } from '../containers/ListingPage/ListingPage.duck';
import { handleHttpAction } from '../util/api';

const { userTypeRider, userTypeHorseowner } = config;

/**
 * TODO handle the following transitions which are handled in the background
 * TRANSITION_ENQUIRY_EXPIRE
 * TRANSITION_PAUSE_SUBSCRIPTION
 * TRANSITION_START_REVIEWS_PERIOD_AND_SUBSCRIPTION_ACTIVATED
 */

export const interceptSendMessage = () => ({ getState }) => next => action => {
    const { type, payload } = action;

    const chatPageActivityType = resolveChatPageActivity(type);

    if (chatPageActivityType) {
        const { uuid } = payload;
        const {
            user: {
                currentUser: {
                    id: { uuid: currentUserId },
                    attributes: {
                        profile: {
                            publicData: { userType },
                        },
                    },
                },
            },
            marketplaceData: {
                entities: { transaction },
            },
        } = getState();

        const transactionData = transaction[uuid];
        const {
            relationships: {
                customer: {
                    data: {
                        id: { uuid: customerId },
                    },
                },
                provider: {
                    data: {
                        id: { uuid: providerId },
                    },
                },
            },
        } = transactionData;

        const userIsRider = userType === userTypeRider;
        const userIsOwner = userType === userTypeHorseowner;
        /**
         * if a current user is rider,
         * other party has to receive a
         * message as an opposite - owner
         * and vise versa
         */
        const transactionType = userIsRider
            ? userTypeHorseowner
            : userIsOwner
            ? userTypeRider
            : null;

        if (!transactionType) {
            next(action);
        }

        const isCustomer = customerId === currentUserId;
        const otherPartyId = isCustomer ? providerId : customerId;
        const senderId = isCustomer ? customerId : providerId;
        const messageType = getMessageType(type);

        markMessageAsUnread({
            receiverId: otherPartyId,
            senderId,
            transactionId: uuid,
            transactionType,
            messageType,
        });
    }
    next(action);
};

async function markMessageAsUnread({
    receiverId,
    transactionId,
    transactionType,
    messageType,
    senderId,
}) {
    try {
        const url = `/api/unread-messages/${receiverId}/${transactionId}/${transactionType}/${messageType}/${senderId}/create-message`;
        const response = await handleHttpAction(url, { method: 'PATCH' });
        const unreadMessageData = await response.json();
        return unreadMessageData;
    } catch (e) {
        console.log(e.message || 'Failed to create a new unread message data.');
    }
}

function resolveChatPageActivity(type) {
    return (
        type === SEND_MESSAGE_SUCCESS ||
        type === SEND_FILE_SUCCESS ||
        type === SEND_APPOINTMENT_SUCCESS ||
        type === SEND_ENQUIRY_SUCCESS ||
        type === HANDLE_INQUIRY_SUCCESS ||
        type === SEND_APPOINTMENT_UPDATE_SUCCESS
    );
}

function getMessageType(type) {
    const messageTypeConfig = {
        [SEND_MESSAGE_SUCCESS]: 'message',
        [SEND_FILE_SUCCESS]: 'file',
        [SEND_APPOINTMENT_SUCCESS]: 'appointment',
        [SEND_APPOINTMENT_UPDATE_SUCCESS]: 'appointment',
        [SEND_ENQUIRY_SUCCESS]: 'transition',
        [HANDLE_INQUIRY_SUCCESS]: 'transition',
    };
    return messageTypeConfig[type] || 'unknown';
}
