import { useState, useEffect } from 'react';
import { getMarketplaceReviews } from '../util/api';
import { checkIOS } from '../util/ios';
import { adaptToSafariTime } from '../util/ios';

export const useReviews = ({ allowed, perPage }) => {
    const [reviewsData, setReviewsData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchReviews = async perPage => {
        try {
            const response = await getMarketplaceReviews(perPage);
            const reviewsData = await response.json();
            const { error, data } = reviewsData;

            if (error || !data) return setError(true);

            const { safari } = checkIOS();

            setReviewsData(
                safari
                    ? data.map(review => ({
                          ...review,
                          date_created: adaptToSafariTime(review.date_created),
                      }))
                    : data
            );
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (!allowed) return;

        setLoading(true);
        fetchReviews(perPage);
    }, [allowed, perPage]);

    return [reviewsData, loading, error];
};
