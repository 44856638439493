import React from 'react';
import { obfuscatedCoordinates } from '../../util/maps';
import { IconSpinner, Map } from '../../components';
import config from '../../config';
import css from './EntityLocationFormFields.css';

export default ({ geolocationMaybe, predictionInProgress }) => {
    if (predictionInProgress) return <IconSpinner />;
    if (!geolocationMaybe) return null;

    const cacheKey = geolocationMaybe
        ? `${geolocationMaybe.origin.lat}_${geolocationMaybe.origin.lng}`
        : null;

    const mapProps = geolocationMaybe
        ? { obfuscatedCenter: obfuscatedCoordinates(geolocationMaybe.origin, cacheKey) }
        : {};

    return (
        <section className={css.mapSection}>
            <Map
                {...mapProps}
                mapsConfig={{
                    ...config.maps,
                    fuzzy: {
                        ...config.maps.fuzzy,
                        enabled: true,
                        defaultZoomLevel: 15,
                        offset: 500,
                    },
                }}
            />
        </section>
    );
};
