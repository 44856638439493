import React, { useEffect, useState } from 'react';
import { IconSpinner, ListingCardsCarousel } from '../../components';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getMatchingScore } from '../../util/api';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingCardsCarouselWithMatchingScore.css';
import classNames from 'classnames';
import { getVisibleSlidesByViewport } from '../ListingCardsCarousel/helpers';

const ListingCardsCarouselWithMatchingScore = ({
    listings,
    riderListing,
    isLoggedIn,
    minMatchingRate,
    scoreData,
    matchRiderListing = true,
    header,
    ...rest
}) => {
    const [isMobile, , computing, viewport] = useIsMobile();
    const [error, setError] = useState(null);
    const [inProgress, setInprogress] = useState(false);
    const [sliderListings, setSliderListings] = useState(null);
    const [listingIds, setListingIds] = useState(null);

    const riderListingId = riderListing && riderListing.id ? riderListing.id.uuid : null;

    const getAllListingsMatchingScore = async (entitiesIds, idToGetScoreFrom) =>
        Promise.all(
            entitiesIds.map(async entitiyId => {
                const params = matchRiderListing
                    ? {
                          riderListingId: idToGetScoreFrom,
                          horseOwnerListingId: entitiyId,
                      }
                    : {
                          riderListingId: entitiyId,
                          horseOwnerListingId: idToGetScoreFrom,
                      };

                const result = await getMatchingScore(params);
                return [entitiyId, result];
            })
        )
            .then(res => {
                const idsDictionary = res.reduce(
                    (acc, [id, score]) => ({
                        ...acc,
                        [id]: score,
                    }),
                    {}
                );
                if (minMatchingRate && isLoggedIn) {
                    setSliderListings([
                        ...listings.reduce((acc, listing) => {
                            const dictionaryListing = idsDictionary[listing.id.uuid];

                            if (!dictionaryListing) {
                                return acc;
                            }
                            if (dictionaryListing.finalScore < minMatchingRate) {
                                return acc;
                            }

                            return [...acc, listing];
                        }, []),
                    ]);
                } else {
                    setSliderListings([
                        ...listings.reduce(
                            (acc, listing) =>
                                idsDictionary[listing.id.uuid] ? [...acc, listing] : acc,
                            []
                        ),
                    ]);
                }
            })
            .catch(e => setError(e))
            .finally(() => {
                setInprogress(false);
            });

    useEffect(() => {
        /**
         * Convert listings into uuids' string
         */
        if (!Array.isArray(listings) || listings.length === 0) {
            return;
        }

        setListingIds(
            listings.reduce((acc, { id: { uuid } }) => (acc ? `${acc},${uuid}` : uuid), '')
        );
    }, [listings]);

    useEffect(() => {
        /**
         * Fetch listings matching data
         */
        if (inProgress || !listingIds) {
            return;
        }

        if (!riderListingId) {
            return setSliderListings(listings);
        }

        setInprogress(true);

        getAllListingsMatchingScore(listingIds.split(','), riderListingId);
    }, [listingIds, riderListingId]);

    if (inProgress) {
        return <IconSpinner />;
    }

    if (error) {
        return (
            <p className={css.error}>
                <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
            </p>
        );
    }

    const dataAvailable = !computing && Array.isArray(sliderListings) && sliderListings.length > 0;

    const sliderClassName = classNames({
        [css.oneItemColumn]: dataAvailable && sliderListings.length === 1,
        [css.twoItemsColumn]: dataAvailable && sliderListings.length === 2,
    });

    const { visibleSlidesByViewport } = getVisibleSlidesByViewport(viewport);

    return dataAvailable ? (
        <>
            {header || (
                <h2 className={css.listingSectionTitle}>
                    <FormattedMessage
                        id={`ListingPage.sectionSuggestionsTitle${scoreData ? 'WithMatching' : ''}`}
                    />
                </h2>
            )}
            {viewport && (
                <ListingCardsCarousel
                    isMobile={isMobile}
                    listings={sliderListings}
                    sliderVisibleSlides={Math.min(visibleSlidesByViewport, sliderListings.length)}
                    sliderClassName={sliderClassName}
                    {...rest}
                />
            )}
        </>
    ) : null;
};

export default ListingCardsCarouselWithMatchingScore;
