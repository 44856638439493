import React from 'react';
/** icon-clock-xxl */
/** icon-clock */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
        <path
            d="M33.208 3.118 C 26.542 3.766,20.515 6.175,15.360 10.252 C 14.200 11.169,11.169 14.200,10.252 15.360 C 6.592 19.988,4.243 25.415,3.343 31.320 C 2.961 33.827,2.961 38.183,3.344 40.680 C 4.259 46.643,6.572 51.987,10.252 56.640 C 11.152 57.779,14.196 60.829,15.300 61.699 C 20.110 65.487,25.268 67.727,31.320 68.656 C 33.823 69.040,38.177 69.040,40.680 68.656 C 48.109 67.516,54.485 64.267,59.583 59.021 C 64.578 53.882,67.558 47.857,68.654 40.680 C 68.874 39.241,68.920 38.431,68.920 36.000 C 68.920 33.569,68.874 32.759,68.654 31.320 C 67.733 25.287,65.483 20.104,61.699 15.300 C 60.845 14.216,57.784 11.155,56.700 10.301 C 51.902 6.522,46.658 4.242,40.740 3.362 C 38.838 3.079,34.917 2.952,33.208 3.118 M40.320 6.300 C 43.549 6.803,46.405 7.702,49.260 9.114 C 54.100 11.509,58.055 14.988,61.045 19.481 C 62.837 22.174,64.591 26.271,65.267 29.340 C 65.806 31.787,65.935 33.075,65.935 36.000 C 65.935 38.925,65.806 40.213,65.267 42.660 C 64.375 46.712,62.054 51.507,59.393 54.799 C 58.415 56.008,56.008 58.415,54.799 59.393 C 51.507 62.054,46.712 64.375,42.660 65.267 C 40.213 65.806,38.925 65.935,36.000 65.935 C 33.075 65.935,31.787 65.806,29.340 65.267 C 26.233 64.583,22.153 62.828,19.418 60.998 C 12.248 56.202,7.636 48.901,6.280 40.200 C 6.118 39.163,6.065 38.118,6.065 36.000 C 6.065 32.131,6.406 29.962,7.567 26.460 C 9.328 21.149,12.754 16.224,17.199 12.613 C 21.307 9.275,27.021 6.848,32.184 6.249 C 32.798 6.178,33.462 6.097,33.660 6.070 C 34.494 5.954,39.125 6.114,40.320 6.300 M35.220 23.419 C 35.088 23.492,34.887 23.666,34.773 23.806 C 34.582 24.039,34.565 24.571,34.563 30.360 L 34.560 36.660 42.033 44.130 L 49.506 51.600 50.029 51.600 C 50.486 51.600,50.617 51.534,51.076 51.076 C 51.534 50.618,51.600 50.486,51.600 50.030 L 51.600 49.509 44.520 42.422 L 37.440 35.335 37.437 29.698 C 37.434 23.555,37.458 23.772,36.758 23.414 C 36.425 23.244,35.534 23.247,35.220 23.419 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
