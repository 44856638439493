import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalPortal, Button } from '../../components';
import { IconCheckCircleXXL } from '../../icons';
import { parse } from '../../util/urlHelpers';
import { USER_ACTION_EMAIL_VERIFIED } from '../EmailVerificationPage/EmailVerificationPage';
import css from './VerifyEmailModal.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

export default () => {
    const [modalVisible, setModalVisibility] = useState(false);

    const history = useHistory();
    const { location } = history;
    const { action } = parse(location.search);

    const isEmailVerifiedAction = action === USER_ACTION_EMAIL_VERIFIED;

    useEffect(() => {
        if (isEmailVerifiedAction) {
            setModalVisibility(true);
        }
    }, [isEmailVerifiedAction]);

    const onClose = () => {
        setModalVisibility(false);

        history.replace(
            createResourceLocatorString('ContactDetailsPage', routeConfiguration(), {}, {})
        );
    };

    return (
        <ModalPortal
            id="contact-details-page-action-modal"
            isOpen={modalVisible}
            onClose={onClose}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
        >
            <IconCheckCircleXXL />
            <h3 className={css.modalHeading}>Gut gemacht!</h3>
            <p className={css.modalDesc}>Deine E-Mail-Adresse wurde erfolgreich verifiziert.</p>
            <footer className={css.modalAction}>
                <Button onClick={onClose}>Schliessen</Button>
            </footer>
        </ModalPortal>
    );
};
