import React, { useState } from 'react';
import css from './ActivityFeed.css';
import config from '../../config';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { trimDisplayNameLastWord } from '../../util/text';
import { USER_AVAILABILITY } from '../../forms/ProfileSettingsForm/config';
import EditUserInfoModal from '../../forms/ProfileSettingsForm/EditUserInfoModal';

const { userTypeRider } = config;

export default ({ currentUser, otherParty }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const {
        firstName: currentUserName,
        publicData: {
            userType: currentUserType,
            availabilityStatus: currentUserAvailabilityStatus,
        },
    } = currentUser.attributes.profile;

    const currentUserIsNotAvailable =
        currentUserType === userTypeRider &&
        currentUserAvailabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;

    const {
        displayName: otherPartyName,
        publicData: { userType: otherPartyType, availabilityStatus },
    } = otherParty.attributes.profile;

    const otherPartyIsRider = otherPartyType === userTypeRider;
    const otherPartyIsNotAvailable =
        otherPartyIsRider && availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;

    const riderName = otherPartyIsRider ? trimDisplayNameLastWord(otherPartyName) : currentUserName;

    const getNotification = () => {
        if (otherPartyIsNotAvailable) return <span>{riderName} ist nicht mehr verfügbar.</span>;
        if (currentUserIsNotAvailable)
            return (
                <>
                    <span>Du bist nicht mehr verfügbar.</span>
                    <br />
                    <span className={css.link} onClick={() => setModalOpen(true)}>
                        Verfügbarkeit bestätigen
                    </span>
                </>
            );
        return null;
    };

    const notification = getNotification();
    if (!notification) return null;

    return (
        <>
            <li className={css.transitionItem}>{notification}</li>
            {modalOpen && (
                <EditUserInfoModal
                    isOpen
                    editSectionName={USER_AVAILABILITY}
                    currentUser={currentUser}
                    modalParams={{ isConfirm: false }}
                    onClose={() => setModalOpen(false)}
                />
            )}
        </>
    );
};
