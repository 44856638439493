import React from 'react';

/** icon-message-l */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M1.803 0.045 C 1.533 0.090,1.079 0.308,0.839 0.507 C 0.429 0.846,0.119 1.366,0.043 1.840 C 0.014 2.015,0.001 4.610,0.001 9.780 C 0.001 18.219,-0.012 17.761,0.244 18.267 C 0.483 18.739,0.950 19.158,1.471 19.367 C 1.690 19.455,1.758 19.459,3.100 19.479 L 4.500 19.500 4.520 21.523 L 4.540 23.546 4.650 23.690 C 4.834 23.932,4.962 24.000,5.230 24.000 C 5.440 24.000,5.492 23.983,5.683 23.848 C 5.803 23.764,7.164 22.747,8.710 21.588 L 11.519 19.480 16.811 19.480 C 22.626 19.480,22.320 19.492,22.799 19.237 C 23.310 18.965,23.760 18.417,23.905 17.893 C 23.977 17.630,23.980 17.329,23.980 9.720 C 23.980 2.043,23.978 1.813,23.903 1.586 C 23.685 0.921,23.144 0.369,22.457 0.110 L 22.220 0.020 12.120 0.014 C 6.227 0.011,1.929 0.024,1.803 0.045 M22.040 1.555 C 22.186 1.635,22.358 1.803,22.434 1.940 C 22.495 2.050,22.501 2.662,22.511 9.620 C 22.518 14.954,22.508 17.242,22.478 17.390 C 22.426 17.641,22.284 17.824,22.062 17.925 C 21.907 17.995,21.653 17.998,16.540 17.999 C 13.137 18.000,11.128 18.015,11.038 18.040 C 10.951 18.064,9.945 18.792,8.468 19.900 C 7.132 20.901,6.031 21.720,6.021 21.720 C 6.012 21.720,5.998 20.987,5.992 20.090 C 5.981 18.534,5.976 18.454,5.899 18.321 C 5.854 18.245,5.755 18.146,5.679 18.101 C 5.546 18.024,5.459 18.019,3.780 18.000 C 2.217 17.982,2.006 17.972,1.895 17.911 C 1.740 17.827,1.570 17.629,1.530 17.487 C 1.513 17.428,1.500 13.933,1.500 9.720 L 1.500 2.060 1.598 1.888 C 1.703 1.705,1.888 1.568,2.094 1.518 C 2.163 1.501,6.657 1.491,12.080 1.494 C 20.353 1.499,21.956 1.509,22.040 1.555 "
            stroke="none"
            fillRule="evenodd"
            fill="#1A2B49"
        />
    </svg>
);
