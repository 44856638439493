import React from 'react';
// import { useSelector } from 'react-redux';
import { OverlayView } from 'react-google-maps';
import { CustomOverlayView } from './CustomOverlayView';
import { getPixelPositionOffset } from '../SearchMap.helpers.js';
import { getUserCurrentLocationSP } from '../../../util/localStorage.js';
import css from './CurrentLocationMarker.css';

export default () => {
    // const currentUser = useSelector(s => s.user.currentUser);
    // const isLoggedIn = Boolean(currentUser && currentUser?.id?.uuid);
    const currentLocationLS = getUserCurrentLocationSP() || {};
    const { origin } = currentLocationLS || {};

    return origin ? (
        <CustomOverlayView
            position={origin}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
        >
            <div className={css.marker}>
                <div />
            </div>
        </CustomOverlayView>
    ) : null;
};
