import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IconCheckCircleL, IconLock } from '../../icons';
import { createResourceLocatorString } from '../../util/routes';
import { composeValidators, emailFormatValid, required } from '../../util/validators';
import {
    FieldTextInput,
    FieldEmailAutocomplete,
    FieldAggregateTextInput,
    CopyToClickBoard,
} from '../../components';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import PreviewInviteModal from './PreviewInviteModal';
import { useInitialValues } from './useInitialValues';
import css from './InviteReferralsForm.css';

export default ({ form, values, messageRequired, children, errors }) => {
    const currentUser = useSelector(s => s.user.currentUser);

    useInitialValues({ form, currentUser });

    const { recipientsList, email } = values || {};

    const isErrorState = !!(email && errors.email);
    const isValidState =
        !isErrorState && Array.isArray(recipientsList) && recipientsList.length > 0;

    const {
        id: { uuid: userId },
        attributes: {
            profile: {
                firstName,
                lastName,
                publicData: { userType },
            },
        },
    } = currentUser;

    const noDuplicateValues = message => value =>
        (recipientsList || []).includes(value) ? message : undefined;

    const recommendPage = createResourceLocatorString(
        'AddExternalReview',
        routeConfiguration(),
        {
            userNameToRecommend: `${firstName}-${lastName}`,
            userId,
            userType,
        },
        {}
    );
    const copyLink = `${config.canonicalRootURL}${recommendPage}`;

    useEffect(() => {
        form.resetFieldState('message');
    }, [messageRequired]);

    return (
        <div className={css.root}>
            <p className={css.collectInfoDescription}>
                Bitte jemanden um eine Empfehlung, die anschliessend in deinem Profil angezeigt wird
                und deine Kenntnisse bestätigt.
            </p>
            <div className={css.benefitsList}>
                <p>
                    <IconCheckCircleL />
                    <span>Keine Registrierung erforderlich.</span>
                </p>
                <p>
                    <IconCheckCircleL />
                    <span>Verdoppelung deiner Erfolgschancen.</span>
                </p>
                <p>
                    <IconCheckCircleL />
                    <span>Erste Empfehlung = 20 Credits für dich.</span>
                </p>
            </div>
            <div>
                <h4>Mit Link einladen</h4>
                <CopyToClickBoard actionStrRepresentation={copyLink} actionString={copyLink} />
            </div>

            <p className={css.delimiter}>
                <span>oder</span>
            </p>

            <section className={css.recipientsListection}>
                <h4>Einladung senden</h4>
                <p className={css.msgSection}>
                    <IconLock />
                    <span>Die E-Mail-Adresse der Empfänger:innen wird nicht gespeichert.</span>
                </p>
                <div>
                    <FieldEmailAutocomplete
                        name="email"
                        id="email"
                        placeholder="Empfänger:innen hinzufügen"
                        aggregateFieldName="recipientsList"
                        form={form}
                        validate={composeValidators(
                            emailFormatValid('Wir brauchen eine gültige E-Mail-Adresse von dir'),
                            noDuplicateValues('Diese E-Mail-Adresse existiert bereits')
                        )}
                    >
                        <FieldAggregateTextInput
                            inputClass={isValidState ? 'FieldTextInput_inputSuccess_pseudo' : null}
                        />
                    </FieldEmailAutocomplete>
                    <aside className={css.recommendationsActionInfo}>
                        Enter-Taste drücken, um eine E-Mail-Adresse hinzuzufügen.
                    </aside>
                </div>

                <FieldTextInput
                    id="message"
                    name="message"
                    type="textarea"
                    placeholder="Deine Nachricht"
                    key={messageRequired}
                    validate={
                        messageRequired
                            ? composeValidators(required('Dieses Feld wird benötigt'))
                            : null
                    }
                />
            </section>
            {children}
            <PreviewInviteModal currentUser={currentUser} />
        </div>
    );
};
