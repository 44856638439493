import React from 'react';
import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import config from '../../../../config';
import css from './SectionAdvantages.css';
import {
    IconBulletListExtraLarge,
    IconClockXXL,
    IconFilterExtraLarge,
    IconHeartExtraLarge,
    IconLockExtraLarge,
    IconMessageExtraLarge,
    IconProfilCircleExtraLarge,
} from '../../../../icons';

const { listingTypeHorse, listingTypeRider } = config;

const iconsSearchForRider = [
    <IconBulletListExtraLarge />,
    <IconClockXXL />,
    <IconFilterExtraLarge />,
    <IconHeartExtraLarge />,
    <IconLockExtraLarge />,
    <IconMessageExtraLarge />,
];

const iconsSearchForHorse = [
    <IconBulletListExtraLarge />,
    <IconClockXXL />,
    <IconHeartExtraLarge />,
    <IconFilterExtraLarge />,
    <IconProfilCircleExtraLarge />,
    <IconMessageExtraLarge />,
];

const iconsConfig = {
    [listingTypeRider]: iconsSearchForRider,
    [listingTypeHorse]: iconsSearchForHorse,
};

export default ({ type }) => (
    <section className={css.root}>
        <div className={css.content}>
            <h2 className={css.subHeading}>
                <FormattedMessage id="SectionAdvantages.subHeading" />
            </h2>
            <p className={css.heading}>
                <FormattedMessage id={`SectionAdvantages.heading-${type}`} />
            </p>
            <p>
                <FormattedMessage id={`SectionAdvantages.description-${type}`} />
            </p>
        </div>

        <main className={css.advantagesHolder}>
            {iconsConfig[type].map((icon, index) => (
                <div key={index} className={css.advantagesItem}>
                    {icon}
                    <h3>
                        <FormattedMessage
                            id={`SectionAdvantages.advantage-heading-${index}-${type}`}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={`SectionAdvantages.advantage-desc-${index}-${type}`}
                        />
                    </p>
                </div>
            ))}
        </main>

        <footer className={css.footer}>
            <NamedLink
                name={type === 'horse' ? 'SignupRider' : 'SignupHorseowner'}
                className={css.subLinkPrimary}
            >
                <FormattedMessage id="SectionAdvantages.action" />
            </NamedLink>
        </footer>
    </section>
);
