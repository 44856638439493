import React from 'react';
import classNames from 'classnames';
import css from '../MapWithGoogleMap.css';
/** icon-control-bold-m */
export default ({ setZoom, zoom, minZoom = 21 }) => {
    const disabled = typeof minZoom === 'number' && zoom >= minZoom;

    return (
        <code
            className={classNames(css.zoomControlElemIn, {
                [css.disabled]: disabled,
            })}
            onClick={() => {
                if (disabled) return;
                setZoom(zoom + 1);
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    d="M7.862 1.058 C 7.597 1.145,7.424 1.298,7.331 1.529 L 7.268 1.686 7.260 4.342 L 7.252 6.998 4.752 6.999 C 3.366 7.000,2.161 7.012,2.048 7.027 C 1.519 7.095,1.264 7.414,1.264 8.004 C 1.264 8.594,1.519 8.913,2.048 8.982 C 2.161 8.996,3.366 9.009,4.752 9.009 L 7.252 9.010 7.260 11.666 L 7.268 14.322 7.331 14.479 C 7.407 14.667,7.556 14.820,7.749 14.909 C 7.875 14.967,7.933 14.974,8.259 14.973 C 8.574 14.972,8.647 14.963,8.760 14.911 C 8.960 14.820,9.104 14.655,9.176 14.436 C 9.236 14.252,9.237 14.236,9.237 11.631 L 9.237 9.012 11.879 9.003 C 14.274 8.995,14.534 8.990,14.663 8.947 C 14.923 8.858,15.115 8.662,15.194 8.404 C 15.239 8.254,15.239 7.755,15.194 7.605 C 15.115 7.346,14.923 7.150,14.663 7.062 C 14.534 7.018,14.274 7.013,11.879 7.005 L 9.237 6.996 9.237 4.377 C 9.237 1.772,9.236 1.757,9.176 1.572 C 9.103 1.352,8.959 1.187,8.760 1.099 C 8.592 1.024,8.041 0.999,7.862 1.058 "
                    stroke="none"
                    fillRule="evenodd"
                    fill="#1A2B49"
                />
            </svg>
        </code>
    );
};
