// ================ Action types ================ //

import { getUserCountryName } from '../util/location';
import { getPlaceDetailsFromString } from '../util/geocoder';
import { excludeTypes } from '../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import { isLocalhost, sanitizeLocationSearchString } from '../util/urlHelpers';
import { checkUserPredictionData, setUserPredictionData } from '../util/localStorage';

export const CURRENT_LOCATION_REQUEST = 'app/location/CURRENT_LOCATION_REQUEST';
export const CURRENT_LOCATION_SUCCESS = 'app/location/CURRENT_LOCATION_SUCCESS';

// ================ Reducer ================ //

const initialState = {
    currentLocation: null,
    currentLocationRequestInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case CURRENT_LOCATION_REQUEST:
            return {
                ...state,
                currentLocationRequestInProgress: true,
            };
        case CURRENT_LOCATION_SUCCESS:
            return { ...state, currentLocationRequestInProgress: false, currentLocation: payload };
        default:
            return state;
    }
}

// ================ Action creators ================ //

export const locationRequest = () => ({ type: CURRENT_LOCATION_REQUEST });
export const locationRequestSuccess = payload => ({ type: CURRENT_LOCATION_SUCCESS, payload });

// ================ Selectors ================ //

export const locationRequestInProgress = state => state.location.currentLocationRequestInProgress;

// ================ Thunks ================ //

export const requestCurrentLocation = () => (dispatch, getState) => {
    if (locationRequestInProgress(getState())) {
        if (isLocalhost()) {
            console.log('Location request already in progress');
        }
        return;
    }

    dispatch(locationRequest());

    const { currentUser } = getState().user;

    if (!currentUser || !currentUser.id) {
        return dispatch(locationRequestSuccess(null));
    }

    const { city, postalCode } = currentUser.attributes.profile.publicData;

    const locationUnitsStr = `${postalCode} ${city}, ${getUserCountryName()}`;

    getPlaceDetailsFromString({ locationUnitsStr, excludeTypes })
        .then(response => {
            dispatch(locationRequestSuccess(response));
            /**
             * Re-set the local storage values with an extra params;
             * Here we want the current location to be stored in
             * the storage permanently, so the timestamp is set to Infinity
             */
            /** same as for googleMaps.js getPlacePredictions function  */
            const search = sanitizeLocationSearchString(locationUnitsStr);
            /**
             * prediction data has to be already set to local storage
             * after successful getPlacePredictions call
             */
            const userPredictionData = checkUserPredictionData(search);

            if (userPredictionData && userPredictionData.data) {
                /**
                 * local storage value is stringified, pure Infinity is converted to null;
                 * in order to avoid it, the Infinity is converted to a string 'Infinity'
                 */
                setUserPredictionData(search, userPredictionData.data, { timestamp: 'Infinity' });
            }
        })
        .catch(e => {
            if (isLocalhost()) {
                console.log('requestCurrentLocation failed:' + e.message);
            }
            dispatch(locationRequestSuccess(null));
        });
};
