import { useEffect } from 'react';
import { FILL_ADDRESS_STEP } from '../EntityLocationFormFields/helpers';

export const useNewInitialLocationValues = ({ form, currentListing, values, step }) => {
    /**
     * if there is no addressDetails yet, means a listing is a draft (new),
     * and a user wants to fill the location manually, means no data has been predicted,
     * set form location fields from user location data
     */
    const {
        city: userCity,
        postalCode: userPostalCode,
        houseNumber: userHouseNumber,
        streetAddress: userStreetAddress,
        country: userCountry,
    } = currentListing.author.attributes.profile.publicData;

    const isLocationStep = step === FILL_ADDRESS_STEP;
    const isPresaved = !!values.addressDetails;
    const isPredicted =
        values.location ||
        values.city ||
        values.streetAddress ||
        values.houseNumber ||
        values.postalCode;

    useEffect(() => {
        if (!form) return;
        if (!isLocationStep) return;
        if (isPredicted) return;
        if (isPresaved) return;

        const { change: changeFormField } = form;

        changeFormField('city', userCity);
        changeFormField('postalCode', userPostalCode);
        changeFormField('houseNumber', userHouseNumber);
        changeFormField('streetAddress', userStreetAddress);
        changeFormField('country', userCountry);
    }, [
        isLocationStep,
        isPredicted,
        isPresaved,
        userCity,
        userPostalCode,
        userHouseNumber,
        userStreetAddress,
        userCountry,
    ]);
};
