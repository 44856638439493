import config from '../../config';

const { userTypeHorseowner } = config;

const ownHorsesId = 27246;

export const disabledGroupsConfig = {
    20092: true,
    27244: true,
};

export const getGroupFormKey = ({ name, id }) => `${name}_${id}`;

export const sanitizeItemByType = userType => ({ id }) =>
    id == ownHorsesId ? userType === userTypeHorseowner : true;
