import React from 'react';
import { validFilterParams } from '../../../containers/SearchPage/SearchPage.helpers';
import routeConfiguration from '../../../routeConfiguration';
import { CURRENT_LOCATION_ID } from '../../../util/googleMaps';
import { createResourceLocatorString } from '../../../util/routes';
import { parse } from '../../../util/urlHelpers';
import { IconCompassFilledL, IconCompassL } from '../../../icons';

import Geocoder from '../../LocationAutocompleteInput/GeocoderGoogleMaps';
import css from '../MapWithGoogleMap.css';
import { setUserCurrentLocationSP } from '../../../util/localStorage';
import { userLocationSearchParamsMatch } from '../../../util/location';
import { fitMapToBounds } from '../SearchMap.helpers';

const currentLocationData = {
    id: CURRENT_LOCATION_ID,
    predictionPlace: {},
};

export default ({ location, filters, history, mapEl }) => {
    const { page, ...searchInURL } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });
    const { mapSearch, listingType, ...rest } = searchInURL;

    const isCurrentUserLocation = userLocationSearchParamsMatch(rest);

    const navigateToUserLocation = () => {
        const geocoder = new Geocoder();

        geocoder.getPlaceDetails(currentLocationData).then(({ address, origin, bounds }) => {
            const searchParams = {
                address,
                origin,
                bounds,
                mapSearch: false,
                listingType,
                ...validFilterParams(rest, filters),
                mapView: rest.mapView,
            };

            setUserCurrentLocationSP({ address, origin });

            history.push(
                createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
            );

            mapEl &&
                fitMapToBounds({
                    map: mapEl,
                    bounds,
                });
        });
    };

    return (
        <code className={css.currentLocationControlElem} onClick={() => navigateToUserLocation()}>
            {isCurrentUserLocation ? (
                <IconCompassFilledL rootClassName={css.currentLocationMarkerFilled} />
            ) : (
                <IconCompassL />
            )}
        </code>
    );
};
