import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import css from '../ProfileSettingsForm.css';
import sectionCss from './AwardsSection.css';
import AwardIcon from '../icons/AwardIcon';
import { AWARDS_ID } from '../NavigationBar';
import { IconSpinner } from '../../../components';
import { AWARDS_DOC } from '../../../marketplace-custom-config';
import { USER_AWARDS } from '../config';
import LogoutOverlay from '../LogoutOverlay';

const AwardsSection = ({
    renderEditPencilIcon,
    uploadDocumentsInProgress,
    userDocuments,
    handleEditSection,
    setModalParams,
    awards,
    isPublic,
    isLoggedOut,
    userName,
    setPreviewModalOpen,
    setPreviewDoc,
    switchUserRoleAction,
    pageParams,
}) => {
    const noAwardsData = !awards || awards.length === 0;

    if (isPublic && noAwardsData) {
        return null;
    }

    const logoutOverlayVisible = isLoggedOut || switchUserRoleAction;

    const classes = classNames({
        [css.infoBlock]: true,
        [css.noInfoBlock]: noAwardsData,
        [css.viewProfileRequestWrapper]: logoutOverlayVisible,
        [sectionCss.viewProfileRequestWrapper]: logoutOverlayVisible,
    });

    return (
        <div className={classes} id={AWARDS_ID}>
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.awardsHeading" />
            </h2>
            {!noAwardsData ? (
                <section>
                    {awards.map(({ awardName, organizationName, awardYear }, i) => {
                        const document =
                            userDocuments &&
                            userDocuments[AWARDS_DOC] &&
                            userDocuments[AWARDS_DOC].find(
                                ({ name, year, organization }) =>
                                    name === awardName &&
                                    organizationName === organization &&
                                    String(awardYear) === year
                            );
                        return (
                            <React.Fragment key={`${awardName}${organizationName}${awardYear}`}>
                                {i > 0 && <div className={sectionCss.divider} />}
                                <div className={sectionCss.awardsHolder}>
                                    <div className={sectionCss.awardInfoHolder}>
                                        <AwardIcon />
                                        <div className={sectionCss.awardInfo}>
                                            <p>{awardName}</p>
                                            <p>{organizationName}</p>
                                            <p>{awardYear}</p>
                                        </div>
                                    </div>
                                    <div
                                        className={css.editBlock}
                                        onClick={() =>
                                            setModalParams({
                                                awardName,
                                                organizationName,
                                                awardYear,
                                            })
                                        }
                                    >
                                        {renderEditPencilIcon(
                                            USER_AWARDS,
                                            null,
                                            <p className={css.editText}>
                                                <FormattedMessage id="ProfileSettingsForm.editText" />
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {uploadDocumentsInProgress ? (
                                    <IconSpinner />
                                ) : (
                                    <p
                                        className={`${sectionCss.docBlock} ${document &&
                                            sectionCss.docExists}`}
                                    >
                                        {document ? (
                                            <span
                                                className={css.previewLink}
                                                onClick={() => {
                                                    setPreviewModalOpen(true);
                                                    setPreviewDoc(document);
                                                }}
                                            >
                                                <FormattedMessage id="ProfileSettingsForm.sectionDoc" />
                                            </span>
                                        ) : (
                                            <FormattedMessage id="ProfileSettingsForm.noQualificationDocuments" />
                                        )}
                                    </p>
                                )}
                            </React.Fragment>
                        );
                    })}
                </section>
            ) : (
                <div className={`${sectionCss.placeholderWrapper} ${css.placeholderWrapper}`}>
                    <main className={css.flex}>
                        <div className={css.placeholderAvatar} />
                        <div className={css.placeholderUserInfo}>
                            <div className={css.placeholderItem} />
                            <div className={css.placeholderItem} />
                            <div className={css.placeholderItem} />
                        </div>
                    </main>
                    <footer>
                        <div className={css.placeholderItem} />
                        <div className={css.placeholderItem} />
                        <div className={css.placeholderItem} />
                    </footer>
                </div>
            )}
            {!isPublic && (
                <button
                    className={`${css.actionItem} ${css.actionBtn}`}
                    onClick={() => {
                        handleEditSection(USER_AWARDS);
                        setModalParams({});
                    }}
                >
                    <FormattedMessage id="ProfileSettingsForm.awardsAction" />
                </button>
            )}
            <LogoutOverlay
                userName={userName}
                isLoggedOut={isLoggedOut}
                rootClassName={sectionCss.viewProfileRequestAction}
                sectionName={USER_AWARDS}
                switchUserRoleAction={switchUserRoleAction}
                pageParams={pageParams}
            />
        </div>
    );
};

export default AwardsSection;
