import { ultimateGoogleMapsCountriesConfig } from '../../util/googleMaps';
import config from '../../config';
import { capitalizeFormEntry } from '../../util/text';

const { stripe } = config;
const { supportedCountries: stripeSupportedCountries } = stripe;

const countriesOrderConfig = { CH: 0, DE: 1, AT: 2, NL: 3 };

export const PREDICT_LOCATION_STEP = 'location';
export const FILL_ADDRESS_STEP = 'address';

// ['CH', 'DE', 'AT', 'NL'];
const allowedCodes = Object.values(ultimateGoogleMapsCountriesConfig).map(({ abbr }) =>
    abbr.toUpperCase()
);
/**
 * @param {*} typeToFind route, postal_code, etc
 * @param {*} addressComponents google api address_components
 * @returns string
 */
export const findByType = (typeToFind, addressComponents) =>
    (addressComponents.find(({ types = [] }) => types.includes(typeToFind)) || {}).long_name;

export const availableCountries = stripeSupportedCountries
    .reduce((allCountries, country) => {
        const { code } = country;
        if (allowedCodes.includes(code)) {
            allCountries.push(country);
        }

        return allCountries;
    }, [])
    .sort((a, b) => countriesOrderConfig[a.code] - countriesOrderConfig[b.code]);

export const getSearchConfigurations = userCountry =>
    userCountry
        ? {
              componentRestrictions: {
                  country: [(userCountry || '').toLowerCase()],
              },
          }
        : null;

export const prettifyFormValue = (fieldName, form) => {
    const { value } = form.getFieldState(fieldName) || {};

    if (typeof value === 'string') {
        capitalizeFormEntry(fieldName, form);
    }

    form.change(fieldName, value.trim());
};
