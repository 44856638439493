import React from 'react';
import { bool, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
    LayoutWrapperAccountSettingsSideNav,
    LayoutSideNavigation,
    LayoutWrapperTopbar,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    Button,
    Footer,
    Page,
    Form,
} from '../../components';
// import { PayoutDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { savePersonalInfoDetails, loadData } from './PersonalInfoPage.duck';

import css from './PersonalInfoPage.css';
import { EntityLocationFormFields } from '../../forms';
import { IconLock } from '../../icons';

export const PersonalInfoPageComponent = ({
    onPersonalInfoDetailsFormSubmit,
    personalInfoDetailsError,
    scrollingDisabled,
    submitInProgress,
    currentUser,
    intl,
}) => {
    const {
        city,
        country,
        userType,
        streetAddress,
        houseNumber,
        postalCode,
        userLocation,
    } = currentUser.attributes.profile.publicData;

    return (
        <Page
            title={intl.formatMessage({ id: 'PersonalInfoPage.title' })}
            scrollingDisabled={scrollingDisabled}
        >
            <LayoutSideNavigation>
                <LayoutWrapperTopbar>
                    <TopbarContainer
                        currentPage="PersonalInfoPage"
                        desktopClassName={css.desktopTopbar}
                        mobileClassName={css.mobileTopbar}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperAccountSettingsSideNav currentTab="PersonalInfoPage" />
                <LayoutWrapperMain blobBackground className={css.layoutWrapperMain}>
                    <div className={css.content}>
                        <h1 className={css.title}>
                            <FormattedMessage id="PersonalInfoPage.heading" />
                        </h1>
                        <FinalForm
                            initialValues={{
                                streetAddress: (streetAddress || '').trim(),
                                city: (city || '').trim(),
                                houseNumber,
                                postalCode,
                                country,
                                userLocation,
                            }}
                            onSubmit={values => {
                                const { location, ...rest } = values;
                                const userLocation = location || rest.userLocation;

                                if (!userLocation) return;

                                onPersonalInfoDetailsFormSubmit({
                                    ...values,
                                    userLocation,
                                });
                            }}
                            render={fieldRenderProps => {
                                const {
                                    handleSubmit,
                                    disabled,
                                    pristine,
                                    ready,
                                    errors,
                                } = fieldRenderProps;

                                const isError = !!Object.keys(errors).length;
                                const submitDisabled =
                                    pristine ||
                                    isError ||
                                    disabled ||
                                    submitInProgress ||
                                    !userLocation;

                                return (
                                    <Form onSubmit={handleSubmit} className={css.form}>
                                        <EntityLocationFormFields
                                            {...fieldRenderProps}
                                            disabledFields={{ country: true }}
                                            suppressMap
                                        />
                                        <p className={css.contactSupport}>
                                            Bitte{' '}
                                            <a
                                                href="https://www.horsedeal.com/faq"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                kontaktiere unseren Support
                                            </a>
                                            , um das Land zu ändern.
                                        </p>
                                        <p className={css.dataProtectionInfo}>
                                            <IconLock />
                                            Deine Kontaktdaten sind nicht öffentlich sichtbar.
                                        </p>
                                        <Button
                                            type="submit"
                                            inProgress={submitInProgress}
                                            disabled={submitDisabled}
                                            ready={ready}
                                        >
                                            Änderungen Speichern
                                        </Button>
                                        {personalInfoDetailsError && (
                                            <p className={css.error}>
                                                Hoppla, da ist etwas schiefgelaufen.
                                            </p>
                                        )}
                                    </Form>
                                );
                            }}
                        />
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter className={css.footer}>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSideNavigation>
        </Page>
    );
};

PersonalInfoPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    scrollingDisabled: bool.isRequired,
    personalInfoDetailsSaveInProgress: bool.isRequired,
    onPersonalInfoDetailsFormSubmit: func.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { currentUser } = state.user;
    const { personalInfoDetailsSaveInProgress, personalInfoDetailsError } = state.PersonalInfoPage;
    return {
        currentUser,
        submitInProgress: personalInfoDetailsSaveInProgress,
        personalInfoDetailsError,
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const mapDispatchToProps = dispatch => ({
    onPersonalInfoDetailsFormSubmit: values => dispatch(savePersonalInfoDetails(values)),
});

const PersonalInfoPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(PersonalInfoPageComponent);
PersonalInfoPage.loadData = loadData;

export default PersonalInfoPage;
