import React from 'react';
import { NamedLink, Followers } from '../../../../components';

import SectionHeroHeader from '../SectionHero/SectionHeroHeader';

import css from './SectionHeroLoggedOut.css';

export default () => (
    <section className={css.root}>
        <div className={css.container}>
            <div className={css.column}>
                <SectionHeroHeader
                    rootClassName={css.headerRoot}
                    subHeading="Reitbeteiligung mit HorseDeal"
                    mainHeading="Hier findest Du deine"
                    description="Wir machen die Suche nach einem Pferd oder nach passenden Pferdemenschen für dein Pferd einfacher und transparenter als jemals zuvor."
                />
                <footer className={css.footer}>
                    <NamedLink name="SignupPage" className={css.subLinkPrimary}>
                        Kostenlos registrieren
                    </NamedLink>
                    <Followers rootClassName={css.followers}>
                        <p>
                            Sarah, Julia, David und 30’000 weitere sind bereits Teil unserer
                            Community.
                        </p>
                    </Followers>
                </footer>
            </div>
            <div className={css.column}>
                <img
                    src={
                        'https://horsedeal.imgix.net/static/landingPage/horsedeal-reitbeteiligung.webp'
                    }
                    alt="our-customers"
                />
            </div>
        </div>
    </section>
);
