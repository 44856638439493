import React, { useState } from 'react';
import classNames from 'classnames';
import { Slide } from 'pure-react-carousel';

import { IconSpinner, NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import { useIsMobile } from '../../../../hooks/useIsMobile';

import Carousel from '../../../../components/ListingCardsCarousel/Carousel';
import { useReviews } from '../../../../hooks/useReviews';
import { useIsMounted } from '../../../../hooks/useIsMounted';

import css from './SectionCommunityFeedbacks.css';
import { ReviewItem } from './ReviewItem';
import { ReviewsSummary } from './ReviewsSummary';
import { adaptToSafariTime } from '../../../../util/ios';

export default ({ subHeadingId, headingId, descriptionId, type }) => {
    const [previewedForward, setPreviewedForward] = useState(false);
    const [previewedBackward, setPreviewedBackward] = useState(false);
    const appIsMounted = useIsMounted();

    const [isMobile, , computing] = useIsMobile(768);
    const [reviewsData, loading, error] = useReviews({
        allowed: !computing,
        perPage: isMobile ? 10 : 26,
    });

    const { review_count: reviewCount, average_rating: averageRating, reviews } = reviewsData;

    if (error || !Array.isArray(reviews) || reviews.length === 0) return null;
    if (loading) return <IconSpinner />;

    const schemaData = {
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        name: 'HorseDeal | Die Sharing-Plattform für Pferde',
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: averageRating,
            reviewCount: reviewCount,
        },
        review: reviews.map(review => ({
            '@type': 'Review',
            author: {
                '@type': 'Person',
                name: review.author.name.replace(/\t/g, ' '),
            },
            reviewRating: {
                '@type': 'Rating',
                ratingValue: review.rating,
            },
            reviewBody: review.comments,
            datePublished: adaptToSafariTime(review.date_created),
        })),
    };

    const leftIndexReviews = reviews.slice(0, 13);
    const rightIndexReviews = reviews.slice(13);

    return (
        <section className={css.root}>
            <header className={css.header}>
                <h2 className={css.subHeading}>
                    <FormattedMessage id={subHeadingId || 'SectionCommunityFeedbacks.subHeading'} />
                </h2>
                <p className={css.heading}>
                    <FormattedMessage id={headingId || 'SectionCommunityFeedbacks.heading'} />
                </p>
                <p className={css.description}>
                    {descriptionId ? (
                        <FormattedMessage id={descriptionId} />
                    ) : (
                        `Wir setzen alles daran, für dich den passenden Treffer zu finden. \nMehr als 1'000 positive Bewertungen beweisen es.`
                    )}
                </p>
            </header>
            <main>
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
                <ReviewsSummary averageRating={averageRating} reviewCount={reviewCount} />

                {appIsMounted && isMobile && (
                    <Carousel
                        isMobile
                        items={reviews}
                        sliderVisibleSlides={1}
                        minItemsCount={1}
                        sliderClassName={css.mobSliderClassName}
                        isIntrinsicHeight={true}
                        rendererSingleItem={() => null}
                        rendererCarousel={(item, index) => (
                            <Slide index={index} key={index}>
                                <ReviewItem {...item} isMobile />
                            </Slide>
                        )}
                    />
                )}
                {appIsMounted && !isMobile && (
                    <>
                        <div
                            className={classNames(css.carousel, css.forward, {
                                [css.previewed]: previewedForward,
                            })}
                            onMouseOver={() => setPreviewedForward(true)}
                            onMouseLeave={() => setPreviewedForward(false)}
                        >
                            <div>
                                {leftIndexReviews.map((item, index) => (
                                    <ReviewItem key={index} {...item} />
                                ))}
                            </div>
                            <div>
                                {leftIndexReviews.map((item, index) => (
                                    <ReviewItem key={index} {...item} />
                                ))}
                            </div>
                        </div>

                        <div
                            className={classNames(css.carousel, css.backward, {
                                [css.previewed]: previewedBackward,
                            })}
                            onMouseOver={() => setPreviewedBackward(true)}
                            onMouseLeave={() => setPreviewedBackward(false)}
                        >
                            <div>
                                {rightIndexReviews.map((item, index) => (
                                    <ReviewItem key={index} {...item} />
                                ))}
                            </div>
                            <div>
                                {rightIndexReviews.map((item, index) => (
                                    <ReviewItem key={index} {...item} />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </main>

            <footer className={classNames(css.footer, css.linksHolder)}>
                <NamedLink
                    name={type === 'horse' ? 'SignupRider' : 'SignupHorseowner'}
                    className={css.subLinkPrimary}
                >
                    <FormattedMessage id="SectionCommunityFeedbacks.action" />
                </NamedLink>
            </footer>
        </section>
    );
};
