import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { checkMarketplaceCurrentUser } from '../../util/data';
import { decodeLatLngBounds, encodeLatLngBounds, parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { SearchMap, Page } from '../../components';
import { TopbarContainer } from '../../containers';
import { updateUserProfileInfo } from '../../ducks/user.duck';
import {
    setMapBounds,
    searchListings,
    searchMapListings,
    combineSearchMapListings,
    discardVisitedListings,
    setListingScores,
} from './SearchPage.duck';
import {
    pickSearchParamsOnly,
    validURLParamsForExtendedData,
    validFilterParams,
    createSearchResultSchema,
    getFilters,
    applyVerticalSpacing,
    defaultSearchParams,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';
import { useIsMobile } from '../../hooks/useIsMobile';
import { getUserCountry } from '../../util/location';
import { useListings } from '../../hooks/useListings';
import { getMatchingScore } from '../../util/api';
import { resolveStatusByMatchingMatrixScore, SCORE_INSUFFICIENT } from '../../util/user';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 40;
const MOBILE_BREAKPOINT = 1024; // Search is in modal on mobile layout
// const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const {
    initialSearchAddress,
    listingTypeHorse,
    listingTypeRider,
    userTypeRider,
    userTypeHorseowner,
} = config;
const userCountry = getUserCountry();
const { bounds: countryBounds, address: countryAddress } = initialSearchAddress[userCountry];

const SearchPageComponent = ({
    history,
    location,
    params,
    currentUser,
    onUpdateUserProfileInfo,
    intl,
    searchMapListingIdsStr,
    scrollingDisabled: scrollingDisabledProp,
    updateInfoInProgress,
    updateInfoError,
    onSearchListings,
    onSearchMapListings,
    onCombineSearchMapListings,
    onDiscardingVisitedListings,
    activeListingId,
    mapBoundsString,
    onMapBoundsSetting,
    searchMapListingInProgess,
    onSettingListingScoresBunch,
    ...rest
}) => {
    const [mapFullScreenViewed, setMapFullScreenViewed] = useState(false);
    const [mapPanelOpen, setMapPanelOpen] = useState(false);
    const [isMobileLayout, , computing] = useIsMobile(MOBILE_BREAKPOINT);
    const [mapVerticalSpacingAdjusted, setMapVerticalSpacingAdjusted] = useState(false);
    const [riderListing, setRiderListing] = useState(null);

    const locationSearch = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });
    /**
     * isLandingPage - the page is rendered inside lp in iframe,
     * no need to show top bar and map
     */
    const { page, mobilesearch, distance, mapView, isLandingPage, ...searchInURL } = locationSearch;
    const { listingType, sort, extraSort, address, bounds, origin, ...otherParamsAndFilters } =
        searchInURL || {};
    const { mapSearch, ...filtersSearchParams } = otherParamsAndFilters || {};

    const mapViewMaybe = mapView ? { mapView: true } : {};
    const distanceMaybe = distance ? { distance } : {};

    const filtersSearchParamsString = JSON.stringify(filtersSearchParams || {});
    const searchBoundsString = encodeLatLngBounds(bounds);

    const currentUserId = currentUser && currentUser.id && currentUser.id.uuid;
    const currentUserType = currentUserId
        ? currentUser.attributes.profile.publicData.userType
        : null;
    const mainHorseId = currentUserId
        ? currentUser.attributes.profile.publicData.mainHorseId
        : null;

    const isRider = currentUserType === userTypeRider;
    const isHorseOwner = currentUserType === userTypeHorseowner;
    const isHorsesSearch = listingType === listingTypeHorse;
    const isRidersSearch = listingType === listingTypeRider;
    /**
     * a horseowner looks on a horse listing;
     * not allowed, edge case is handled
     */
    const ownersView = isHorseOwner && isHorsesSearch;
    /**
     * no matching
     * - when rider searches for other riders
     * - when owner searches for other horses
     */
    const riderViewsRider = isRider && isRidersSearch;

    const matchingAllowed = Boolean(
        currentUserType &&
            !riderViewsRider &&
            !ownersView &&
            (isRider ? riderListing : true) &&
            (isHorseOwner ? mainHorseId : true) &&
            !searchMapListingInProgess &&
            searchMapListingIdsStr &&
            mapPanelOpen
    );

    const [riderListings] = useListings({
        params: {
            authorId: currentUserId,
        },
        allowed: !!currentUserId && isRider,
    });

    const handleListingsSearch = (params, search) => {
        onSearchListings(params, search)
            .then(response => {
                const { status, data } = response;
                const isSuccessfulResp = status === 200 && data && data.data;

                if (isSuccessfulResp /* && !mapView */) {
                    onCombineSearchMapListings();
                }
            })
            .catch(() => {
                // do nothing
            });
    };

    useEffect(() => {
        if (Array.isArray(riderListings)) {
            setRiderListing(
                riderListings.find(
                    ({
                        attributes: {
                            publicData: { userRepresentationId },
                        },
                    }) => userRepresentationId === currentUserId
                )
            );
        }
    }, [riderListings, currentUserId]);

    useEffect(() => {
        if (!matchingAllowed) return;

        const searchMapListingIds = searchMapListingIdsStr.split(',');

        Promise.all(
            searchMapListingIds.map(async id => {
                /** list of horses + rider listing id */
                const riderMatchingParams = {
                    riderListingId: riderListing,
                    horseOwnerListingId: id,
                };
                /** list of riders + main horse id */
                const ownerMatchingParams = {
                    riderListingId: id,
                    horseOwnerListingId: mainHorseId,
                };

                const scoreParams = isRider ? riderMatchingParams : ownerMatchingParams;

                const scoreRes = await getMatchingScore(scoreParams);
                const scorePalette =
                    scoreRes && scoreRes.finalScore
                        ? resolveStatusByMatchingMatrixScore(scoreRes.finalScore)
                        : SCORE_INSUFFICIENT;

                return { score: scorePalette, uuid: id };
            })
        ).then(data =>
            onSettingListingScoresBunch(
                data.reduce((acc, { score, uuid }) => ({ ...acc, [uuid]: score }), {})
            )
        );
    }, [matchingAllowed, searchMapListingIdsStr]);

    useEffect(() => {
        if (!isLandingPage) return;

        handleListingsSearch({ bounds }, location.search);
    }, [isLandingPage]);

    useEffect(() => {
        // discard visited listings on a new session
        onDiscardingVisitedListings();

        return () => {
            onMapBoundsSetting(null);
        };
    }, []);

    useEffect(() => {
        // scroll to active listing, e.g. when a user comes from LP
        if (!activeListingId) return;
        if (typeof isMobileLayout === 'undefined') return;

        setTimeout(() => {
            const listingCardNode = document.getElementById(`ListingCard-${activeListingId}`);
            listingCardNode &&
                listingCardNode.scrollIntoView(
                    isMobileLayout ? { block: 'end' } : { block: 'end', behavior: 'smooth' }
                );
        }, 500);
    }, [isMobileLayout]);

    useEffect(() => {
        setMapPanelOpen(!!mapView);
    }, [mapView]);

    useEffect(() => {
        /**
         * skip use effect on the mount phase
         */
        if (computing) return;
        /**
         * remove bounds on page navigation,
         * e.g. when back button is clicked
         */
        onMapBoundsSetting(null);
    }, [address, searchBoundsString]);

    useEffect(() => {
        if (!mapBoundsString) return;

        if (mapPanelOpen) {
            onSearchMapListings({ bounds: decodeLatLngBounds(mapBoundsString) }, location.search);
        } else {
            onCombineSearchMapListings();
        }
    }, [mapPanelOpen, mapBoundsString, listingType]);

    useEffect(() => {
        if (!mapBoundsString) return;
        // if (mapPanelOpen) return;

        handleListingsSearch({ bounds: decodeLatLngBounds(mapBoundsString) }, location.search);
    }, [
        mapPanelOpen,
        page,
        mapBoundsString,
        listingType,
        sort,
        extraSort,
        filtersSearchParamsString,
    ]);

    const { searchGroupIdToDelete } = params || {};

    const filters = getFilters({ intl, ...rest }, searchInURL);

    const navigateToSearchPage = (params = {}) => {
        const searchParams = {
            page,
            distance,
            ...searchInURL,
            ...validFilterParams(rest, filters),
            ...params,
        };

        history.push(
            createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        );
    };

    // Callback to determine if new search is needed
    // when map is moved by user or viewport has changed
    const onMapMoveEnd = (viewportBoundsChanged, data) => {
        const { viewportBounds, viewportMapCenter: viewportCenter } = data;
        const searchPagePath = pathByRouteName('SearchPage', routeConfiguration());
        const currentPath =
            typeof window !== 'undefined' && window.location && window.location.pathname;

        // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
        const isSearchPage = currentPath === searchPagePath;
        // If mapSearch url param is given
        // or original location search is rendered once,
        // we start to react to "mapmoveend" events by generating new searches
        // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)

        if (viewportBoundsChanged && isSearchPage && !computing && !mobilesearch) {
            /**
             * sort is removed in onSearchListings func
             */
            const originMaybe = /* sort ? {} : */ { origin: viewportCenter };

            const searchParams = {
                page: 1,
                ...validFilterParams(rest, filters),
                ...searchInURL,
                address: 'Kartenbereich',
                ...originMaybe,
                ...mapViewMaybe,
                bounds: viewportBounds,
                mapSearch: true,
            };
            /**
             * no radius/distance param on moving map
             */
            delete searchParams.distance;

            history.push(
                createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
            );
        }
    };

    const handleSubmit = values => {
        const { search, selectedPlace } = values.location;
        const { origin, bounds } = selectedPlace;

        const searchParams = {
            ...urlQueryParams,
            address: search,
            bounds,
            origin,
        };

        history.push(
            createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        );
    };

    useEffect(() => {
        /**
         * add Y spacing to and reduce the height of the map in order
         * to prevent overlapping with topbar, email confirmation bar and fitlers
         */

        /**
         * wait until email notification is made
         * visible/hidden
         * on map modal
         * closing/opening
         *
         * see applyVerticalSpacing for SF
         */
        if (isMobileLayout) {
            setMapVerticalSpacingAdjusted(true);
        }

        if (computing || isMobileLayout) return;

        setTimeout(() => {
            applyVerticalSpacing();
            setMapVerticalSpacingAdjusted(true);
        }, 0);
    }, [isMobileLayout, computing, mapPanelOpen]);

    useEffect(() => {
        /**
         * searchGroupIdToDelete is an key in searchGroups object;
         *
         * here we handle cases when a user wants to unsubscribe
         * from current search group notifications;
         *
         * there two ways to accomplish it:
         * 1. to click on ring icon;
         * 2. to navigate to the current page with search group id in params (used in emails);
         */
        if (searchGroupIdToDelete) {
            const routes = routeConfiguration();
            const defaultSearchParams = {
                address: countryAddress,
                bounds: countryBounds,
            };

            const resourceLocatorString = createResourceLocatorString(
                'SearchPage',
                routes,
                {},
                defaultSearchParams
            ).replace(/%252C/g, '%2C');

            const protectedData = currentUser ? currentUser.attributes.profile.protectedData : null;
            const searchGroups = protectedData ? protectedData.searchGroups : null;
            const searchGroupExists = searchGroups && searchGroups[searchGroupIdToDelete];

            if (!currentUser || !searchGroupExists) {
                /** if there is no such id, just redirect without updating user profile */

                return history.push(resourceLocatorString);
            }
            const searchGroupsUpdated = {
                ...searchGroups,
            };

            delete searchGroupsUpdated[searchGroupIdToDelete];

            const userProfileParams = {
                protectedData: { ...protectedData, searchGroups: { ...searchGroupsUpdated } },
            };

            onUpdateUserProfileInfo(userProfileParams).then(() => {
                history.push(resourceLocatorString);
            });
        }
    }, [searchGroupIdToDelete]);

    const scrollingDisabled = Boolean(scrollingDisabledProp || (isMobileLayout && mapPanelOpen));

    useEffect(() => {
        const body = document.querySelector('body');
        const action = scrollingDisabled ? 'add' : 'remove';

        if (!body) return;
        /** see marketplaceIndex.css */
        body.classList[action]('scrollingDisabled');

        return () => {
            body.classList.remove('scrollingDisabled');
        };
    }, [scrollingDisabled]);

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)

    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    // const urlQueryString = stringify(urlQueryParams);
    // const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    // const searchParamsAreInSync = urlQueryString === paramsQueryString;
    const validQueryParams = validURLParamsForExtendedData(
        { ...searchInURL, ...mapViewMaybe, ...distanceMaybe },
        filters
    );

    const { title, description, schema } = createSearchResultSchema(address, intl);

    const showTopbarWhenMapOpen = isMobileLayout ? !mapPanelOpen : true;

    const applyHeightToMobileMap = () => {
        if (typeof document !== 'object') return {};

        return { height: `${window.innerHeight - 72}px` };
    };

    const isMapSearch = address === 'Kartenbereich';
    const applyNoIndexTag = isMapSearch
        ? [
              {
                  name: 'robots',
                  content: 'noindex',
              },
          ]
        : [];

    return (
        <Page
            scrollingDisabled={scrollingDisabled}
            description={description}
            title={title}
            schema={schema}
            className={css.page}
            metaTags={applyNoIndexTag}
        >
            {!isLandingPage && (
                <TopbarContainer
                    className={classNames(css.topbar, {
                        [css.topbarHidden]: !showTopbarWhenMapOpen,
                    })}
                    desktopClassName={css.topbarDesktop}
                    currentPage="SearchPage"
                    currentSearchParams={urlQueryParams}
                    rawSearchParams={searchInURL}
                    address={address}
                    showEmailNotificationBar={!mapPanelOpen}
                />
            )}
            <div className={css.container}>
                <MainPanel
                    urlQueryParams={validQueryParams}
                    updateUserProfileInfoInProgress={updateInfoInProgress}
                    updateUserProfileInfoError={updateInfoError}
                    mapPanelOpen={mapPanelOpen}
                    primaryFilters={filters}
                    address={address}
                    handleSubmit={handleSubmit}
                    onUpdateUserProfileInfo={onUpdateUserProfileInfo}
                    isLandingPage={isLandingPage}
                    onMapIconClick={() => {
                        navigateToSearchPage({ mapView: true });
                        setMapPanelOpen(true);
                    }}
                />
                {computing && <div className={css.placeholder} />}
                {!computing && !isLandingPage && (
                    <div
                        className={classNames(css.mapWrapper, {
                            [css.mapPanelOpen]: mapPanelOpen,
                        })}
                        style={applyHeightToMobileMap()}
                    >
                        <SearchMap
                            reusableContainerClassName={css.map}
                            bounds={bounds}
                            mapVerticalSpacingAdjusted={
                                isMobileLayout || mapVerticalSpacingAdjusted
                            }
                            center={origin}
                            location={location}
                            onMapMoveEnd={onMapMoveEnd}
                            mapPanelOpen={mapPanelOpen}
                            onMapPanelToggle={flag => {
                                navigateToSearchPage(flag ? { mapView: flag } : {});
                                setMapPanelOpen(flag);
                                setMapFullScreenViewed(flag);
                            }}
                            mapFullScreenViewed={mapFullScreenViewed}
                            setMapFullScreenViewed={setMapFullScreenViewed}
                            filters={filters}
                            history={history}
                        />
                    </div>
                )}
            </div>
        </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
};

const mapStateToProps = state => {
    const { updateUserProfileInfoInProgress, updateUserProfileInfoError } = state.user;
    const {
        activeListingId,
        searchMapListingIds,
        searchMapListingInProgess,
        mapBounds,
    } = state.SearchPage;

    return {
        scrollingDisabled: isScrollingDisabled(state),
        updateInfoInProgress: updateUserProfileInfoInProgress,
        updateInfoError: updateUserProfileInfoError,
        currentUser: checkMarketplaceCurrentUser(state),
        activeListingId,
        mapBoundsString: mapBounds ? encodeLatLngBounds(mapBounds) : null,
        searchMapListingIdsStr: (searchMapListingIds || []).map(s => s.uuid).join(','),
        searchMapListingInProgess,
    };
};

const mapDispatchToProps = dispatch => ({
    onMapBoundsSetting: bounds => dispatch(setMapBounds(bounds)),
    onDiscardingVisitedListings: () => dispatch(discardVisitedListings()),
    onUpdateUserProfileInfo: params => dispatch(updateUserProfileInfo(params)),
    onCombineSearchMapListings: () => dispatch(combineSearchMapListings()),
    onSettingListingScoresBunch: scoreDataBunch => dispatch(setListingScores(scoreDataBunch)),
    onSearchMapListings: (params = {}, search = '') => {
        const queryParams = parse(search, {
            latlng: ['origin'],
            latlngBounds: ['bounds'],
        });

        const { page = 1, address, origin, listingType, bounds, ...rest } = queryParams;
        // const perPage = mapView ? 100 : RESULT_PAGE_SIZE;
        const originMaybe = { origin };

        dispatch(
            searchMapListings(
                {
                    ...rest,
                    ...params,
                    ...originMaybe,
                    // page: 1,
                    // perPage: 100,
                    pub_type: listingType || listingTypeHorse,
                    ...defaultSearchParams,
                },
                {
                    collectAll: true,
                }
            )
        );
    },
    onSearchListings: (params = {}, search = '') => {
        const queryParams = parse(search, {
            latlng: ['origin'],
            latlngBounds: ['bounds'],
        });

        const {
            page = 1,
            address: originAddress,
            origin,
            listingType,
            mapView,
            ...rest
        } = queryParams;
        const originMaybe = { origin };

        return dispatch(
            searchListings(
                {
                    ...rest,
                    ...params,
                    ...originMaybe,
                    page,
                    perPage: RESULT_PAGE_SIZE,
                    pub_type: listingType || listingTypeHorse,
                    ...defaultSearchParams,
                },
                { initiator: 'SearchPage', originAddress }
            )
        );
    },
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(SearchPageComponent);

export default SearchPage;
