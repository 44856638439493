import React from 'react';
import { IconChartXXL, IconPenXXL, IconPersonSafetyXXL } from '../../../../icons';
import css from './SectionMainBenefits.css';

export default () => (
    <section className={css.root}>
        <main className={css.grid}>
            <div className={css.gridItem}>
                <IconChartXXL />
                <h2>{`Mehr Wachstum \nfür dein Unternehmen`}</h2>
                <p>
                    Wir erhöhen die Bekanntheit deines Unternehmens und steigern so deinen Umsatz.
                </p>
            </div>
            <div className={css.gridItem}>
                <IconPenXXL />
                <h2>Massgeschneiderte Leistungen</h2>
                <p>
                    Wir bieten dir eine Vielzahl an individuell gestalteten Massnahmen mit
                    transparentem Reporting.
                </p>
            </div>
            <div className={css.gridItem}>
                <IconPersonSafetyXXL />
                <h2>Persönliche Unterstützung</h2>
                <p>
                    Wir stehen dir von Tag 1 an zur Seite und legen gemeinsam mit dir passende Ziele
                    und Strategien für deine Marke fest.
                </p>
            </div>
        </main>
        <footer className={css.footer}>
            <button
                className={css.subLinkPrimary}
                onClick={() => {
                    const node = document.getElementById('pakete');
                    node && node.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }}
            >
                Unsere Pakete
            </button>
        </footer>
    </section>
);
