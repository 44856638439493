import React from 'react';
import { useSelector } from 'react-redux';

import { FieldRadioButton, IconSpinner } from '..';

import css from './InquiryModal.css';
import { useAssets } from '../../hooks/useAssets';
import classNames from 'classnames';
import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import { resolveStatusByMatchingMatrixScore } from '../../util/user';
import { useMainHorseId } from './useMainHorseId';
import { useInquiryModalContext } from './useInquiryModalContext';

export default ({ listing, riderListing, form, mainHorseId, horseListingSelected }) => {
    const { maxScore, setMaxScore } = useInquiryModalContext();
    const assetsLoadingRequests = useSelector(state => state.Assets.assetsLoadingRequests);

    const {
        id: { uuid: listingId },
        attributes: { title },
    } = listing;

    const listingAssetsRequestInProgress =
        assetsLoadingRequests && assetsLoadingRequests[listingId];

    const images = useAssets({
        listing,
        allowedTypes: ['image'],
    });

    const [matchingMatrixLoading, matchingScore] = useMatchingMatrix({
        riderListingId: riderListing && riderListing.id ? riderListing.id.uuid : null,
        horseOwnerListingId: listingId,
    });

    const firstImage =
        images &&
        images[0] &&
        images[0].attributes &&
        images[0].attributes.variants &&
        images[0].attributes.variants['landscape-crop'] &&
        images[0].attributes.variants['landscape-crop'].url ? (
            <img
                className={css.listingAvatar}
                src={images[0].attributes.variants['landscape-crop'].url}
            />
        ) : null;

    const listingIdentityData = firstImage || (
        <div className={css.identityPlaceholder}>
            {title
                .split(' ')
                .map(nameUnit => nameUnit.slice(0, 1))
                .splice(0, 2)
                .join(' ')}
        </div>
    );

    const loading = matchingMatrixLoading || listingAssetsRequestInProgress;

    const score = (matchingScore && matchingScore.finalScore) || 0;
    const scorePalette = resolveStatusByMatchingMatrixScore(score);

    useMainHorseId({ mainHorseId, setMaxScore, listingId, maxScore, score, title, form });

    return (
        <div className={css.listingData}>
            {loading ? (
                <IconSpinner />
            ) : (
                <FieldRadioButton
                    className={css.radioButton}
                    id={listingId}
                    value={listingId}
                    form={form}
                    notifyOnChange={() => {
                        if (horseListingSelected === listingId) {
                            form.change('matchingScore', scorePalette);
                            form.change('listingTitle', title);
                        }
                    }}
                    name="listingId"
                    label={
                        <div className={css.listingIdentity}>
                            <div>
                                {listingIdentityData}
                                <p>{title}</p>
                            </div>
                            <mark className={classNames([css.scoreBadge, css[scorePalette]])}>
                                {score}%
                            </mark>
                        </div>
                    }
                />
            )}
        </div>
    );
};
