import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { USER_INTEREST } from '../config';
import { IconCheckmark, WarningIcon } from '../../../icons';
import { IconSpinner } from '../../../components';
import config from '../../../config';
import ScoreBadge from '../ScoreBadge';
import css from '../ProfileSettingsForm.css';
import classNames from 'classnames';

const activitiesKeys = config.custom.activities.map(({ key }) => key);

const InterestsSection = ({
    interest,
    renderEditPencilIcon,
    handleEditSection,
    isPublic,
    scoreData,
    scoreReqInProgress,
}) => {
    const noData = !interest || interest.length === 0;

    if (isPublic && noData) return null;

    return (
        <div className={css.infoBlock}>
            {scoreData && scoreData.baseScoreBreakdown && (
                <ScoreBadge scoreData={scoreData.baseScoreBreakdown.interestAndActivityScore} />
            )}
            {scoreReqInProgress && <IconSpinner />}
            <h2 className={css.infoHeading}>
                <FormattedMessage id="ProfileSettingsForm.interestsHeading" />
                {noData ? (
                    <WarningIcon rootClassName={css.noInfoWarningIcon} />
                ) : (
                    <div className={css.editBlock}>{renderEditPencilIcon(USER_INTEREST)}</div>
                )}
            </h2>
            {!noData ? (
                interest
                    .sort((a, b) => activitiesKeys.indexOf(a) - activitiesKeys.indexOf(b))
                    .map(item => (
                        <p
                            key={item}
                            className={classNames(css.editBlock, css.flex, css.vCtr, css.mB8)}
                        >
                            <IconCheckmark />
                            {item}
                        </p>
                    ))
            ) : (
                <button
                    type="button"
                    onClick={() => handleEditSection(USER_INTEREST)}
                    className={classNames(css.actionItem, css.actionBtn)}
                >
                    <FormattedMessage id="ProfileSettingsForm.editUserInfoAction-userInterest" />
                </button>
            )}
        </div>
    );
};
export default InterestsSection;
