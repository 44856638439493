import React from 'react';
import classNames from 'classnames';
import css from './TopbarMobile.css';

export default ({ className, rootClassName }) => (
    <svg
        className={classNames(rootClassName || css.rootSearchIcon, className)}
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.01651 10.9998C9.85743 10.9998 11.3498 9.50744 11.3498 7.66652C11.3498 5.82561 9.85743 4.33325 8.01651 4.33325C6.1756 4.33325 4.68324 5.82561 4.68324 7.66652C4.68324 9.50744 6.1756 10.9998 8.01651 10.9998Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.01652 1C11.6983 1 14.6831 3.98471 14.6831 7.66654C14.6831 10.6949 10.1729 18.2886 8.57206 20.7019C8.44855 20.8881 8.23996 21 8.01652 21C7.79307 21 7.58449 20.8881 7.46097 20.7019C5.86011 18.2886 1.34998 10.6949 1.34998 7.66654C1.34998 3.98471 4.33469 1 8.01652 1Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
