import React from 'react';
/** icon-newsletter-xl */
/** icon-newsletter */
export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path
            d="m3.149 9.149-.349.35V24.24c0 14.426.003 14.746.154 14.991.085.137.274.321.42.409.264.159.408.16 20.626.16s20.362-.001 20.626-.16c.146-.088.344-.286.44-.44l.174-.28.024-14.2c.014-7.81.001-14.434-.028-14.719-.048-.474-.082-.549-.394-.86l-.341-.341H3.499l-.35.349m37.802 2.167c-.02.019-3.221 2.487-7.113 5.483-5.087 3.915-7.237 5.523-7.642 5.718-.622.298-1.568.523-2.196.523-.628 0-1.574-.225-2.196-.523-.405-.195-2.555-1.803-7.642-5.718a3674.905 3674.905 0 0 1-7.113-5.483c-.02-.02 7.608-.036 16.951-.036s16.971.016 16.951.036M12.68 18.803c3.74 2.874 7.07 5.399 7.4 5.61 2.302 1.471 5.588 1.457 7.888-.033.356-.231 3.804-2.85 7.663-5.82 3.859-2.97 7.051-5.412 7.093-5.427.049-.017.069 4.385.056 12.08l-.02 12.107-18.74.02-18.74.02V13.107l.3.234c.165.129 3.36 2.587 7.1 5.462"
            fillRule="evenodd"
            fill="#000"
        />
    </svg>
);
