import React, { useState, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { CREDITS_PACKAGE_1, CREDITS_PACKAGE_2, CREDITS_PACKAGE_3 } from '../../credits-config';
import { ModalPortal, Button, Form, FieldRadioButton, NamedLink } from '../../components';
import { IconLockS } from '../../icons';
import { getUserCountry } from '../../util/location';
import { isNativeIOS } from '../../util/ios';
import { postIAPMessage } from '../../util/ios';
import { useNativeIosPurchasingEvent } from '../../hooks/useNativeIosPurchasingEvent';
import config from '../../config';
import PaymentIconsBar from '../PaymentIconsBar/PaymentIconsBar';
import css from './CreditsPurchasingModal.css';
import { createPayrexxPayLink } from '../../util/payments';
import { createCreditsPurchasingPaylink } from '../../util/api';

const { supportedCountries } = config.maps;
const defaultcurrency = 'CHF';

export default ({
    isOpen,
    onClose,
    heading = 'Credits hinzufügen',
    description = 'Wähle die Anzahl Credits',
    sidenote = null,
    onError = () => null,
    currentUser,
}) => {
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);

    const actionSectionRef = useRef();

    const userCountry = getUserCountry();
    const { currency = defaultcurrency } = supportedCountries[userCountry];

    const handlePayLinkCreation = async packageId =>
        await createPayrexxPayLink({
            handler: createCreditsPurchasingPaylink,
            errorHandler: () => onError('paymentFailed'),
            onInit: () => setLoading(true),
            onFinalize: () => setLoading(false),
            extraParams: {
                packageId,
                referenceId: currentUser.id.uuid,
                userEmail: currentUser.attributes.email,
            },
        });

    const handleCreditsPurchase = ({ packageId }) => {
        if (isNativeIOS()) {
            setLoading(true);
            postIAPMessage(packageId, () => onError('iapIdMissed'));
        } else {
            handlePayLinkCreation(packageId);
        }
    };

    const scrollToActionSection = () => {
        if (!actionSectionRef.current) return;

        actionSectionRef.current.scrollIntoView({
            block: 'end',
            inline: 'end',
            behavior: 'smooth',
        });
    };

    useNativeIosPurchasingEvent({
        errorHandler: () => {
            setLoading(false);
            setReady(false);
        },
        paymentFailedHandler: () => {
            onError('paymentFailed');
        },
        onSuccessHandler: () => {
            setLoading(false);
            setReady(true);

            const { location } = window;
            const { origin, pathname } = location;
            location.replace(origin + pathname);
        },
    });

    return (
        <ModalPortal
            id="edit-image-description"
            isOpen={isOpen}
            onClose={loading ? () => null : onClose}
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            containerClassNameJoined
        >
            <h3 className={css.modalHeading}>{heading}</h3>
            <p className={css.modalDesc}>{description}</p>
            {sidenote}

            <FinalForm
                initialValues={{
                    packageId: CREDITS_PACKAGE_1,
                }}
                onSubmit={handleCreditsPurchase}
                render={fieldRenderProps => {
                    const { form, values, handleSubmit } = fieldRenderProps;
                    const { packageId } = values || {};

                    const creditsOptions = [
                        { price: '8.90', creditsToPurchase: 30, id: CREDITS_PACKAGE_1 },
                        {
                            price: '29.90',
                            creditsToPurchase: 150,
                            spare: '33',
                            id: CREDITS_PACKAGE_2,
                        },
                        {
                            price: '44.90',
                            creditsToPurchase: 300,
                            spare: '50',
                            id: CREDITS_PACKAGE_3,
                        },
                    ];

                    const { price } = creditsOptions.find(({ id }) => packageId === id);

                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className={css.form}>
                                {creditsOptions.map(
                                    ({ price, creditsToPurchase, spare, id }, index) => (
                                        <FieldRadioButton
                                            key={index}
                                            form={form}
                                            className={css.field}
                                            id={id}
                                            name="packageId"
                                            value={id}
                                            notifyOnChange={scrollToActionSection}
                                            label={
                                                <div className={css.option}>
                                                    <span className={css.creditsToPurchase}>
                                                        {creditsToPurchase} Credits
                                                    </span>
                                                    <div className={css.subOption}>
                                                        <span className={css.price}>
                                                            {price} {currency}
                                                        </span>
                                                        {spare && (
                                                            <span className={css.spare}>
                                                                (spare {spare}%)
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )
                                )}
                            </div>
                            <div className={css.subTotal}>
                                <div className={css.subTotalHeading}>
                                    <p>Gesamttotal</p>
                                    <p>Einmalige Zahlung</p>
                                </div>
                                <div className={css.subTotalPricing}>
                                    {price} {currency}
                                </div>
                            </div>

                            <div className={css.paymentOptions}>
                                <p>
                                    <IconLockS />
                                    Sichere Zahlung
                                </p>
                                <PaymentIconsBar />
                            </div>

                            <p className={css.sidenote}>
                                Geschützt durch unsere{' '}
                                <NamedLink name="GuaranteePage" openInNewTab>
                                    Zufriedenheitsgarantie
                                </NamedLink>
                                .
                            </p>

                            <Button
                                inProgress={loading}
                                disabled={loading || ready}
                                className={css.actionButton}
                                ready={ready}
                                buttonRef={actionSectionRef}
                            >
                                Bezahlung
                            </Button>
                        </Form>
                    );
                }}
            />
        </ModalPortal>
    );
};
