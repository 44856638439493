import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import 'react-dates/initialize';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import { IntlProvider } from './util/reactIntl';
import { setCurrentUserLocation, setUTMDataToSessionStorage } from './util/localStorage';
import configureStore from './store';
import routeConfiguration from './routeConfiguration';
import Routes from './Routes';
import config from './config';

// Flex template application uses English translations as default.
import defaultMessages from './translations/de.json';

// If you want to change the language, change the imports to match the wanted locale:
//   1) Change the language in the config.js file!
//   2) Import correct locale rules for Moment library
//   3) Use the `messagesInLocale` import to add the correct translation file.
//   4) To support older browsers we need add the correct locale for intl-relativetimeformat to `util/polyfills.js`

// Note that there is also translations in './translations/countryCodes.js' file
// This file contains ISO 3166-1 alpha-2 country codes, country names and their translations in our default languages
// This used to collect billing address in StripePaymentAddress on CheckoutPage

// Step 2:
// If you are using a non-english locale with moment library,
// you should also import time specific formatting rules for that locale
// e.g. for French: import 'moment/locale/fr';

// Step 3:
// If you are using a non-english locale, point `messagesInLocale` to correct .json file
import messagesInLocale from './translations/de.json';
import { useSetupMixpanelTracking } from './mixpanel/useSetupMixpanelTracking';
import { initMixpanelSDK } from './mixpanel/initMixpanel';
import { PushNotificationsSubscriptionModal } from './components';
import { handlerRecaptchaLoadLocally, initUsercentrics } from './util/scripts';
import { addMissingTranslations } from './util/translation';
import { checkIOS, isNativeIOS } from './util/ios';
import { addIosWvGlobalListeners } from './util/ios';
import { OneSignaServiceProvider } from './services/oneSignal';
import { useIosWvPermissionGranted } from './containers/LandingPage/useIosWvPermissionGranted';

initMixpanelSDK();

const isDefaultLanguageInUse = config.locale === 'ge';

const messages = isDefaultLanguageInUse
    ? defaultMessages
    : addMissingTranslations(defaultMessages, messagesInLocale);

const isTestEnv = process.env.NODE_ENV === 'test';

// Locale should not affect the tests. We ensure this by providing
// messages with the key as the value of each message.
const testMessages = mapValues(messages, (val, key) => key);
const localeMessages = isTestEnv ? testMessages : messages;

const setupLocale = () => {
    if (isTestEnv) {
        // Use english as a default locale in tests
        // This affects app.test.js and app.node.test.js tests
        config.locale = 'en';
        return;
    }

    // Set the Moment locale globally
    // See: http://momentjs.com/docs/#/i18n/changing-locale/
    moment.locale(config.locale);
};

export const ClientApp = ({ store }) => {
    const { getState } = store;
    const { currentUser } = getState().user;

    setupLocale();

    useEffect(() => {
        setUTMDataToSessionStorage();

        const recaptchaScript = document.createElement('script');
        handlerRecaptchaLoadLocally(recaptchaScript);

        /**
         * check if localStorage has user location data
         * otherwise make request to api
         */
        setCurrentUserLocation(getState());
        const wisepopsDefined = typeof window.wisepops !== 'undefined';
        const { webView } = checkIOS();

        if (wisepopsDefined && !webView) {
            window.wisepops('pageview');
        }

        if (isNativeIOS()) {
            /**
             * Do not init usercentrics until a user gives his consent
             * via out-of-box apple Allow Tracking screen
             */
            document.addEventListener('message', addIosWvGlobalListeners);
            window.addEventListener('message', addIosWvGlobalListeners);
        } else {
            initUsercentrics();
        }

        return function() {
            document.body.removeChild(recaptchaScript);
            document.removeEventListener('message', addIosWvGlobalListeners);
            window.removeEventListener('message', addIosWvGlobalListeners);
        };
    }, []);

    useSetupMixpanelTracking(store);

    const authPathIncluded = () => {
        if (typeof window === 'object') {
            const {
                location: { pathname },
            } = window;
            return pathname === '/signup' || pathname === '/login' || pathname === '/confirm';
        }

        return true;
    };

    const credentialPickerAvailable = !currentUser && !authPathIncluded();

    return (
        <IntlProvider locale={config.locale} messages={localeMessages} textComponent="span">
            <Provider store={store}>
                <HelmetProvider>
                    <BrowserRouter>
                        <OneSignaServiceProvider>
                            <Routes routes={routeConfiguration()} />
                            <div id="recaptcha" className="g-recaptcha" />
                            {credentialPickerAvailable && (
                                <div
                                    id="g_id_onload"
                                    data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    // see router.post('/auth/google'
                                    data-login_uri={`${process.env.REACT_APP_CANONICAL_ROOT_URL}/api/auth/google?defaultReturn=/&from=/&defaultConfirm=/confirm`}
                                ></div>
                            )}
                            <PushNotificationsSubscriptionModal />
                        </OneSignaServiceProvider>
                    </BrowserRouter>
                </HelmetProvider>
            </Provider>
        </IntlProvider>
    );
};

const { any, string } = PropTypes;

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = props => {
    const { url, context, helmetContext, store } = props;
    setupLocale();

    HelmetProvider.canUseDOM = false;
    return (
        <IntlProvider locale={config.locale} messages={localeMessages} textComponent="span">
            <Provider store={store}>
                <HelmetProvider context={helmetContext}>
                    <StaticRouter location={url} context={context}>
                        <Routes routes={routeConfiguration()} />
                    </StaticRouter>
                </HelmetProvider>
            </Provider>
        </IntlProvider>
    );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (url, serverContext, preloadedState) => {
    // Don't pass an SDK instance since we're only rendering the
    // component tree with the preloaded store state and components
    // shouldn't do any SDK calls in the (server) rendering lifecycle.
    const store = configureStore(preloadedState);

    const helmetContext = {};

    const body = ReactDOMServer.renderToString(
        <ServerApp url={url} context={serverContext} helmetContext={helmetContext} store={store} />
    );
    const { helmet: head } = helmetContext;
    return { head, body };
};
