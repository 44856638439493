import React from 'react';

export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.70097 5.47422V20.2163M15.4019 3.00022V17.7423M15.8996 3.09582C15.5801 2.96806 15.2237 2.96806 14.9043 3.09582L9.19863 5.37862C8.87916 5.50638 8.52279 5.50638 8.20331 5.37862L2.91938 3.26469C2.81743 3.22383 2.707 3.20866 2.5978 3.2205C2.48861 3.23235 2.384 3.27086 2.29319 3.33264C2.20238 3.39442 2.12814 3.47757 2.07702 3.57478C2.02589 3.67199 1.99945 3.78028 2.00001 3.89011V16.7318C1.99996 16.9997 2.08021 17.2615 2.23039 17.4833C2.38057 17.7052 2.5938 17.8769 2.84254 17.9764L8.20331 20.1207C8.52279 20.2485 8.87916 20.2485 9.19863 20.1207L14.9043 17.8379C15.2237 17.7102 15.5801 17.7102 15.8996 17.8379L21.1835 19.9483C21.2855 19.9892 21.3959 20.0043 21.5051 19.9925C21.6143 19.9806 21.7189 19.9421 21.8097 19.8803C21.9005 19.8186 21.9748 19.7354 22.0259 19.6382C22.077 19.541 22.1035 19.4327 22.1029 19.3229V6.48472C22.1029 6.21682 22.0227 5.95506 21.8725 5.73321C21.7223 5.51136 21.5091 5.33961 21.2604 5.24013L15.8996 3.09582Z"
            stroke="#1A2B49"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
