import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { verify } from '../../ducks/EmailVerification.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { parse } from '../../util/urlHelpers';
import { ensureCurrentUser } from '../../util/data';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    IconSpinner,
    NamedRedirect,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './EmailVerificationPage.css';
export const USER_ACTION_EMAIL_VERIFIED = 'emailVerified';
/**
  Parse verification token from URL

  Returns stringified token, if the token is provided.

  Returns `null` if verification token is not provided.

  Please note that we need to explicitely stringify the token, because
  the unwanted result of the `parse` method is that it automatically
  parses the token to number.
*/
const parseVerificationToken = search => {
    if (!search) return null;

    const urlParams = parse(search);
    const verificationToken = urlParams.t;

    if (verificationToken) return `${verificationToken}`;

    return null;
};

export const EmailVerificationPageComponent = ({
    currentUser,
    scrollingDisabled,
    submitVerification,
    emailVerificationInProgress,
    verificationError,
    location,
}) => {
    const user = ensureCurrentUser(currentUser);
    const { emailVerified, pendingEmail } = user.attributes;

    const errorMessage = (
        <div className={css.error}>Wir konnten deine E-Mail-Adresse nicht verifizieren.</div>
    );

    const verificationToken = parseVerificationToken(
        location && location.search ? location.search : null
    );

    const shouldVerify =
        !emailVerified && !!verificationToken && !emailVerificationInProgress && !verificationError;

    useEffect(() => {
        const verify = async () => {
            try {
                await submitVerification(verificationToken);
            } catch (e) {
                // do nothing
            }
        };
        if (shouldVerify && verificationToken) {
            verify();
        }
    }, [shouldVerify, verificationToken]);

    if (emailVerified && !pendingEmail) {
        return (
            <NamedRedirect
                name="ContactDetailsPage"
                search={`action=${USER_ACTION_EMAIL_VERIFIED}`}
            />
        );
    }

    return (
        <Page
            title="Verifiziere deine E-Mail-Adresse"
            scrollingDisabled={scrollingDisabled}
            referrer="origin"
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain className={css.layoutWrapperMain}>
                    <div className={css.root}>
                        <div className={css.content}>
                            {emailVerificationInProgress ? <IconSpinner /> : null}

                            {verificationError || !verificationToken ? errorMessage : null}

                            {!user.id ? (
                                <FormattedMessage id="EmailVerificationPage.loadingUserInformation" />
                            ) : null}
                        </div>
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, func, shape, string } = PropTypes;

EmailVerificationPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    scrollingDisabled: bool.isRequired,
    submitVerification: func.isRequired,
    emailVerificationInProgress: bool.isRequired,
    verificationError: propTypes.error,
    // from withRouter
    location: shape({
        search: string,
    }).isRequired,
};

const mapStateToProps = state => {
    const { currentUser } = state.user;
    const { verificationError, verificationInProgress } = state.EmailVerification;
    return {
        verificationError,
        emailVerificationInProgress: verificationInProgress,
        currentUser,
        scrollingDisabled: isScrollingDisabled(state),
    };
};

const mapDispatchToProps = dispatch => ({
    submitVerification: token => dispatch(verify(token)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EmailVerificationPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EmailVerificationPageComponent);

export default EmailVerificationPage;
