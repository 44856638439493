import React, { createContext, useContext } from 'react';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { isNativeIOS, postWebViewMessage } from '../../util/ios';

import css from './SocialLoginButtons.css';

const SocialLoginButtonsContext = createContext(undefined);

const useSocialLoginButtonsContext = () => {
    const context = useContext(SocialLoginButtonsContext);

    if (!context) {
        throw new Error(
            'useSocialLoginButtonsContext must be used within SocialLoginButtonsContext.'
        );
    }

    return context;
};

const SocialLoginButtons = ({
    rootClassName,
    buttonsHolderClassName,
    defaultConfirmSearch = '',
    children,
}) => {
    const getDefaultRoutes = () => {
        const routes = routeConfiguration();
        const baseUrl = apiBaseUrl();

        // // Route where the user should be returned after authentication
        // // This is used e.g. with EditListingPage and ListingPage
        // const fromParam = from ? `from=${from}` : '';

        // Default route where user is returned after successfull authentication
        const defaultReturn = pathByRouteName('IdpAuthSuccessPage', routes);
        const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

        // Route for confirming user data before creating a new user
        const defaultConfirm = pathByRouteName('ConfirmPage', routes);

        const defaultConfirmParam = defaultConfirm
            ? `&defaultConfirm=${defaultConfirm}${defaultConfirmSearch}`
            : '';

        return { baseUrl, defaultReturnParam, defaultConfirmParam };
    };

    /**
     * Initiate auth with idp
     * @param {string} idp - apple, google, facebook
     */
    const authWithIdp = idp => {
        if (isNativeIOS()) {
            /** handle the scenario when the native app is used */
            postWebViewMessage(`${idp}LoginClicked`);
        } else {
            const defaultRoutes = getDefaultRoutes();
            const { baseUrl, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
            window.location.href = `${baseUrl}/api/auth/${idp}?${defaultReturnParam}${defaultConfirmParam}`;
        }
    };

    return (
        <SocialLoginButtonsContext.Provider value={{ authWithIdp }}>
            <div className={rootClassName || css.root}>
                <p className={css.heading}>Weiter mit:</p>
                <section
                    className={classNames(css.idpButtons, {
                        [buttonsHolderClassName]: !!buttonsHolderClassName,
                    })}
                >
                    {children}
                </section>
            </div>
        </SocialLoginButtonsContext.Provider>
    );
};

const Logo = idp => (
    <img
        src={`https://horsedeal.imgix.net/static/icons/icon-logo-${idp}-color-l.svg`}
        alt={`login via ${idp}`}
    />
);

const IdpButton = ({ idp, rootClassName = '', children = null }) => {
    const { authWithIdp } = useSocialLoginButtonsContext();
    return (
        <button className={rootClassName} type="button" onClick={() => authWithIdp(idp)}>
            {children || Logo(idp)}
        </button>
    );
};

const GoogleLogo = () => Logo('google');
const FacebookLogo = () => Logo('facebook');
const AppleLogo = () => Logo('apple');

const GoogleLoginButton = props => IdpButton({ ...props, idp: 'google' });
const FacebookLoginButton = props => IdpButton({ ...props, idp: 'facebook' });
const AppleLoginButton = props => IdpButton({ ...props, idp: 'apple' });

SocialLoginButtons.GoogleLoginButton = GoogleLoginButton;
SocialLoginButtons.FacebookLoginButton = FacebookLoginButton;
SocialLoginButtons.AppleLoginButton = AppleLoginButton;

SocialLoginButtons.GoogleLogo = GoogleLogo;
SocialLoginButtons.FacebookLogo = FacebookLogo;
SocialLoginButtons.AppleLogo = AppleLogo;

export default SocialLoginButtons;
