import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { ModalPortal } from '../../components';
import { TopbarLocationSearchForm } from '../../forms';
import { useListingType } from '../../hooks/useListingType';
import TopbarDesktopSearchPanel from '../Topbar/components/TopbarDesktop/TopbarDesktopSearchPanel';
import css from './MobileSearchPanel.css';

const MobileSearchPanel = ({ userType, location, isOpen, onClose, intl }) => {
    const [listingTypeTab, setListingTypeTab] = useListingType({ location, userType });

    return (
        <ModalPortal
            id="TopbarMobileSearchPanel"
            containerClassName={css.modalContainer}
            contentClassName={css.modalContent}
            scrollLayerClassName={css.scrollLayer}
            isOpen={isOpen}
            onClose={onClose}
        >
            <TopbarLocationSearchForm
                searchPanelOpen
                listingTypeTab={listingTypeTab}
                setListingTypeTab={setListingTypeTab}
            />
            <TopbarDesktopSearchPanel
                intl={intl}
                listingTypeTab={listingTypeTab}
                setListingTypeTab={setListingTypeTab}
                rootClassName={css.searchPanelRoot}
            />
        </ModalPortal>
    );
};

export default compose(withRouter, injectIntl)(MobileSearchPanel);
