/**
 * Reset date TO field in case FROM is changed and is greater than TO
 * @param {*} param0
 */
export const resetDateFieldOnChange = ({
    form,
    fieldNameFrom = 'dateFrom',
    fieldNameTo = 'dateTo',
}) => {
    const { value: from } = form.getFieldState(fieldNameFrom) || {};
    const { value: to } = form.getFieldState(fieldNameTo) || {};

    if (from && to && from > to) {
        form.change(fieldNameTo, undefined);
        form.resetFieldState(fieldNameFrom);
    }
};

export const getYearsList = () =>
    Array.from({ length: new Date().getFullYear() - 2000 + 1 }, (_, i) => ({
        label: 2000 + i,
        value: 2000 + i,
    })).reverse();
