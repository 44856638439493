import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import {
    NamedLink,
    UploadUserAvatar,
    Tooltip,
    IconSpinner,
    AvailabilityBadge,
    FavoriteIcon,
} from '../../../components';
import { calculateAge, isProfileComplete } from '../../../util/user';
import { ConditionalWrapper } from '../../../util/common';
import { USER_AGE, USER_NAME, USER_HEIGHT, USER_WEIGHT } from '../config';
import { GET_RECOMMENDATIONS_FORM } from '../../../containers/EditUserInfoPage/EditUserInfoPage';
import LikeIcon from '../icons/LikeIcon';
import HeartIcon from '../icons/HeartIcon';
import { CheckmarkIcon, IconEyeL, IconCrossL } from '../../../icons';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDER_AVAILABILITY_NOT_AVAILABLE,
} from '../../../marketplace-custom-config';
import { navigateTo } from '../helpers';
import { RECOMMENDATIONS_ID } from '../NavigationBar';
import AcceptInquirySection from './AcceptInquirySection';
import ExperienceSection from './ExperienceSection';
import css from './EditUserMainInfoSection.css';

const DEFAULT_AVAILABILITY_STATUS = RIDER_AVAILABILITY_NOT_AVAILABLE;

const EditUserMainInfoSection = ({
    form,
    user,
    currentUser,
    uploadInProgress,
    isPublic,
    riderPage,
    onImageUpload,
    renderEditPencilIcon,
    image,
    uploadImageError,
    onUpdateProfile,
    externalReviewsRequests,
    recommendations: recommendationsFromProps,
    isSameUser,
    handleEditSection,
    inquiryDisabled,
    riderListing,
    scoreData,
    isLoggedOut,
    transactionDataInProgress,
    uploadAvatarRef,
    intl,
}) => {
    const { attributes, profileImage: profileImageFromUserData } = user;

    const { profile } = attributes;
    const { publicData, displayName } = profile;
    const {
        birthDate = {},
        userType,
        userHeight,
        userWeight,
        availabilityStatus = DEFAULT_AVAILABILITY_STATUS,
    } = publicData;

    const { baseScoreBreakdown: score } = scoreData || {};
    const userBodyParamsMatches =
        score && isPublic ? score.minimumAgeScore && score.minimumAgeScore.total : false;
    const recommendations = (recommendationsFromProps || []).filter(r => r.status === 'approved');

    const { isPrimaryFieldsComplete } = isProfileComplete(user);

    const {
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = currentUser;
    const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : null;

    const [firstName] = (displayName || '').split(' ');

    const profileImageId = profileImageFromUserData ? profileImageFromUserData.id : null;
    const profileImage = image || { imageId: profileImageId };

    const userAge =
        birthDate && Object.entries(birthDate).length !== 0 ? calculateAge(birthDate) : null;

    const notAvailable = availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;
    const isRider = currentUserType === 'rider';
    const noFavorite = inquiryDisabled || isSameUser || isRider || notAvailable;

    const resolveActionDisabledKey = () => {
        const usersAreRiders = riderPage && isRider && 'usersAreRiders';
        const isSameUserKey = isSameUser && 'noFavoriteForSameUser';
        const notAvailableKey = notAvailable && RIDER_AVAILABILITY_NOT_AVAILABLE;

        return isSameUserKey || usersAreRiders || notAvailableKey || 'default';
    };

    const actionDisabledKey = resolveActionDisabledKey();

    const recommendAndCheckAvailabilityHolder = riderPage && (
        <aside
            className={classNames(css.recommendAndCheckAvailabilityHolder, {
                [css.recommendAndCheckAvailabilityHolderOwn]: !isPublic && isSameUser,
                [css.recommendAndCheckAvailabilityHolderPublic]:
                    isPublic && !isPrimaryFieldsComplete,
            })}
        >
            {isPublic && (
                <ConditionalWrapper
                    condition={noFavorite || !riderListing}
                    wrapper={children => (
                        <Tooltip
                            targetClassName={css.tooltipRecommendTarget}
                            tooltipClassName={classNames({
                                [css.tooltip]: true,
                                [css.recommendUserTooltip]: true,
                            })}
                            content={
                                <>
                                    <p>Hinzufügen nicht möglich</p>
                                    <p>
                                        <FormattedMessage
                                            id={`Favorites.action.disabled.desc.${actionDisabledKey}`}
                                            values={{ userName: firstName }}
                                        />
                                    </p>
                                </>
                            }
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    {noFavorite || !riderListing ? (
                        <HeartIcon
                            rootClassName={classNames([css.recommendUserIcon, css.inquiryDisabled])}
                        />
                    ) : (
                        <div className={css.favorite}>
                            <FavoriteIcon
                                rootClassName={css.favoriteIcon}
                                inWishlistClassName={css.favoriteIconinWishlist}
                                currentListing={riderListing}
                            />
                        </div>
                    )}
                </ConditionalWrapper>
            )}
            {riderPage && (
                <AvailabilityBadge
                    user={user}
                    editAllowed={!isPublic}
                    helpTextAllowed
                    isPublic={isPublic}
                    onLightBackground
                />
            )}
        </aside>
    );

    const externalReviewsRequest =
        externalReviewsRequests && externalReviewsRequests[currentUserId];
    const askForRecommendationsMaybe =
        !externalReviewsRequest && !isPublic && recommendations.length === 0;

    const recommendationsNumMaybe = !externalReviewsRequest && recommendations.length > 0;

    const noRecommendationsPublicMaybe =
        !externalReviewsRequest && isPublic && recommendations.length === 0;

    const userBodyParamsList = riderPage
        ? [
              { key: USER_AGE, value: userAge, useKey: true },
              { key: USER_HEIGHT, value: userHeight, useKey: false },
              { key: USER_WEIGHT, value: userWeight, useKey: false },
          ]
        : [{ key: USER_AGE, value: userAge, useKey: true }];

    return (
        <section className={css.upperSection}>
            <div
                className={`${css.userIdentitySection} ${(!isPrimaryFieldsComplete ||
                    availabilityStatus !== RIDER_AVAILABILITY_CONFIRMED) &&
                    !isPublic &&
                    css.userIdentityNonconfirmed}`}
            >
                <div className={`${css.flex} ${css.mob}`}>
                    {recommendAndCheckAvailabilityHolder}
                </div>
                <UploadUserAvatar
                    form={form}
                    currentUser={user}
                    profileImage={profileImage}
                    onImageUpload={onImageUpload}
                    onUpdateProfile={onUpdateProfile}
                    uploadInProgress={uploadInProgress}
                    uploadImageError={uploadImageError}
                    rootClassName={classNames({
                        [css.uploadAvatarRoot]: true,
                        [css.uploadAvatarHiddenControls]: isPublic,
                        [css.publicUserPreview]: isPublic && !isSameUser,
                    })}
                    uploadDisabled={isPublic}
                    previewAllowed={isSameUser}
                    ref={uploadAvatarRef}
                />
                <div className={css.userIdentity}>
                    <ExperienceSection
                        sectionClassName={css.desktop}
                        isPublic={isPublic}
                        handleEditSection={handleEditSection}
                        renderEditPencilIcon={renderEditPencilIcon}
                        intl={intl}
                        user={user}
                    />
                    <section className={css.userNameSection}>
                        <h1>{displayName}.</h1> {renderEditPencilIcon(USER_NAME)}
                        {isPublic && (
                            <div className={`${css.flex} ${css.desktop}`}>
                                {recommendAndCheckAvailabilityHolder}
                            </div>
                        )}
                    </section>
                    {riderPage && externalReviewsRequest && <IconSpinner />}
                    {riderPage && askForRecommendationsMaybe && (
                        <NamedLink
                            name="EditUserInfo"
                            params={{
                                section: GET_RECOMMENDATIONS_FORM,
                                userType: userType || 'rider',
                            }}
                            className={css.recommendationsAllowed}
                        >
                            <LikeIcon />
                            <FormattedMessage id="ProfileSettingsPage.askForRecommendations" />
                        </NamedLink>
                    )}

                    {recommendationsNumMaybe && (
                        <p
                            className={css.recommendationsNumSection}
                            onClick={() => {
                                window.history.pushState(null, null, `#${RECOMMENDATIONS_ID}`);
                                navigateTo(`#${RECOMMENDATIONS_ID}`);
                            }}
                        >
                            <LikeIcon /> {recommendations.length}{' '}
                            <FormattedMessage id="ProfileSettingsPage.recommendations" />
                        </p>
                    )}
                    {noRecommendationsPublicMaybe && riderPage && (
                        <p className={css.noRecommendationsParapgraph}>
                            <FormattedMessage id="ProfileSettingsPage.noRecommendations" />
                        </p>
                    )}
                    <ExperienceSection
                        sectionClassName={css.mob}
                        isPublic={isPublic}
                        handleEditSection={handleEditSection}
                        renderEditPencilIcon={renderEditPencilIcon}
                        intl={intl}
                        user={user}
                    />
                    <section className={css.sectionItemHolder}>
                        {userBodyParamsList.map(({ key, value, useKey }) => {
                            const isAge = score && key === USER_AGE;
                            const hasBodyScore = userBodyParamsMatches !== null;
                            const ageParamMatches =
                                isPublic && (!hasBodyScore || userBodyParamsMatches) && isAge;
                            const ageParamDoesNotMatch =
                                isPublic && hasBodyScore && !userBodyParamsMatches && isAge;

                            return (
                                <React.Fragment key={key}>
                                    {value && (
                                        <div
                                            className={classNames({
                                                [css.sectionItem]: true,
                                                [css.sectionItemPublic]: isPublic,
                                                [css.bodyParamsMatches]: ageParamMatches,
                                                [css.bodyParamDoesNotMatch]: ageParamDoesNotMatch,
                                            })}
                                            onClick={() => {
                                                if (!isPublic) {
                                                    handleEditSection(key);
                                                }
                                            }}
                                        >
                                            {Boolean(ageParamMatches) && <CheckmarkIcon />}
                                            {Boolean(ageParamDoesNotMatch) && <IconCrossL />}
                                            {value}{' '}
                                            {useKey && (
                                                <FormattedMessage
                                                    id={`ProfileSettingsPage.${key}`}
                                                />
                                            )}{' '}
                                            {renderEditPencilIcon(key, css.smallIcon, null, {
                                                onClick: () => null,
                                            })}
                                        </div>
                                    )}
                                    {!value && !isPublic && (
                                        <div
                                            className={
                                                value
                                                    ? css.sectionItem
                                                    : classNames([
                                                          css.sectionItem,
                                                          css.sectionItemAction,
                                                      ])
                                            }
                                            onClick={() => {
                                                if (!value) {
                                                    handleEditSection(key);
                                                }
                                            }}
                                        >
                                            <FormattedMessage
                                                id={`ProfileSettingsPage.${key}-absent`}
                                            />
                                            {value ? renderEditPencilIcon(key) : null}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </section>
                </div>
            </div>
            {isPublic && riderPage && (
                <AcceptInquirySection
                    user={user}
                    userName={firstName}
                    isSameUser={isSameUser}
                    disabled={inquiryDisabled}
                    riderPage={riderPage}
                    isLoggedOut={isLoggedOut}
                    transactionDataInProgress={transactionDataInProgress}
                    riderListingId={riderListing?.id?.uuid}
                />
            )}
            {!isPublic && (
                <div className={css.userPreferencesSection}>
                    {riderPage && (
                        <AvailabilityBadge
                            rootClassName={css.desktop}
                            user={user}
                            isPublic={isPublic}
                            editAllowed
                            helpTextAllowed
                            onLightBackground
                        />
                    )}
                    {user.id ? (
                        <NamedLink
                            className={css.profileLink}
                            name="ProfilePagePreview"
                            params={{ id: user.id.uuid, userType }}
                        >
                            <IconEyeL />
                            <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                        </NamedLink>
                    ) : null}
                </div>
            )}
        </section>
    );
};

export default EditUserMainInfoSection;
