import React from 'react';
import { Button } from '../../components';
import { ArrowNextIcon } from '../../icons';
import LastStepButton from './LastStepButton';
import css from './Footer.css';

export const Footer = ({
    disabled,
    navigateToPreviousForm = null,
    updateInfoInProgress,
    handleSubmit,
    isLast,
    values = {},
    currentUser,
}) => {
    const commonProps = {
        inProgress: updateInfoInProgress,
        disabled: disabled,
        onClick: handleSubmit,
    };

    return (
        <footer className={css.footer}>
            <div className={css.buttonHolder}>
                <Button
                    className={css.prevPageButton}
                    inProgress={updateInfoInProgress}
                    disabled={!navigateToPreviousForm}
                    onClick={navigateToPreviousForm ? navigateToPreviousForm : () => null}
                >
                    Zurück
                </Button>
                {isLast ? (
                    <LastStepButton
                        values={values}
                        commonProps={commonProps}
                        currentUser={currentUser}
                    />
                ) : (
                    <Button {...commonProps} className={css.nextPageButton}>
                        Weiter
                        <ArrowNextIcon />
                    </Button>
                )}
            </div>
        </footer>
    );
};
