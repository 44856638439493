import React from 'react';

import { Button } from '../../../components';
import { EditUserAvailabilityForm } from '../../../forms';

import TooltipSectionInfo from './TooltipSectionInfo';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDER_AVAILABILITY_NOT_AVAILABLE,
} from '../../../marketplace-custom-config';
import { USER_AVAILABILITY_REASON_TO_CLOSE } from '../config';
import { stringifyToMarketplaceISO } from '../../../util/dates';
import { updateCreditsHistory } from '../../../util/api';
import { CREDITS_ACTION_AVAILABILITY_CONFIRMED } from '../../../credits-config';

export default ({
    isConfirm,
    values,
    valid,
    pristine,
    form,
    updateInProgress,
    parentSubmit,
    setEditSectionValues,
    setEditSection,
    currentUser,
}) => {
    const { availability, openForPartTimeProposals, availabilityUpdatedAt } = values || {};
    const disabled = !availability || !valid || !openForPartTimeProposals;

    return (
        <>
            <EditUserAvailabilityForm
                form={form}
                selectedValue={availability}
                openForPartTimeProposals={openForPartTimeProposals}
                options={[
                    { label: 'flexible', key: 'flexible' },
                    { label: '1-2', key: 'numPerWeek' },
                    { label: '2-3', key: 'numPerWeek' },
                    { label: '3-5', key: 'numPerWeek' },
                    {
                        label: RIDER_AVAILABILITY_NOT_AVAILABLE,
                        key: RIDER_AVAILABILITY_NOT_AVAILABLE,
                    },
                ]}
            />
            <footer>
                <TooltipSectionInfo
                    headingId="EditUserAvailabilityForm.sideNoteTooltipHeading"
                    descriptionId="EditUserAvailabilityForm.sideNoteTooltipDesc"
                    sidenoteId="EditUserForms.updateAvailabilitySideNote"
                />
                <Button
                    disabled={disabled}
                    type="button"
                    onClick={() => {
                        const availabilityStatus =
                            availability === RIDER_AVAILABILITY_NOT_AVAILABLE
                                ? availability
                                : RIDER_AVAILABILITY_CONFIRMED;

                        const isNotAvailable =
                            availability === RIDER_AVAILABILITY_NOT_AVAILABLE &&
                            availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;
                        const params = {
                            ...values,
                            availabilityStatus,
                            availabilityUpdatedAt: stringifyToMarketplaceISO(new Date()),
                        };

                        const notUpdatedYet = typeof availabilityUpdatedAt === 'undefined';
                        const initialConfirmation = notUpdatedYet && !isNotAvailable;
                        const currentAvailability =
                            currentUser.attributes.profile.publicData.availabilityStatus;

                        if (
                            !initialConfirmation &&
                            currentAvailability &&
                            availability !== currentAvailability
                        ) {
                            params.availabilityStatusPrevious =
                                currentUser.attributes.profile.publicData.availabilityStatus;
                        }

                        if (initialConfirmation) {
                            /**
                             * first time a rider confirms his availability
                             */
                            Object.assign(params, {
                                availabilityFirstTimeConfirmed: stringifyToMarketplaceISO(
                                    new Date()
                                ),
                            });
                            updateCreditsHistory(
                                JSON.stringify({
                                    userId: currentUser.id.uuid,
                                    eventName: CREDITS_ACTION_AVAILABILITY_CONFIRMED,
                                })
                            );
                        }

                        if (!isNotAvailable) {
                            return parentSubmit({
                                ...params,
                                /**
                                 * remove reason to close when a user re-confirms his availability
                                 */
                                availabilityReasonToClose: null,
                            });
                        }

                        setEditSectionValues(params);
                        setEditSection(USER_AVAILABILITY_REASON_TO_CLOSE);
                    }}
                    inProgress={updateInProgress}
                >
                    <span>
                        {isConfirm && pristine
                            ? 'Verfügbarkeit bestätigen'
                            : 'Verfügbarkeit hinzufügen'}
                    </span>
                </Button>
            </footer>
        </>
    );
};
