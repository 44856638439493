import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Cropper } from 'react-advanced-cropper';
import {
    ImageFromFile,
    ResponsiveImage,
    IconSpinner,
    ImageCarousel,
    Tooltip,
    SdkVideo,
    ModalPortal,
    ConfirmActionModal,
} from '../../components';
import { TOOLTIP_DATA_REQUEST_ATTR } from '../../components/Tooltip/Tooltip';

import EditIcon from '../../containers/ListingPage/Icons/EditIcon';
import EditDescriptionModal from './EditDescriptionModal';
import ShowCarouselButton from './ShowCarouselButton';
import RemoveImageButton from './RemoveImageButton';
import css from './AddImages.css';

export const ThumbnailWrapper = ({
    className,
    wrapperClassName = '',
    showCarouselClassName = '',
    image,
    loading,
    allImages,
    index,
    savedImageAltText,
    onRemoveImage,
    imageIdDragged,
    imageIdToDrop,
    showImageCarousel,
    onDragOver,
    onDragStart,
    onDragEnd,
    onDrop,
    onDragLeave,
    isFirst,
    isLast,
    useControls,
    changeImagesOrder,
    onRotateImage,
    onChangeImageDescription,
    setControlPanelIndex,
    setMainPicture,
    controlPanelIndex,
    videoControls = true,
    uploadingAssets = false,
}) => {
    const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
    const [removeImageModalOpen, setRemoveImageModalOpen] = useState(null);
    const [editDescriptionModalOpen, setEditDescriptionModalOpen] = useState(false);

    const controlPanelOpen = controlPanelIndex === index;
    const isVideo = image && image.type === 'video';
    const cropperRef = useRef();
    const cropperContainer = useRef();

    const handleRemoveClick = e => {
        e.stopPropagation();
        e.preventDefault();
        onRemoveImage(image.id);
        setControlPanelIndex(-1);
    };

    const setMainPictureClick = e => {
        e.stopPropagation();
        setMainPicture(e, 0, index);
        setControlPanelIndex(-1);
    };

    if (image.file) {
        // Add remove button only when the image has been uploaded and can be removed
        const removeButton = image.imageId ? (
            <RemoveImageButton onClick={handleRemoveClick} />
        ) : null;

        // While image is uploading we show overlay on top of thumbnail
        const uploadingOverlay =
            !image.imageId || uploadingAssets ? (
                <div className={css.thumbnailLoading}>
                    <IconSpinner />
                </div>
            ) : null;

        return (
            <ImageFromFile
                id={image.id}
                className={className}
                rootClassName={css.thumbnail}
                file={image.file}
            >
                {removeButton}
                {uploadingOverlay}
            </ImageFromFile>
        );
    } else {
        const imgId = image.id.uuid;

        const classes = classNames({
            [css.thumbnail]: true,
            [className]: !!className,
            [css.imageIdDragged]: imgId === imageIdDragged,
            [css.imageIdToDrop]: imgId === imageIdToDrop,
            [css.loading]: loading,
        });

        const firtImageControl = isFirst ? (
            <Tooltip
                tooltipClassName={classNames([css.controls, css.firstImageControlTooltipContent])}
                rootClassName={css.firstImageControl}
                content={
                    <p> Dieses Foto wird deinen Profilbesuchern am prominentesten angezeigt.</p>
                }
            >
                <aside> Highlight</aside>
            </Tooltip>
        ) : null;

        const rootTooltipClasses = classNames({
            [css.imagesControl]: true,
            [css.controlOpened]: controlPanelOpen,
        });

        const imagesControl = useControls ? (
            <Tooltip
                isStatic
                staticTooltipState={controlPanelOpen}
                outsideClickHandler={() => setControlPanelIndex(-1)}
                staticTooltipStateHandler={() => setControlPanelIndex(-1)}
                tooltipClassName={classNames(css.controls, css.imageControlTooltipContent)}
                rootClassName={rootTooltipClasses}
                content={
                    <ul>
                        {!isFirst && <li onClick={setMainPictureClick}> Highlight</li>}
                        {!isFirst && (
                            <li
                                onClick={() => {
                                    const prevImageIndex = index - 1;
                                    changeImagesOrder(null, index, prevImageIndex);
                                    setControlPanelIndex(-1);
                                }}
                            >
                                Nach vorne
                            </li>
                        )}
                        {!isLast && (
                            <li
                                onClick={() => {
                                    const nextImageIndex = index + 1;
                                    changeImagesOrder(null, index, nextImageIndex);
                                    setControlPanelIndex(-1);
                                }}
                            >
                                Nach hinten
                            </li>
                        )}
                        {!isVideo && (
                            <li
                                onClick={() => {
                                    onRotateImage(image);
                                    setControlPanelIndex(-1);
                                }}
                            >
                                Bild drehen
                            </li>
                        )}
                        <li
                            onClick={() => {
                                setRemoveImageModalOpen(isVideo ? 'video' : 'image');
                                setControlPanelIndex(-1);
                            }}
                        >
                            Löschen
                        </li>
                    </ul>
                }
            >
                <aside
                    onClick={prevState => setControlPanelIndex(prevState === index ? -1 : index)}
                >
                    <span className={css.ellipsis} data-role={TOOLTIP_DATA_REQUEST_ATTR}>
                        ...
                    </span>
                </aside>
            </Tooltip>
        ) : null;

        const carouselControl = showImageCarousel ? (
            <ShowCarouselButton
                onClick={() => setImageCarouselOpen(true)}
                className={showCarouselClassName}
            />
        ) : null;

        return (
            <>
                <div
                    className={classes}
                    onDragOver={e => onDragOver(e, imgId)}
                    onDragStart={() => onDragStart(imgId)}
                    onDragEnd={() => onDragEnd(null)}
                    onDrop={onDrop}
                    onDragLeave={onDragLeave}
                >
                    {!isVideo && onRotateImage && (
                        <aside className={css.cropper}>
                            <Cropper
                                ref={cropperRef}
                                key={onRotateImage.toString()}
                                src={image.attributes.variants['landscape-crop2x'].url}
                            />
                        </aside>
                    )}
                    <div className={`${css.threeToTwoWrapper} ${wrapperClassName}`}>
                        <div className={css.aspectWrapper} ref={cropperContainer}>
                            {onChangeImageDescription && (
                                <>
                                    <p
                                        className={classNames({
                                            [css.description]: true,
                                            [css.descriptionSmall]: !isFirst,
                                            [css.descriptionAbsent]: !image.description,
                                        })}
                                        onClick={() => setEditDescriptionModalOpen(true)}
                                    >
                                        {image.description && <EditIcon />}
                                        {image.description
                                            ? 'Beschreibung'
                                            : 'Beschreibung hinzufügen...'}
                                    </p>
                                </>
                            )}
                            {isVideo ? (
                                <SdkVideo
                                    fitAllowed={false}
                                    draggable
                                    entity={image}
                                    controls={videoControls}
                                />
                            ) : (
                                <ResponsiveImage
                                    rootClassName={css.rootForImage}
                                    image={image}
                                    alt={savedImageAltText}
                                    variants={['landscape-crop', 'landscape-crop2x']}
                                />
                            )}
                        </div>
                        {loading ? null : carouselControl || imagesControl}
                        {firtImageControl}
                    </div>
                </div>
                {imageCarouselOpen && (
                    <ModalPortal
                        id="AddImages.imageCarousel"
                        scrollLayerClassName={css.carouselModalScrollLayer}
                        containerClassName={css.carouselModalContainer}
                        contentClassName={css.modalContent}
                        lightCloseButton
                        closeButtonClassName={css.closeButtonClassName}
                        isOpen={imageCarouselOpen}
                        onClose={() => setImageCarouselOpen(false)}
                    >
                        <ImageCarousel
                            variants={['landscape-crop2x']}
                            selectedImageIndex={index}
                            images={allImages}
                        />
                    </ModalPortal>
                )}
                {!!removeImageModalOpen && (
                    <ConfirmActionModal
                        id="remove-image-confirmation"
                        isOpen={!!removeImageModalOpen}
                        onClose={() => setRemoveImageModalOpen(null)}
                        actionHandler={() => onRemoveImage(image.id)}
                        heading="AddImages.removeImageHeading"
                        description={`AddImages.removeDesc-${removeImageModalOpen}`}
                        action={`AddImages.removeAction-${removeImageModalOpen}`}
                    />
                )}
                {editDescriptionModalOpen && (
                    <EditDescriptionModal
                        isOpen={editDescriptionModalOpen}
                        image={image}
                        onClose={() => setEditDescriptionModalOpen(false)}
                        onChangeImageDescription={onChangeImageDescription}
                    />
                )}
            </>
        );
    }
};
